import axios from "axios";
import { API_ENDPOINT_URL } from "../../config";
import React, { useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as EditSVG } from "../../assets/icon/edit.svg";
import { ReactComponent as FavoriteSVG } from "../../assets/icon/favorites.svg";
import { ReactComponent as CommentSVG } from "../../assets/icon/message.svg";
import { ReactComponent as ShareSVG } from "../../assets/icon/share.svg";
import Web23Button from "../web23-button";
import { ContentState, convertToRaw } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import "./index.css";
import useToast from "../../utility/useToast";
import { SettingContext } from "../../context/context";
import parseToHTML from 'html-react-parser';
import EmojiPicker from 'emoji-picker-react';

const Web23TimeLineItem: React.FC<{
  category?: "audio" | "photo" | "video" | "article" | "NFT";
  content?: string;
  location?: string;
  copyright?: string;
  detail?: string;
  nftName?: string;
  nftDescription?: string;
  tokenId?: string;
  serialNumber?: string;
  price?: any;
  symbol?: string;
  likes?: string;
  nftImage?: string;
  isCreator?: boolean;
  commentNumber?: number;
  id?:string;
  domain?:string;
  walletName?:string;
  postStatus?:number;
}> = ({
  category,
  content,
  location,
  copyright,
  detail,
  nftName,
  nftDescription,
  serialNumber,
  symbol,
  price,
  likes,
  commentNumber,
  nftImage,
  tokenId,
  isCreator = false,
  id,
  domain,
  walletName,
  postStatus
}) => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingContext);
  const { ToasterBox, showToast } = useToast();
  const [likeStatus,setLikeStatus]=useState<boolean>(false);
  const [likeCount,setLikeCount]=useState<number>(0);
  const [wallComments,setWallComments]=useState<any>([]);
  const [emojiShow,setEmojiShow]=useState<boolean>(false);
  const [popup,setPopup]=useState<boolean>(false);
  const [postStatusCode,setPostStatusCode]=useState<number>(postStatus?postStatus:0);
  useEffect(()=>{
	
	if(id){
		getLikeStatus();
		getLikeCount();
		getWallComments();
	}
  },[id]);
  const getLikeStatus=async()=>{
	 if(settings && settings.userId && settings.userId.length>0){
		let loginUser:string=settings.userId;
		const response = await axios.post(API_ENDPOINT_URL + 'likes/getLikes', {
		  fromUserId:loginUser,
		  likeType:category,
		  likeToId:id
		});
		if(response.status===200){
			let data=response.data;
			if( data && data.data && data.data.likeToId!=undefined && data.data.likeToId===id){
				setLikeStatus(true);
			}
		}
	 }
  }
  const getLikeCount=async()=>{
	 
		const response = await axios.post(API_ENDPOINT_URL + 'likes/getLikeCount', {
		  likeType:category,
		  likeToId:id
		});
		if(response.status===200){
			if(response.data && response.data.data){
				setLikeCount(response.data.data[0]?response.data.data[0]?.count:0);
			}
		}
	
  }
  const getWallComments=async()=>{
	 
		const response = await axios.post(API_ENDPOINT_URL + 'comments/getWallComment', {
		  itemId:id,
		  itemType:category
		});
		if(response.status===200){
			if(response.data && response.data.data){
				setWallComments(response.data.data);
			}
		}
	
  }
  const hidePost=async()=>{
	const response = await axios.post(API_ENDPOINT_URL + 'post/hp', {
		  itemId:id,
		  itemType:category,
		  uid:settings.userId
	});
	if(response.status===200){
		if(response.data && response.data.data && response.data.data.status===9){
			setPostStatusCode(response.data.data.status);
		}
	}  
  }
  const unhidePost=async()=>{
	const response = await axios.post(API_ENDPOINT_URL + 'post/uhp', {
		  itemId:id,
		  itemType:category,
		  uid:settings.userId
	});
	if(response.status===200){
		if(response.data && response.data.data && response.data.data.status===0){
			setPostStatusCode(response.data.data.status);
		}
	}  
  }
  
  return (
    <div className="flex flex-col w-full pt-0 mt-5 bg-white border-1 rounded-lg border-slate-200 shadow-lg " id={id}>
	{category!="NFT" && isCreator && 
	  <div  className="flex flex-row w-full flex-row-reverse p-2">
		<div className='relative bg-[#efefef] text-black rounded-full text-center' style={{width:"48px", height:"48px",lineHeight:'48px'}}
		onClick={(e)=>{
			setPopup(!popup);
			
		}}
		>   
			{postStatusCode===0?
				<i className="bi bi-three-dots" ></i>
				:
				<i className="bi bi-eye-slash-fill"></i>
			}
			{popup && 
			<ul className='absolute right-0 z-[9999] shadow-lg bg-[#efefef] p-1 w-[250px] rounded'>
				<li>
				{postStatusCode===0?
					<div className='cursor-pointer'	onClick={(e)=>hidePost()}>Hide Post</div>
					:
					<div className='cursor-pointer'	onClick={(e)=>unhidePost()}>Show Post</div>
				}
				</li>
				
			 </ul>
			}
		</div>
	  </div>	
	}
	  {category === "video" ? (
        <video className="w-[600px] h-[330px]" controls autoPlay muted>
          <source
            src={`https://tokeman.mypinata.cloud/ipfs/${content}`}
            width="100%"
            height="100%"
          />
        </video>
      ) : category === "audio" ? (
        <video className="w-[600px] h-[170px]" controls autoPlay muted>
          <source
            src={`https://tokeman.mypinata.cloud/ipfs/${content}`}
            width="100%"
            height="100%"
          />
        </video>
      ) : category === "article" ? (
        <Editor
          editorState={EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(content ?? "").contentBlocks
            )
          )}
          editorClassName="timeline-editor"
          toolbarClassName="timeline-editor-toolbar"
        />
      ) : (
		<div className="w-full">
			<div className="w-full text-center">
			{nftImage?.indexOf("https://")===0?
			  <img src={nftImage}  className="w-full rounded-lg" />
			  :
			  <img src={`https://tokeman.mypinata.cloud/ipfs/${category === "NFT" ? nftImage : content}`}  className="w-full rounded-lg" />
			} 
			</div>
		</div>
      )}
	  <div className='p-6'>
	   {detail!="" && 
				<div className='py-4 text-lg text-black text-base'>
					{detail}
				</div>
		}
		{nftDescription!="" && 
				<div className='py-4 text-lg text-black text-base'>
					{nftDescription}
				</div>
		}
      <div>
        {category === "NFT" ? (
          <>
            <div className="flex items-center justify-between mt-7">
              <span className="text-xl font-bold text-grey-900">
                {nftName} 
				{(walletName==="MetaMask" || walletName==="Solana")?'':('#'+serialNumber)}
              </span>
              <span className="text-xl font-bold text-grey-900">{price?.price}{price?.currency}</span>
            </div>
            <div className="flex items-center justify-between mt-1">
              <span className="text-base font-medium text-grey-700">
                {symbol}
              </span>
              <span className="font-mediaum text-bae text-grey-700">Price</span>
            </div>
          </>
        ) : (
          <div className="flex items-center justify-between">
            <span className="text-base font-bold text-grey-700">
              {location}
            </span>
            <span className="text-base font-bold text-grey-700">
              {copyright}
            </span>
          </div>
        )}
        <hr className="w-full" />
        <div className="mt-[20px] flex items-center justify-between">
          <div className="flex items-center gap-7">
			<div onClick={async (e)=>{
					if(settings && settings.userId && settings.userId.length>0){
						let loginUser:string=settings.userId;
						const response = await axios.post(API_ENDPOINT_URL + `likes/setLikes`, {
						  fromUserId:loginUser,
						  likeType:category,
						  likeToId:id
						});
						if(response.status===200)
							if(response.data.data.remove) setLikeStatus(false);
							else setLikeStatus(true);
					}
					else showToast("Kindly Login to like!");
				

			}}>
			{ likeStatus===true?
			
				<i  className="bi bi-heart-fill text-3xl cursor-pointer text-red-300"></i>
			:
				<i  className="bi bi-heart text-3xl cursor-pointer text-black"></i>
			}
			</div>	
			
            <div onClick={(e)=>{
				
				let _domain=domain?.replace(/\./,"");
				let shareLink=window.location.protocol+"//"+window.location.host+"/"+_domain+"/"+tokenId+"/"+serialNumber+"/visitor";
				showToast("Copied shared link");
				navigator.clipboard.writeText(shareLink);
				//(window as any).open("https://web.whatsapp.com://send?text="+encodeURIComponent(shareLink));
			}}
			>
				<ShareSVG className="cursor-pointer" />
			</div>
          </div>
          <div className="w-[108px]">
            {
              category === 'NFT' && (
                <Web23Button
                text={`${isCreator ? "Manage": "Buy Now"}`}
                variant="primary"
                onClick={() => {
                  navigate(
                    isCreator ? window.location.pathname + "/" + tokenId + "/" + serialNumber + "/creator" : window.location.pathname + "/" + tokenId + "/" + serialNumber + "/visitor"
                  );
                }}
              />  
              )
            }
          </div>
        </div>
        <p className="mt-3 text-base font-bold text-grey-900">{likeCount} likes</p>
        <div className="mt-3 text-base font-bold text-grey-900 w-full">
			<textarea className='w-full overflow-hidden resize-y px-4 py-2 text-xl border-slate-300 border rounded-xl' id={'_wc'+id}
				onKeyDown={e=>{
						if(e.ctrlKey && (e.keyCode === 13 || e.keyCode === 10)) {
							
						}
						else if(e.shiftKey && (e.keyCode === 13 || e.keyCode === 10)) {
							
						}
						else if(e.keyCode === 13 || e.keyCode === 10){
							e.preventDefault();
							//alert("enter");
						}
						//let content:any=(window as any).document.getElementById('_wc'+id).value;
						
					}}	
					
			></textarea>
			<div className='w-full flex flex-row'>
				<div className='flex-none'>
					<button className='flex-none px-2 py-1 border-0 text-2xl'
						onClick={() => {
							setEmojiShow(!emojiShow);
						}}
					>&#x1F610;</button>
				{emojiShow && 
					<div className='absolute z-50'>
					<EmojiPicker onEmojiClick={(emojiObject)=>{
						//setChosenEmoji(emojiObject);
						//alert(emojiObject.emoji);
						(window as any).document.getElementById('_wc'+id).value=(window as any).document.getElementById('_wc'+id).value+" "+emojiObject.emoji;
					}}
					/>
					</div>
				}
				</div>
				<div className='grow'>{id}</div>
				<button className='flex-none px-8 py-1 border-slate-300 border rounded-xl'
				 onClick={async() => {
					 let content:any=(window as any).document.getElementById('_wc'+id).value;
					 if(settings && settings.userId && settings.userId.length>0 && content.trim().length>0){
							let loginUser:string=settings.userId;
							const response = await axios.post(API_ENDPOINT_URL + `comments/setWallComment`, {
							  userId:loginUser,
							  itemType:category,
							  itemId:id,
							  content:content
							});
							if(response.status===200)
							{
								let currentWall={content:response.data.data.content,
												created:response.data.data.created,
												displayName:settings.displayName,
												domainName:settings.domain,
												itemId:response.data.data.itemId,
												itemType:response.data.data.itemType,
												_id:response.data.data._id
								};
								let tmpWalls=wallComments;
								setWallComments([currentWall, ...tmpWalls]);
								(window as any).document.getElementById('_wc'+id).value="";
							}
						}
						else showToast("Kindly Login to like!");
				 }}
				 
				> Post </button>
			</div>
		</div>
        <a
          href="#"
          className="mt-2 text-base font-bold underline text-grey-600 py-4 block"
        >
          View all {commentNumber} comments
        </a>
		<div className="py-4">
			{ wallComments && wallComments.length>0 && 
			  wallComments.map((item:any,ind:any)=>{
					return(
							<>
								<div className='flex mt-4'>
									<img src='https://tokeman.mypinata.cloud/ipfs/QmcXSeYnimkHuCj7mHJiSFDA4SqttAYdCxBHtKafz4jhMd' className='border rounded-full' style={{ width:"48px",height:"48px"}}/>
									<div className='flex flex-col px-4 text-base w-full'>
										<div className="font-bold">{item.displayName}</div>
										<div className="mb-2 text-gray-800">@{item.domainName}</div>
										<div className='text-black-200'>{parseToHTML(item.content)}</div>
										<div className='text-gray-800 py-4 text-sm text-right w-full'>{item.created}</div>
									</div>	
								</div>
							</>
					);
			  })
			}
		</div>
    </div>
	</div>
    </div>
  );
};

export default Web23TimeLineItem;
