import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router";
import {
  Web23Button,
  Web23Card,
  Web23Input,
  Web23NftPreview,
  Web23Spinner,
} from "../components";
import DateTimePicker from "react-datetime-picker";

import { useImageUpload } from "../hooks/image_upload";

import { pinFileToIPFS, pinJsonToIPFS } from "../utility/ipfs";
import connectWallet from "../utility/connect-wallet";

import { ModalProcessingContext } from "../context/context";
import { SettingContext } from "../context/context";
import { useHashconnectService } from "../context/Hashconnect";
import { createNFT, mintNFT } from "../utility/hashgraph-transaction";
import { createEthNFT } from "../utility/metamask-transaction";

import { API_ENDPOINT_URL } from "../config";

import { ReactComponent as ArrowLeftSVG } from "../assets/icon/ArrowLeft.svg";

import { ReactComponent as AuctionSVG } from "../assets/icon/Bid.svg";

import { ReactComponent as CheckCircleSVG } from "../assets/icon/check_circle.svg";
import { ReactComponent as CloseIcon } from "../assets/icon/close.svg";
import { ReactComponent as ContentCopySVG } from "../assets/icon/content_copy.svg";
import { ReactComponent as DateRangeSVG } from "../assets/icon/date_range.svg";
import { ReactComponent as AvalancheWalletSVG } from "../assets/icon/Avalanche.svg";
import { ReactComponent as SolanaWalletSVG } from "../assets/icon/Solana.svg";
import { ReactComponent as MetaMaskSVG } from "../assets/icon/Metamask.svg";
import { ReactComponent as BladeWalletSVG } from "../assets/icon/Blade1.svg";
import { ReactComponent as HashPackSVG } from "../assets/icon/HashPack.svg";
import { ReactComponent as HederaSVG } from "../assets/icon/Hedera.svg";
import { ReactComponent as CircleCheckSVG } from "../assets/icon/ic_check_circle.svg";
import { ReactComponent as ArrowDownSVG } from "../assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as ArrowUpSVG } from "../assets/icon/keyboard_arrow_up.svg";
import { ReactComponent as LinkOffSVG } from "../assets/icon/link_off.svg";
import { ReactComponent as FixedPriceSVG } from "../assets/icon/local_offer.svg";

import { ReactComponent as RainbowSVG } from "../assets/icon/Rainbow.svg";

import { ReactComponent as NotForSaleSVG } from "../assets/icon/spa.svg";
import { ReactComponent as DeleteSVG} from "../assets/icon/delete.svg";

import EmptyIamgeLogo from "../assets/img/emptyImg.png";

const defaultProgressText = [
  "Choose a Blockchain",
  "Choose a File",
  "Add Details",
  "Pricing Type",
];

const hederaWallets = [
  {
    name: "Blade",
    icon: <BladeWalletSVG />,
  },
  { name: "Hashpack", icon: <HashPackSVG /> },
];
const ethereumWallets = [
  { name: "Metamask", icon: <MetaMaskSVG /> },
  { name: "Rainbow", icon: <RainbowSVG /> },
];
const solanaWallets = [
  { name: "Phantom", icon: <SolanaWalletSVG /> }
];
const walletDetail = [
  {
    type: "Hedera",
    detail: hederaWallets,
  },
  {
    type: "Ethereum",
    detail: ethereumWallets,
  },
  {
    type: "Solana",
    detail: solanaWallets,
  },
  {
    type: "Polygon",
    detail: [],
  },
  {
    type: "Solana",
    detail: [],
  },
];

const chainType = [
  { name: "Blade", icon: <BladeWalletSVG /> },
  { name: "Hashpack", icon: <HashPackSVG /> },
  { name: "MetaMask", icon: <MetaMaskSVG /> },
  { name: "Solana", icon: <SolanaWalletSVG /> },
  { name: "Avalanche", icon: <AvalancheWalletSVG /> },
];

const chainIds={
				"0x0":{name:""},
				"0x1":{name:"Etherium Mainnet"},
				"0x5":{name:"Goreli Testnet"}
};
type TCIDs = keyof typeof chainIds;
const CreatingNftModal: React.FC<{
  final?: boolean;
  effectFunc?: () => void;
}> = ({ effectFunc }) => {
  const [progress, setProgress] = useState<number>(0);
  const [showConnectWallet, setShowConnectWallet] = useState<boolean>(false);
  const [walletTab, setWalletTab] = useState<number>(0);
  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const [connectedWallet, setConnectedWallet] = useState<
    {
      name: string;
      address: string;
      icon: React.ReactNode;
	  symbol:string;
	  chainId:string;
	  chainName:any;
    }[]
  >([]);
  
  const [preview, setPreview, selectedFile, setSelectedFile, onSelectFile] =
    useImageUpload();

  const [chooseSupply, setChooseSupply] = useState<boolean>(false);
  const [aboutNft, setAboutNft] = useState<string>();
  const [showAddMoreDetail, setShowAddMoreDetail] = useState<boolean>(false);
  const [showAddProperties, setShowAddProperties] = useState<boolean>(false);
  const [fixedType, setFixedType] = useState<boolean>(false);
  const [auctionType, setAuctionType] = useState<boolean>(false);
  const [notSaleType, setNotSaleType] = useState<boolean>(false);

  const [nftName, setNftName] = useState<string>();
  const [supplyType, setSupplyType] = useState<string>("Unique");
  const [supplyNumber, setSupplyNumber] = useState<string>("1");
  const [chooseCollection, setChooseCollection] = useState<string>("");
  const [externalLink, setExternalLink] = useState<string>("");
  const [alternativeText, setAlternativeText] = useState<string>("");
  const [contentUnlock, setContentUnlock] = useState<string>("");
  const [propertyName, setPropertyName] = useState<string>("");
  const [propertyValue, setPropertyValue] = useState<string>("");
  const [fixedPrice, setFixedPrice] = useState<string>("");
  const [auctionPrice, setAuctionPrice] = useState<string>("");
  const [royalty, setRoyalty] = useState<string>("0");
  const [auctionRoyalty, setAuctionRoyalty] = useState<string>("");
  const [auctionStTime, setAuctionStTime] = useState<Date>(new Date());
  const [auctionEdTime, setAuctionEdTime] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);

  const { settings, saveSettings } = useContext(SettingContext);
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  
  const [attributes, setAttributes] = useState<{ name: string; value: string }[]>([{ name: "", value: "" }]);
  const [editAttributes, setEditAttributes] = useState<{ name: string; value: string }[]>([{ name: "", value: "" }]);
  
  const connectMetamask= async()=> {
   //to get around type checking
   (window as any).ethereum
     .request({
         method: "eth_requestAccounts",
		 params: [{ chainId: "0x5" }]
     })
     .then((accounts : string[]) => {
		 let cId:string=(window as any).ethereum.chainId;
		 if((window as any).ethereum.chainId=='0x5'){
			  setWalletConnecting(false);
			   setConnectedWallet(
				 [
				  {
					name: "MetaMask",
					address: accounts[0],
					icon: <MetaMaskSVG />,
					symbol:"ETH",
					chainId:cId,
					chainName:chainIds[cId as TCIDs].name
				  }
				]
			  )
		 } else alert("Kindly Switch to Testnet Goreli Network");
     })
     .catch((error: any) => {
         alert(`Something went wrong: ${error}`);
     });
 }

const getProvider = async () => {
    if ("solana" in (window as any)) {
		  const provider = (window as any).solana;
		  if (provider.isPhantom) {
			const resp = await provider.connect();
			let cId:string='0x0';
			alert(resp.publicKey.toString());
			   setConnectedWallet(
				 [
				  {
					name: "Solana",
					address: resp.publicKey.toString(),
					icon: <SolanaWalletSVG />,
					symbol:"SOL",
					chainId:cId,
					chainName:chainIds[cId as TCIDs].name
				  }
				]
			  )
		  }
    } else {
        window.open("https://www.phantom.app/", "_blank");
    }
};

  const {
    pairingData,
    availableExtension,
    connectToExtension,
    disconnect,
    getProviderAndSigner,
    publicKey,
  } = useHashconnectService();

  const navigate = useNavigate();
  const handleConnectWallet = async (
    walletName: string,
    icon: React.ReactNode
  ) => {
    if (walletName === "MetaMask") {
      await connectMetamask();
      setWalletConnecting(true);
      setShowConnectWallet(true);
      return;
    }
    else if (walletName === "Solana") {
      
      await getProvider();
      setShowConnectWallet(true);
      return;
    }
    else if (walletName !== "Hashpack") {
      alert("Support only hashpack now!");
      return;
    }
    else{
    if(availableExtension && !pairingData) {
      setWalletConnecting(true);
      setShowConnectWallet(true);

      connectToExtension();
    } else if (pairingData) disconnect();
    else {
      alert("Install the Hashpack chrome extension.");
    }}
  };

  const handleCreateNft = async () => {
	  
    
    let mintedNFT=null;
    let createdNFT=null;
	let chainName="Hashpack";
	let walletAddress=null;
	let currency="HBAR";
	try {
		setLoading(true);
		  setModalProcess({
			...modalProcess,
			finalizaingNftCreation: true,
			finishedNftCreation: false,
			isCreatingNFT: false,
		  });
		if(connectedWallet[0].name==="MetaMask"){
			chainName="MetaMask";
			currency="ETH";
			walletAddress=connectedWallet[0].address;
		}
        else{
		  chainName="Hashpack";
		  currency="HBAR";
		  walletAddress=pairingData?.accountIds[0] ?? "";
		  
		}
		const imgHash = await pinFileToIPFS(selectedFile);
		const metadata = {
							name: nftName ?? "",
							description: aboutNft ?? "",
							image: imgHash.IpfsHash ?? "",
							supply: supplyNumber ?? "",
							detail: {
							  collection: chooseCollection ?? "",
							  externalLinks: externalLink ?? "",
							  altText: alternativeText ?? "",
							},
							price: {
							  priceType: fixedType
								? "Fixed"
								: auctionType
								? "Auction"
								: "Not For Sale",
							  price: fixedType ? fixedPrice : auctionType ? auctionPrice : Infinity,
							  royalty: fixedType ? royalty : auctionRoyalty,
							  currency: currency,
							  auctionStart: fixedType ? "" : auctionStTime.toString(),
							  auctionEnd: fixedType ? "" : auctionEdTime.toString(),
							},
							property: [...attributes],
							additional: {},
						};
		const metadataHash = await pinJsonToIPFS(metadata);
		const baseURL = `${API_ENDPOINT_URL}` + "nft/create";
		const response = await axios.post(baseURL, {
													user_id: settings.userId,
													chainName: chainName,
													walletAddress: walletAddress,
													ipfsHash: metadataHash.IpfsHash,
													metadata: metadata,
													tokenId: "0.0.0",
													serialNumber: "-1",
												  });
	  
      if(response.status === 200) {
			saveSettings({
							...settings,
							nft: {
							chainName: chainName,
							nftName: nftName ?? "",
							nftImage: preview ?? "",
							tokenId: response?.data?.data?.tokenId ?? "",
							serialNumber: response?.data?.data?.serialNumber,
							supply: Number.parseInt(supplyNumber, 10) ?? 0,
							nftDescription: aboutNft ?? "",
							details: {
							  collection: chooseCollection,
							  externalLinks: externalLink,
							  altText: alternativeText,
							},
							price: {
							  priceType: fixedType
								? "Fixed"
								: auctionType
								? "Auction"
								: "Not For Sale",
							  price: fixedType
								? parseFloat(fixedPrice)
								: auctionType
								? parseFloat(auctionPrice)
								: Infinity,
							  royalty: fixedType
								? parseFloat(royalty)
								: parseFloat(auctionRoyalty),
							  currency: currency,
							  auctionStart: fixedType ? "" : auctionStTime.toString(),
							  auctionEnd: fixedType ? "" : auctionEdTime.toString(),
							},
							additional: {
							  watchNumber: 0,
							  favorites: 0,
							  messagesNumber: 0,
							},
						  },
					});
      }// if
      setModalProcess({
        ...modalProcess,
        finalizaingNftCreation: true,
        finishedNftCreation: true,
        isCreatingNFT: false,
      });
      //navigate("/" + settings.domain.split(".").join(""));
	  navigate(0);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setModalProcess({
        ...modalProcess,
        finalizaingNftCreation: true,
        finishedNftCreation: true,
        isCreatingNFT: false,
      });
	  setLoading(false);
    }
  };

  useEffect(() => {
    if (pairingData) {
      setWalletConnecting(false);
	    let cId:string='0x0';
		setConnectedWallet((prev) => {
        return [
          {
            name: "Hashpack",
            address: pairingData?.accountIds?.[0],
            icon: <HashPackSVG />,
			symbol:"HBAR",
			chainId:cId,
			chainName:chainIds[cId as TCIDs].name
          },
        ];
      });
    }
  }, [pairingData]);

  return (
    <div className="flex h-full w-full flex-col rounded-2xl border border-black bg-white p-6 transition-all duration-150">
      <div className="flex justify-between gap-4">
        <div
          className="mb-4 flex cursor-pointer items-center gap-[17.34px] align-top text-sm font-bold text-grey-600 active:fill-grey-900 active:text-grey-900"
          onClick={() => {
            setShowConnectWallet(false);
            if (progress) setProgress(progress - 1);
          }}
        >
          <ArrowLeftSVG />
          <p className="text-3xl font-bold text-grey-900">Create NFT</p>
        </div>
        <div className="flex justify-center gap-[20px] py-4">
          {defaultProgressText.map((item, index) => (
            <div
              key={`${index} + ${item}`}
              className="flex flex-col items-center justify-center"
            >
              <p
                className={`relative flex h-6 w-6 items-center justify-center rounded-full text-center text-base font-medium text-white before:absolute before:top-[10px] before:left-[22px] before:h-[2px] before:w-[30px] after:absolute after:top-[10px] after:-left-[32px] after:h-[2px] after:w-[30px] after:bg-black ${
                  progress >= index ? "bg-green-700" : "bg-grey-300"
                } ${
                  progress >= index
                    ? "before:bg-green-700 after:bg-green-700"
                    : "before:bg-grey-300 after:bg-grey-300"
                } ${
                  index === 3 ? "before:content-none" : 'before:content-[""]'
                } ${index === 0 ? "after:content-none" : 'after:content-[""]'}`}
              >
                {index < progress ? <CircleCheckSVG /> : index + 1}
              </p>
              <p className="mt-2 w-[64px] text-center text-sm font-medium text-black">
                {item}
              </p>
            </div>
          ))}
        </div>
        <div
          className="cursor-pointer px-2 pt-4 duration-150"
          onClick={() => {
            setProgress(0);
            setModalProcess({
              ...modalProcess,
              isCreatingNFT: false,
            });
          }}
        >
          <CloseIcon className="rounded-full duration-150 hover:bg-grey-300" />
        </div>
      </div>
      {/* <hr className='border-grey-800 border-opacity-40' /> */}
      {progress === 0 && !showConnectWallet && (
        <div className="flex h-full flex-col justify-between">
          <div className="mt-6 pr-[446px]">
            <h6 className="mb-2 text-[24px] font-bold text-grey-900">
              1. Choose a Blockchain
            </h6>
            <p className="mb-6 whitespace-nowrap text-base font-medium text-grey-600">
              Choose your favorite Blockchain to post the NFT
            </p>
            <div className="mt-6 flex flex-col gap-3 pb-[260px]">
              {chainType?.map((item, index) => (
                <div
                  key={item.name + index}
                  className="flex cursor-pointer items-center justify-between rounded-[32px] border border-grey-900 p-3 active:bg-grey-100"
                  onClick={() => {
                    handleConnectWallet(item.name, item.icon);
                  }}
                  // onClick={() => {
                  //   setWalletConnecting(true);
                  //   setShowConnectWallet(true);
                  //   setTimeout(() => {
                  //     setWalletConnecting(false);
                  //     setConnectedWallet({
                  //       name: 'Hedera',
                  //       addres: '0x6a218A95...94F09',
                  //     });
                  //   }, 3000);
                  // }}
                >
                  <div className="flex items-center gap-3">
                    {item.icon}
                    <p className="py-1 text-base font-bold text-grey-900">
                      {item.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {progress === 0 && showConnectWallet && (
        <div className="h-full">
          {!walletConnecting && !connectedWallet?.length && (
            <div>
              <div
                className="mb-4 mt-6 flex cursor-pointer items-center gap-1 p-1 text-sm font-bold text-grey-600 active:fill-grey-900 active:text-grey-900"
                onClick={() => {
                  setShowConnectWallet(false);
                }}
              >
                <ArrowLeftSVG />
                <p className="underline">Connect Wallet</p>
              </div>
              <div>
                <h6 className="mb-2 text-[32px] font-bold text-grey-900">
                  Link/Create Wallet
                </h6>
                <p className="mb-6 text-base font-medium text-grey-600">
                  Choose your wallet connection. However, we only support Hedera
                  blockchain for now. More options will be available soon.
                </p>
                <div className="flex justify-center">
                  {walletDetail.map((walletItem, index) => (
                    <div
                      key={walletItem.type + index}
                      className={`cursor-pointer  border-b p-4 pt-2 text-xl font-bold text-grey-600 ${
                        walletTab === index
                          ? "border-grey-900"
                          : "border-grey-100"
                      }`}
                      onClick={() => setWalletTab(index)}
                    >
                      {walletItem.type}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {walletConnecting && (
            <div className="flex h-full flex-col justify-center">
              <div className="flex items-center justify-center">
                <div
                  className="inline-block h-[75px] w-[75px] animate-spin rounded-full border-4 border-green-500 border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
              <p className="my-2 text-center text-base font-medium text-grey-600">
                Communicating with wallet. Sign in with your wallet.
              </p>
              <div className="flex justify-center">
                <div className="w-[148px]">
                  <Web23Button
                    text="Disconnect"
                    variant="secondary"
                    onClick={() => disconnect()}
                  />
                </div>
              </div>
            </div>
          )}
          {!walletConnecting && connectedWallet?.length && (
            <div className="flex h-full flex-col justify-between">
              <div>
                <p className="mb-2 text-base font-medium text-grey-600">
                  Connected wallets
                </p>
                <div className="rounded-3xl border border-grey-900 p-4 mb-4">
                  {connectedWallet?.map((item) => {
                    return (
                      <div className="flex items-center justify-between gap-2">
                        <div
                          key={item.name + item.address}
                          className="flex items-center gap-3"
                        >
                          {/* <BladeWalletMDSVG /> */}
                          {item.icon}
                          <div>
                            <p className="mb-1 text-base font-medium text-gray-600">
                              {item.name}  <span className='text-sm text-slate-600'>{item.chainName}</span>
                            </p>
                            <p className="text-base font-bold text-grey-900">
                              {item.address}
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-3">
                          <div className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full bg-grey-100 duration-150 hover:bg-grey-300">
                            <ContentCopySVG className="fill-grey-900" />
                          </div>
                          <div className="flex h-[44px] w-[44px] cursor-pointer items-center justify-center rounded-full bg-grey-100 duration-150 hover:bg-grey-300">
                            <LinkOffSVG className="fill-grey-900" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                
              </div>
              <Web23Button
                text="Continue"
                onClick={() => {
                  setProgress((prev) => prev + 1);
                }}
              />
            </div>
          )}
        </div>
      )}
      {progress === 1 && (
        <div className="flex h-full flex-col justify-between">
          <div className="mt-6">
            <h6 className="mb-2 text-[24px] font-bold text-grey-900">
              2. Choose a File
            </h6>
            <p className="mb-6 whitespace-nowrap text-base font-medium text-grey-600">
              File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
              OGG, GLB, GLTF. Max size: 100 MB
            </p>

            <Web23Card
              className="m-auto pt-[78px] pb-[104px]"
              variant={selectedFile ? "secondary" : "primary"}
              img={
                selectedFile ? (
                  <div
                    className="relative h-[276px] w-[276px] rounded-md border-2 bg-cover"
                    style={{ backgroundColor: "white" }}
                  >
                    <div
                      className="absolute top-4 left-4 h-[240px] w-[240px] bg-cover"
                      style={{ backgroundImage: `url(${preview})` }}
                    ></div>

                    <div
                      className="absolute top-0 left-[calc(100%+12px)] cursor-pointer rounded-full p-2 duration-150 hover:bg-grey-100"
                      onClick={() => {
                        setPreview(undefined);
                        setSelectedFile(undefined);
                      }}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                ) : (
                  <img src={EmptyIamgeLogo} alt="img" />
                )
              }
              button={
                selectedFile ? (
                  <Web23Button
                    variant="primary"
                    text="Continue"
                    onClick={() => setProgress(2)}
                  />
                ) : (
                  <Web23Button
                    variant="primary"
                    text="Upload"
                    onClick={() =>
                      document.getElementById("upload_img")?.click()
                    }
                  />
                )
              }
            />
            <input
              type="file"
              className="hidden"
              id="upload_img"
              onChange={onSelectFile}
            />
          </div>
        </div>
      )}
      {progress === 2 && (
        <div className="flex h-full flex-col justify-between">
          <div className="mt-6">
            <h6 className="mb-2 text-[24px] font-bold text-grey-900">
              3. Add Details
            </h6>
            <p className="mb-6 whitespace-nowrap text-base font-medium text-grey-600">
              File types supported: JPG, PNG, GIF, SVG, MP4, WEBM, MP3, WAV,
              OGG, GLB, GLTF. Max size: 100 MB
            </p>

            <div className="flex items-start gap-4">
              <div className="flex flex-col items-center">
                <div
                  className="relative h-[276px] w-[276px] rounded-md border-2 bg-cover"
                  style={{ backgroundColor: "white" }}
                >
                  <div
                    className="absolute top-4 left-4 h-[240px] w-[240px] bg-cover"
                    style={{ backgroundImage: `url(${preview})` }}
                  ></div>
                </div>
                <div className="mt-2">
                  <span className="text-center text-base font-medium text-grey-400">
                    NFT Preview
                  </span>
                </div>
              </div>

              <div className="flex w-full flex-col">
                {!(showAddMoreDetail || showAddProperties) ? (
                  <>
                    <Web23Input
                      placeholder="Enter NFT Name"
                      className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                      value={nftName}
                      onChange={(e) => setNftName(e.target.value)}
                    />
                    <div className="mt-3">
                      {
                        <>
					
                          {
                          supplyType === "Multiple" ? (
                            <div className="flex gap-2">
                              <Web23Input
                                placeholder="Choose Supply"
                                validate={true}
                                value={supplyType}
                                onChange={() => setSupplyType("Multiple")}
                                className="hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                              >
                                <ArrowDownSVG
                                  onClick={() => {
                                    setSupplyNumber("1");
                                    setChooseSupply((prev) => !prev)
                                }}
                                />
                              </Web23Input>
                              <Web23Input
                                placeholder="Number of Copies"
                                value={supplyNumber}
                                onChange={(e) => setSupplyNumber(e.target.value)}
                                className="hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                              />
                            </div>
                          ) : (
                          <Web23Input
                            placeholder="Choose Supply"
                            className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                            value={supplyType}
                            onChange={(e)=> setSupplyType(supplyType)}
                            validate={true}
							
                          >
						  {/*<ArrowDownSVG
                            onClick={() => {
                              setSupplyNumber("1");
                              setChooseSupply((prev) => !prev)
                            }}                              
						  />**/}
                          </Web23Input>
                            ) 
                          }
                          {
                            chooseSupply && (
                              <div className={`${supplyType !== 'Multiple' ? "ml-auto mr-6 w-[calc(95%-32px)]": "mr-auto ml-6 w-[calc(45%-32px)]"} rounded-b-[1px] rounded-bl-[12px] 
                                rounded-br-[12px] flex flex-col items-center border-grey-300 border-[1px] border-t-0 overflow-hidden`}>
                                <span 
                                  className="py-2 cursor-pointer font-bold text-base text-grey-900 hover:bg-grey-300 w-full 
                                  text-center border-grey-300 border-b-[1px]" 
                                  onClick={() => {
                                    setSupplyType("Unique")
                                    setChooseSupply(prev => !prev);
                                  }}>
                                  Unique
                                </span>
                                <span 
                                  className="py-2 cursor-pointer font-bold text-base text-grey-900
                                   hover:bg-grey-300 w-full text-center overflow-hidden"
                                  onClick={() => {
                                    setSupplyType("Multiple")
                                    setChooseSupply(prev => !prev)
                                  }}   
                                >
                                    Multiple
                                </span>
                              </div>
                            )
                          }
                        </>
                      }

                    </div>
                    <div className="relative">
                      <textarea
                        id="aboutNft"
                        className="cols-4 relative mt-3  w-full rounded-[32px] border-2 border-grey-200 p-6 text-base font-bold text-grey-400 hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                        value={aboutNft}
                        onChange={(e) => setAboutNft(e.target.value)}
                      ></textarea>
                      <label
                        id="aboutNft"
                        className={`${
                          aboutNft?.length
                            ? "absolute top-[18px] left-[20px] text-sm font-bold text-grey-300"
                            : "absolute top-[30px] left-[30px] text-base font-bold text-grey-400"
                        } duration-150`}
                      >
                        Tell People about your NFT...
                      </label>
                    </div>
                    <div className="mt-2 flex cursor-pointer items-center justify-between rounded-[32px] border border-grey-900 p-3 active:bg-grey-100">
                      <div>
			{connectedWallet?.map((item) => {
                       return( 
			<div className="flex items-center gap-3">
                          {item.icon}
                          <p className="py-1 text-base font-bold text-grey-900">
                          {item.name}
                          </p>
			
                        </div>
			)})}
                      </div>
                      <p className="mr-4 text-base font-bold text-grey-600">
                        Wallet
                      </p>
                    </div>
                    <div
                      className="mt-3 flex items-center gap-2"
                      onClick={() => setShowAddMoreDetail((prev) => !prev)}
                    >
                      <span className="text-base font-bold text-grey-900">
                        Add more details(Optional)
                      </span>
                      {!showAddMoreDetail && <ArrowDownSVG />}
                      {showAddMoreDetail && <ArrowUpSVG />}
                    </div>
                    <div
                      className="mt-3 flex items-center gap-2"
                      onClick={() => setShowAddProperties((prev) => !prev)}
                    >
                      <span className="text-base font-bold text-grey-900">
                        Add Properties (Optional)
                      </span>
                      {!showAddProperties && <ArrowDownSVG />}
                      {showAddProperties && <ArrowUpSVG />}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="mt-3 flex items-center gap-2"
                      onClick={() => setShowAddMoreDetail((prev) => !prev)}
                    >
                      <span className="text-base font-bold text-grey-900">
                        Add more details(Optional)
                      </span>
                      {!showAddMoreDetail && <ArrowDownSVG />}
                      {showAddMoreDetail && <ArrowUpSVG />}
                    </div>
                    {showAddMoreDetail && (
                      <div>
                        <div className="pt-3">
                          <Web23Input
                            placeholder="Choose Collection"
                            className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                            value={chooseCollection}
                            onChange={(e) =>
                              setChooseCollection(e.target.value)
                            }
                          />
                        </div>
                        <div className="pt-3">
                          <Web23Input
                            placeholder="Enter External Link"
                            className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                            value={externalLink}
                            onChange={(e) => setExternalLink(e.target.value)}
                          />
                        </div>
                        <div className="pt-3">
                          <Web23Input
                            placeholder="Alternative Text"
                            className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                            value={alternativeText}
                            onChange={(e) => setAlternativeText(e.target.value)}
                          />
                        </div>
                        <div className="flex items-center gap-3 pt-3">
                          <input
                            type="checkbox"
                            value={contentUnlock}
                            onChange={(e) => setContentUnlock(e.target.value)}
                          />
                          <span className="text-base font-bold text-grey-700">
                            Unlock Content once purchased
                          </span>
                        </div>
                      </div>
                    )}
                    <div
                      className="mt-3 flex items-center gap-2"
                      onClick={() => {
                          setEditAttributes(attributes);
                          setShowAddProperties(prev => !prev);          
                      }}
                    >
                      <span className="text-base font-bold text-grey-900">
                        Add Properties (Optional)
                      </span>
                      {!showAddProperties && <ArrowDownSVG />}
                      {showAddProperties && <ArrowUpSVG />}
                    </div>
                    {
                      // showAddProperties && (
                      // <div className="flex items-center gap-2 pt-3">
                      //   <Web23Input
                      //     placeholder="Property 1"
                      //     className="hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                      //     value={propertyName}
                      //     onChange={(e) => setPropertyName(e.target.value)}
                      //   />
                      //   <Web23Input
                      //     placeholder="Property 1 %"
                      //     className="hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                      //     value={propertyValue}
                      //     onChange={(e) => setPropertyValue(e.target.value)}
                      //   />
                      // </div>
                      // )
                      <div className="max-h-[370px]">
                        {
                          <div className="max-h-[300px] max-overflow-auto">
                            {
                            editAttributes.map((item, index) => (
                              <div className="py-4 my-4 bg-grey-50 rounded-xl" key={`Attributes_${index}`}>
                                <div className="flex items-center justify-between px-5 pb-3">
                                  <p className="text-sm font-bold text-grey-900">
                                    Attribute {index + 1}
                                  </p>
                                  <div
                                    className="fill-red-500 active:fill-red-800"
                                    onClick={() => {
                                      setEditAttributes((prev) =>
                                        prev.filter((_, idx) => idx === 0 || index !== idx)
                                      );
                                    }}
                                  >
                                    <DeleteSVG />
                                  </div>
                                </div>
                                <div className="flex gap-4 px-3">
                                  <div>
                                    <Web23Input
                                      placeholder="Name"
                                      value={editAttributes[index].name}
                                      onChange={(e) => {
                                        const newAttributes = [...editAttributes];
                                        newAttributes[index].name = e.target.value;
                                        setEditAttributes(newAttributes);
                                      }}
                                    />
                                  </div>
                                  <Web23Input
                                    placeholder="Value"
                                    value={editAttributes[index].value}
                                    onChange={(e) => {
                                      const newAttributes = [...editAttributes];
                                      newAttributes[index].value = e.target.value;
                                      setEditAttributes(newAttributes);
                                    }}
                                  />
                                </div>
                              </div>
                            ))
                            }
                          </div>
                        }
                      
                        <div className="flex item-center gap-4 mb-8">
                          <Web23Button
                            text="Add another"
                            size="sm"
                            onClick={() =>
                              setEditAttributes((prev) => prev.concat({ name: "", value: "" }))
                            }
                          />
                          <Web23Button
                            text="Save"
                            size="sm"
                            onClick={() => {
                              const resAttribute = editAttributes.filter(
                                (item) => item.name !== "" && item.value !== ""
                              );
                              setAttributes(resAttribute);
                              setShowAddProperties(false);
                            }}
                          />
                        </div>
                      </div>
                    }
                  </>
                )}

                <Web23Button
                  text="Continue"
                  className="mt-12 w-full"
                  onClick={() => setProgress((prev) => prev + 1)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {progress === 3 && (
        <div className="flex h-full flex-col justify-between">
          <div className="mt-6">
            <h6 className="mb-2 text-[24px] font-bold text-grey-900">
              4. Pricing Type
            </h6>
            <p className="mb-6 whitespace-nowrap text-base font-medium text-grey-600">
              How do you want to sell your NFT?{" "}
            </p>
          </div>

          <div className="mb-6 flex justify-center gap-6 pr-[134px]">
            <div
              className={`${
                fixedType
                  ? "border-2 border-green-900 bg-green-300"
                  : "border-grey-900  hover:bg-green-300"
              } 'h-[208px] hover: relative w-[208px] cursor-pointer rounded-3xl border duration-150 hover:border-2`}
              onClick={() => {
                setAuctionType(false);
                setNotSaleType(false);
                setAuctionPrice("");
                setAuctionRoyalty("");
                setAuctionStTime(new Date());
                setAuctionEdTime(new Date());
                setFixedType((prev) => !prev);
              }}
            >
              <div className="mt-12 mb-3 flex justify-center pt-5">
                <FixedPriceSVG />
              </div>
              <p className="px-10 text-center text-xl font-bold text-grey-900">
                Fixed Price
              </p>
              {fixedType && (
                <div className="absolute top-2 right-2">
                  <CheckCircleSVG />
                </div>
              )}
            </div>
            <div
              className={`${
                auctionType
                  ? " border-2 border-green-900 bg-green-300"
                  : "border-grey-900 hover:bg-green-300"
              } relative h-[208px] w-[208px] cursor-pointer rounded-3xl border duration-150 hover:border-2 `}
              onClick={() => {
                setFixedType(false);
                setFixedPrice("");
                setRoyalty("");
                setNotSaleType(false);
                setAuctionType((prev) => !prev);
              }}
            >
              <div className="mt-12 mb-3 flex justify-center pt-5">
                <AuctionSVG />
              </div>
              <p className="px-10 text-center text-xl font-bold text-grey-900">
                Auction
              </p>
              {auctionType && (
                <div className="absolute top-2 right-2">
                  <CheckCircleSVG />
                </div>
              )}
            </div>
            <div
              className={`${
                notSaleType
                  ? " border-2 border-green-900 bg-green-300"
                  : "border-grey-900 hover:bg-green-300"
              } relative h-[208px] w-[208px] cursor-pointer rounded-3xl border duration-150 hover:border-2 `}
              onClick={() => {
                setFixedType(false);
                setFixedPrice("");
                setRoyalty("");
                setAuctionType(false);
                setAuctionPrice("");
                setAuctionRoyalty("");
                setAuctionStTime(new Date());
                setAuctionEdTime(new Date());
                setNotSaleType((prev) => !prev);
              }}
            >
              <div className="mt-12 mb-3 flex justify-center pt-5">
                <NotForSaleSVG />
              </div>
              <p className="px-10 text-center text-xl font-bold text-grey-900">
                Not For Sale
              </p>
              {notSaleType && (
                <div className="absolute top-2 right-2">
                  <CheckCircleSVG />
                </div>
              )}
            </div>
          </div>
          {fixedType && (
            <div className="pb-10">
              <p className="text-base font-bold text-grey-900">Enter Price</p>
              <div className="flex items-center gap-3 pt-3">
                <div className="w-[300px]">
                  <Web23Input
                    placeholder="Enter Price"
                    validate={true}
                    className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                    value={fixedPrice}
                    onChange={(e) => setFixedPrice(e.target.value)}
                  >
                    <span className="text-[14px] font-bold text-grey-600">
                      {connectedWallet && <>{connectedWallet[0].symbol}</>}
                    </span>
                  </Web23Input>
                </div>
                
              </div>
            </div>
          )}
          {auctionType && (
            <div className="pb-10">
              <p className="text-base font-bold text-grey-900">
                Enter Pricing Detail
              </p>
              <div className="flex items-center gap-3 pt-3">
                <div className="w-[300px]">
                  <Web23Input
                    placeholder="Enter Minimum Bid Price"
                    validate={true}
                    className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                    value={auctionPrice}
                    onChange={(e) => {
                      setAuctionPrice(e.target.value);
                    }}
                  >
                    <span className="text-[14px] font-bold text-grey-600">
					{connectedWallet && <>{connectedWallet[0].symbol}</>}
                    </span>
                  </Web23Input>
                </div>
                <div className="w-[300px]">
                  <Web23Input
                    placeholder="Enter Royalty"
                    className="w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
                    value={auctionRoyalty}
                    onChange={(e) => setAuctionRoyalty(e.target.value)}
                  />
                </div>
              </div>
			  
				
			  <div className="flex items-center gap-3 pt-3">
                <div className="w-[300px]">
				  <DateTimePicker
                  onChange={(e) => setAuctionStTime(e)}
                  disableClock={true}
                  value={auctionStTime}
                  showLeadingZeros={true}
                  format="y-MM-dd"
                 />
                  
                </div>
                <div className="w-[300px]">
				  <DateTimePicker
                  onChange={(e) => setAuctionEdTime(e)}
                  disableClock={true}
                  value={auctionEdTime}
                  showLeadingZeros={true}
                  format="y-MM-dd"
                 />
                </div>
              </div>	
              
            </div>
          )}

          <Web23Button
            text="Continue"
            className="mt-12 w-full hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50"
            onClick={() => setProgress((prev) => prev + 1)}
          />
        </div>
      )}
      {progress === 4 && (
        <>
          <Web23NftPreview
            nftSrc={preview}
            nftName={nftName}
            nftDescription={aboutNft}
            nftSupplyType={supplyType}
            nftSupplyAmount={supplyNumber}
            nftPrice={fixedType ? fixedPrice : auctionPrice}
            nftRoyalty={fixedType ? royalty : auctionRoyalty}
            walletName={settings.walletName}
            walletIcon={
              hederaWallets.filter(
                (item) => item.name === settings.walletName
              )[0].icon
            }
          />
          {loading && (
            <div className="m-auto pb-4">
              <Web23Spinner />
            </div>
          )}
          <Web23Button
            text="Continue"
            className="w-full"
            onClick={handleCreateNft}
            // onClick={() => {
            //   router.push({
            //     pathname: '/onboarding',
            //     query: { created: true },
            //   });
            // }}
          />
        </>
      )}
    </div>
  );
};

export default CreatingNftModal;
