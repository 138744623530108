import React, { useState, useContext, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icon/close.svg";
import {
  Web23Button,
} from "../../components";
export default (props:any)=>{
	const [show,setShow]=useState<number>(props.show);
	useEffect(()=>{
		setShow(props.show);
	},[props.show]);
	return(
		<>
		{show!=0 &&
			<div className="fixed top-0 left-0 w-full h-full " >
			  <div className="custom-scroll fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[480px] overflow-y-auto rounded-lg border border-grey-900 scrollbar-thin scrollbar-thumb-grey-900 overflow-x-hidden scrollbar-track-white">
			
				<div className="w-[640px] rounded-md bg-white p-6">
					<div className="flex items-center justify-between">
					  <h5 className="text-[24px] font-bold text-grey-600">
						Confirm
					  </h5>
					  <CloseIcon
						className="rounded-full cursor-pointer hover:bg-grey-300"
						onClick={() => {setShow(0);}}
					  />
					</div>
					<div className="mt-2">
					  <h5 className="text-[25px] font-normal text-grey-900">
					  {props.message}
					  </h5>
					</div>
					<div className="flex mt-4 mb-4">
						<Web23Button
						  text="No"
						  size="sm"
						  onClick={() => {setShow(0);}}
						  variant='secondary'
						  
						/>
						<Web23Button
						  text="Yes"
						  size="sm"
						  onClick={() => {alert("Hello");props.acceptOffer();}}
						  variant='primary'
						/>
					</div>
				</div>
				
			  </div>
			</div>
		}
		</>
	);	
	
};
