import React from 'react';
import Web23Spinner from '../web23-spinner';

type IWeb23Button = {
  text?: string;
  variant?: 'primary' | 'secondary' | 'fixed';
  onClick?: () => void;
  size?: 'sm' | 'md';
  className?: string;
  disabled?: boolean;
  border?: string;
  loading?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right",
  id?:string
};

const Web23Button: React.FC<IWeb23Button> = ({
  text = 'Sample Button',
  variant = 'primary',
  onClick,
  size = 'md',
  className,
  disabled = false,
  border,
  loading = false,
  icon,
  iconPosition = "left",
  id
}) => {
  return (
    <div className={`${variant !== 'fixed' ? 'w-full' : `${className}`}`}>
      <button
        className={`${className} flex items-center justify-center h-[50px] relative w-full rounded-3xl border border-grey-900 text-center text-base font-bold text-grey-900 hover:bg-grey-100 active:bg-lime-500 ${
          variant === 'primary' ? 'bg-green-500' : 'bg-white'
        } ${size === 'sm' ? 'py-2' : 'py-3'}
        ${disabled ? 'bg-green-300 text-grey-300 active:bg-none' : ''} ${
          border?.length ? 'border-2' : ''
        }`}
        onClick={onClick}
        disabled={disabled}
		id={id}
      >
        {loading ? (
          <div className="h-[30px] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
            <Web23Spinner variant='sm'/>
          </div>
        ) : (
          <div className="flex items-center justify-center gap-1">
          {iconPosition === "left" && icon && icon}
          {text}
          {iconPosition === "right" && icon && icon}
          </div>
          )}
      </button>
    </div>
  );
};

export default Web23Button;
