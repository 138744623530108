export const arrayBufferToBase64 = (buffer: any) => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
}
  
export const makeImgString = (buffer: any) => {
    return "data:image/jpeg;base64," + arrayBufferToBase64(buffer);
}

