import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CartSVG } from "../../assets/icon/cart02.svg";
import { numberValidator } from "../../utility/utils";

type IDomainSuggestion = {
  name: string;
  id: number;
  price: string;
  pxd: string;
};

const Web23DomainSuggestion: React.FC<{
  resData: IDomainSuggestion[];
}> = ({ resData = [] }) => {
  const [hbarPrice, setHbarPrice] = useState<number>(0);
  useEffect(() => {
    const getHbarPrice = async () => {
      const { data } = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=HBAR&tsyms=usd&api_key=8fc3e1cafe0aefdfb9819310e48db8e7ae472dbdfe734816e2b4bd1ae2f55ac8`
      );
      setHbarPrice(parseFloat(data["USD"]));
    };
    getHbarPrice();
  }, []);
  const navigate = useNavigate();

  return (
    <div className="w-[600px] rounded-[10px] bg-grey-50 p-3">
      <h4 className="mb-3 text-base font-bold text-grey-900">
        Similar Suggested Domains
      </h4>
      <table className="empty-cells-hidden mx-auto my-auto w-[100%] border-collapse border-spacing-[0] border-solid border-grey-300">
        <thead className="border-0">
          <tr className="border-0">
            <th>Domain</th>
            <th>Price in USD</th>
            <th>Price in Hbar</th>
          </tr>
        </thead>
        <tbody>
          {resData?.map((item: IDomainSuggestion) => {
            return (
              <tr key={item.name} className="border-t-[1px] border-t-grey-300">
                <td className="py-3 text-center border-0 border-collapse">
                  {item.name}
                </td>
                <td className="text-center border-0 border-collapse">
                  ${numberValidator((parseFloat(item.price) * hbarPrice).toFixed(2))}
                </td>
                <td className="text-center border-0 border-collapse">
                  {item.price} ℏ/year
                </td>
                <td className="pl-5 text-center border-0 border-collapse">
                  <div className="relative h-[30px] w-[30px] cursor-pointer rounded-full duration-300 hover:bg-green-500">
                    <CartSVG className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-full hover:bg-green-500" />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Web23DomainSuggestion;
