import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import { Web23PersonalDetail, Web23Modal } from "../../components";

import SampleNFT from "../../assets/img/sampleNFT.png";
import { ReactComponent as Web23BadgeSVG } from "../../assets/icon/Web23-Badge.svg";
import { ReactComponent as SmartPageLogoSVG } from "../../assets/icon/smart-page-logo.svg";

const CreateAccountPage: React.FC = () => {
  const myParam = useLocation().search;
  const [showDayPicker, setShowDayPicker] = useState<boolean>(false);

  return (
    <div>
      <div
        className={`flex h-full w-full flex-col bg-white ${
          showDayPicker ? "blur-lg" : "blur-0"
        }`}
      >
        <div className="flex items-center gap-2 px-8 py-3 mb-2">
          <div className="flex items-center justify-between justify-items-center">
            <SmartPageLogoSVG className="mr-10" />
            <Web23BadgeSVG />
          </div>
        </div>
        <div className="flex justify-center flex-1 px-8 pb-8">
          <div className="grid justify-center w-full h-full grid-cols-1 md:grid-cols-2 bg-yellow-50">
            <div className="flex flex-col justify-between h-full m-auto">
              <div className="mt-[120px]">
                <div className="flex justify-center p-5 rounded-lg">
                  <div className="p-5 bg-white rounded-lg">
                    <img src={SampleNFT} alt="nft sample" />
                  </div>
                </div>
              </div>
              <p className="max-w-[400px] px-8 mb-8 text-sm font-medium text-center text-grey-700">
                By Signing up for Web23, you have already agreed to our terms &
                Conditions. Learn have we process your data in privacy policy
                and cookies policy
              </p>
            </div>

            <Web23PersonalDetail
              final={myParam.indexOf("true") > 0 ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccountPage;
