import axios from "axios";
import React, { useEffect, useState } from "react";

import { Web23Input } from "../../components";

import { ReactComponent as ArrowDownSVG } from "../../assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as ArrowUpSVG } from "../../assets/icon/keyboard_arrow_up.svg";

const Web23NftPreview: React.FC<{
  nftSrc?: string;
  nftName?: string;
  nftSymbol?: string;
  nftDescription?: string;
  nftSymbolIcon?: React.ReactNode;
  nftSupplyType?: string;
  nftSupplyAmount?: string;
  walletIcon?: React.ReactNode;
  walletName?: string;
  nftPrice?: string;
  nftRoyalty?: string;
}> = ({
  nftSrc,
  nftName,
  nftSymbol,
  nftDescription,
  nftSymbolIcon,
  nftSupplyType,
  nftSupplyAmount,
  walletIcon,
  walletName,
  nftPrice,
  nftRoyalty,
}) => {
  const [showAddMoreDetail, setShowAddMoreDetail] = useState<boolean>(false);
  const [nftPriceUSD, setNftPriceUSD] = useState<string>("");

  useEffect(() => {
    const getHbarPrice = async () => {
      const { data } = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=HBAR&tsyms=usd&api_key=8fc3e1cafe0aefdfb9819310e48db8e7ae472dbdfe734816e2b4bd1ae2f55ac8`
      );
      setNftPriceUSD(
        (parseFloat(data["USD"]) * parseFloat(nftPrice ?? "0")).toFixed(3).toString()
      );
    };
    getHbarPrice();
  }, [nftPrice]);

  return (
    <div className="flex flex-col items-start pt-8">
      <div className="pb-8">
        <h4 className="text-2xl font-bold text-grey-900">Preview</h4>
      </div>
      <div className="w-min-[520px] mb-8 flex items-start gap-8">
        <div className="flex flex-col items-center">
          <div
            className="relative h-[276px] w-[276px] rounded-md border-2 bg-cover"
            style={{ backgroundColor: "white" }}
          >
            <div
              className="absolute top-4 left-4 h-[240px] w-[240px] bg-cover"
              style={{ backgroundImage: `url(${nftSrc})` }}
            ></div>
          </div>
          <div className="mt-2">
            <span className="text-center text-base font-medium text-grey-400">
              NFT Preview
            </span>
          </div>
        </div>
        <div>
          <h6 className="mb-1 text-xl font-bold text-grey-900">{nftName}</h6>
          <div className="mb-3 flex items-center gap-1">
            <span className="text-base font-medium text-grey-700">
              {nftSymbol}
            </span>
            {nftSymbolIcon}
          </div>
          <p className="text-base font-medium text-grey-600">
            {nftDescription}
          </p>

          <div className="mt-2 flex cursor-pointer items-center justify-between rounded-[32px] border border-grey-900 p-3 active:bg-grey-100">
            {nftSupplyType == "Unique" ? (
              <p className="py-1 text-base font-bold text-grey-900">
                {nftSupplyType}
              </p>
            ) : (
              <div className="flex gap-2">
                <p className="py-1 text-base font-bold text-grey-900">
                  {nftSupplyType}
                </p>
                <p className="py-1 text-base font-bold text-grey-900">
                  {nftSupplyAmount}
                </p>
              </div>
            )}

            <p className="mr-4 text-base font-bold text-grey-600">
              Supply Type
            </p>
          </div>

          <div className="mt-2 mb-3 flex cursor-pointer items-center justify-between rounded-[32px] border border-grey-900 p-3 active:bg-grey-100">
            <div>
              <div className="flex items-center gap-3">
                {walletIcon}
                <p className="py-1 text-base font-bold text-grey-900">
                  {walletName}
                </p>
              </div>
            </div>
            <p className="mr-4 text-base font-bold text-grey-600">Wallet</p>
          </div>

          <div className="mb-3 flex items-center justify-between gap-3">
            <Web23Input placeholder="Price" validate={true} value={nftPrice}>
              <span className="p-1 text-base font-bold text-grey-900">
                {nftPriceUSD} $
              </span>
            </Web23Input>
            <Web23Input placeholder="Royalty" value={nftRoyalty} />
          </div>

          <div
            className="mb-3 flex items-center gap-3"
            onClick={() => setShowAddMoreDetail((prev) => !prev)}
          >
            <span className="text-base font-bold text-grey-900">
              Add more details(Optional)
            </span>
            {!showAddMoreDetail && <ArrowDownSVG />}
            {showAddMoreDetail && <ArrowUpSVG />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Web23NftPreview;
