import React from 'react';
import { Range } from 'react-range';

const Web23RangeBar: React.FC<{
  value: number;
  setValue: (e: number) => void;
  min?: number;
  max?: number;
  step?: number;
}> = ({ value, setValue, min = 0, max = 100, step = 0.1 }) => {
  return (
    <Range
      step={step}
      min={min}
      max={max}
      values={[value]}
      onChange={(values) => {
        setValue(values[0]);
      }}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '8px',
            width: '100%',
            background: `linear-gradient(to right, #9FDB7B ${
              (100 * (value - min)) / (max - min) + '%'
            }, #696969 1%`,
            borderRadius: '8px',
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '32px',
            width: '32px',
            borderRadius: '100%',
            backgroundColor: '#9FDB7B',
          }}
        />
      )}
    />
  );
};

export default Web23RangeBar;
