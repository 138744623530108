import React, { ReactNode, useRef, useState } from 'react';

type IWeb23Button = {
  id?: string;
  placeholder?: string;
  value?: string;
  type?: 'input' | 'password';
  className?: string;
  variant?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  validate?: boolean;
  defaultValue?:string;
  disabled?:boolean;
  editPencil?:boolean;
};

const Web23Input: React.FC<IWeb23Button> = ({
  id,
  placeholder = 'input data',
  type = 'input',
  className,
  onChange,
  onBlur,
  value,
  // variant = 'primary',
  validate,
  children,
  defaultValue,
  disabled,
  editPencil
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className='relative'>
      <input
        id={id}
        type={type}
		defaultValue={defaultValue}
        value={value}
        onFocus={() => setFocus(true)}
        onBlur={onBlur}
        onChange={onChange}
        className={`${className} rounded-[32px] border bg-white p-5 font-bold text-grey-600 hover:bg-grey-50 focus:bg-grey-50 active:bg-grey-50`}
        ref={inputRef}
		disabled={disabled}
      />
	  {editPencil===true && 
	  <span className='absolute text-[22px] top-[25%] right-[1%]'><i className="bi bi-pencil-square"></i></span>
	  }
      <label
        className={`text-base absolute text-grey-400 duration-150 ${
          focus || (value?.length || defaultValue?.length)
            ? 'top-[10px] left-5 text-xs'
            : 'top-[50%] left-5 translate-y-[calc(-50%)] text-base'
        }`}
        onClick={() => {
          inputRef?.current?.focus();
        }}
      >
        {placeholder}
      </label>
	  
      <div className='absolute right-6 top-[50%] translate-y-[calc(-50%)] rounded-full  duration-150 hover:bg-grey-300'>
        {validate || value?.length ? children : null}
      </div>
    </div>
  );
};

export default Web23Input;
