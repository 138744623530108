import React from "react";

const Web23Modal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full">
      <div className="custom-scroll fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[480px] overflow-y-auto rounded-lg border border-grey-900 scrollbar-thin scrollbar-thumb-grey-900 overflow-x-hidden scrollbar-track-white">
        {children}
      </div>
    </div>
  );
};

export default Web23Modal;
