// import Image from 'next/image';

import React, {useEffect,useState,useContext} from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CopyIcon } from "../../assets/icon/copy.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icon/Twitter.svg";
import { ReactComponent as LanguageIcon } from "../../assets/icon/language.svg";
import { ReactComponent as LocationIcon } from "../../assets/icon/location.svg";
import { SettingContext } from "../../context/context";
import { getImageFromURL } from "../../utility/jimage";
import axios from "axios";
const introAvatar=require("./../../assets/img/introAvatar.png");

//const introAvatar ="https://tokeman.mypinata.cloud/ipfs/QmcXSeYnimkHuCj7mHJiSFDA4SqttAYdCxBHtKafz4jhMd";
//const introAvatar ="https://tokeman.mypinata.cloud/ipfs/QmcXSeYnimkHuCj7mHJiSFDA4SqttAYdCxBHtKafz4jhMd";


const Introduction: React.FC<{
  isCreator?: boolean;
  imgSrc?: string;
  domainName?: string;
  introduction?: string;
  location?: string;
  links?: string[];
  website?: string;
  children?: JSX.Element | JSX.Element[];
  walletAddress?: string;
  onClickInsertProfile: () => void;
  onClickCoppyAddress: () => void;
  subscription?:any;
  
 
}> = ({
  imgSrc,
  domainName,
  introduction,
  location,
  links,
  website,
  walletAddress,
  isCreator,
  onClickInsertProfile,
  onClickCoppyAddress,
  subscription
  
}) => {
  const { settings } = useContext(SettingContext);	
  const navigate = useNavigate();	
  const [dpImage,setDpImage]=useState<any>(null);
  useEffect(()=>{
	  //imgSrc => conating base46 string image
	  if(imgSrc){
	  /*const init=async()=>{
		let data=await getImageFromURL(imgSrc?imgSrc:"");
		await setDpImage(await getImageFromURL(imgSrc?imgSrc:""));
	  }
	  init();*/
	  setDpImage(imgSrc);
	  }
  },[imgSrc]);
  return (
    <div className="relative top-[0px]">
      <div className="flex flex-col items-start">
		<div className="hidden md:block relative top-[-75px]">
          {(dpImage===null)?
            <div
              className="!bg-transparent h-[180px] w-[180px] rounded-full bg-cover"
            ><img src={introAvatar} className="h-[180px] w-[180px] rounded-full" /></div>
			:
              <div
                className="!bg-transparent h-[180px] w-[180px] rounded-full bg-cover border-2 border-white shadow-lg"
                style={{ backgroundImage: `url(${dpImage})` }}>
				</div>
          
		 
		  
		  }
        </div>
        <p className="mt-[132px] md:mt-3 text-3xl font-bold cursor-pointer text-grey-900">
          {domainName ? domainName.slice(0, domainName.lastIndexOf(".")) : ""}{""}
          <span className="text-grey-700">
            {domainName?.slice(domainName.lastIndexOf("."))}
          </span>
        </p>
		<div className='flex flex-wrap flex-row items-start'>
			<div className="mt-1 flex cursor-pointer items-center  px-0 py-1">
			  <span className="max-w-[128px] text-grey-700 text-ellipsis overflow-hidden">
				{walletAddress ? walletAddress : "x.x.xxxx"}
			  </span>
			  <span className="rounded-full duration-150 hover:bg-grey-300 pl-2" onClick={onClickCoppyAddress}>
				<CopyIcon />
			  </span>
			</div>
		</div>	
        {!introduction && !location && !website ? (
          isCreator && (
            <p
              className="mt-1 text-base duration-150 cursor-pointer text-sky-700 hover:text-grey-900 underline"
              onClick={onClickInsertProfile}
            >
              Insert introduction here 
            </p>
          )
        ) : (
          <div>
            <p className="mt-3 text-base text-slate-500 mb-4">
              {introduction}
            </p>

            <div className="flex items-center mt-3 cursor-pointer">
              <LocationIcon />
              <a
                className="ml-[6px] text-base font-bold text-grey-700"
                href={`https://maps.google.com/maps?q=${location}`}
                target="_blank"
                rel="noreferrer"
              >
                {location}
              </a>
            </div>

            <div className="flex flex-col flex-wrap justify-between">
              <div className="flex mt-3 cursor-pointer">
                <LanguageIcon />
                <a
                  className="ml-[6px] text-base font-bold text-grey-700 underline"
                  href={website}
                  target="_blank"
                  rel="noreferrer"
                >
                  {website}
                </a>
              </div>
              {links?.map((item: string, id: number) => {
				 if(item.indexOf("twitter")>=0){
					return (
						  <div
							key={item + id}
							className="flex  mt-3 cursor-pointer gap-1items-center"
						  >
							<TwitterIcon />
							<a
							  className="ml-[6px] text-base font-bold text-grey-700 underline"
							  href={item}
							  target="_blank"
							  rel="noreferrer"
							>
							  {item}
							</a>
						  </div>
					);
				 } else {
					return (
						  <div
							key={item + id}
							className="flex  mt-3 cursor-pointer gap-1items-center"
						  >
							<LanguageIcon />
							<a
							  className="ml-[6px] text-base font-bold text-grey-700 underline"
							  href={item}
							  target="_blank"
							  rel="noreferrer"
							>
							  {item}
							</a>
						  </div>
					); 
				 }
              })}
            </div>
          </div>
        )}
		<div className='flex flex-wrap flex-row items-start'>
			<div className="mt-3 flex flex-col items-start rounded-[32px] bg-transparent py-2">
			{subscription && subscription.planName!=undefined &&
			  <>
				<div>
				<span className="mr-[6.33px] text-xl font-bold text-grey-700"><i>&#x1F4cc;</i> {(subscription?.planName)}</span><span className="rounded-full p-[3px] duration-150 hover:bg-grey-300"></span>
				</div>
				<div className='cursor-pointer'
				onClick={(e)=>{
					//alert("Hello");
				}}
				>
				<i className="bi bi-info-circle"></i> <span className='text-lg text-black-800'>NFT Balance {(subscription?.data?.balance)+"/"+(subscription?.plan?.mintValue)}</span>
				</div>
			 	<button className='px-4 mt-4 py-2 bg-blue-500 rounded-full text-white'
				onClick={e=>{
					let pathName=window.location.pathname.slice(1);
					navigate("./import-website");
				}}
				>Import Website to IPFS</button>
			  </>
			}
			</div>
		</div>
		
      </div>
	 
    </div>
  );
};

export default Introduction;
