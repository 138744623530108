import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import TimePicker from "react-time-picker";
import DateTimePicker from "react-datetime-picker";


import { PageContainer, TopMenu } from "../../../../layouts/top-menu";

import { API_ENDPOINT_URL } from "../../../../config";
import { makeImgString } from "../../../../utility/makeImage";
import { numberValidator } from "../../../../utility/utils";

import {
  Web23Modal,
  Web23ImgButton,
  Web23PostMedia,
  Web23Input,
  Web23Button,
  Web23Confirm
} from "../../../../components";

import {
  SettingContext,
  ModalProcessingContext,
} from "../../../../context/context";

import { formatDate, validateNumber } from "../../../../utility/libs";

import { ReactComponent as CloseIcon } from "../../../../assets/icon/close.svg";
import { ReactComponent as NftSVG } from "../../../../assets/icon/NFT.svg";
import { ReactComponent as AudioSVG } from "../../../../assets/icon/Audio.svg";
import { ReactComponent as PhotoSVG } from "../../../../assets/icon/Photo.svg";
import { ReactComponent as VideoSVG } from "../../../../assets/icon/Video.svg";
import { ReactComponent as ArticleSVG } from "../../../../assets/icon/article.svg";
import { ReactComponent as VerifiedSVG } from "../../../../assets/icon/verified.svg";
import { ReactComponent as FavoriteSVG } from "../../../../assets/icon/favorite_border.svg";
import { ReactComponent as CommentSVG } from "../../../../assets/icon/mode_comment.svg";
import { ReactComponent as ShareSVG } from "../../../../assets/icon/share.svg";
import { ReactComponent as RemoveRedEyeSVG } from "../../../../assets/icon/remove_red_eye.svg";
import { ReactComponent as BookmarkSVG } from "../../../../assets/icon/bookmark_border.svg";
import { ReactComponent as MoreSVG } from "../../../../assets/icon/more_horiz.svg";
import { ReactComponent as MsgFillSVG } from "../../../../assets/icon/message_fill.svg";
import { ReactComponent as DateRangeSVG } from "../../../../assets/icon/date_range.svg";
import { ReactComponent as BidSVG } from "../../../../assets/icon/Bid.svg";
import { ReactComponent as CircleCheckSVG } from "../../../../assets/icon/check_circle.svg";
import { ReactComponent as OfferSVG } from "../../../../assets/icon/local_offer.svg";

const PROPERTY = 0;
const COMMENT = 1;
const BID = 2;
const OFFER = 3;

const InitPriceMode = 0;
const FixedPriceMode = 1;
const AuctionPriceMode = 2;

type IBidItem = {
  bidderAddress: string;
  bidderName: string;
  price: number;
  maxPrice: number;
  toTokenId: string;
  toSerialNumber: string;
  created: string;
};

type IOfferItem = {
  bidderAddress: string;
  bidderName: string;
  price: number;
  coinType: string;
  toTokenId: string;
  toSerialNumber: string;
  memo: string;
  created: string;
};

const initBidList: IBidItem[] = [
  {
    bidderAddress: "0.0.123456789",
    bidderName: "Abrahan",
    price: 152,
    maxPrice: 1000,
    toTokenId: "0.0.123456",
    toSerialNumber: "1",
    created: "2023-03-20T12:21:46.555Z",
  },
  {
    bidderAddress: "0.0.123456789",
    bidderName: "Indra Reddy",
    price: 100,
    maxPrice: 1000,
    toTokenId: "0.0.123456",
    toSerialNumber: "1",
    created: "2023-03-20T12:21:46.555Z",
  },
];

const initOfferList: IOfferItem[] = [
  {
    bidderAddress: "0.0.123456789",
    bidderName: "Mickey Chan",
    price: 100,
    memo: "I'm gonna buy it",
    toTokenId: "0.0.123456",
    toSerialNumber: "1",
    coinType: "Hbar",
    created: "2023-03-20T12:21:46.555Z",
  },
  {
    bidderAddress: "0.0.123456789",
    bidderName: "Indra Reddy",
    price: 152,
    memo: "I'm gonna buy it",
    toTokenId: "0.0.123456",
    toSerialNumber: "1",
    coinType: "Hbar",
    created: "2023-03-20T12:21:46.555Z",
  },
];

const bidItemColor = ["#FFCE0A", "#A22CDD", "#5957E5", "#D17F00", "#FF707E"];

type IProperty = {
  name: string;
  value: string;
}
const NFTDetailForCreator: React.FC = () => {
  const [postingModal, setPostingModal] = useState<boolean>(false);
  const [convertAuction, setConvertAuction] = useState<boolean>(false);
  const [fixedPrice, setFixedPrice] = useState<boolean>(false);
  const { settings, saveSettings } = useContext(SettingContext);
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  const [price, setPrice] = useState<string>("");
  const [royalty, setRoyalty] = useState<string>("");
  const [mode, setMode] = useState<number>(PROPERTY);
  const [currentPriceMode, setCurrentPriceMode] =
    useState<number>(InitPriceMode);
  const [currentBid, setCurrentBid] = useState<string>("");
  const [totalBid, setTotalBid] = useState<string>("");
  const [endingTime, setEndingTime] = useState<string>("");
  const [showUnlist, setShowUnlist] = useState<boolean>(false);
  const [showListForSale, setShowListForSale] = useState<boolean>(false);
  const [salePrice, setSalePrice] = useState<string>("");
  const [nftCurrency, setNftCurrency] = useState<string>("");
  const [saleRoyalty, setSaleRoyalty] = useState<string>("");
  const [saleEndDate, setSaleEndDate] = useState<Date>(new Date());
  const [saleMode, setSaleMode] = useState<boolean>(false);
  const [domainName, setDomainName] = useState<string>("dilipmerugu.hbar");
  const [nftName, setNftName] = useState<string>("");
  const [serialNumber, setSerialNumber] = useState<number>(1);
  const [ownerAddress, setOwnerAddress] = useState<string>("");
  const [nftImage, setNftImage] = useState<string>("");
  const [nftDescription, setNftDescription] = useState<string>("");
  const [tokenId, setTokenId] = useState<string>("");

  const [hbarPrice, setHbarPrice] = useState<number>(0);
  const [bidList, setBidList] = useState<IBidItem[]>(initBidList);
  const [offerList, setOfferList] = useState<IOfferItem[]>(initOfferList);
  const [creatorProfileImage, setCreatorProfileImage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [auctionStTime, setAuctionStTime] = useState<string>("");
  const [auctionEdTime, setAuctionEdTime] = useState<string>("");
  const [convertBidPrice, setConvertBidPrice] = useState<string>("");
  const [convertAuctionRoyalty, setConvertAuctionRoyalty] =
    useState<string>("");
  const [convertStTime, setConvertStTime] = useState<string>("");
  const [convertEdTime, setConvertEdTime] = useState<string>("");
  const [convertFixedPrice, setConvertFixedPrice] = useState<string>("");
  const [convertFixedRoyalty, setConvertFixedRoyalty] = useState<string>("");
  const [showDayPicker, setShowDayPicker] = useState<boolean>(false);
  const [showStartDayPicker, setShowStartDayPicker] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState("10:00");
  const [selectedStartTime, setSelectedStartTime] = useState("10:00");
  const [saleStartDate, setSaleStartDate] = useState<Date>(new Date());
  const [selectedDay, setSelectedDay] = useState<Date>(new Date());
  const [properties, setProperties] = useState<IProperty[]>([]);
  useEffect(() => {
    const getHbarPrice = async () => {
      const { data } = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=HBAR&tsyms=usd&api_key=8fc3e1cafe0aefdfb9819310e48db8e7ae472dbdfe734816e2b4bd1ae2f55ac8`
      );
      setHbarPrice(parseFloat(data["USD"]));
    };
    getHbarPrice();
  }, []);

  useEffect(() => {
	 
    const fetchNft = async () => {
      const mid = window.location.pathname.split("/");
      const domain = mid[mid.length - 4];
      const tokenId = mid[mid.length - 3];

      const serialNumber = mid[mid.length - 2];
      const baseURL = `${API_ENDPOINT_URL}nft/${tokenId}/${serialNumber}`;

      try {
        const response = await axios.post(baseURL, {
          tokenId: tokenId,
          serialNumber: serialNumber,
        });

        if (response?.status === 200) {
          setNftName(response?.data?.data?.nftName);
          setDomainName(domain);
          setTokenId(tokenId);
          setSerialNumber(response?.data?.data?.serialNumber);
          setOwnerAddress(response?.data?.data?.currentOwner);
          setPrice(response?.data?.data?.price?.price);
          setNftCurrency(response?.data?.data?.price?.currency);
          setRoyalty(response?.data?.data?.price?.royalty);
          setCurrentPriceMode(
            response?.data?.data?.price?.priceType?.toLowerCase() === "fixed"
              ? FixedPriceMode
              : response?.data?.data?.price?.priceType?.toLowerCase() === "auction"
              ? AuctionPriceMode
              : currentPriceMode
          );
          if (
            response?.data?.data?.price?.priceType?.toLocaleLowerCase() === "auction"
          ) {
            const startDate = new Date(response?.data?.data?.price?.auctionStart);
            const endDate = new Date(response?.data?.data?.price?.auctionEnd);
            setSelectedDay(new Date(startDate));
            setSelectedStartTime(
              startDate.getHours() +
                ":" +
                startDate.getMinutes() +
                ":" +
                startDate.getSeconds()
            );
            setSaleEndDate(new Date(endDate));
            setSelectedTime(
              endDate.getHours() +
                ":" +
                endDate.getMinutes() +
                ":" +
                endDate.getSeconds()
            );
          }
          setNftImage(response?.data?.data?.nftImage);
          setNftDescription(response?.data?.data?.nftDescription);
          setProperties([...response?.data?.data?.property]);
        }
      } catch (e: any) {
        console.log("Error: ", e.message);
      }

      //fetch NFT creator
      try {
        const response = await axios.get(
          `${API_ENDPOINT_URL}account/getUserId`,
          {
            params: {
              domainName: domain.slice(0, -4) + "." + domain.slice(-4),
            },
          }
        );

        if (response.status === 200) {
          const response1 = await axios.post(
            `${API_ENDPOINT_URL}account/getAccount`,
            {
              user_id: response?.data?.data,
            }
          );
          if (response1.status === 200) {
            const profileImage = await axios.get(`${API_ENDPOINT_URL}account/downloadImage`, {
              params: {
                name: response1?.data?.data?.profileImageURL
              }
            })
            
            setCreatorProfileImage(makeImgString(profileImage?.data?.data?.data));
          }
        }
      } catch (e: any) {
        console.log("Error: ", e.message);
      }
    };

    const calculateCurrentBid = () => {
      setCurrentBid(
        bidList?.sort((a:any, b:any) => {
            return a?.created?.localeCompare(b?.created);
          })[0]
          ?.price?.toString()
      );
    };
    fetchNft();
    handleBids();
    handleOffers();
    calculateCurrentBid();
  }, []);

  const handleBids = async () => {
    setMode(BID);
    const mid = window.location.pathname.split("/");
    const tokenId = mid[mid.length - 3];
    const serialNumber = mid[mid.length - 2];

    const baseURL = `${API_ENDPOINT_URL}bid/getBids`;
    try {
      const response = await axios.get(baseURL, {
        params: {
          toTokenId: tokenId,
          toSerialNumber: serialNumber,
        },
      });
      if (response.status === 200) {
        setBidList(
          response?.data?.data?.map((item: any) => {
            return {
              bidderAddress: item?.bidderAddress,
              bidderName: item?.bidderName,
              price: parseFloat(item?.price),
              maxPrice: parseFloat(item?.maxPrice),
              toTokenId: item?.toTokenId,
              toSerialNumber: item?.toSerialNumber,
              created: item?.created,
            };
          })
        );
      }
    } catch (e: any) {
      console.log("Error: ", e.message);
    }
  };

  const handleOffers = async () => {
    setMode(OFFER);
    const mid = window.location.pathname.split("/");
    const tokenId = mid[mid.length - 3];
    const serialNumber = mid[mid.length - 2];

    const baseURL = `${API_ENDPOINT_URL}offer/getOffers`;
    try {
      const response = await axios.get(baseURL, {
        params: {
          toTokenId: tokenId,
          toSerialNumber: serialNumber,
        },
      });
      if (response.status === 200) {
        setOfferList(
          response?.data?.data?.map((item: any) => {
            return {
              bidderAddress: item?.bidderAddress,
              bidderName: item?.bidderName,
              price: parseFloat(item?.price),
              coinType: item?.coinType,
              toTokenId: item?.toTokenId,
              toSerialNumber: item?.toSerialNumber,
              memo: item?.memo,
              created: item?.created,
            };
          })
        );
      }
    } catch (e: any) {
      console.log("Error: ", e.message);
    }
  };
  const [showConfirmFlag,setShowConfirmFlag]=useState<number>(0);
  const [confirmMessage,setConfirmMessage]=useState<string>("");
  const [supplyTo,setSupplyTo]=useState<string>("");
  const showConfirmAccept=(__accountTo:string)=>{
	    setSupplyTo(__accountTo);
	    setShowConfirmFlag(Math.ceil(Math.random()*100));
	    setConfirmMessage("Sure you want to accept the respective offer?");
  };
  const acceptOffer = async () => {
	
    let accountTo=supplyTo;
    const mid = window.location.pathname.split("/");
    const tokenId = mid[mid.length - 3];
    const serialNumber = mid[mid.length - 2];

    const baseURL = `${API_ENDPOINT_URL}offer/acceptOffer`;
    try {
      const response = await axios.post(baseURL, {
        params: {
          toTokenId: tokenId,
          toSerialNumber: serialNumber,
		  accountTo:accountTo
        },
      });
      if (response.status === 200) {
        
      }
    } catch (e: any) {
      console.log("Error: ", e.message);
    }
  };
  const handleUnlisting = async () => {
    const mid = window.location.pathname.split("/");
    const tokenId = mid[mid.length - 3];
    const serialNumber = mid[mid.length - 2];

    const baseURL = `${API_ENDPOINT_URL}nft/${tokenId}/${serialNumber}/unlistNft`;
    try {
      setLoading(true);
      const response = await axios.post(baseURL, {
        tokenId: tokenId,
        serialNumber: serialNumber,
        priceType: "not for sale",
      });

      if (response.status === 200) {
        setCurrentPriceMode(InitPriceMode);
        setShowUnlist(false);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log("Error: ", e.message);
    }
  };

  const handleListing = async () => {
    const mid = window.location.pathname.split("/");
    const tokenId = mid[mid.length - 3];
    const serialNumber = mid[mid.length - 2];

    const baseURL = !saleMode
      ? `${API_ENDPOINT_URL}nft/${tokenId}/${serialNumber}/convertToFixedPrice`
      : `${API_ENDPOINT_URL}nft/${tokenId}/${serialNumber}/convertToAuction`;

    const request = !saleMode
      ? {
          tokenId: tokenId,
          serialNumber: serialNumber,
          price: salePrice,
          priceType: "fixed",
          royalty: saleRoyalty,
          currency: "Hbar",
          listingEndTime:
            saleEndDate.getFullYear() +
            "-" +
            formatDate(saleEndDate.getMonth().toString()) +
            "-" +
            formatDate(saleEndDate.getDate().toString()) +
            "T" +
            formatDate(saleEndDate.getHours().toString()) +
            ":" +
            formatDate(saleEndDate.getMinutes().toString()) +
            ":56.000Z",
        }
      : {
          tokenId: tokenId,
          price: salePrice,
          serialNumber: serialNumber,
          priceType: "auction",
          royalty: saleRoyalty,
          currentBid: "Hbar",
          auctionStart:
            selectedDay.getFullYear() +
            "-" +
            formatDate(selectedDay.getMonth().toString()) +
            "-" +
            formatDate(selectedDay.getDate().toString()) +
            "T" +
            selectedStartTime +
            ":56.000Z",
          auctionEnd:
            saleEndDate.getFullYear() +
            "-" +
            formatDate(saleEndDate.getMonth().toString()) +
            "-" +
            formatDate(saleEndDate.getDate().toString()) +
            "T" +
            formatDate(saleEndDate.getHours().toString()) +
            ":" +
            formatDate(saleEndDate.getMinutes().toString()) +
            ":56.000Z",
        };
    
        console.log("request", request);
    try {
      setLoading(true);
      const response = await axios.post(baseURL, request);

      if (response.status === 200) {
        if (!saleMode) setCurrentPriceMode(FixedPriceMode);
        else setCurrentPriceMode(AuctionPriceMode);
        setShowListForSale(false);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log("Error: ", e.message);
    }
  };

  const handleConvertToAuction = async () => {
    const mid = window.location.pathname.split("/");
    const tokenId = mid[mid.length - 3];
    const serialNumber = mid[mid.length - 2];

    const baseURL = `${API_ENDPOINT_URL}nft/${tokenId}/${serialNumber}/convertToAuction`;
    const request = {
      tokenId: tokenId,
      serialNumber: serialNumber,
      priceType: "auction",
      price: convertBidPrice,
      royalty: convertAuctionRoyalty,
      currentBid: "Hbar",
      auctionStart:
        selectedDay.getFullYear() +
        "-" +
        formatDate(selectedDay.getMonth().toString()) +
        "-" +
        formatDate(selectedDay.getDate().toString()) +
        "T" +
        selectedStartTime +
        ":56.000Z",
      auctionEnd:
        saleEndDate.getFullYear() +
        "-" +
        formatDate(saleEndDate.getMonth().toString()) +
        "-" +
        formatDate(saleEndDate.getDate().toString()) +
        "T" +
        selectedTime +
        ":56.000Z",
    };
    try {
      setLoading(true);
      const response = await axios.post(baseURL, request);

      if (response.status === 200) {
        setCurrentPriceMode(AuctionPriceMode);
        setConvertAuction(false);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log("Error: ", e.message);
    }
  };

  const handleConvertToFixedPrice = async () => {
    const mid = window.location.pathname.split("/");
    const tokenId = mid[mid.length - 3];
    const serialNumber = mid[mid.length - 2];

    const baseURL = `${API_ENDPOINT_URL}nft/${tokenId}/${serialNumber}/convertToFixedPrice`;
    const request = {
      tokenId: tokenId,
      serialNumber: serialNumber,
      price: convertFixedPrice,
      priceType: "fixed",
      royalty: convertFixedRoyalty,
      currency: "Hbar",
      listingEndTime:
        saleEndDate.getFullYear() +
        "-" +
        formatDate(saleEndDate.getMonth().toString()) +
        "-" +
        formatDate(saleEndDate.getDate().toString()) +
        "T" +
        selectedTime +
        ":56.000Z",
    };

    try {
      setLoading(true);
      const response = await axios.post(baseURL, request);

      if (response.status === 200) {
        setCurrentPriceMode(FixedPriceMode);
        setFixedPrice(false);
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log("Error: ", e.message);
    }
  };

  return (
    <div className="bg-white h-full">
	
      <div className="h-full">
        <div
          className={`flex h-full w-full flex-col ${
            modalProcess.isPostingVideo ||
            modalProcess.isPostingAudio ||
            modalProcess.isPostingArticle ||
            modalProcess.isPostingPhoto ||
            postingModal ||
            convertAuction ||
            fixedPrice ||
            showUnlist
              ? "blur-lg"
              : "blur-0"
          }`}
        >
          <PageContainer className="h-full flex flex-col">
			
            <TopMenu onClick={() => setPostingModal(true)} isCreator={true} />
            <div className="mt-6 flex justify-center px-[70px]">
              <div>
                <div
                  className="rounded-lg border border-grey-200 w-[600px] p-5 mb-4 bg-cover"
                >
				{nftImage?.indexOf("https://")===0?
				  <img src={nftImage}  className="w-full rounded-lg" />
				  :
				  <img src={`https://tokeman.mypinata.cloud/ipfs/${nftImage}`}  className="w-full rounded-lg" />
				} 
				</div>
                <p className="mb-2 text-grey-900 text-2xl font-bold">
                  Description
                </p>
                <p className="text-grey-600 font-bold text-base">
                  {nftDescription}
                </p>
              </div>
              <div className="px-6">
                <h3 className="font-bold text-grey-900 text-[32px] leading-10 mb-1">
                   {nftName}<br/><span className='text-sm'>#{serialNumber}</span>
                </h3>
                <div className="flex gap-1 items-center mb-3">
                  <p className="font-medium text-base text-grey-700">
                    Croak Mountain
                  </p>
                  <VerifiedSVG />
                </div>
                <div className="flex gap-2 items-center mb-6">
                  <div
                    className={`w-12 h-12 rounded-full ${
                      creatorProfileImage?.length === 0 || !creatorProfileImage
                        ? "bg-grey-600"
                        : "bg-cover"
                    }`}
                    style={{
                      backgroundImage: `url(${creatorProfileImage})`,
                    }}
                  />
                  <div className="py-1">
                    <p className="uppercase text-grey-600 text-xs font-bold">
                      Creator
                    </p>
                    <p className="font-bold text-base text-grey-900">
                      {domainName}
                      <span className="text-grey-600">
                        {/* {domainName.slice(domainName.indexOf("."))} */}
                      </span>
                    </p>
                  </div>
                </div>
                <hr className="border-grey-200" />
                <div className="flex justify-between items-center py-4 mb-6">
                  <div className="flex gap-4 items-center">
                    <div className="flex gap-1 items-center">
                      <FavoriteSVG />
                      <p className="text-grey-900 font-bold text-base">1,200</p>
                    </div>
                    <div className="flex gap-1 items-center">
                      <CommentSVG />
                      <p className="text-grey-900 font-bold text-base">45</p>
                    </div>
                    <ShareSVG />
                  </div>
                  <div className="flex gap-4 items-center">
                    <div className="flex gap-1 items-center">
                      <RemoveRedEyeSVG />
                      <p className="text-grey-900 font-bold text-base">4,001</p>
                    </div>
                    <div className="flex gap-1 items-center">
                      <BookmarkSVG />
                      <p className="text-grey-900 font-bold text-base">32</p>
                    </div>

                    <MoreSVG />
                  </div>
                </div>
                {currentPriceMode === FixedPriceMode ? (
                  <div className="flex gap-3 mb-6 justify-between">
                    <div className="w-full rounded-[32px] border border-grey-100 p-3">
                      <p className="font-bold text-xs text-grey-600">Price</p>
                      <div className="flex justify-between">
                        <p className="font-medium text-sm text-grey-600">
                          {price}
                        </p>
                        <p className="font-medium text-sm text-grey-600">
                          $
                          {numberValidator((parseFloat(price) * hbarPrice)
                            .toFixed(3)
                            .toString())}
                        </p>
                      </div>
                    </div>
                    <div className="w-full rounded-[32px] border border-grey-100 p-3">
                      <p className="font-bold text-xs text-grey-600">Royalty</p>
                      <div className="flex justify-between">
                        <p className="font-medium text-sm text-grey-600">
                          {royalty}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  currentPriceMode === AuctionPriceMode && (
                    <div className="grid grid-cols-2 gap-3 mb-6">
                      <div className="rounded-[32px] border border-grey-100 p-3">
                        <p className="font-bold text-xs text-grey-600">
                          Minimum Bid
                        </p>
                        <div className="flex justify-between">
                          <p className="font-medium text-sm text-grey-600">
                            {price}
                          </p>
                          <p className="font-medium text-sm text-grey-600">
                            $
                            {numberValidator((parseFloat(price) * hbarPrice)
                              .toFixed(3)
                              .toString())}
                          </p>
                        </div>
                      </div>
                      <div className="rounded-[32px] border border-grey-100 p-3">
                        <p className="font-bold text-xs text-grey-600">
                          Royalty
                        </p>
                        <div className="flex justify-between">
                          <p className="font-medium text-sm text-grey-600">
                            {royalty}
                          </p>
                        </div>
                      </div>
                      <div className="rounded-[32px] border border-grey-100 p-3">
                        <p className="font-bold text-xs text-grey-600">
                          Current Bid
                        </p>
                        <div className="flex justify-between">
                          <p className="font-medium text-sm text-grey-600">
                            {currentBid + "ℏ"}
                          </p>
                          <p className="font-medium text-sm text-grey-600">
                            ${numberValidator((parseFloat(currentBid) * hbarPrice).toFixed(3))}
                          </p>
                        </div>
                      </div>
                      <div className="rounded-[32px] border border-grey-100 p-3">
                        <p className="font-bold text-xs text-grey-600">
                          Total Bids
                        </p>
                        <div className="flex justify-between">
                          <p className="font-medium text-sm text-grey-600">
                            {bidList.length.toString()}
                          </p>
                        </div>
                      </div>
                      <div className="rounded-[32px] border border-grey-100 p-3">
                        <p className="font-bold text-xs text-grey-600">
                          Auction ending in
                        </p>
                        <div className="flex justify-between">
                          <p className="font-medium text-sm text-grey-600">
                            {auctionEdTime}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                )}
                {currentPriceMode === InitPriceMode ? (
                  <div className="mb-10">
                    <Web23Button
                      text="List for sale"
                      size="sm"
                      onClick={() => setShowListForSale(true)}
                    />
                  </div>
                ) : (
                  <div className="flex gap-3 mb-10">
                    <Web23Button
                      text="Unlist"
                      variant="secondary"
                      onClick={() => setShowUnlist(true)}
                    />
                    {currentPriceMode === FixedPriceMode ? (
                      <Web23Button
                        text="Convert to Auction"
                        onClick={() => setConvertAuction(true)}
                      />
                    ) : (
                      <Web23Button
                        text="Convert to Fixed Price"
                        onClick={() => setFixedPrice(true)}
                      />
                    )}
                  </div>
                )}
                <div className="bg-grey-50 rounded-[32px] p-2 flex gap-2 justify-between mb-4">
                  <div
                    className={`px-6 py-2 flex gap-1 rounded-[32px] border cursor-pointer ${
                      mode === PROPERTY
                        ? "border-grey-900 bg-white"
                        : "border-grey-100 bg-transparent"
                    }`}
                    onClick={() => setMode(PROPERTY)}
                  >
                    <p className="text-grey-900 font-bold text-base">
                      Properties
                    </p>
                    <p className="text-grey-600 font-bold text-base">7</p>
                  </div>
                  <div
                    className={`px-6 py-2 flex gap-1 rounded-[32px] border cursor-pointer ${
                      mode === COMMENT
                        ? "border-grey-900 bg-white"
                        : "border-grey-100 bg-transparent"
                    }`}
                    onClick={() => setMode(COMMENT)}
                  >
                    <p className="text-grey-900 font-bold text-base">
                      Comments
                    </p>
                    <p className="text-grey-600 font-bold text-base">0</p>
                  </div>
                  <div
                    className={`px-6 py-2 flex gap-1 rounded-[32px] border cursor-pointer ${
                      mode === BID
                        ? "border-grey-900 bg-white"
                        : "border-grey-100 bg-transparent"
                    }`}
                    onClick={() => {
                      setMode(BID);
                    }}
                  >
                    <p className="text-grey-900 font-bold text-base">Bids</p>
                  </div>
                  <div
                    className={`px-6 py-2 flex gap-1 rounded-[32px] border cursor-pointer ${
                      mode === OFFER
                        ? "border-grey-900 bg-white"
                        : "border-grey-100 bg-transparent"
                    }`}
                    onClick={async () => {
                      setMode(OFFER);
                    }}
                  >
                    <p className="text-grey-900 font-bold text-base">Offers</p>
                  </div>
                </div>
                {mode === PROPERTY && (
                  <div className="grid grid-cols-2 gap-x-4 gap-y-3 mb-[54px]">
                  {
                      properties.map((item, index) => {
                        return (
                          <div key={item.value+index} className="rounded-[32px] border border-grey-100 p-3">
                            <p className="font-bold text-xs text-grey-600">
                              {item.name}
                            </p>
                            <div className="flex justify-between">
                              <p className="font-medium text-sm text-grey-600">{item?.value?.split(' ')[0]}</p>
                              <p className="font-medium text-sm text-grey-600">{item?.value?.split(' ')[1]}</p>
                            </div>
                        </div>
                        )
                      })
                    }
                  </div>
                )}
                {mode === COMMENT && (
                  <div className="mb-[72px]">
                    <div className="border border-grey-200 rounded-lg w-full h-[280px] flex justify-center items-center mb-3">
                      <div className="bg-[#FFEDD1] rounded-full p-4">
                        <MsgFillSVG />
                      </div>
                    </div>
                    <div className="flex gap-4 items-center">
                      <Web23Input
                        placeholder="Add a comment"
                        className="w-[400px]"
                      />
                      <Web23Button text="Post" />
                    </div>
                  </div>
                )}
                {mode === BID && (
                  <div className="mb-[72px] w-full">
                    <div
                      className={`border border-grey-200 rounded-lg w-full h-[280px] flex flex-col justify-center mb-3 ${
                        bidList.length === 0
                          ? "justify-center"
                          : "justify-between"
                      }`}
                    >
                      {bidList.length ? (
                        <div>
                          {bidList.map((bidItem: IBidItem, idx: number) => {
                            return (
                              <div
                                key={bidItem.bidderName + idx}
                                className="flex justify-between w-full items-center pl-3 pr-6 py-[10px] border-b border-b-grey-200 last:border-b-0"
                              >
                                <div className="flex items-center gap-3">
                                  <div
                                    className={`w-10 h-10 rounded-full bg-[${
                                      bidItemColor[idx % 5]
                                    }]`}
                                  />
                                  <div className="py-1">
                                    <p className="mb-1 font-base text-base text-grey-900">
                                      {bidItem.bidderAddress}
                                    </p>
                                    <p className="font-medium text-sm text-grey-600">
                                      {bidItem.bidderName} {bidItem.created}
                                   </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="text-grey-900 font-bold text-base">
                                    ℏ{bidItem.price}
                                  </p>
                                  <p className="text-grey-600 font-medium text-sm">
                                    ${numberValidator((bidItem.price * hbarPrice).toFixed(3))}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <div className="flex justify-center">
                            <div className="bg-[#FFEDD1] rounded-full p-4 w-[64px] h-[64px]">
                              <BidSVG />
                            </div>
                          </div>
                          <p className="mt-1 font-medium text-xl text-grey-600">
                            No bids in yet
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {mode === OFFER && (
                  <div className="mb-[72px]">
                    <div
                      className={`border border-grey-200 rounded-lg w-full h-[280px] flex flex-col justify-center mb-3 ${
                        offerList.length === 0
                          ? "justify-center"
                          : "justify-between"
                      }`}
                    >
                      {offerList.length ? (
                        <div>
                          {offerList.map(
                            (offerItem: IOfferItem, idx: number) => {
                              return (
                                <div
                                  key={offerItem.bidderName + idx}
                                  className="flex justify-between items-center pl-3 pr-6 py-[10px] border-b border-b-grey-200 last:border-b-0"
                                >
                                  <div className="flex items-center gap-3">
                                    <div
                                      className={`w-10 h-10 rounded-full bg-[${
                                        bidItemColor[idx % 5]
                                      }]`}
                                    />
                                    <div className="py-1">
                                      <p className="mb-1 font-base text-base text-grey-900">
                                        {offerItem.bidderAddress}
                                      </p>
                                      <p className="font-medium text-sm text-grey-600">
                                        {offerItem.bidderName}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex items-center gap-6">
                                    <div>
                                      <p className="text-grey-900 font-bold text-base">
                                        ℏ{offerItem.price}
                                      </p>
                                      <p className="text-grey-600 font-medium text-sm">
                                        $
                                        {numberValidator(((offerItem.price * hbarPrice).toFixed(
                                          3
                                        )))}
                                      </p>
                                    </div>
                                    <div className="flex items-center gap-4">
                                      <div
                                        className="bg-green-600 cursor-pointer hover:bg-green-900 duration-150 relative w-[48px] h-[48px] rounded-full"
                                        onClick={() => {showConfirmAccept(offerItem.bidderAddress);}}
                                      >
										
                                        <span className="top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] absolute w-[12px] h-[6px] border-[1px] border-t-0 border-r-0 border-black rotate-[-45deg]"></span>
                                      </div>
                                      <div
                                        className="bg-red-400 cursor-pointer hover:bg-red-700 duration-150 relative w-[48px] h-[48px] rounded-full"
                                        onClick={() => {}}
                                      >
                                        <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[12px] h-[1px] bg-black rotate-[45deg]"></span>
                                        <span className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[12px] h-[1px] bg-black rotate-[-45deg]"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <div className="flex flex-col items-center">
                          <div className="flex justify-center">
                            <div className="bg-[#FFEDD1] rounded-full p-4 w-[64px] h-[64px]">
                              <BidSVG />
                            </div>
                          </div>
                          <p className="mt-1 font-medium text-xl text-grey-600">
                            No offers in yet
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
      {postingModal && (
        <Web23Modal>
          <div className="w-[640px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between">
              <h5 className="text-[24px] font-bold text-grey-600">
                Hi, {settings.displayName}{" "}
              </h5>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setPostingModal(false)}
              />
            </div>
            <div className="mt-2">
              <h3 className="text-[32px] font-bold text-grey-900">
                Choose any option to post
              </h3>
            </div>
            <div>
              <div className="mt-6">
                <div className="flex items-center justify-around p-4 rounded-xl bg-grey-50">
                  <Web23ImgButton
                    text="NFT"
                    img={<NftSVG />}
                    backColor="bg-green-200"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isCreatingNFT: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Photo"
                    img={<PhotoSVG />}
                    backColor="bg-red-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingPhoto: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Audio"
                    img={<AudioSVG />}
                    backColor="bg-purple-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingAudio: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Video"
                    img={<VideoSVG />}
                    backColor="bg-indigo-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingVideo: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Article"
                    img={<ArticleSVG />}
                    backColor="bg-grey-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingArticle: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Web23Modal>
      )}
      {modalProcess.isPostingPhoto && (
        <Web23Modal>
          <Web23PostMedia variant="photo" />
        </Web23Modal>
      )}
      {modalProcess.isPostingAudio && (
        <Web23Modal>
          <Web23PostMedia variant="audio" />
        </Web23Modal>
      )}
      {modalProcess.isPostingVideo && (
        <Web23Modal>
          <Web23PostMedia variant="video" />
        </Web23Modal>
      )}
      {modalProcess.isPostingArticle && (
        <Web23Modal>
          <Web23PostMedia variant="article" />
        </Web23Modal>
      )}
      {convertAuction && (
        <Web23Modal>
          <div className="min-w-[700px] p-6 bg-white">
            <div className="flex justify-between items-center mb-6">
              <h3 className="font-bold text-[32px] leading-10 text-grey-900 mb-6">
                Convert to Auction
              </h3>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setConvertAuction(false)}
              />
            </div>
            <div className="border-grey-200 border flex items-center gap-3 p-2 max-w-[360px] mb-6">
              <div
                className="w-12 h-12 bg-cover"
                style={{
                  backgroundImage: `url(https://tokeman.mypinata.cloud/ipfs/${nftImage})`,
                }}
              />

              <div>
                <p className="text-base font-bold text-grey-900 mb-1">
                  {nftName} #{serialNumber}
                </p>
                <div className="flex items-center">
                  <p className="font-medium text-base text-grey-400 pr-1">
                    Croakmores
                  </p>
                  <VerifiedSVG />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-3 gap-y-6 mb-6">
              <Web23Input
                placeholder="Enter Minimum bid price"
                className="w-full"
                value={convertBidPrice}
                onChange={(e) => setConvertBidPrice(e.target.value)}
                validate={true}
              >
                ${numberValidator((parseFloat(convertBidPrice) * hbarPrice).toFixed(3))}
              </Web23Input>
              <Web23Input
                placeholder="Enter Royalty"
                className="w-full"
                value={convertAuctionRoyalty}
                onChange={(e) => setConvertAuctionRoyalty(e.target.value)}
              />
              <div className="w-full relative">
                <span className="absolute text-grey-400 font-bold text-xs left-5 top-2">
                  Auction start time (Optional)
                </span>
                <DateTimePicker
                  onChange={(e) => {
                    setSelectedDay(e);
                  }}
                  disableClock={true}
                  value={selectedDay}
                  showLeadingZeros={true}
                  format="y-MM-dd h:mm:ss a"
                />
              </div>

              <div className="w-full">
                <span className="absolute text-grey-400 font-bold text-xs left-5 top-2">
                  Auction end time (Optional)
                </span>
                <DateTimePicker
                  onChange={(e) => {
                    setSaleEndDate(e);
                  }}
                  disableClock={true}
                  value={saleEndDate}
                  showLeadingZeros={true}
                  format="y-MM-dd h:mm:ss a"
                />
              </div>
            </div>
            <Web23Button
              text="Confirm conversion to auction"
              size="sm"
              onClick={async () => {
                await handleConvertToAuction();
              }}
            />
          </div>
        </Web23Modal>
      )}
      {fixedPrice && (
        <Web23Modal>
          <div className="min-w-[700px] p-6 bg-white">
            <div className="flex justify-between items-center mb-6">
              <h3 className="font-bold text-[32px] leading-10 text-grey-900 mb-6">
                Convert to Fixed Price
              </h3>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setFixedPrice(false)}
              />
            </div>
            <div className="border-grey-200 border flex items-center gap-3 p-2 max-w-[360px] mb-6">
              <div
                className="w-12 h-12 bg-cover"
                style={{
                  backgroundImage: `url(https://tokeman.mypinata.cloud/ipfs/${nftImage})`,
                }}
              />

              <div>
                <p className="text-base font-bold text-grey-900 mb-1">
                  {nftName} #{serialNumber}
                </p>
                <div className="flex items-center">
                  <p className="font-medium text-base text-grey-400 pr-1">
                    Croakmores
                  </p>
                  <VerifiedSVG />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-3 gap-y-6 mb-6">
              <div>
                <Web23Input
                  placeholder="Enter price"
                  className="w-full"
                  validate={true}
                  value={convertFixedPrice}
                  onChange={(e) => setConvertFixedPrice(e.target.value)}
                >
                  $11.19
                </Web23Input>
                {convertFixedPrice.length > 0 &&
                  !validateNumber(convertFixedPrice) && (
                    <span className="pl-8 text-xs font-bold text-red-700">
                      Only number is required
                    </span>
                  )}
              </div>
              <div>
                <Web23Input
                  placeholder="Enter Royalty"
                  className="w-full"
                  value={convertFixedRoyalty}
                  onChange={(e) => setConvertFixedRoyalty(e.target.value)}
                />
                {convertFixedRoyalty.length > 0 &&
                  !validateNumber(convertFixedRoyalty) && (
                    <span className="pl-8 text-xs font-bold text-red-700">
                      Only number is required
                    </span>
                  )}
              </div>

              <div className="w-full relative">
                <span className="absolute text-grey-400 font-bold text-xs left-5 top-2">
                  Listing end time (Optional)
                </span>
                <DateTimePicker
                  onChange={(e) => {
                    setSaleEndDate(e);
                  }}
                  disableClock={true}
                  value={saleEndDate}
                  showLeadingZeros={true}
                  format="y-MM-dd h:mm:ss a"
                />
              </div>
            </div>
            <Web23Button
              text="Confirm conversion to auction"
              size="sm"
              onClick={async () => {
                await handleConvertToFixedPrice();
              }}
            />
          </div>
        </Web23Modal>
      )}
      {showUnlist && (
        <Web23Modal>
          <div className="min-w-[700px] p-6 bg-white">
            <div className="flex justify-between items-center mb-6">
              <h3 className="font-bold text-2xl leading-10 text-grey-900">
                Are you sure you want to unlist the following NFT?
              </h3>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setShowUnlist(false)}
              />
            </div>
            <div className="border-grey-200 border flex items-center gap-3 p-2 max-w-[360px] mb-6">
              <div
                className="w-12 h-12 bg-cover"
                style={{
                  backgroundImage: `url(https://tokeman.mypinata.cloud/ipfs/${nftImage})`,
                }}
              />
              <div>
                <p className="text-base font-bold text-grey-900 mb-1">
                  {nftName} #{serialNumber}
                </p>
                <div className="flex items-center">
                  <p className="font-medium text-base text-grey-400 pr-1">
                    Croakmores
                  </p>
                  <VerifiedSVG />
                </div>
              </div>
            </div>
            <p className="font-bold text-base text-grey-900 mb-[48px]">
              Unlisting the NFT will just make it unavailable for sale, but will
              remain on your timeline. Users will still be able to make offers.
            </p>
            <div className="flex gap-3">
              <Web23Button
                text="Cancel"
                size="sm"
                variant="secondary"
                onClick={() => {
                  setShowUnlist(false);
                }}
              />
              <Web23Button
                text="Confirm Unlisting"
                size="sm"
                onClick={async () => {
                  await handleUnlisting();
                }}
              />
            </div>
          </div>
        </Web23Modal>
      )}
      {showListForSale && (
        <Web23Modal>
          <div className="min-w-[700px] p-6 bg-white">
            <div className="flex justify-between items-center mb-6">
              <h3 className="font-bold text-2xl leading-10 text-grey-900">
                List for Sale
              </h3>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setShowListForSale(false)}
              />
            </div>
            <div className="border-grey-200 border flex items-center gap-3 p-2 max-w-[360px] mb-6">
              <div
                className="w-12 h-12 bg-cover"
                style={{
                  backgroundImage: `url(https://tokeman.mypinata.cloud/ipfs/${nftImage})`,
                }}
              />
              <div>
                <p className="text-base font-bold text-grey-900 mb-1">
                  {nftName} #{serialNumber}
                </p>
                <div className="flex items-center">
                  <p className="font-medium text-base text-grey-400 pr-1">
                    Croakmores
                  </p>
                  <VerifiedSVG />
                </div>
              </div>
            </div>
            <p className="font-bold text-base text-grey-900 mb-3">
              Pricing type
            </p>
            <p className="font-medium text-base text-grey-600 mb-6">
              How do you want to sell your NFT?
            </p>
            <div className="flex gap-6 mb-6">
              <div
                className="relative border border-grey-200 rounded-[32px] p-3 w-[200px] h-[200px] flex flex-col items-center justify-center"
                onClick={() => setSaleMode(false)}
              >
                {!saleMode && (
                  <div className="absolute top-5 right-5">
                    <CircleCheckSVG />
                  </div>
                )}
                <div>
                  <div className="mb-4 flex justify-center">
                    <OfferSVG />
                  </div>
                  <p className="text-center font-bold text-xl text-grey-600">
                    Fixed Price
                  </p>
                </div>
              </div>
              <div
                className="relative border border-grey-200 rounded-[32px] p-3 w-[200px] h-[200px] flex flex-col items-center justify-center"
                onClick={() => setSaleMode(true)}
              >
                {saleMode && (
                  <div className="absolute top-5 right-5">
                    <CircleCheckSVG />
                  </div>
                )}
                <div>
                  <div className="mb-4 flex justify-center">
                    <BidSVG />
                  </div>
                  <p className="text-center font-bold text-xl text-grey-600">
                    Auction
                  </p>
                </div>
              </div>
            </div>
            <p className="font-medium text-base text-grey-600 mb-3">
              Enter Price
            </p>
            <div className="grid grid-cols-2 gap-3 mb-6">
              <div>
                <Web23Input
                  placeholder="Enter Price"
                  value={salePrice}
                  onChange={(e) => setSalePrice(e.target.value)}
                  validate={true}
                  className="w-full"
                >
                  ℏ
                </Web23Input>
                {salePrice.length > 0 && !validateNumber(salePrice) && (
                  <span className="pl-8 text-xs font-bold text-red-700">
                    Only number is required
                  </span>
                )}
              </div>
              <div>
                <Web23Input
                  placeholder="Enter Royalty"
                  value={saleRoyalty}
                  onChange={(e) => setSaleRoyalty(e.target.value)}
                  className="w-full"
                />
                {saleRoyalty.length > 0 && !validateNumber(saleRoyalty) && (
                  <span className="pl-8 text-xs font-bold text-red-700">
                    Only number is required
                  </span>
                )}
              </div>
            </div>
            <div className="mb-6">
              {!saleMode ? (
                <div className="w-full relative">
                  <span className="absolute text-grey-400 font-bold text-xs left-5 top-2">
                    Listing end time (Optional)
                  </span>
                  <DateTimePicker
                    onChange={(e) => {
                      setSaleEndDate(e);
                    }}
                    disableClock={true}
                    value={saleEndDate}
                    showLeadingZeros={true}
                    format="y-MM-dd h:mm:ss a"
                  />
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-3">
                  <div className="w-full relative">
                    <span className="absolute text-grey-400 font-bold text-xs left-5 top-2">
                      Auction start time (Optional)
                    </span>
                    <DateTimePicker
                      onChange={(e) => {
                        setSelectedDay(e);
                      }}
                      disableClock={true}
                      value={selectedDay}
                      showLeadingZeros={true}
                      format="y-MM-dd h:mm:ss a"
                    />
                  </div>

                  <div className="w-full relative">
                    <span className="absolute text-grey-400 font-bold text-xs left-5 top-2">
                      Auction end time (Optional)
                    </span>
                    <DateTimePicker
                      onChange={(e) => {
                        setSaleEndDate(e);
                      }}
                      disableClock={true}
                      value={saleEndDate}
                      showLeadingZeros={true}
                      format="y-MM-dd h:mm:ss a"
                    />
                  </div>
                </div>
              )}
            </div>
            <Web23Button
              text="Confirm Listing"
              size="sm"
              disabled={
                !validateNumber(salePrice) || !validateNumber(saleRoyalty)
              }
              onClick={async () => {
                await handleListing();
              }}
            />
          </div>
        </Web23Modal>
      )}

      {showDayPicker && (
        <Web23Modal>
          <div className="relative bg-grey-50 flex flex-col gap-8 pt-6">
            <DayPicker
              mode="single"
              selected={saleEndDate}
              onSelect={(e) => {
                setSaleEndDate(e as any);
              }}
            />
            <TimePicker
              value={selectedTime}
              onChange={(e: any) => {
                setSelectedTime(e);
              }}
            />
            <div className="absolute top-3 right-5">
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setShowDayPicker(false)}
              />
            </div>
          </div>
        </Web23Modal>
      )}

      {showStartDayPicker && (
        <Web23Modal>
          <div className="relative bg-grey-50 flex flex-col gap-8 pt-6">
            <DayPicker
              mode="single"
              selected={selectedDay}
              onSelect={(e) => {
                setSelectedDay(e as any);
              }}
            />
            <TimePicker
              value={selectedStartTime}
              onChange={(e: any) => {
                setSelectedStartTime(e);
              }}
            />
            <div className="absolute top-3 right-5">
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setShowStartDayPicker(false)}
              />
            </div>
          </div>
        </Web23Modal>
      )}
	  <Web23Confirm message={confirmMessage} show={showConfirmFlag} acceptOffer={acceptOffer} />
    </div>
  );
};

export default NFTDetailForCreator;
