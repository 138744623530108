import React from 'react';

type IWeb23Tabs = {
  tabs: {
    label: string;
    index: number;
    Component: React.FC<{ index: number }>;
  }[];

  selectedTab: number;
  onClick: (index: number) => void;
  className?: string;
};

const Web23Tabs: React.FC<IWeb23Tabs> = ({
  className,
  tabs = [],
  selectedTab = 0,
  onClick,
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab);

  return (
    <div className={`${className}`}>
      <div className='flex w-full items-center border-b-[2px] border-b-grey-400'>
        {tabs.map((tab) => (
          <button
            className={`px-8 py-4 font-bold ${
              selectedTab === tab.index
                ? 'border-b-[2px] border-b-green-700 text-green-700'
                : 'border-b-transparent text-grey-700'
            }  text-lg`}
            onClick={() => onClick(tab.index)}
            // disabled={selectedTab !== tab.index}
            key={tab.index}
            type='button'
            tabIndex={selectedTab === tab.index ? 0 : -1}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className='flex flex-col items-center pb-3'>
        {Panel && <Panel.Component index={selectedTab} />}
      </div>
    </div>
  );
};

export default Web23Tabs;
