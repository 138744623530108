import React, { ReactNode } from "react";

type IWeb23ImgButton = {
  backColor?: string;
  img?: ReactNode;
  text?: string;
  onClick?: () => void;
  hoverBgColor?: string;
};

const Web23ImgButton: React.FC<IWeb23ImgButton> = ({
  backColor,
  img,
  text,
  onClick,
  hoverBgColor,
}) => {
  return (
    <div
      className={`flex px-1 ${backColor} w-full cursor-pointer flex-col rounded-xl duration-150 hover:${hoverBgColor}`}
      onClick={onClick}
    >
      <div className="flex justify-center pt-1">{img}</div>
      <div className="mb-1 text-base font-bold text-center text-grey-900">
        {text}
      </div>
    </div>
  );
};

export default Web23ImgButton;
