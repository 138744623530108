import { useEffect, useState } from 'react';

export const useImageUpload = () => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return undefined;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files?.[0]);
  };
  return [
    preview,
    setPreview,
    selectedFile,
    setSelectedFile,
    onSelectFile,
  ] as const;
};
