import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
  Web23Button,
  Web23Card,
  Web23ImgButton,
  Web23Input,
  Web23Spinner,
  Web23Modal,
  Web23PostMedia,
} from "../../components";

import { SettingContext, initialSettings } from "../../context/context";

import CreatingNftModal from "../../layouts/create-nft";

import { ModalProcessingContext } from "../../context/context";

import { PageContainer } from "../../layouts/top-menu";

import { TopMenu } from "../../layouts/top-menu";

import useToast from "../../utility/useToast";

import { API_ENDPOINT_URL } from "../../config";

import { ReactComponent as BladeWalletSVG } from "../../assets/icon/Blade1.svg";
import { ReactComponent as CloseIcon } from "../../assets/icon/close.svg";
import { ReactComponent as NftSVG } from "../../assets/icon/NFT.svg";
import { ReactComponent as PhotoSVG } from "../../assets/icon/Photo.svg";
import { ReactComponent as AudioSVG } from "../../assets/icon/Audio.svg";
import { ReactComponent as VideoSVG } from "../../assets/icon/Video.svg";
import { ReactComponent as ArticleSVG } from "../../assets/icon/article.svg";
import { ReactComponent as CheckBigSVG } from "../../assets/icon/ic_check_circle_big.svg";
import { ReactComponent as ArrowBackSVG } from "../../assets/icon/arrow_back.svg";
import { ReactComponent as PersonSVG } from "../../assets/icon/person.svg";
import { ReactComponent as NotificationSVG } from "../../assets/icon/notifications.svg";
import { ReactComponent as SubscriptionSVG } from "../../assets/icon/subscriptions_md.svg";
import { ReactComponent as AccessibilitySVG } from "../../assets/icon/accessibility.svg";
import { ReactComponent as SpaceDashboardSVG } from "../../assets/icon/space_dashboard.svg";
import { ReactComponent as TokenMenuSVG } from "../../assets/icon/token_menu.svg";
import { ReactComponent as PrivacySVG } from "../../assets/icon/privacy_tip.svg";
import { ReactComponent as MetaMaskSVG } from "../../assets/icon/Metamask.svg";
import { ReactComponent as HashPackSVG } from "../../assets/icon/HashPack.svg";
import { ReactComponent as RainbowSVG } from "../../assets/icon/Rainbow.svg";
import { ReactComponent as ContentCopySVG } from "../../assets/icon/content_copy.svg";
import { ReactComponent as BladeWalletMDSVG } from "../../assets/icon/Blade_md.svg";
import { ReactComponent as LinkOffSVG } from "../../assets/icon/link_off.svg";
import { ReactComponent as MoreSVG } from "../../assets/icon/more_vert.svg";
import { ReactComponent as ArrowDownSVG } from "../../assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as LogoutSVG } from "../../assets/icon/logout.svg";
import { ReactComponent as DarkModeSVG } from "../../assets/icon/dark_mode.svg";
import { ReactComponent as LightModeSVG } from "../../assets/icon/light_mode.svg";
import { ReactComponent as PremiumSVG } from "../../assets/icon/workspace_premium.svg";
import SampleNft from "../../assets/img/sampleNFT.png";

import { useHashconnectService } from "../../context/Hashconnect";
import { transferHBARTo,associateSPToken} from "../../utility/hashgraph-transaction";


const tldList = ["blade", "bhc", "hangry", "boo", "cream", "hbar"];

type IUserData = {
  birthday: string;
  countryCode: string;
  created: string;
  displayName: string;
  domainName: string;
  email: string;
  password: string;
  phoneNumber: string;
  role: string;
  verified: boolean;
  walletAddress: string;
  walletStatus: boolean;
  id: string;
  profile: {
    introduction: string;
    location: string;
    website: string;
    otherLinks: string[];
    twitter: string;
    coverImg: string;
    img: string;
  };
  socialToken: any;
};

const initialUserData = {
  birthday: "",
  countryCode: "",
  created: "",
  displayName: "",
  domainName: "",
  email: "",
  password: "",
  phoneNumber: "",
  role: "",
  verified: false,
  walletAddress: "",
  walletName: "",
  walletStatus: false,
  id: "",
  profile: {
    introduction: "",
    location: "",
    website: "",
    otherLinks: [],
    twitter: "",
    coverImg: "",
    img: "",
  },
  socialToken: {},
};

const walletDetail = [
  {
    type: "Hedera",
    detail: [
      { name: "Blade", icon: <BladeWalletSVG /> },
      { name: "Hashpack", icon: <HashPackSVG /> },
    ],
  },
  {
    type: "Ethereum",
    detail: [
      { name: "Metamask", icon: <MetaMaskSVG /> },
      { name: "Rainbow", icon: <RainbowSVG /> },
    ],
  },
  {
    type: "Flow",
    detail: [],
  },
  {
    type: "Polygon",
    detail: [],
  },
  {
    type: "Solana",
    detail: [],
  },
];

const menu = [
  { title: "Your Account", icon: <PersonSVG /> },
  { title: "Notifications", icon: <NotificationSVG /> },
  { title: "Subscription", icon: <SubscriptionSVG /> },
  { title: "Accessibility", icon: <AccessibilitySVG /> },
  { title: "Display and themes", icon: <SpaceDashboardSVG /> },
  { title: "Social Tokens", icon: <TokenMenuSVG /> },
  { title: "Privacy and Safety", icon: <PrivacySVG /> },
];

const SettingPage = () => {
  const [postingModal, setPostingModal] = useState<boolean>(false);
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  const [userData, setUserData] = useState<IUserData>(initialUserData);
  const [showFromWalletModal, setShowFromWalletModal] =
    useState<boolean>(false);
  const [addingNft, setAddingNft] = useState<boolean>(false);
  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const [connectedWallet, setConnectedWallet] = useState<{
    name: string;
    addres: string;
  }>();
  const [walletStatus, setWalletStatus] = useState<boolean>(false);
  const [walletTab, setWalletTab] = useState<number>(0);
  const { ToasterBox, showToast } = useToast();
  const [selMenu, setSelMenu] = useState<number>(0);
  const [userName, setUserName] = useState<string>("");
  const [nameValue, setNameValue] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const { settings, saveSettings } = useContext(SettingContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDeactive, setShowDeactive] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteConfirm, setDeleteConfirm] = useState<string>("");
  const [terminateConfirm, setTerminateConfirm] = useState<string>("");
  const navigate = useNavigate();
  
  
  const {
    pairingData,
    availableExtension,
    connectToExtension,
    disconnect,
    getProviderAndSigner,
    publicKey,
  } = useHashconnectService();
  
  useEffect(()=>{
	  setSelMenu(2);
  },[]);
  useEffect(() => {
	  
    const rawDomain = window.location.pathname.slice(1).split("/")[0];
    let searchIdx = -1;
    for (let i = 0; i < tldList.length; i++) {
      searchIdx = rawDomain.lastIndexOf(tldList[i]);
      if (searchIdx > 0 && rawDomain.length - searchIdx < 7) break;
    }
    const urlParam =
      rawDomain.slice(0, searchIdx) + "." + rawDomain.slice(searchIdx);

    const getProfile = async () => {
      try {
        const id = await axios.get(
          `${API_ENDPOINT_URL}` + "account/getUserId",
          {
            params: {
              domainName: urlParam,
            },
          }
        );

        if (id.status === 200) {
          setUserData({
            ...id?.data?.data,
            id: id?.data?.data._id,
          });
        }

        const profileInfo = await axios.post(
          `${API_ENDPOINT_URL}` + "account/getProfile",
          {
            user_id: id?.data?.data?._id,
          }
        );

        if (profileInfo.status === 200) {
          setUserData((prev) => {
            return {
              ...prev,
              ...id?.data?.data,
              id: id?.data?.data?._id,
              profile: {
                location: profileInfo?.data?.data?.location,
              },
            };
          });
        }
      } catch (e) {}
    };

    getProfile();
	getSubscriptions();
  }, [settings]);

  useEffect(() => {
	  
    setUserName(userData?.displayName || "");
    setNameValue(userData?.displayName || "");
    setEmail(userData?.email || "");
    setPhone(userData?.phoneNumber || "");
    setLocation(userData?.profile?.location || "");

    if (userData.id !== "")
      if (userData.id !== settings?.userId) navigate("/login");
  }, [userData]);
 const [requestFlag,setRequestFlag]=useState<boolean>(false);
 const [alertLines,setAlertLines]=useState<any>([]);
 
 const [userSubscription,setUserSubscription]=useState<any>(null);
 const [subsriptionRecords,setSubsriptionRecords]=useState<any>(null);
 
 const getSubscriptions=async()=>{
	try{
	if(settings && settings.userId && settings.userId.length>0){ 
		let loginUser:string=settings.userId;
		const response = await axios.post(API_ENDPOINT_URL + `subscription/getAll`, {
		  ci:loginUser
		});
		if(response.status===200){
			if(response.data.subscriptions){
				await setUserSubscription(response.data.subscribed);
				await setSubsriptionRecords(response.data.subscriptions);
			} 
		} 
	}}catch(_e){
	}
 };

  return (
    <>
      <div className="relative h-full">
        <div className={`${(showDeactive || showDelete) && "blur-sm"}`}>
          <PageContainer className="flex flex-col">
            <TopMenu onClick={() => setPostingModal(true)} isCreator={true} />
            <div className={`${isOpen ? "blur-[2px]" : "blur-none"}`}>
              <div className="flex lg:gap-[130px] gap-10 h-full bg-white pb-[104px]">
                <div className="pt-6 lg:pl-[70px] pl-8 hidden md:block">
                  <div
                    className="flex items-center gap-3 mb-6"
                    onClick={() => {
                      navigate(
                        "/" + window.location.pathname.slice(1).split("/")[0]
                      );
                    }}
                  >
                    <ArrowBackSVG />
                    <p className="font-bold text-[32px] leading-10 text-grey-900">
                      Settings
                    </p>
                  </div>
                  <div className="border border-grey-100 rounded-lg py-6 w-[310px]">
                    {menu.map((item, idx) => (
                      <div
                        key={`${menu}+${item.title}`}
                        className={`${
                          idx === selMenu ? "bg-[#EFFBDF]" : "bg-white"
                        } px-6 cursor-pointer hover:bg-[#f9fef1] py-4 flex gap-3 items-center relative`}
                        onClick={() => setSelMenu(idx)}
                      >
                        <div
                          className={`${
                            idx === selMenu ? "fill-[#3C8725]" : "fill-grey-400"
                          }`}
                        >
                          {item.icon}
                        </div>
                        <p
                          className={`font-bold text-xl ${
                            idx === selMenu ? "text-[#3C8725]" : "text-grey-400"
                          }`}
                        >
                          {item.title}
                        </p>
                        {idx === selMenu && (
                          <div className="absolute bg-[#3C8725] w-[6px] h-8 top-4 left-0" />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex w-full px-8 m-auto md:m-0 lg:px-0">
                  {selMenu === 0 && (
                    <div className="max-w-[640px] border border-grey-100 rounded-2xl p-6 mt-6">
                      <div className="flex items-center justify-between mb-6">
                        <h3 className="text-grey-900 text-[32px] leading-10">
                          Your Account
                        </h3>
                        <span><i className="bi bi-info-circle"></i> auto save</span>
                      </div>
                      <div className="flex flex-col gap-4 mb-10">
                        <Web23Input
                          className="w-full"
                          placeholder="Domain Name"
                          value={settings.domain}
                          disabled={true}
                        />
                        <Web23Input
                          className="w-full"
                          placeholder="Name"
                          defaultValue={nameValue}
                          onBlur={async(e) => {
							let tmpName=e.target.value;
							try{
								if(settings && settings.userId && settings.userId.length>0){ 
									let loginUser:string=settings.userId;
									const response = await axios.post(API_ENDPOINT_URL + 'account/ed/dn', {
									  uid:loginUser,
									  cn:nameValue,
									  dn:tmpName
									});
									if(response.status===200){
										setNameValue(tmpName);
									} 
								}
							}catch(_e){
							}
						  }}
						  editPencil={true}
                        />
                        <Web23Input
                          className="w-full"
                          placeholder="Email address"
                          value={email}
                          disabled={true}
                        />
                        <Web23Input
                          className="w-full"
                          placeholder="Phone Number"
                          value={phone}
                          disabled={true}
                        />
                        <Web23Input
                          className="w-full"
                          placeholder="Location"
                          defaultValue={location}
                          onBlur={async(e) => {
							let tmpLocation=e.target.value;
							try{
								if(settings && settings.userId && settings.userId.length>0){ 
									let loginUser:string=settings.userId;
									const response = await axios.post(API_ENDPOINT_URL + 'account/ed/lo', {
									  uid:loginUser,
									  cn:location,
									  dn:tmpLocation
									});
									if(response.status===200){
										setLocation(tmpLocation);
									} 
								}
							}catch(_e){
							}
						  }}
						  editPencil={true}
                        />
                      </div>
                      <h3 className="mb-4 text-2xl font-bold text-grey-900">
                        Account Activation
                      </h3>
                      <div
                        className="flex items-center justify-between pb-5 mb-5 border-b cursor-pointer border-b-grey-100"
                        onClick={() => setShowDeactive(true)}
                      >
                        <div>
                          <p className="mb-1 text-base font-bold text-grey-900">
                            Deactivate temporarily
                          </p>
                          <p className="text-sm font-medium text-grey-600">
                            Your posts will be inactive and not interactable at
                            this time. You can log in anytime to reactivate your
                            account
                          </p>
                        </div>
                        <ArrowDownSVG className="-rotate-90" />
                      </div>
                      <div
                        className="flex items-center justify-between pb-5 mb-5 border-b cursor-pointer border-b-grey-100"
                        onClick={() => setShowDelete(true)}
                      >
                        <div>
                          <p className="mb-1 text-base font-bold text-grey-900">
                            Delete Permanently
                          </p>
                          <p className="text-sm font-medium text-grey-600">
                            All your data which is part of web23 will be erased
                            permanently Blockchain data will remain uneffected
                          </p>
                        </div>
                        <ArrowDownSVG className="-rotate-90" />
                      </div>
                      <div
                        className="flex items-center gap-1 cursor-pointer"
                        onClick={() => {
                          saveSettings({ ...initialSettings });
                          navigate("/login");
                        }}
                      >
                        <LogoutSVG />
                        <p className="text-[#D10014] font-bold text-base">
                          Log Out
                        </p>
                      </div>
                    </div>
                  )}
				  
				  
				  {selMenu === 2 && (
					<div className='flex flex-col w-full' style={{paddingRight:"2rem"}}>
						<h3 className="text-grey-900 text-2xl leading-10 mt-4 ">
						{userSubscription===null?
							<>No Plan selected</>
							:
							<></>
						}
						</h3>
						<div className="flex flex-row w-full mt-2 gap-x-2">
						
						{ subsriptionRecords && subsriptionRecords.map((plan:any,index:any)=>{
							return(
							<div className={(userSubscription.subscriptionId===plan.id)?'basis-1/4 border text-white border-grey-100 rounded-xl p-6 mt-6 bg-green-600 scale-110':'basis-1/4 border border-grey-100 rounded-xl p-6 mt-6 bg-green-200'}>
								<h3 className="text-grey-900 text-3xl leading-10 mb-8">{plan.planName}</h3>
								<h3 className="text-grey-900 text-4xl leading-10 mb-8 text-center">{plan.price}</h3>
								<ul className="px-8 list-disc text-xl list-outside mb-8 ">
									
									<li className='py-2'>{plan.mintAllowance}</li>
									<li className='py-2'>{plan.duration}</li>
									<li className='py-2'>{plan.socialToken}</li>
									<li className='py-2'>{plan.pfp}</li>
									<li className='py-2'>{plan.airdrop}</li>
									
									
								</ul>
								<p className='text-base'>{/*
								No, you can't trigger a different key based on a keypress, for good reasons. Otherwise, you could trick browsers into auto-completing passwords or other sensitive information or even cause a password manager to do a global autofill.
								*/}</p>
								{(userSubscription===null || userSubscription.subscriptionId===undefined) && 
								<button role='button' className={requestFlag===false?'hover:bg-green-200 mt-4 w-full rounded-full border-2 py-4 text-xl bg-green-500':'mt-4 w-full rounded-full border-2 py-4 text-xl bg-slate-200'} disabled={requestFlag}
								onClick={async(e)=>{
									
									const loadAlerts=(_alerts:[])=>{
										let alertBox:any=(window as any).document.getElementById("alertBody_9090");
										let _html=_alerts.map((data,index)=>{
											return("<div class='text-[14px] text-emerald-700 py-2'>"+data+"</div>");
										});
										alertBox.innerHTML=_html.join("");
									};
									
									if(settings && settings.userId && settings.userId.length>0){
										try{
											const accountId = settings.walletAddress;
											const { signer } = getProviderAndSigner(
											settings.network,
											accountId,
											pairingData?.topic ?? ""
											);
											if (!pairingData) {
											alert("reconnect the Hashpack wallet");
											await connectToExtension();
											}
											else{
											alertLines.push("Transaction is initiated");
											await setRequestFlag(true);
												loadAlerts(alertLines);
												let txnData=await transferHBARTo(signer,plan.priceValue,accountId,"0.0.6730");
												associateSPToken(signer,accountId);
												if(txnData!=null && txnData.data.transactionId!=undefined){
													alertLines.push("Transaction has been completed");
													alertLines.push("Wait for activation settings");
													loadAlerts(alertLines);
													setTimeout(async()=>{
															
																let _TXN=txnData.data.transactionId;
																console.log("----------- TXN:"+_TXN);
																// submit transaction
																let loginUser:string=settings.userId;
																const response = await axios.post(API_ENDPOINT_URL + `subscription/subscribe`, {
																  ci:loginUser,
																  vi:plan.id,
																  txn:_TXN,
																  walletId:accountId
																});
																if(response.status===200){
																	if(response.data.status===1)
																	{	
																		alertLines.push("Completed");
																		loadAlerts(alertLines);
																		const rawDomain = window.location.pathname.slice(1);
																		const il=rawDomain.indexOf("/");
																		const _href=rawDomain.substring(0,il);
																		showToast("Activation has been completed","success");
																		setRequestFlag(false);
																		setAlertLines([]);
																		//navigate("/"+_href);
																	}
																	else {
																		alertLines.push(response.data?.msg);
																		loadAlerts(alertLines);
																	}
																	
																}
															setRequestFlag(false);
															setAlertLines([]);
													},1000*20);
												}else {showToast("Transaction has been failed","error");
													   setRequestFlag(false);
													   setAlertLines([]);
												}
											}
										}catch(_e){
											setRequestFlag(false);
											setAlertLines([]);
											showToast("Something went wrong [UI-8900]","error");
											console.log(_e);
										}
									}
									else showToast("Kindly Login to like!");
								}}
								>Activate</button>
								}
							</div>
						);})
						}
						
						</div>  
					</div>
				  )}
                  {selMenu === 4 && (
                    <div className="max-w-[640px] border border-grey-100 rounded-2xl p-6 mt-6">
                      <div className="flex items-center justify-between mb-6">
                        <h3 className="text-grey-900 text-[32px] leading-10">
                          Display and Themes
                        </h3>
                        <MoreSVG />
                      </div>
                      <h3 className="mb-4 text-2xl font-bold text-grey-900">
                        Display Theme
                      </h3>
                      <div className="flex flex-col gap-4 mb-10 cursor-pointer sm:flex-row">
                        <div
                          onClick={() =>
                            saveSettings({
                              ...settings,
                              setting: { theme: "light" as "light" | "dark" },
                            })
                          }
                          className={`border ${
                            settings?.setting?.theme === undefined ||
                            settings?.setting?.theme?.length === 0 ||
                            settings?.setting?.theme === "light"
                              ? "border-[#3C8725] bg-[#EFFBDF]"
                              : "border-grey-200 bg-grey-50"
                          } flex gap-2 items-center w-[194px] justify-center py-6 rounded-2xl`}
                        >
                          <LightModeSVG />
                          <p className="text-xl font-bold text-grey-900">
                            Light Mode
                          </p>
                        </div>
                        <div
                          onClick={() =>
                            saveSettings({
                              ...settings,
                              setting: { theme: "dark" as "light" | "dark" },
                            })
                          }
                          className={`border ${
                            settings?.setting?.theme === "dark"
                              ? "border-[#3C8725] bg-[#EFFBDF]"
                              : "border-grey-200 bg-grey-50"
                          } flex gap-2 items-center w-[194px] justify-center py-6 rounded-2xl`}
                        >
                          <DarkModeSVG />
                          <p className="text-xl font-bold text-grey-900">
                            Light Mode
                          </p>
                        </div>
                      </div>
                      <div className="bg-[#EEEDFC] rounded-xl p-6 cursor-pointer">
                        <div className="flex justify-center mb-4">
                          <div className="bg-[#B9B8F4] p-3 flex items-center justify-center rounded-2xl">
                            <PremiumSVG />
                          </div>
                        </div>
                        <p className="mb-2 text-2xl font-bold text-center text-grey-900">
                          Want to add more themes, colors and more features?
                        </p>
                        <p className="mb-4 text-base font-medium text-grey-600">
                          Become a premium member to customize the page your way
                        </p>
                        <div className="text-center">
                          <Web23Button
                            className="w-[140px]"
                            text="Check Plans"
                            size="sm"
                            variant="secondary"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {selMenu === 6 && (
                    <div className="max-w-[640px] border border-grey-100 rounded-2xl p-6 mt-6">
                      <div className="flex items-center justify-between mb-6">
                        <h3 className="text-grey-900 text-[32px] leading-10">
                          Privacy and Safety
                        </h3>
                        <MoreSVG />
                      </div>
                      <p className="text-sm font-medium text-grey-900">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Feugiat in fermentum posuere urna nec.
                        Feugiat sed lectus vestibulum mattis ullamcorper velit
                        sed ullamcorper morbi. Massa sapien faucibus et molestie
                        ac feugiat. Velit dignissim sodales ut eu sem integer
                        vitae. Adipiscing enim eu turpis egestas. Adipiscing
                        tristique risus nec feugiat in fermentum posuere urna.
                        Ornare arcu dui vivamus arcu felis bibendum ut tristique
                        et. Id faucibus nisl tincidunt eget. Sapien et ligula
                        ullamcorper malesuada proin libero nunc. Nunc sed
                        blandit libero volutpat sed cras.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </PageContainer>
        </div>

        <div
          className="flex md:hidden justify-center items-center fixed opacity-75 w-[45px] h-[45px] border border-grey-400 rounded-full bg-grey-100 bottom-10 right-10"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <div className="flex flex-col gap-[2px]">
            <div
              className={`w-5 h-1 bg-grey-400 duration-300 ${
                isOpen ? "rotate-45 translate-y-2" : "rotate-0"
              }`}
            />
            <div
              className={`w-5 h-1 ${
                isOpen ? "bg-transparent" : "bg-grey-400 "
              }`}
            />
            <div
              className={`w-5 h-1 bg-grey-400 duration-300 ${
                isOpen ? "-rotate-45 -translate-y-1" : "rotate-0"
              }`}
            />
          </div>
        </div>
        <div
          className={`block md:hidden fixed border border-grey-100 rounded-lg w-[310px] top-[138px] left-0 h-[calc(100vh-138px)] bg-white duration-300 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          {menu.map((item, idx) => (
            <div
              key={`${menu}+${item.title}`}
              className={`${
                idx === selMenu ? "bg-[#EFFBDF]" : "bg-white"
              } px-6 cursor-pointer hover:bg-[#f9fef1] py-4 flex gap-3 items-center relative`}
              onClick={() => {
                setSelMenu(idx);
                setIsOpen(false);
              }}
            >
              <div
                className={`${
                  idx === selMenu ? "fill-[#3C8725]" : "fill-grey-400"
                }`}
              >
                {item.icon}
              </div>
              <p
                className={`font-bold text-xl ${
                  idx === selMenu ? "text-[#3C8725]" : "text-grey-400"
                }`}
              >
                {item.title}
              </p>
              {idx === selMenu && (
                <div className="absolute bg-[#3C8725] w-[6px] h-8 top-4 left-0" />
              )}
            </div>
          ))}
        </div>
      </div>

      <div
        className={`${
          showDeactive ? "scale-100" : "scale-0"
        } duration-300 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[640px] p-6 bg-white bg-opacity-80 shadow-lg`}
      >
        <div className="flex justify-between mb-2">
          <h3 className="font-bold text-[32px] leading-10 text-grey-900">
            You are going to deactivate your account temporarily
          </h3>
          <div onClick={() => setShowDeactive(false)}>
            <CloseIcon />
          </div>
        </div>
        <p className="mb-8 text-base font-medium text-grey-600">
          All your posts will be inactive and not available for any kindof
          interaction. You can activate your account by logging in at any
          moment.
        </p>
        <div className="flex flex-col items-center w-full gap-3 lg:flex-row">
          <div className="w-full grow">
            <Web23Input
              placeholder={`Type “Deactivate my account” to proceed`}
              className="w-full"
              value={terminateConfirm}
              onChange={(e) => setTerminateConfirm(e.target.value)}
            />
          </div>
          <div className="lg:w-[225px] w-full">
            <Web23Button
              text="Deactivate Account"
              className="px-1"
              onClick={async () => {
                if (terminateConfirm === "Deactivate my account") {
                  try {
                    const response = await axios.post(
                      API_ENDPOINT_URL + "auth/changeAccountSetting",
                      {
                        userId: userData.id,
                        status: "DEACTIVE",
                      }
                    );

                    if (response.status === 200) {
                      showToast("Successfully deactivate your account");
                      saveSettings({ ...initialSettings });
                      navigate("/login");
                    } else {
                      showToast(
                        "Something went wrong while connecting the server",
                        "error"
                      );
                    }
                  } catch (e) {}
                }
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={`${
          showDelete ? "scale-100" : "scale-0"
        } duration-300 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[640px] p-6 bg-white bg-opacity-80 shadow-lg`}
      >
        <div className="flex justify-between mb-2">
          <h3 className="font-bold text-[32px] leading-10 text-grey-900">
            You are going to delete your account{" "}
            <span className="text-[#D10014]">permanently</span>
          </h3>
          <div onClick={() => setShowDelete(false)}>
            <CloseIcon />
          </div>
        </div>
        <p className="mb-8 text-base font-medium text-grey-600">
          All your information which has been given to web23 like your name,
          location, public profile will no longer be stored or viewed through
          web23. Your blockchian data remains uneffected{" "}
          <span className="font-bold underline">know more</span>
        </p>
        <div className="flex flex-col items-center w-full gap-3 lg:flex-row">
          <div className="w-full grow">
            <Web23Input
              placeholder={`Type “Delete my account” to proceed`}
              className="w-full"
              value={deleteConfirm}
              onChange={(e) => setDeleteConfirm(e.target.value)}
            />
          </div>
          <div className="lg:w-[225px] w-full">
            <Web23Button
              text="Delete Account"
              className="px-1"
              onClick={async () => {
                if (deleteConfirm === "Delete my account") {
                  try {
                    const response = await axios.post(
                      API_ENDPOINT_URL + "auth/changeAccountSetting",
                      {
                        userId: userData.id,
                        status: "SUSPENDED",
                      }
                    );

                    if (response.status === 200) {
                      showToast("Successfully suspend your account");
                      saveSettings({ ...initialSettings });
                      navigate("/login");
                    } else {
                      showToast(
                        "Something went wrong while connecting the server",
                        "error"
                      );
                    }
                  } catch (e) {}
                }
              }}
            />
          </div>
        </div>
      </div>

      {postingModal && (
        <Web23Modal>
          <div className="w-[640px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between">
              <h5 className="text-[24px] font-bold text-grey-600">
                Hi, {userData.displayName}
              </h5>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setPostingModal(false)}
              />
            </div>
            <div className="mt-2">
              <h3 className="text-[32px] font-bold text-grey-900">
                Choose any option to post
              </h3>
            </div>
            <div>
              <div className="mt-6">
                <div className="flex items-center justify-around p-4 rounded-xl bg-grey-50">
                  <Web23ImgButton
                    text="NFT"
                    img={<NftSVG />}
                    backColor="bg-green-200"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({ ...modalProcess, isCreatingNFT: true });
                      // setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Photo"
                    img={<PhotoSVG />}
                    backColor="bg-red-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingPhoto: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Audio"
                    img={<AudioSVG />}
                    backColor="bg-purple-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingAudio: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Video"
                    img={<VideoSVG />}
                    backColor="bg-indigo-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingVideo: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Article"
                    img={<ArticleSVG />}
                    backColor="bg-grey-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingArticle: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Web23Modal>
      )}
      {modalProcess.isCreatingNFT && (
        <Web23Modal>
          <div className="flex w-[480px]  flex-col rounded-md bg-white p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-3xl font-bold text-grey-900">Post NFT</h3>
              <div
                className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() =>
                  setModalProcess({
                    ...modalProcess,
                    isCreatingNFT: false,
                  })
                }
              >
                <CloseIcon />
              </div>
            </div>
            <div>
              <Web23Card
                className="m-auto"
                img={
                  <img className="rounded-[32px]" src={SampleNft} alt="nft" />
                }
                description="Do you know that more than 1 million NFTs are minted everyday?"
                button={
                  <Web23Button
                    text="Create an NFT"
                    variant="primary"
                    className="w-full"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isCreatingNFT: false,
                      });
                    }}
                  />
                }
                bottomButton={
                  <Web23Button
                    text="Add from Wallet"
                    variant="secondary"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isCreatingNFT: false,
                      });
                      setShowFromWalletModal(true);
                      setAddingNft(true);
                      setTimeout(() => {
                        setAddingNft(false);
                      }, 13000);
                    }}
                  />
                }
              />
            </div>
          </div>
        </Web23Modal>
      )}
      {showFromWalletModal &&
        (walletStatus ? (
          <Web23Modal>
            <div className="flex w-[480px]  flex-col rounded-md bg-white p-6">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-3xl font-bold text-grey-900">
                  Add NFT from Wallet
                </h3>
                <div
                  className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                  onClick={() => setShowFromWalletModal(false)}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="m-auto">
                <Web23Card
                  className="m-auto"
                  img={
                    addingNft ? (
                      <div className="relative">
                        <Web23Spinner />
                      </div>
                    ) : (
                      <div className="text-xl font-bold text-center text-green-800">
                        {" "}
                        Successfully added from wallet!
                      </div>
                    )
                  }
                  variant="primary"
                  description="Communicating with wallet. Sign in with your wallet."
                  button={
                    <Web23Button
                      text="Disconnect"
                      variant="secondary"
                      className="w-[148px]"
                    />
                  }
                />
              </div>
            </div>
          </Web23Modal>
        ) : (
          <Web23Modal>
            <div className="flex flex-col p-6 bg-white rounded-md">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-3xl font-bold text-grey-900">
                  Link Wallet
                </h3>
                <div
                  className="p-2 duration-150 cursor-pointer hover:bg-grey-300"
                  onClick={() => {
                    setShowFromWalletModal(false);
                  }}
                >
                  <CloseIcon />
                </div>
              </div>

              <div className="h-full">
                {!walletConnecting && !connectedWallet?.name && (
                  <div>
                    <p className="mb-6 text-base font-medium text-grey-600">
                      Choose your wallet connection. However, we only support
                      Hedera blockchain for now. More options will be available
                      soon.
                    </p>
                    <div>
                      <div className="relative flex justify-between before:absolute before:top-[100%] before:w-[100%] before:border">
                        {walletDetail.map((walletItem, index) => (
                          <div
                            key={walletItem.type + index}
                            className={`cursor-pointer px-6 py-3 pt-2 text-xl font-bold text-grey-600 ${
                              walletTab === index
                                ? "border-b border-grey-900 text-green-600"
                                : "border-grey-100"
                            }`}
                            onClick={() => setWalletTab(index)}
                          >
                            {walletItem.type}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 mt-6">
                      {walletDetail[walletTab].detail?.map((item, index) => (
                        <div
                          key={item.name + index}
                          className="flex cursor-pointer items-center justify-between rounded-[32px] border border-grey-900 p-3 active:bg-grey-100"
                          onClick={() => {
                            setWalletConnecting(true);
                            setTimeout(() => {
                              setWalletConnecting(false);
                              setConnectedWallet({
                                name: "Hedera",
                                addres: "0x6a218A95...94F09",
                              });
                            }, 3000);
                          }}
                        >
                          <div className="flex items-center gap-3">
                            {item.icon}
                            <p className="py-1 text-base font-bold text-grey-900">
                              {item.name}
                            </p>
                          </div>
                          {walletTab > 0 && (
                            <div className="rounded-[4px] bg-yellow-300 px-1 text-base font-bold text-grey-900 ">
                              Coming Soon
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {walletConnecting && (
                  <div className="flex flex-col justify-center h-full">
                    <div className="flex items-center justify-center">
                      <div
                        className="inline-block h-[75px] w-[75px] animate-spin rounded-full border-4 border-green-500 border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    </div>
                    <p className="my-2 text-base font-medium text-center text-grey-600">
                      Communicating with wallet. Sign in with your wallet.
                    </p>
                    <div className="flex justify-center">
                      <div className="w-[148px]">
                        <Web23Button text="Disconnect" variant="secondary" />
                      </div>
                    </div>
                  </div>
                )}
                {!walletConnecting && connectedWallet?.name && (
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="mb-3 text-base font-medium text-grey-600">
                        Connected wallets
                      </p>
                      <div className="flex items-center justify-between p-4 mb-3 border rounded-3xl border-grey-900">
                        <div className="flex items-center gap-3">
                          <BladeWalletMDSVG />
                          <div>
                            <p className="mb-1 text-base font-medium text-grey-600">
                              Hedera
                            </p>
                            <p className="text-base font-bold text-grey-900">
                              0x6a218A95...94F09
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-3">
                          <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-grey-100">
                            <ContentCopySVG className="fill-grey-900" />
                          </div>
                          <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-grey-100">
                            <LinkOffSVG className="fill-grey-900" />
                          </div>
                        </div>
                      </div>
                      <p className="p-1 mb-3 text-base font-bold underline cursor-pointer text-grey-900 active:text-grey-700">
                        Link more wallets
                      </p>
                    </div>
                    <Web23Button
                      text="Continue"
                      onClick={() => {
                        setShowFromWalletModal(false);
                        setWalletStatus(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Web23Modal>
        ))}
      {modalProcess.isCreatingNFT && (
        <Web23Modal>
          <CreatingNftModal />
        </Web23Modal>
      )}
      {modalProcess.finalizaingNftCreation && (
        <>
          {!modalProcess.finishedNftCreation ? (
            <Web23Modal>
              <div className="flex w-[528px] flex-col items-start rounded-lg bg-white p-6">
                <h3 className="mb-6 text-[32px] font-bold text-grey-900">
                  Create NFT
                </h3>
                <div className="flex flex-col items-center w-full">
                  <Web23Spinner />
                  <p className="mt-6 text-base font-bold text-grey-600">
                    Complete the process in your wallet
                  </p>
                </div>
              </div>
            </Web23Modal>
          ) : (
            <Web23Modal>
              <div
                className="p-6 bg-white rounded-lg cursor-pointer"
                onClick={() => {
                  setModalProcess({
                    ...modalProcess,
                    finalizaingNftCreation: false,
                  });
                }}
              >
                <h3 className="mb-6 text-[32px] font-bold text-grey-900">
                  NFT Creation Successful
                </h3>
                <div className="flex flex-col items-center">
                  <CheckBigSVG />
                  <p className="mt-6 text-base font-bold text-grey-600">
                    NFT will be posted on to your timeline as soon as it’s
                    minted.
                  </p>
                </div>
              </div>
            </Web23Modal>
          )}
        </>
      )}
      {modalProcess.isPostingPhoto && (
        <Web23Modal>
          <Web23PostMedia variant="photo" />
        </Web23Modal>
      )}
      {modalProcess.isPostingAudio && (
        <Web23Modal>
          <Web23PostMedia variant="audio" />
        </Web23Modal>
      )}
      {modalProcess.isPostingVideo && (
        <Web23Modal>
          <Web23PostMedia variant="video" />
        </Web23Modal>
      )}
      {modalProcess.isPostingArticle && (
        <Web23Modal>
          <Web23PostMedia variant="article" />
        </Web23Modal>
      )}
      {ToasterBox}
	  
	  {requestFlag===true &&  
		<div className="fixed top-0 left-0 w-full h-full  backdrop-blur-sm bg-white/30 ">
		  <div className="opacity-100 bg-white fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  min-w-[480px] overflow-y-auto rounded-lg border border-grey-900 scrollbar-thin scrollbar-thumb-grey-900 overflow-x-hidden scrollbar-track-white">
			<div className="text-2xl p-4 bg-green-200">Transaction</div>
			<div className='alertBody p-4' id="alertBody_9090">
			
			</div>
			<div className="text-lg p-4 bg-green-200">...</div>
		  </div>
		</div>
	  } 
	  
    </>
  );
};

export default SettingPage;
