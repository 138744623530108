import React from 'react';

type IWeb23Spinner = {
  variant?: 'sm' | 'md'
}

const Web23Spinner: React.FC<IWeb23Spinner> = (
  {variant='md'}
) => {
  return (
    <div
      className={`${variant === 'md' ? 'h-[75px] w-[75px] animate-spin rounded-full border-[8px] border-green-500 border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]' :
        'h-[25px] w-[25px] animate-spin rounded-full border-[4px] border-green-500 border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'}
      `}
      role='status'
    >
      <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
        Loading...
      </span>
    </div>
  );
};

export default Web23Spinner;
