// import Image from 'next/image';
import React from 'react';

import { Web23Button, Web23Card } from '../../components';

import SampleNft from '../../assets/img/sampleNFT.png';

const NftTab: React.FC = () => {
  return (
    <Web23Card
      img={<img className='rounded-[32px]' src={SampleNft} alt='nft' />}
      title="Let's populate this space, shall we?"
      description='Post an update or share your thoughts'
      button={<Web23Button text='Post' variant='primary' />}
    />
  );
};

export default NftTab;
