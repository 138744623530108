import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import { ROUTES } from "./routes/constants";

import HomePage from "./pages/home-page";
import LoginPage from "./pages/login";
import OnboardingPage from "./pages/onboarding";
import SharedPage from "./pages/onboarding/sharedPage";
import CreateAccountPage from "./pages/create-account";
import ConfigDomainPage from "./pages/create-account/domain";
import NFTDetailForCreator from "./pages/onboarding/detail/creator";
import NFTDetailForVisitor from "./pages/onboarding/detail/visitor";
import SettingPage from "./pages/setting";
import ImportWebsite from "./pages/import-website";
let pageURL:string=(window as any).location.href;

function App() {
  return (
    <>
      <main>
        <Routes>
          <Route path={ROUTES.HOME} element={<HomePage />} />
          
          <Route path={ROUTES.SIGN_UP} element={<CreateAccountPage />} />
          <Route path={ROUTES.DOMAIN} element={<ConfigDomainPage />} />
          <Route path={ROUTES.SIGN_IN} element={<LoginPage />} />
          <Route path={ROUTES.ONBOARD} element={<OnboardingPage />}  />
		  <Route path={ROUTES.SHARED} element={<SharedPage />}/>
		  <Route path={ROUTES.IMPORT_WEBSITE} element={<ImportWebsite />}/>
          <Route
            path={ROUTES.NFT_DETAIL_CREATOR}
            element={<NFTDetailForCreator />}
          />
          <Route
            path={ROUTES.NFT_DETAIL_VISITOR}
            element={<NFTDetailForVisitor />}
          />
          <Route path={ROUTES.SETTING} element={<SettingPage />} />
          <Route path="*" element={<OnboardingPage />} />
        </Routes>
      </main>
	  
    </>
  );
}

export default App;
