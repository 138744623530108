import axios from "axios";
import React, { useEffect, useState,useContext } from "react";
import { API_ENDPOINT_URL } from "../config";
import { SettingContext} from "./../context/context";
import useToast from "./../utility/useToast";
const DisplayNftCardETH: React.FC<{
  nft?: any;
  
}> = ({
  nft
}) => {
  const { showToast } = useToast();	
  const { settings, saveSettings } = useContext(SettingContext);
  const [nftImage,setNftImg]=useState<string>("");
  const [nftMetadata,setNftMetadata]=useState<any>({});
  const [isAdded,setIsAdded]=useState<boolean>(false);
  let lockEffect:boolean=false;
  useEffect(() => {
  }, []);
  
  useEffect(()=>{
	if(lockEffect===false) { lockEffect=true;
		callNFTFromIPFS();
		checkAdded();
	}
  },[]);
  let __o:any=null;
  const callNFTFromIPFS=async()=>{
	  try{
	  
	  let ipfsToken:string=nft?.token_uri; 
	  let response:any=await axios.get(ipfsToken);
	  if(response.status===200){
		  console.log(response.data);
		  let nftData=response?.data;
		  if(nftData===null || nftData===undefined) { return;}
		  setNftMetadata(nftData);
		  try{clearInterval(__o)}catch(_t){}
		  
	  }else{ console.log("__________ETH CARD--"+response.status+"---------");
			 console.log(response?.data);
	  }
	  }catch(_E){
		  console.log("__________ETH CARD-----------");
		  console.log(_E);
		  let _e:any=_E;
		  if(_e?.response?.status===429){
			 requestAgain();
		  }
	  }
  }
  const requestAgain=()=>{
	 __o = setInterval(()=>{
					callNFTFromIPFS();
				}, 2000);
  }
  const checkAdded=async()=>{
	  try{
		let nftslno:string=nft?.token_id;
		let nfttoken:string=nft?.token_address; 
		const baseURL = `${API_ENDPOINT_URL}` + "nft/get";  
		const response = await axios.post(baseURL, 
								{
								tokenId: nfttoken,
								serialNumber: nftslno
							  });
		if(response.status===200){
			setIsAdded(true);
		} else setIsAdded(false);			
	  }catch(_e){ console.log(_e); setIsAdded(false);}
  }
  return(
    <>
		{nftMetadata && nftMetadata?.image &&
		<div  className='h-[390px] border border-slate-300 overflow-hidden shadow rounded pb-2'>
			<>
				<div className='relative h-[280px] flex flex-col w-full justify-center overflow-hidden'>
					<img src={(nftMetadata?.image)} className='m-auto' />
					<div className='absolute top-0 left-0 z-50 text-right w-full'>
					
					{ isAdded===false? 
						<>
						<button className='bg-blue-400 text-white px-4 rounded-tr-l'
						onClick={async(e)=>{
						try{
							let nftslno:string=nft?.token_id;
							let nfttoken:string=nft?.token_address; 
							let accountId=nft?.owner_of;
							let name=nftMetadata?.name;
							let nftImg=nftMetadata?.image;
							let created_timestamp=nft?.last_token_uri_sync;
							let description=nftMetadata?.description;
							let ipfsToken=nft?.token_uri;
							let supply="1";
							const baseURL = `${API_ENDPOINT_URL}` + "nft/addexternalnft";
							const metadata = {
											name: name,
											description: description,
											image: nftImg,
											supply: 1,
											detail: {
											  collection: "",
											  externalLinks:"",
											  altText:"",
											},
											price: {
											  priceType:"Not For Sale",
											  price:0,
											  royalty: 0,
											  currency: "ETH",
											  auctionStart:"",
											  auctionEnd:"",
											},
											property: [],
											additional: {},
										  };
							const response = await axios.post(baseURL, {
								user_id: settings.userId,
								chainName: "MetaMask",
								walletAddress: accountId,
								ipfsHash: ipfsToken,
								metadata: metadata,
								tokenId: nfttoken,
								nftId: nftslno
							  });
							  if(response.status===200) {
								  showToast("Added Successfully");
								saveSettings({
											  ...settings,
											  nft: {
												chainName: "MetaMask",
												nftName: name,
												nftImage: nftImg,
												tokenId: nfttoken,
												serialNumber: nftslno,
												supply: 1,
												nftDescription: description,
												details: {
												  collection: "",
												  externalLinks: "",
												  altText: "",
												},
												price: {
												  priceType: "Not For Sale",
												  price: 0,
												  royalty: 0,
												  currency: "ETH",
												  auctionStart:"",
												  auctionEnd: "",
												},
												additional: {
												  watchNumber: 0,
												  favorites: 0,
												  messagesNumber: 0,
												},
											  },
										});  
								  
							  }
							  else showToast("Something went wrong","error");
						}catch(_E){
							console.log(" ADD EXT ");
							console.log(_E);
							let _e:any=_E;
							if(_e.response.status===400) showToast(_e.response?.data?.error,"error");
							else showToast("Something went wrong","error");
							
						}
						}}
						>Add to Timeline</button>
						</>
						:
						<><i className='bi bi-check2-square bg-blue-400 text-white px-4 rounded-tr-l py-2'></i></>
					}
					</div>
				</div>
				<div className='block h-[100px] p-2 bg-slate-50'>
					<div className='text-base text-black '>{nftMetadata?.name}</div>
					<div className='text-sm text-slate-500 pt-2'>{nftMetadata?.description}</div>
				</div>
			</>
		
		</div>
	}
	</>
  );
};

export default DisplayNftCardETH;
