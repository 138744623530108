import { useRef, useEffect } from "react";
import { Link, useLocation,useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { Web23Button } from "../../components";

import { ReactComponent as Web23BadgeSVG } from "../../assets/icon/Web23-Badge.svg";
import { ReactComponent as SmartPageLogoSVG } from "../../assets/icon/smart-page-logo.svg";
import ImageLogo from "../../assets/img/login001.png";
import ImageLogo1 from "../../assets/img/login002.png";
import ImageLogo2 from "../../assets/img/login003.png";
import ImageDashboard from "../../assets/img/dashImg.png";

const HomePage = () => {
  const navigate=useNavigate();
  const sliderRef = useRef<Slider | null>(null);
  const { pathname } = useLocation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const timer = setInterval(() => {
      sliderRef.current?.slickNext();
    }, 4000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="flex flex-col w-full h-full bg-white overflow-x-hidden">
      <div className="flex items-center gap-2 px-8 py-3 mb-2">
        <div className="flex items-center justify-between justify-items-center">
          <SmartPageLogoSVG className="mr-10" />
          <Web23BadgeSVG />
        </div>
      </div>
      <div className="bg-yellow-50">
        <Slider {...settings} ref={sliderRef}>
          <div className="!flex justify-center">
            <div className="flex items-center justify-center sm:p-[52px] p-4 ">
              <div className="relative rounded-lg bg-yellow-50">
                <img
                  src={ImageDashboard}
                  style={{ backgroundColor: "#FFFBEB" }}
                  alt="avatar"
                />
              </div>
            </div>
          </div>
          <div className="!flex justify-center">
            <div className="flex items-center justify-center sm:p-[52px] p-4 ">
              <div className="relative rounded-lg bg-yellow-50">
                <img
                  src={ImageLogo}
                  style={{ backgroundColor: "#FFFBEB" }}
                  alt="avatar"
                />
                <div className="absolute top-0 left-[-96px] hidden sm:block">
                  <img src={ImageLogo1} alt="avatar" />
                </div>
                <div className="absolute bottom-0 right-[-96px] hidden sm:block">
                  <img src={ImageLogo2} alt="avatar" />
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div className="flex justify-center flex-1 px-8 pb-8 bg-yellow-50">
        <div className="flex flex-col justify-center w-full h-full">
          <div className="flex items-center justify-center sm:p-[52px] p-4 "></div>
          <h3 className="mb-2 text-center sm:text-[36px] text-xl font-bold text-grey-900">
            Build your economy with your unique currency
          </h3>
          <p className="sm:mb-10 mb-5 text-center sm:text-[20px] text-sm font-bold sm:leading-7 text-grey-600">
            Create Social tokens and rewards your fans and followers with them
          </p>
          <div className="flex justify-center mb-3">
            <div className="w-[480px]">
              
              <Web23Button
                text="Create an Account"
                onClick={() => {
                  //document.getElementById("create-account")?.click();
				  navigate("/create-account");
                }}
                size="sm"
              />
            </div>
          </div>
          <p className="mb-10 text-xs font-medium text-center sm:text-sm text-grey-900">
            Already have an account?{" "}
            <Link
              to={"/login"}
              className="text-sm underline cursor-pointer text-bold text-grey-900"
              //   onClick={() => {
              //     router.push('/login');
              //   }}
            >
              Log In
            </Link>
          </p>
          <div className="flex justify-center">
            <p className="max-w-[420px] text-center sm:text-xs text-[8px] font-medium text-grey-700">
              By Signing up for Web23, you have already agreed to our terms &
              Conditions. Learn have we process your data in privacy policy and
              cookies policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
