import axios from "axios";
import React, { useEffect, useState,useContext} from "react";
import { API_ENDPOINT_URL } from "../config";
import { SettingContext} from "./../context/context";
import { useHashconnectService } from "../context/Hashconnect";
import useToast from "./../utility/useToast";
import { ReactComponent as MetaMaskSVG } from "../assets/icon/Metamask.svg";
import { ReactComponent as BladeWalletSVG } from "../assets/icon/Blade1.svg";
import { ReactComponent as HashPackSVG } from "../assets/icon/HashPack.svg";
import { ReactComponent as HederaSVG } from "../assets/icon/Hedera.svg";
import { ReactComponent as AvalancheWalletSVG } from "../assets/icon/Avalanche.svg";
import { ReactComponent as SolanaWalletSVG } from "../assets/icon/Solana.svg";
import { useNavigate } from "react-router-dom";
import DisplayNftCard from "./../components/displaynftcard";
import DisplayNftCardETH from "./../components/displaynftcardeth";
import DisplayNftCardSolana from "./../components/displaynftcardsolana";

import Moralis from "moralis";
import { SolNetwork } from "@moralisweb3/common-sol-utils";

const chainType = [
  { name: "Blade", icon: <BladeWalletSVG /> },
  { name: "Hashpack", icon: <HashPackSVG /> },
  { name: "MetaMask", icon: <MetaMaskSVG /> },
  { name: "Solana", icon: <SolanaWalletSVG /> },
  { name: "Avalanche", icon: <AvalancheWalletSVG /> },
];
const WalletListCard: React.FC<{
  closeStatus?: number;
  onClose?: () => void;
  
}> = ({
  closeStatus,
  onClose
}) => {
  const navigate = useNavigate();	
  const [closeModel,setCloseModel]=useState<number>(0);	
  const [showWallets,setShowWallets]=useState<boolean>(true);	
  const { showToast } = useToast();	
  const { settings, saveSettings } = useContext(SettingContext);
  const [walletAddress,setWalletAddress]=useState<string>(settings?.walletAddress);
  const [walletName,setWalletName]=useState<string>(settings?.walletName);
  const [nfts,setNfts]=useState<any>([]);
  const {
    pairingData,
    availableExtension,
    connectToExtension,
    disconnect,
    getProviderAndSigner,
    publicKey,
  } = useHashconnectService();
  
  /* COnnect MetaMask */
	  const connectMetamask= async()=> {
	   //to get around type checking
	   (window as any).ethereum
		 .request({
			 method: "eth_requestAccounts",
		params: [{ chainId: "0x5" }]
		 })
		 .then((accounts : string[]) => {
		  setWalletAddress(accounts[0]);
		  setWalletName("MetaMask");
		  })
		 .catch((error: any) => {
			 alert(`Something went wrong: ${error}`);
		 });
	 }
  
  // ENn MEta Mask
  // Connect Solana //
	const connectPhantom = async () => {
    if ("solana" in (window as any)) {
		  const provider = (window as any).solana;
		  if (provider.isPhantom) {
			const resp = await provider.connect();
			setWalletAddress(resp?.publicKey?.toString());
			setWalletName("Solana");
		}
    } else {
        window.open("https://www.phantom.app/", "_blank");
    }
};
  // End Solana 
  const callNFTFromMirror=async(_selectedWallet:string)=>{
	  try{ 
		  setNfts([]);
		  if(_selectedWallet){
			  let response:any=await axios.get("https://testnet.mirrornode.hedera.com/api/v1/accounts/"+_selectedWallet+"/nfts?limit=100");
			  if(response.status===200){
				  console.log(response.data);
				  let nfts=response?.data?.nfts;
				  if(nfts===null || nfts.length===0) { return;}
				  setNfts(nfts);
				  
			  }
			}	
		}catch(_e){}
		
  }
  const callNFTFromEth=async(_selectedWallet:string)=>{
		setNfts([]);	
		try {
			let data = '';

			let config = {
			  method: 'get',
			  maxBodyLength: Infinity,
			  url: 'https://deep-index.moralis.io/api/v2/'+_selectedWallet+'/nft?chain=goerli&format=decimal&media_items=false',
			  headers: { 
				'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZmMDkxYmJhLThhZWYtNDEwZi05MGMyLWU4ZTIwYzRkMjUzZSIsIm9yZ0lkIjoiMzM2OTA4IiwidXNlcklkIjoiMzQ2MzgyIiwidHlwZUlkIjoiYTIzZWE5YjMtMjY1NS00ZjZmLThmMTQtNjdhNDk0ZDI4NDE3IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODQ5MjQxNDAsImV4cCI6NDg0MDY4NDE0MH0.hpvWsrLq2lFW2jri5uL_CXREoSWQkwPgM64MgwDzKEU'
			  },
			  data : data
			};

			axios.request(config)
			.then((response) => {
			  let nftsJSON:any=response.data;
			  setNfts(nftsJSON?.result);
			})
			.catch((error) => {
			  console.log(error);
			});
			//console.log(Moralis2);
			//await Moralis.start({	apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZmMDkxYmJhLThhZWYtNDEwZi05MGMyLWU4ZTIwYzRkMjUzZSIsIm9yZ0lkIjoiMzM2OTA4IiwidXNlcklkIjoiMzQ2MzgyIiwidHlwZUlkIjoiYTIzZWE5YjMtMjY1NS00ZjZmLThmMTQtNjdhNDk0ZDI4NDE3IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODQ5MjQxNDAsImV4cCI6NDg0MDY4NDE0MH0.hpvWsrLq2lFW2jri5uL_CXREoSWQkwPgM64MgwDzKEU"
			//});
			/*const response = await Moralis.EvmApi.nft.getWalletNFTs({
				"chain": "0x5",
				"format": "decimal",
				"mediaItems": false,
				"address": _selectedWallet
			});
			
			if(response?.raw?.result){
				setNfts(response?.raw?.result);
			}*/
		}
		catch (e) {
			console.error(e);
		}
};
  const callNFTFromSolana = async (_selectedWallet:string) => {
	    setNfts([]);
		try{
			let __url="https://solana-gateway.moralis.io/account/devnet/"+_selectedWallet+"/nft";
			let data = '';
			let config = {
			  method: 'get',
			  maxBodyLength: Infinity,
			  url: __url,
			  headers: { 
				'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZmMDkxYmJhLThhZWYtNDEwZi05MGMyLWU4ZTIwYzRkMjUzZSIsIm9yZ0lkIjoiMzM2OTA4IiwidXNlcklkIjoiMzQ2MzgyIiwidHlwZUlkIjoiYTIzZWE5YjMtMjY1NS00ZjZmLThmMTQtNjdhNDk0ZDI4NDE3IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODQ5MjQxNDAsImV4cCI6NDg0MDY4NDE0MH0.hpvWsrLq2lFW2jri5uL_CXREoSWQkwPgM64MgwDzKEU'
			  },
			  data : data
			};

			axios.request(config)
			.then((response) => {
			  let nftsJSON:any=response.data;
			  console.log("*********** SOLANA NFT LIST---------");
			  setNfts(nftsJSON);
			})
			.catch((error) => {
			  console.log(error);
			});
			/*if(Moralis.Core.isStarted===false)
			await Moralis.start({
			apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjZmMDkxYmJhLThhZWYtNDEwZi05MGMyLWU4ZTIwYzRkMjUzZSIsIm9yZ0lkIjoiMzM2OTA4IiwidXNlcklkIjoiMzQ2MzgyIiwidHlwZUlkIjoiYTIzZWE5YjMtMjY1NS00ZjZmLThmMTQtNjdhNDk0ZDI4NDE3IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODQ5MjQxNDAsImV4cCI6NDg0MDY4NDE0MH0.hpvWsrLq2lFW2jri5uL_CXREoSWQkwPgM64MgwDzKEU",
			// ...and any other configuration
			});
			const response = await Moralis.SolApi.account.getNFTs({
				address:_selectedWallet,
				network:SolNetwork.DEVNET
			});
			setNfts(response?.toJSON());
			*/
		}catch(_e){
			console.log("****************Solana Moralis");
			console.log(_e);
		}
};
  
  useEffect(() => {
	  setCloseModel(Math.random()*1000);
	 
  }, [closeStatus]);
  
  useEffect(()=>{
	if(pairingData) { 
	 setWalletAddress(pairingData?.accountIds?.[0]);
	 callNFTFromMirror(pairingData?.accountIds?.[0]);
	}
  },[pairingData]);
  useEffect(()=>{
	
	if(walletAddress && walletAddress!="") { 
		if(walletName==="MetaMask")
			callNFTFromEth(walletAddress);
		else if(walletName==="Solana")
			callNFTFromSolana(walletAddress);
	}
	
  },[walletAddress]);
  
  const handleConnectWallet = async (
    walletName: string,
    icon: React.ReactNode
  ) => {
	setWalletName(walletName);
	setWalletAddress("");
    if (walletName === "MetaMask") {
      await connectMetamask();
      return;
    }
	else if (walletName === "Solana") {
      await connectPhantom();
      return;
    }
	else if (walletName === "Hashpack") {
        if(availableExtension && !pairingData) {
		  await connectToExtension();
		} else if (pairingData) disconnect();
		else {
		  alert("Install the Hashpack chrome extension.");
		}
	}
	
    
    
  }
  return(
    <>
	
	{(closeModel!=0 &&  closeStatus!=0) &&
		<>
		<div className="mx-auto fixed top-0 left-0 w-full h-full mb-6  backdrop-blur-sm bg-white/30 z-40 py-4 " >
		  <div className='container h-full mx-auto overflow-visible overflow-y-scroll'>
			  <div className="bg-white border border-slate-200 scrollbar-thin scrollbar-thumb-grey-900 overflow-x-hidden scrollbar-track-white">
				<div className='flex w-full bg-slate-100'>
				<div className="grow text-2xl p-4 ">Choose Wallet</div>
				<div className="shrink text-base p-4 cursor-pointer "
				onClick={e=>{setCloseModel(0);navigate(0);}}
				>Close</div>
				</div>
				<div className='p-2'>
						<div className="flex  flex-row flex-row-reverse">
							<div className="cursor-pointer rounded-full bg-slate-100 hover:bg-slate-300 w-[48px] h-[48px] text-center leading-[48px]"
							onClick={e=>{
								setShowWallets(!showWallets);
							}}
							><i className="bi bi-three-dots-vertical"></i></div>
						</div>
						<div className="flex  flex-col justify-between">
						<div className="mt-6">
							<div className="flex flex-row mb-6">
												<span className='text-sm text-gray-500 bg-slate-300 py-1 px-2 rounded-full '>Selected Wallet</span>
												{walletAddress!=""?
													<>
													<span className='text-sm text-white bg-blue-500 py-1 px-4 rounded-full'>{walletName}</span>
													<span className='text-sm text-white bg-blue-500 py-1 px-4 rounded-full'>{walletAddress}</span>
													</>
													:
													<>
													<span className='text-sm text-gray-500 bg-slate-100 py-1 px-4 rounded-full '>No Selected</span>
													</>
												}
													
							</div>
							{showWallets===true && 
								<div className="flex flex-col gap-1">
								  {chainType?.map((item, index) => (
									<div
									  key={item.name + index}
									  className="flex cursor-pointer items-center justify-between rounded-full border border-slate-300 shadow p-1 active:bg-grey-100"
									  onClick={() => {
										handleConnectWallet(item.name, item.icon);
									  }}
									 
									>
									  <div className="flex items-center">
										{item.icon}
										<p className="py-1 text-sm font-bold text-grey-900 px-2">
										  {item.name}
										</p>
									  </div>
									</div>
								  ))}
								</div>
							}
						</div>
						</div>
						
						{ nfts && nfts.length>0 && 
							<>
							<div className='mt-4 mb-4 text-center'>
								<span className='text-xl text-black-100'>NFTs</span>
							</div>
							<div className="grid grid-cols-2 md:grid-cols-4 gap-2">
							{nfts.map((item:any,ind:number)=>{
									return(
									<>
									{ walletName==="Hashpack" &&
										<>
										<DisplayNftCard nft={item} />
										</>
									}
									{ walletName==="MetaMask" &&
										<>
										<DisplayNftCardETH nft={item} />
										</>
									}
									{ walletName==="Solana" &&
										<>
										<DisplayNftCardSolana nft={item} />
										</>
									}
									</>
								);})
							}
							</div>
							</>
						}
						
				</div> {/* ALert Body */}
				<div className="text-lg p-4 bg-slate-100"></div>
			  </div>
		  </div>
		</div>
		
	</>	
	}</>
  );
};

export default WalletListCard;
