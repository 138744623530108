import axios from "axios";
export const getImageFromURL=async(__url:string)=>{
	try{
		if(__url==="") return(null);
		let response=await axios.get(__url,{responseType: "arraybuffer"});
		if(response.status===200){
			const base64 = btoa(
                    new Uint8Array(response.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                )
				
            return(`data:;base64,${base64}`);
		}
	}catch(_e){}
	return(null);
}