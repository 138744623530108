export const ROUTES = {
  HOME: "/",
  SIGN_UP: "/create-account",
  DOMAIN: "/create-account/domain",
  SIGN_IN: "/login",
  ONBOARD: "/:domainName",
  SHARED: "/shared/:domainName",
  NFT_DETAIL_CREATOR: "/:domainName/:tokenId/:serialNumber/creator",
  NFT_DETAIL_VISITOR: "/:domainName/:tokenId/:serialNumber/visitor",
  SETTING: "/:domainName/settings",
  IMPORT_WEBSITE: "/:domainName/import-website",
};

export const ROLES = {
  CREATOR: {
    label: "admin",
    id: 1,
  },
  VIEWER: {
    label: "customer",
    id: 2,
  },
};
