import React from 'react';

const Web23DropDown: React.FC<{
  selectTitle?: string;
  optionList?: string[];
}> = ({ selectTitle, optionList }) => {
  return (
    <div>
      <select
        id='web23dropdown'
        className='block w-full rounded-3xl border border-gray-300 bg-gray-50 p-2.5 text-base text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
      >
        <option selected>{`${selectTitle}`}</option>
        {optionList?.map((item: string, id: number) => {
          return (
            <option
              key={`${item} + ${id}`}
              value={`${item}`}
            >{`${item}`}</option>
          );
        })}
      </select>
    </div>
  );
};

export default Web23DropDown;
