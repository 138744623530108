import axios from "axios";
import React from "react";
import { HashConnect, HashConnectTypes, MessageTypes } from "hashconnect";
import { HashConnectConnectionState } from "hashconnect/dist/types";

//create the hashconnect instance
const hashconnect = new HashConnect(true);

export interface ProviderProps {
  children: React.ReactNode;
  network?: "testnet" | "mainnet" | "previewnet";
  metaData?: HashConnectTypes.AppMetadata;
  debug?: boolean;
}

export interface HashconnectContextAPI {
  availableExtension: HashConnectTypes.WalletMetadata;
  state: HashConnectConnectionState;
  topic: string;
  pairingString: string;
  publicKey: string;
  pairingData: HashConnectTypes.SavedPairingData | null;
}

const appMetadata: HashConnectTypes.AppMetadata = {
  name: "web23 smart pages",
  description: "web23 social websites",
  icon: "https://www.hashpack.app/img/logo.svg",
};

const HashconectServiceContext = React.createContext<
  Partial<
    HashconnectContextAPI & {
      network: "testnet" | "mainnet" | "previewnet";
      setState: React.Dispatch<React.SetStateAction<Partial<HashconnectContextAPI>>>;
    }
  >
>({});

export const HashconnectAPIProvider = ({
  children,
  metaData,
  network = "testnet",
  debug,
}: ProviderProps) => {
  const [state, setState] = React.useState<Partial<HashconnectContextAPI>>({});

  const initHashconnect = async () => {
    //initialize and use returned data
    let initData = await hashconnect.init(
      metaData ?? appMetadata,
      network,
      false
    );

    const topic = initData.topic;
    const pairingString = initData.pairingString;
    //Saved pairings will return here, generally you will only have one unless you are doing something advanced
    const pairingData = initData.savedPairings[0];

    const baseURL =
      network === "mainnet"
        ? `https://mainnet-public.mirrornode.hedera.com/api/v1/accounts?account.id=${pairingData?.accountIds[0]}`
        : `https://testnet.mirrornode.hedera.com/api/v1/accounts?account.id=${pairingData?.accountIds[0]}`;
    const response: any = await axios.get(baseURL);

    let pubKey = "";
    if (response.status === 200) pubKey = response.data.accounts[0].key.key;
    setState((exState) => ({
      ...exState,
      topic,
      pairingData,
      publicKey: pubKey,
      pairingString,
      state: HashConnectConnectionState.Disconnected,
    }));
  };

  const onFoundExtension = (data: HashConnectTypes.WalletMetadata) => {
    setState((exState) => ({ ...exState, availableExtension: data }));
  };

  const onParingEvent = async(data: MessageTypes.ApprovePairing) => {
    await setState((exState) => ({ ...exState, pairingData: data.pairingData }));
	console.log("onParingEvent");
	console.log(data.pairingData);
	let _p=data?.pairingData?.accountIds;
	localStorage.setItem("HashConnectPaired",(_p==undefined?"0.0.0":_p[0]));
	console.log("HashConnectPaired",(_p==undefined?"0.0.0":_p[0]));
  };

  const onConnectionChange = (state: HashConnectConnectionState) => {
    setState((exState) => ({ ...exState, state }));
	
	
  };

  const onTransactionEvent = (data: MessageTypes.Transaction) => {};
  //register events
  React.useEffect(() => {
    hashconnect.foundExtensionEvent.on(onFoundExtension);
    hashconnect.pairingEvent.on(onParingEvent);
    hashconnect.connectionStatusChangeEvent.on(onConnectionChange);
    hashconnect.transactionEvent.on(onTransactionEvent);
    return () => {
      hashconnect.foundExtensionEvent.off(onFoundExtension);
      hashconnect.pairingEvent.off(onParingEvent);
      hashconnect.connectionStatusChangeEvent.off(onConnectionChange);
      hashconnect.transactionEvent.off(onTransactionEvent);
    };
  }, []);

  //Call Initialization
  React.useEffect(() => {
    initHashconnect();
  }, []);

  return (
    <HashconectServiceContext.Provider value={{ ...state, setState, network }}>
      {children}
    </HashconectServiceContext.Provider>
  );
};

export const useHashconnectService = () => {
  const value = React.useContext(HashconectServiceContext);
  const { topic, pairingData, network, setState } = value;

  const connectToExtension = async () => {
    //this will automatically pop up a pairing request in the HashPack extension
    await hashconnect.connectToLocalWallet();
  };

  const sendTransaction = async (
    trans: Uint8Array,
    acctToSign: string,
    return_trans: boolean = false,
    hideNfts: boolean = false
  ) => {
    const transaction: MessageTypes.Transaction = {
      topic: topic!,
      byteArray: trans,

      metadata: {
        accountToSign: acctToSign,
        returnTransaction: return_trans,
        hideNft: hideNfts,
      },
    };

    return await hashconnect.sendTransaction(topic!, transaction);
  };

  const getProviderAndSigner = (
    network: string,
    accountId: string,
    topic: string
  ) => {
    const provider = hashconnect.getProvider(network, topic, accountId);
    const signer = hashconnect.getSigner(provider);

    return { provider, signer };
  };

  const disconnect = () => {
    hashconnect.disconnect(pairingData?.topic!);
    setState!((exState) => ({ ...exState, pairingData: null }))!;
	localStorage.setItem("HashConnectPaired","0");
  };

  const requestAccountInfo = async () => {
    const request: MessageTypes.AdditionalAccountRequest = {
      topic: topic!,
      network: network!,
      multiAccount: true,
    };

    await hashconnect.requestAdditionalAccounts(topic!, request);
  };

  const clearPairings = () => {
    hashconnect.clearConnectionsAndData();
    setState!((exState) => ({ ...exState, pairingData: null }));
  };

  return {
    ...value,
    connectToExtension,
    sendTransaction,
    disconnect,
    requestAccountInfo,
    clearPairings,
    getProviderAndSigner,
  };
};
