import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { API_ENDPOINT_URL } from "../../config";

import { ReactComponent as FavoriteSVG } from "../../assets/icon/favorites.svg";
import { ReactComponent as CommentSVG } from "../../assets/icon/message.svg";
import { ReactComponent as ShareSVG } from "../../assets/icon/share.svg";
import { ReactComponent as VerifiedSVG} from "../../assets/icon/verified.svg";
import Web23Button from "../web23-button";

type IBidItem = {
  bidderAddress: string;
  bidderName: string;
  price: number;
  maxPrice: number;
  toTokenId: string;
  toSerialNumber: string;
  created: string;
};

type IOfferItem = {
  bidderAddress: string;
  bidderName: string;
  price: number;
  coinType: string;
  toTokenId: string;
  toSerialNumber: string;
  memo: string;
  created: string;
};


const Web23MarketItem: React.FC<{
  content?: string;
  location?: string;
  copyright?: string;
  detail?: string;
  nftName?: string;
  tokenId?: string;
  serialNumber?: string;
  price?: string;
  symbol?: string;
  likes?: string;
  nftImage?: string;
  isCreator?: boolean;
  commentNumber?: number;
  nftData?: any;
}> = ({
  content,
  location,
  copyright,
  detail,
  nftName,
  serialNumber,
  symbol,
  price,
  likes,
  commentNumber,
  nftImage,
  tokenId,
  isCreator = false,
  nftData,
}) => {
  const navigate = useNavigate();
  const [bidList, setBidList] = useState<IBidItem[]>([]);
  const [offerList, setOfferList] = useState<IOfferItem[]>([]);

  const fetchBid = async() => {
    const baseURL = `${API_ENDPOINT_URL}bid/getBids`;
    try {
      const response = await axios.get(baseURL, {
        params: {
          toTokenId: tokenId,
          toSerialNumber: serialNumber,
        },
      });
      if (response.status === 200) {
        setBidList(
          response?.data?.data?.map((item: any) => {
            return {
              bidderAddress: item?.bidderAddress,
              bidderName: item?.bidderName,
              price: parseFloat(item?.price),
              maxPrice: parseFloat(item?.maxPrice),
              toTokenId: item?.toTokenId,
              toSerialNumber: item?.toSerialNumber,
              created: item?.created,
            };
          })
        );
      }
    } catch (e: any) {
      console.log("Error: ", e.message);
    }
  }

  const fetchOffer = async() => {
    const baseURL = `${API_ENDPOINT_URL}offer/getOffers`;
    try {
      const response = await axios.get(baseURL, {
        params: {
          toTokenId: tokenId,
          toSerialNumber: serialNumber,
        },
      });
      if (response.status === 200) {
        setOfferList(
          response?.data?.data?.map((item: any) => {
            return {
              bidderAddress: item?.bidderAddress,
              bidderName: item?.bidderName,
              price: parseFloat(item?.price),
              coinType: item?.coinType,
              toTokenId: item?.toTokenId,
              toSerialNumber: item?.toSerialNumber,
              memo: item?.memo,
              created: item?.created,
            };
          })
        );
      }
    } catch (e: any) {
      console.log("Error: ", e.message);
    }
  }
  useEffect(() => {
    fetchBid();
    fetchOffer();
  }, [])

  return (
    <div className="p-4 mt-5 border-2 rounded-lg border-grey-200">
        <div
          className="w-full bg-cover sm:h-[280px] h-[360px]"
          style={{
            backgroundImage: `url(https://tokeman.mypinata.cloud/ipfs/${nftImage})`,
          }}
        ></div>

      <div>
        <div className="flex items-center justify-between mt-7">
          <span className="text-xl font-bold text-grey-900">
            {nftName} #{serialNumber}
          </span>
          <span className="text-xl font-bold text-grey-900">{price} ℏ</span>
        </div>
        <div className="flex items-center justify-between mt-1">
          <span className="text-base font-medium text-grey-700 flex items-center gap-1">
            {symbol}
            <VerifiedSVG />
          </span>
            <span className="font-mediaum text-bae text-grey-700">
            {nftData?.price?.priceType?.toLowerCase() === 'auction' ? `Current Bid` : `Fixed Price`}
            </span>
        </div>
          
        <hr className="w-full mt-3" />
        <div className="mt-[20px] flex items-center justify-between">
          <div className="flex items-start flex-col">
            <div className="flex items-center">
              <span className="text-grey-900 text-[14px] font-bold">
                {nftData?.price?.priceType?.toLowerCase() === 'auction' ? `${bidList.length} bids` : `${offerList.length} offers`}
              </span>
              <span className="relative w-[20px] h-[20px]">
                <span className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[4px] h-[4px] rounded-full bg-black"></span>
              </span>
              <span className="text-[14px] text-grey-900 font-bold">
                {
                  bidList?.length === 0 || nftData?.price?.priceType?.toLowerCase() === 'auction' ? 
                    `${nftData?.view?.length || "0" } views` : 
                    "$"+`${bidList.sort((a:any, b:any) => {
                      if (a?.price > b?.price) return -1
                      return 1
                    })[0].price}`
                }
              </span>
            </div>
            <div className="text-sm text-grey-600 font-bold">
              {`Ending in ${nftData?.price?.auctionEnd?.slice(0, -14) || new Date().toLocaleDateString()}`}
            </div>
          </div>
          <div className="w-[108px]">    
            <Web23Button
              text={isCreator ? "Manage" : "Bid"}
              variant="primary"
              onClick={() => {
                navigate(
                  isCreator ? window.location.pathname + "/" + tokenId + "/" + serialNumber + "/creator" : window.location.pathname + "/" + tokenId + "/" + serialNumber + "/visitor"
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Web23MarketItem;
