
import {
  TokenCreateTransaction,
  TokenType,
  Hbar,
  Timestamp,
  TokenMintTransaction,
  TransactionResponse,
  PublicKey,
  TokenAssociateTransaction,
  TokenId,
  ContractId,
  ContractExecuteTransaction,
  ContractFunctionParameters,
  AccountId,
  ContractCallQuery,
  TransferTransaction,
  PrivateKey,
  Client,
  AccountBalanceQuery
} from "@hashgraph/sdk";
import axios from "axios";
import { keys } from "../config";
import BigNumber from "bignumber.js";
import bigInt from "big-integer";
const BID_SMART_CONTRACT="0.0.10569302";
const BID_TOKEN_ID="0.0.10569307";
const sleep = (miliseconds: number) => {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < miliseconds);
};

const getStandardTransactionId = (transId: string) => {
  const mid = transId.split("@");
  return mid[0] + "-" + mid[1].replace(".", "-");
};

const getTokenInforFromTxns = async (
  res: TransactionResponse,
  network: string, 
  flag = false,
) => {
  const baseURL =
    network === "mainnet"
      ? "https://mainnet-public.mirrornode.hedera.com/api/v1/transactions"
      : "https://testnet.mirrornode.hedera.com/api/v1/transactions";

  const transactionId = getStandardTransactionId(Object.values(res)[2]);
  console.log("transactionId", transactionId);

  sleep(15000);

  const response = await axios.get(baseURL + `/${transactionId}`);
  console.log("-response-", response);
  let tokenId;

  if (response.status === 200) {
    if (flag === false) console.log(response?.data?.transactions[0]?.entity_id);
    else console.log(response?.data?.transactions[1]?.entity_id);
    if (flag === false) tokenId = response?.data?.transactions[0]?.entity_id;
    else tokenId = response?.data?.transactions[1]?.entity_id;
  }

  return tokenId;
};


const createSocialToken = async (
  network: string,
  metadata: any,
  publicKey: string,
  treasuryId: string,
  signer: any
) => {
  try {
    const ftCreate = await new TokenCreateTransaction()
      .setTokenName(metadata?.name ?? "FT")
      .setTokenSymbol(metadata?.symbol ?? "FT token")
      .setTreasuryAccountId(treasuryId)
      .setInitialSupply(metadata?.amount ?? 1)
      .setDecimals(0)
      // .setSupplyType(TokenSupplyType.Finite)
      .setTokenType(TokenType.FungibleCommon)
      .setExpirationTime(new Timestamp(10000 + new Date().valueOf() / 1000, 0))
      .setAdminKey(PublicKey.fromString(publicKey))
      .setSupplyKey(PublicKey.fromString(publicKey))
      .setMaxTransactionFee(new Hbar(30)) //Change the default max transaction fee
      .freezeWithSigner(signer);

    const res = await ftCreate.executeWithSigner(signer);

    const tokenId: any = await getTokenInforFromTxns(res, network, false);

    const associateTxn = await new TokenAssociateTransaction()
      .setAccountId(treasuryId)
      .setTokenIds([TokenId.fromString(tokenId.toString())])
      .freezeWithSigner(signer);
    const associateTxnExe = await associateTxn.executeWithSigner(signer);

    return tokenId;
  } catch (e: any) {
    return {};
  }
};

const getPair = async (signer: any, params: any, treasuryId: string) => {
  const swapRouterContractId =
    params.network === "testnet"
      ? ContractId.fromString(keys.saucerSwap.testNet.v02Router ?? "")
      : ContractId.fromString(keys.saucerSwap.mainNet.v02Router ?? "");

  const swapFactoryContractId =
    params.network === "testnet"
      ? ContractId.fromString(keys.saucerSwap.testNet.v02Factory ?? "")
      : ContractId.fromString(keys.saucerSwap.mainNet.v02Factory ?? "");

  const myTokenA = TokenId.fromString(params.tokenA);
  const myTokenB = TokenId.fromString(params.tokenB);

  const contractQuery = new ContractCallQuery()
    .setGas(200000)
    .setContractId(swapFactoryContractId)
    .setFunction(
      "getPair",
      new ContractFunctionParameters()
        .addAddress(myTokenA.toSolidityAddress())
        .addAddress(myTokenB.toSolidityAddress())
    )
    .setQueryPayment(new Hbar(10))
    .setSenderAccountId(AccountId.fromString(treasuryId));

  //Submit to a Hedera network

  const getPair = await contractQuery.executeWithSigner(signer);
};
// By Jilani
const associateSPToken=async(__signer:any,__accountId:string)=>{
	const associateTxn = await new TokenAssociateTransaction()
      .setAccountId(AccountId.fromString(__accountId))
      .setTokenIds([TokenId.fromString(BID_TOKEN_ID)])
      .freezeWithSigner(__signer);
    const associateTxnExe = await associateTxn.executeWithSigner(__signer);
}
const transferHBARTo = async (__signer:any, __amount: any,__fromAccount:string,__toAccount:string) => {
	const fromAccount = AccountId.fromString(__fromAccount);
    const toAccount = AccountId.fromString(__toAccount);
	const sendHbar = await new TransferTransaction()
        .addHbarTransfer(fromAccount, new Hbar(__amount*-1))
        .addHbarTransfer(toAccount, new Hbar(__amount))
        .freezeWithSigner(__signer);
	const sendHbarTx = await sendHbar.executeWithSigner(__signer);
	console.log("JJJJJJ", sendHbarTx)
	return({data:sendHbarTx});
};
const transferTokenTo = async (__amount: any,__fromAccount:string,__toAccount:string) => {
	const myAccountId= AccountId.fromString("0.0.6730");
	const myPrivateKey = PrivateKey.fromString("302e020100300506032b6570042204200de3f6d7fb077f7a56e67d0e09edff842ef8757740b421d391d6911a22bd6036");
	const client = Client.forTestnet();
    client.setOperator(myAccountId, myPrivateKey);
	
	const fromAccount = AccountId.fromString(__fromAccount);
    const toAccount = AccountId.fromString(__toAccount);
	const tokenId=TokenId.fromString("0.0.4472369");
	const transaction = await new TransferTransaction()
        .addTokenTransfer(tokenId,fromAccount, __amount*100*-1)
        .addTokenTransfer(tokenId,toAccount, __amount*100)
        ;
	const txResponse = await transaction.execute(client);
	const receipt = await txResponse.getReceipt(client);
	console.log(receipt);
	const transactionStatus = receipt.status;	
	
};
const tokenBalance = async (__tokenId:string,__n10:number,__accountId:string) => {
	try{
	const myAccountId= AccountId.fromString("0.0.6730");
	const myPrivateKey = PrivateKey.fromString("302e020100300506032b6570042204200de3f6d7fb077f7a56e67d0e09edff842ef8757740b421d391d6911a22bd6036");
	const client = Client.forTestnet();
    client.setOperator(myAccountId, myPrivateKey);
	const accountId=AccountId.fromString(__accountId);
	const accountBalance:any = await new AccountBalanceQuery()
         .setAccountId(accountId)
         .execute(client);
	if(accountBalance===null || accountBalance.tokens==null || accountBalance.tokens.get(__tokenId)===null)
		 return({
		 status:0,
		 value:0
	 });
	 else {
		 let A=accountBalance.tokens.get(__tokenId).low!=null?accountBalance.tokens.get(__tokenId).low:0;
		 
		 return({
			 status:1,
			 value:(A/__n10)
		 });
	 }
	}catch(_E){
		return({status:-1,value:-1});
	}
};
const bidAuction = async (__signer: any, __amount: number,__nftId:string) => {

  const wHbarContract = ContractId.fromString(BID_SMART_CONTRACT);
  var param=new ContractFunctionParameters();
			param.addInt64(BigNumber(__nftId));
  const sendHbar = await new ContractExecuteTransaction()
    .setContractId(wHbarContract)
    .setGas(5000000)
    .setFunction("bid",param)
	.setPayableAmount(new Hbar(__amount))
    .freezeWithSigner(__signer);

  const sendHbarTx = await sendHbar.executeWithSigner(__signer);
  console.log("BIDING SMART CONTRACT")
  console.log(sendHbarTx)
  console.log("END BIDING SMART CONTRACT")
  return({data:sendHbarTx});
}
// ENd Jilani
const testWhbar = async (signer: any, amount: number, treasuryId: string) => {

  const wHbarContract = ContractId.fromString(keys.saucerSwap.testNet.wHbarContract ?? "")

  // send the hbar to wHbarContract and withdraw WHBAR to the address.
  const sendHbar = await new ContractExecuteTransaction()
    .setContractId(wHbarContract)
    .setGas(5000000)
    .setFunction(
      "deposit",
    ).setPayableAmount(new Hbar(amount))
    .freezeWithSigner(signer);

  const sendHbarTx = await sendHbar.executeWithSigner(signer);
  console.log("##########", sendHbarTx)

  // const transaction = await new TransferTransaction()
  // .addHbarTransfer(AccountId.fromString(treasuryId), -amount)
  // .addHbarTransfer(AccountId.fromString(keys.saucerSwap.testNet.wHbarContract), amount)
  // .freezeWithSigner(signer);

  // const txn = await transaction.executeWithSigner(signer);
  // console.log("txn", txn);

  // const withdrawWhbar = await new ContractExecuteTransaction()
  //     .setContractId(wHbarContract)
  //     .setGas(5000000)
  //     .setFunction(
  //       "withdraw",
  //       new ContractFunctionParameters()
  //         .addAddress(AccountId.fromString(keys.saucerSwap.testNet.wHbarContract).toSolidityAddress())
  //         .addAddress(AccountId.fromString(treasuryId).toSolidityAddress())
  //         .addUint256(new BigNumber(amount))
  //     )
  //     .setPayableAmount(new Hbar(100))
  //     .freezeWithSigner(signer)
  // const withdrawHbarTx = await withdrawWhbar.executeWithSigner(signer);
  // console.log("=========", withdrawHbarTx);

}

const createNewPool = async (
  signer: any,
  params: any,
  publicKey: string,
  treasuryId: string
) => {
  try {
    const swapRouterContractId =
      params.network === "testnet"
        ? ContractId.fromString(keys.saucerSwap.testNet.v02Router ?? "")
        : ContractId.fromString(keys.saucerSwap.mainNet.v02Router ?? "");

    const wHbarContract =
      params.network === "testnet"
        ? ContractId.fromString(keys.saucerSwap.testNet.wHbarContract ?? "")
        : ContractId.fromString(keys.saucerSwap.mainNet.wHbarContract ?? "");
   
    const associateTxn = await new TokenAssociateTransaction()
      .setAccountId(treasuryId)
      .setTokenIds([TokenId.fromString(params.tokenB.toString())])
      .freezeWithSigner(signer);
    await associateTxn.executeWithSigner(signer);
    
    // send the hbar to wHbarContract and withdraw WHBAR to the address.
    const swapHbarToWhbar = await new ContractExecuteTransaction()
      .setContractId(wHbarContract)
      .setGas(5000000)
      .setFunction(
        "deposit",
      ).setPayableAmount(new Hbar(params.tokenBAmount))
      .freezeWithSigner(signer);

    const swapHbarToWhbarTx = await swapHbarToWhbar.executeWithSigner(signer);
    console.log("##########", swapHbarToWhbarTx)
    
    const myTokenA = TokenId.fromString(params.tokenA);
    const myTokenB = TokenId.fromString(params.tokenB);
    const deadline = Math.floor(new Date().valueOf() + 100000000000).toString();

    const addLiquidityNewPoolTx = await new ContractExecuteTransaction()
      //The contract to call
      .setContractId(swapRouterContractId as ContractId)
      //The gas for the transaction
      .setGas(5000000)
      //The contract function to call and parameters to pass
      .setFunction(
        "addLiquidityNewPool",
        new ContractFunctionParameters()
          //The account ID to associate the token to
          .addAddress(myTokenA.toSolidityAddress())
          //The token ID to associate to the account
          .addAddress(myTokenB.toSolidityAddress() ?? "")
          .addUint256(new BigNumber(params.tokenAAmount))
          .addUint256(new BigNumber(params.tokenBAmount).multipliedBy(new BigNumber(10**8)))
          .addUint256(new BigNumber(1005))
          .addUint256(new BigNumber(1005))
          .addAddress(
            AccountId.fromString(treasuryId).toSolidityAddress() ?? ""
          )
          .addUint256(new BigNumber(deadline))
      )
      .setPayableAmount(new Hbar(100))
      .freezeWithSigner(signer);

    const txnAddLiquidityNewPool =
      await addLiquidityNewPoolTx.executeWithSigner(signer);

    console.log("txnAddLiquidity", txnAddLiquidityNewPool);

    const tokenId = await getTokenInforFromTxns(txnAddLiquidityNewPool, params.network, true);
            
    console.log("poolAddress", tokenId);
    return tokenId;
    // const contractQuery = await new ContractCallQuery()
    //   //Set the gas for the query
    //   .setGas(500000)
    //   //Set the contract ID to return the request for
    //   .setContractId(swapFactoryContractId)
    //   //Set the contract function to call
    //   .setFunction(
    //     "getPair",
    //     new ContractFunctionParameters()
    //       .addAddress(myTokenA.toSolidityAddress())
    //       .addAddress(myTokenB.toSolidityAddress())
    //   )
    //   .setQueryPayment(new Hbar(15));
    // //Set the query payment for the node returning the request
    // //This value must cover the cost of the request otherwise will fail

    // const getPair = await contractQuery.executeWithSigner(signer);
  } catch (e: any) {}
};

const createNFT = async (
  signer: any,
  metadata: any,
  treasuryId: string,
  publicKey: string,
  network: string
) => {
  try {
    const nftCreate = await new TokenCreateTransaction()
      .setTokenName(metadata?.name ?? "NFT")
      .setTokenSymbol(metadata?.symbol ?? "NFT token")
      .setTreasuryAccountId(treasuryId)
      .setInitialSupply(0)
      .setDecimals(0)
      .setAdminKey(PublicKey.fromString(publicKey))
      .setSupplyKey(PublicKey.fromString(publicKey))
      .setExpirationTime(new Timestamp(100000 + new Date().valueOf() / 1000, 0))
      // .setSupplyType(TokenSupplyType.Finite)
      .setTokenType(TokenType.NonFungibleUnique)
      .setMaxTransactionFee(new Hbar(30)) //Change the default max transaction fee
      .freezeWithSigner(signer);

    const nftCreateSign = await nftCreate.executeWithSigner(signer);

    const tokenId = await getTokenInforFromTxns(nftCreateSign, network);
    return tokenId;
  } catch (e) {
    console.log("Error ", e);
    return {};
  }
};

const mintFT = async (tokenId: any, metadataHash: any, signer: any) => {
  //Minting FT
  console.log("========", TokenId.fromString(tokenId.toString()));

  try {
    const mintTx = await new TokenMintTransaction()
      .setTokenId(TokenId.fromString(tokenId.toString()))
      .setMetadata([Buffer.from(metadataHash)])
      .freezeWithSigner(signer);

    const mintTxSign = await mintTx.executeWithSigner(signer);

    // const serialNumber = mintRx.totalSupply?.low;
    return mintTxSign;
  } catch (e) {
    console.log("Error while minting the FT:", e);
    throw e;
  }
};

const mintNFT = async (
  tokenId: any,
  network: string,
  metadataHash: any,
  signer: any
) => {
  //Minting NFT
  try {
    const mintTx = await new TokenMintTransaction()
      .setTokenId(tokenId)
      .setMetadata([Buffer.from(metadataHash)])
      .freezeWithSigner(signer);

    const mintTxSign = await mintTx.executeWithSigner(signer);

    // const serialNumber = mintRx.totalSupply?.low;
    sleep(10000);
    const transactionId = getStandardTransactionId(
      mintTxSign.transactionId.toString()
    );
    const baseURL =
      network === "mainnet"
        ? "https://mainnet-public.mirrornode.hedera.com/api/v1/transactions"
        : "https://testnet.mirrornode.hedera.com/api/v1/transactions";

    const response = await axios.get(baseURL + `/${transactionId}`);
    console.log("response", response);
    let serialNumber = {};
    if (response.status === 200) {
      serialNumber =
        response?.data?.transactions[0]?.nft_transfers[0]?.serial_number;
    }

    return serialNumber;
  } catch (e) {
    console.log("Error while minting the NFT:", e);

    return {};
  }
};

export {
  associateSPToken,	
  createSocialToken,
  createNFT,
  createNewPool,
  getPair,
  mintNFT,
  mintFT,
  testWhbar,
  transferHBARTo,
  transferTokenTo,
  tokenBalance,
  bidAuction
};
