import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import EmptyPhoto from "../../assets/icon/Photo.svg";
import NFTPhoto from "../../assets/icon/NFT.svg";

import CreatingNftModal from "../../layouts/create-nft";
import CreateToken from "../../layouts/create-token";
import { PageContainer, TopMenu } from "../../layouts/top-menu";

import {
  Introduction,
  Web23Button,
  Web23Card,
  Web23ImgButton,
  Web23Input,
  Web23MarketItem,
  Web23Spinner,
} from "../../components";
import { Web23Modal, Web23PostMedia } from "../../components";
import NftTab from "../../components/web23-tabs/nft-tab";
import Web23TimeLineItem from "../../components/web23-timeline-item";

import { API_ENDPOINT_URL } from "../../config";

import { SettingContext } from "../../context/context";
import { ModalProcessingContext } from "../../context/context";
import { useHashconnectService } from "../../context/Hashconnect";
import { transferHBARTo,transferTokenTo,tokenBalance } from "../../utility/hashgraph-transaction";
// import { pinFileToIPFS } from "../../utility/ipfs";
import useToast from "../../utility/useToast";
import { makeImgString } from "../../utility/makeImage";

import { ReactComponent as BladeWalletSVG } from "../../assets/icon/Blade1.svg";
import { ReactComponent as BladeWalletMDSVG } from "../../assets/icon/Blade_md.svg";
import { ReactComponent as CloseIcon } from "../../assets/icon/close.svg";
import { ReactComponent as ContentCopySVG } from "../../assets/icon/content_copy.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon/delete.svg";
import { ReactComponent as HashPackSVG } from "../../assets/icon/HashPack.svg";
import { ReactComponent as CheckBigSVG } from "../../assets/icon/ic_check_circle_big.svg";
import { ReactComponent as LinkOffSVG } from "../../assets/icon/link_off.svg";
import { ReactComponent as MetaMaskSVG } from "../../assets/icon/Metamask.svg";
import { ReactComponent as ModeIcon } from "../../assets/icon/mode.svg";
import { ReactComponent as NftLogo } from "../../assets/icon/nft_sketch.svg";
import { ReactComponent as NftSVG } from "../../assets/icon/NFT.svg";
import { ReactComponent as PhotoSVG } from "../../assets/icon/Photo.svg";
import { ReactComponent as AudioSVG } from "../../assets/icon/Audio.svg";
import { ReactComponent as VideoSVG } from "../../assets/icon/Video.svg";
import { ReactComponent as ArticleSVG } from "../../assets/icon/article.svg";
import { ReactComponent as RainbowSVG } from "../../assets/icon/Rainbow.svg";
import { ReactComponent as StoreSVG } from "../../assets/icon/store.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icon/twitter_grey.svg";
import { ReactComponent as TokenBGSVG } from "../../assets/icon/token_bg_white.svg";
import { ReactComponent as LoupeSVG } from "../../assets/icon/loupe.svg";
import { ReactComponent as CardGiftSVG } from "../../assets/icon/card_giftcard.svg";

import PerksImg from "../../assets/img/perks.png";
import SampleNft from "../../assets/img/sampleNFT.png";

const introAvatar =
  "https://tokeman.mypinata.cloud/ipfs/QmcXSeYnimkHuCj7mHJiSFDA4SqttAYdCxBHtKafz4jhMd";
const walletDetail = [
  {
    type: "Hedera",
    detail: [
      { name: "Blade", icon: <BladeWalletSVG /> },
      { name: "Hashpack", icon: <HashPackSVG /> },
    ],
  },
  {
    type: "Ethereum",
    detail: [
      { name: "Metamask", icon: <MetaMaskSVG /> },
      { name: "Rainbow", icon: <RainbowSVG /> },
    ],
  },
  {
    type: "Flow",
    detail: [],
  },
  {
    type: "Polygon",
    detail: [],
  },
  {
    type: "Solana",
    detail: [],
  },
];

const imgButtonDetail = [
  {
    text: "NFT",
    img: <NftSVG />,
    backColor: "bg-green-200",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Photo",
    img: <PhotoSVG />,
    backColor: "bg-red-50",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Audio",
    img: <AudioSVG />,
    backColor: "bg-purple-100",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Video",
    img: <VideoSVG />,
    backColor: "bg-indigo-50",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Article",
    img: <ArticleSVG />,
    backColor: "bg-grey-100",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
];

type IImageButtonDetail = {
  text: string;
  img: React.ReactNode;
  backColor: string;
  hoverBgColor: string;
  detail: any[];
}[];

type ITabsType = {
  label: string;
  index: number;
  Component: React.FC;
}[];

// Tabs Array
const tabs: ITabsType = [
  {
    label: "Timeline",
    index: 1,
    Component: NftTab,
  },
  {
    label: "Market",
    index: 2,
    Component: NftLogo,
  },
];

interface IOtherLinks {
  placeholder: string;
  content: string;
}
type IUserData = {
  birthday: string;
  countryCode: string;
  created: string;
  displayName: string;
  domainName: string;
  email: string;
  password: string;
  phoneNumber: string;
  role: string;
  verified: boolean;
  walletAddress: string;
  walletStatus: boolean;
  id: string;
  profile: {
    introduction: string;
    location: string;
    website: string;
    otherLinks: string[];
    twitter: string;
    coverImg: string;
    img: string;
  };
  socialToken: any;
};

const initialUserData = {
  birthday: "",
  countryCode: "",
  created: "",
  displayName: "",
  domainName: "",
  email: "",
  password: "",
  phoneNumber: "",
  role: "",
  verified: false,
  walletAddress: "",
  walletName: "",
  walletStatus: false,
  id: "",
  profile: {
    introduction: "",
    location: "",
    website: "",
    otherLinks: [],
    twitter: "",
    coverImg: "",
    img: "",
  },
  socialToken: {},
};

const tldList = ["blade", "bhc", "hangry", "boo", "cream", "hbar"];
const filterItem = ["nft", "photo", "audio", "video", "article"];

const TO_RADIANS = Math.PI / 180;

const SharedPage: React.FC = () => {
  const [showEditProfileModal, setShowEditProfileModal] =
    useState<boolean>(false);
  const [otherLinks, setOtherLinks] = useState<IOtherLinks[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [walletStatus, setWalletStatus] = useState<boolean>(false);
  const [showFromWalletModal, setShowFromWalletModal] =
    useState<boolean>(false);
  const [addingNft, setAddingNft] = useState<boolean>(false);

  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const [connectedWallet, setConnectedWallet] = useState<{
    name: string;
    addres: string;
  }>();

  const [walletTab, setWalletTab] = useState<number>(0);

  const { settings } = useContext(SettingContext);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  const [dispName, setDispName] = useState<string>("");
  const [introduction, setIntroduction] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [linkItem, setLinkItem] = useState<IOtherLinks>();
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  const [backgroundImage, setBackgroundImage] = useState<boolean>(false);
  const [postingModal, setPostingModal] = useState<boolean>(false);
  const [mediaData, setMediaData] = useState<any[]>([]);
  const [nftData, setNftData] = useState<any[]>([]);

  const [hbarPrcie, setHbarPrice] = useState<number>(0);

  const { ToasterBox, showToast } = useToast();
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>(initialUserData);
  const [timelineTab, setTimelineTab] = useState<string>("timeline");
  const [showCropping, setShowCropping] = useState<boolean>(false);

  const [crop, setCrop] = useState<Crop>();
  const [cropImgSrc, setCropImgSrc] = useState<any>("");
  const [aspect, setAspect] = useState<number | undefined>(1 / 1);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    height: 120,
    unit: "px",
    width: 120,
    x: 0,
    y: 0,
  });
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [filter, setFilter] = useState<string>("");

  const [follwingText,setFollwingText]=useState<string>("Following");
  const [follwingCount,setFollwingCount]=useState<string>("0");
  const [follwerCount,setFollwerCount]=useState<string>("0");
  
  // Transfer Token Jilani
  const [pageDomainName,setPageDomainName]=useState<string>("");
  const [web23TokenBalance,setWeb23TokenBalance]=useState<string>("0");
  const {
    pairingData,
    availableExtension,
    connectToExtension,
    disconnect,
    getProviderAndSigner,
    publicKey,
  } = useHashconnectService();
  
  useEffect(()=>{
	 (window as any).document.body.style.zoom=""+80+"%"; 
  });
  useEffect(()=>{
	  
	  if(userData.id!=null && userData.id!=""){
		getWeb23TokenBalance();
		getFollowingCount(userData.id);
		getFollowerCount(userData.id);
	  }
  },[userData.id]);
  
  const getWeb23TokenBalance=async()=>{
		
		   let bal:any=await tokenBalance("0.0.4472369",100,settings.walletAddress);
		   if(bal.value)
		   {
			   setWeb23TokenBalance(""+bal.value.toString());
		   }
		
  }
  const getFollowingCount=async(__uid:string)=>{
  try {
	    setFollwingCount("0");
	    let uid:string=settings.userId;
        const response = await axios.get(
          `${API_ENDPOINT_URL}` + "flw/count?userId="+uid);
		
		if(response.status===200){
			setFollwingCount(response?.data?.data[0]?.count);
		}
  }catch(_e){
	  
  }}
  const getFollowerCount=async(__uid:string)=>{
  try {
		setFollwerCount("0");
		
	    let uid:string=__uid;
        const response = await axios.get(
          `${API_ENDPOINT_URL}` + "flw/mycount?userId="+uid);
		
		if(response.status===200){
			
			setFollwerCount(response?.data?.data[0]?.count);
		}
  }catch(_e){
	  
  }}
  // End Transfer Token Jilani
  
  useEffect(() => {
	const rawDomain = window.location.pathname.slice(1);
	const paths:any=rawDomain.split("/");
	let urlParam:any="";
	if(paths!=null && paths.length>=2){
		urlParam=paths[1];
		alert(urlParam);
	}
    else return;
	alert(urlParam);
	const getProfile = async () => {
      try {
        const id = await axios.get(
          `${API_ENDPOINT_URL}` + "account/getUserId",
          {
            params: {
              domainName: urlParam,
            },
          }
        );

        if (id.status === 200) {
			
          setUserData((prev) => {
            return {
              ...prev,
              ...id?.data?.data,
              id: id?.data?.data?._id,
            };
          });
		  //fetchMediaData(id?.data?.data?._id); 	// added by Jilani
		  //fetchNftData(id?.data?.data?._id);  		// added by Jilani
        }

        if (id.data.data._id === settings?.userId && settings?.userId?.length > 0){
			setIsCreator(true);
			localStorage.setItem('pageDomainName', urlParam);
		}

        const accountInfo = await axios.post(
          `${API_ENDPOINT_URL}` + "account/getAccount",
          {
            user_id: id?.data?.data?._id,
          }
        );

        let profileImage: any;
        let coverImage: any;
        if (accountInfo.status === 200) {
          setUserData((prev) => {
            return {
              ...prev,
              ...id?.data?.data,
              id: id?.data?.data?._id,
            };
          });
		  
          profileImage = await axios.get(`${API_ENDPOINT_URL}account/downloadImage?name=${accountInfo?.data?.data?.profileImageURL}`);
          coverImage = await axios.get(`${API_ENDPOINT_URL}account/downloadImage?name=${accountInfo?.data?.data?.coverImageURL}`);
          
        }
		
			const profileInfo = await axios.post(`${API_ENDPOINT_URL}account/getProfile`,
			  {
				user_id: id?.data?.data?._id,
			  }
			);
		
			if (profileInfo.status === 200) {
			
			  if(profileImage.status===200 && profileImage.data.status===1)	{
				//alert(profileImage?.data?.data[0]?.data)
				setCropImgSrc(makeImgString(profileImage?.data?.data[0]?.data));
			  }
			  setUserData((prev) => {
				return {
				  ...prev,
				  ...id?.data?.data,
				  id: id?.data?.data?._id,
				  profile: {
					introduction: profileInfo?.data?.data?.introduction,
					location: profileInfo?.data?.data?.location,
					website: profileInfo?.data?.data?.website,
					twitter: profileInfo?.data?.data?.twitter,
					otherLinks: [...profileInfo?.data?.data?.links],
					img: makeImgString(profileImage?.data?.data[0]?.data),
					coverImg: makeImgString(coverImage?.data?.data[0]?.data),
				  },
				};
			  });
			}
			
        getSocialToken(id?.data?.data?._id);
      } catch (e) {}
    };
    getProfile();
  }, [settings]);

  useEffect(() => {
    if (!selectedFile) {
      if (backgroundImage) {
        setBackgroundImage(false);
      } else {
        setPreview("");
      }
      return;
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      if (backgroundImage) {
        setBackgroundImage(false);
      } else {
        setPreview(objectUrl);
      }
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
	fetchMediaData(); 
    fetchNftData(); 
	
  }, [userData.id]);

  useEffect(() => {
    const getHbarPrice = async () => {
      const { data } = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=HBAR&tsyms=usd&api_key=8fc3e1cafe0aefdfb9819310e48db8e7ae472dbdfe734816e2b4bd1ae2f55ac8`
      );
      setHbarPrice(parseFloat(data["USD"]));
    };
    getHbarPrice();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return undefined;
    }
    if (backgroundImage) {
      await uploadCoverImage(e.target.files?.[0]);
      setSelectedFile(undefined);
    } else {
      setCrop({
        height: 120,
        unit: "px",
        width: 120,
        x: 0,
        y: 0,
      }); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setCropImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setSelectedFile(e.target.files?.[0]);
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useEffect(() => {
    const t = setTimeout(async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [completedCrop, scale, rotate]);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 120,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const getSocialToken = async (userId: string) => {
    const baseURL = `${API_ENDPOINT_URL}socialToken`;

    // try {
    const response = await axios.post(baseURL + `/${userId}`, {
      user_id: userId,
    });

    if (response.status === 200) {
      const response1 = await axios.post(baseURL + "/getPairAddress", {
        user_id: userId,
      });

      console.log("socialToken", response1);
      if (response1.status === 200) {
        setUserData((prev) => {
          return {
            ...prev,
            socialToken: {
              name: response?.data?.data?.name,
              tokenId: response1?.data?.data?.tokenA,
              coinTokenId: response1?.data?.data?.tokenB,
              tokenAmount: Number.parseFloat(
                response1?.data?.data?.tokenAAmount
              ),
              coinTokenAmount: Number.parseFloat(
                response1?.data?.data?.tokenBAmount
              ),
              pairAddress: response1?.data?.data?.pairAddress,
            },
          };
        });
      }
    }
  };
  const uploadCoverImage = async (fileName: File) => {
    let coverImageURL;
    try {
      const formData = new FormData();
      formData.append("file", fileName);
      const response = await axios.post(
        `${API_ENDPOINT_URL}account/uploadImage`,
        formData,
        {
          params: {
            type: "cover",
            userId: userData.id,
          },
        }
      );
      if (response.status === 200) {
        coverImageURL = response?.data?.data?.filename;
      }
      setBackgroundImage(false);
    } catch (e) {
      setBackgroundImage(false);
      console.log(e);
    }

    try {
      const imgUpdate = await axios.post(
        `${API_ENDPOINT_URL}` + "account/editImage",
        {
          user_id: userData.id,
          coverImage: coverImageURL,
        }
      );

      if (imgUpdate.status === 200) {
        const coverImage = await axios.get(
          `${API_ENDPOINT_URL}account/downloadImage`,
          {
            params: {
              name: coverImageURL,
            },
          }
        );

        setUserData((prev) => {
          return {
            ...prev,
            profile: {
              ...prev.profile,
              coverImg: makeImgString(coverImage?.data?.data[0]?.data),
            },
          };
        });
      }
      showToast("Success saving the cover image.", "success");
      setBackgroundImage(false);
    } catch (e) {
      setBackgroundImage(false);
      showToast(
        "Error while saving the Cover Image. Please try again.",
        "error"
      );
    }
  };

  const fetchMediaData = async () => {
	 if(userData.id==="") return;
    const baseUrl = `${API_ENDPOINT_URL}` + "media/getMedia";
    try {
      const response = await axios.post(baseUrl, {
        user_id: userData.id,	// commented by Jilani
        //user_id: __id,	// Added by Jilani
      });
      setMediaData(response?.data?.data);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const fetchNftData = async () => {
	if(userData.id==="") return;  
	  //alert("I am baba in fttlist="+settings.userId);
    const baseUrl = `${API_ENDPOINT_URL}` + "nft/getNftList";
    try {
      const response = await axios.post(baseUrl, {
        user_id: userData.id,	// commented by Jilani
        //user_id: __id,	// Added by Jilani 
      });

      setNftData(response?.data?.data);
    } catch (e) {
      console.log("Error ", e);
    }
  };

  const handleSaveProfile = async () => {
    setLoading(true);

    let profileImageURL;
    try {
      const formData = new FormData();
      formData.append("file", selectedFile || "");

      const response = await axios.post(
        `${API_ENDPOINT_URL}account/uploadImage`,
        formData,
        {
          params: {
            type: "profile",
            userId: userData.id,
          },
        }
      );
      if (response.status === 200) {
        profileImageURL = response.data.data.filename;
        showToast(
          "Success uploading the profile image to database.",
          "success"
        );
      }
    } catch (e) {
      showToast(
        "Failed to upload the profile image to database. please try again.",
        "error"
      );
    }

    try {
      const baseUrl = `${API_ENDPOINT_URL}`;

      const response = await axios.post(baseUrl + "account/editIntro", {
        user_id: userData.id,
        introduction: introduction,
        website: website,
        twitter: twitter,
        location: location,
        links: otherLinks.map((item) => item.content),
      });

      if (response.status === 200) {
        setUserData((prev) => {
          return {
            ...prev,
            profile: {
              ...prev.profile,
              introduction: response?.data?.data?.introduction,
              website: response?.data?.data?.website,
              twitter: response?.data?.data?.twitter,
              location: response?.data?.data?.location,
              otherLinks: response?.data?.data?.links?.length
                ? response.data.data.links.map((item: string) => item)
                : [],
            },
          };
        });
      }

      const imgUpdate = await axios.post(baseUrl + "account/editImage", {
        user_id: userData.id,
        profileImage: profileImageURL,
      });

      if (imgUpdate.status === 200) {
        const profileImage = await axios.get(
          `${API_ENDPOINT_URL}account/downloadImage`,
          {
            params: {
              name: imgUpdate?.data?.data?.profileImageURL,
            },
          }
        );
        setUserData((prev) => {
          return {
            ...prev,
            profile: {
              ...prev.profile,
              img: makeImgString(profileImage?.data?.data[0]?.data),
            },
          };
        });
      }
      setDispName("");
      setIntroduction("");
      setWebsite("");
      setTwitter("");
      setOtherLinks([]);
      setLocation("");
      setPreview("");
      setSelectedFile(undefined);
      setLoading(false);
      setShowEditProfileModal(false);
    } catch (e) {
      console.log("error", e);
      setDispName("");
      setIntroduction("");
      setWebsite("");
      setTwitter("");
      setOtherLinks([]);
      setLocation("");
      setPreview("");
      setSelectedFile(undefined);
      setLoading(false);
      setShowEditProfileModal(false);
      showToast("Error while saving the Profile", "error");
    }
  };

  const handleClickImageBtn = (indexTab: number) => {
    if (indexTab === 0) {
      setModalProcess({
        ...modalProcess,
        isCreatingNFT: true,
      });
    } else if (indexTab === 1) {
      setModalProcess({
        ...modalProcess,
        isPostingPhoto: true,
      });
    } else if (indexTab === 2) {
      setModalProcess({
        ...modalProcess,
        isPostingAudio: true,
      });
    } else if (indexTab === 3) {
      setModalProcess({
        ...modalProcess,
        isPostingVideo: true,
      });
    } else {
      setModalProcess({
        ...modalProcess,
        isPostingArticle: true,
      });
    }
    setFilter("");
  };

  return (
    <>
      <div
        className={`flex h-full w-full flex-col bg-white ${
          showEditProfileModal ||
          showFromWalletModal ||
          modalProcess.finalizaingNftCreation ||
          modalProcess.isCreatingNFT ||
          modalProcess.isPostingVideo ||
          modalProcess.isPostingAudio ||
          modalProcess.isPostingArticle ||
          modalProcess.isPostingPhoto ||
          modalProcess.isCreatingSocialToken ||
          postingModal
            ? "blur-lg"
            : "blur-0"
        }`}
      >
        <PageContainer>
          <TopMenu
            onClick={() => setPostingModal(true)}
            isCreator={isCreator}
			pageDomain={pageDomainName}
          />
          <div className="w-full m-auto">
            <div
              className={`${
                userData?.profile?.coverImg
                  ? "w-[100%] h-[320px] bg-cover relative top-[-72px] md:px-[54px] px-1"
                  : " bg-blue-100 w-[100%] h-[320px] relative top-[-72px] md:px-[54px] px-1"
              } `}
              style={
                userData?.profile?.coverImg &&
                userData?.profile?.coverImg.length === 46
                  ? {
                      backgroundImage: `url(https://tokeman.mypinata.cloud/ipfs/${userData?.profile?.coverImg})`,
                    }
                  : { backgroundImage: `url(${userData?.profile?.coverImg})` }
              }
            >
				
              {isCreator && (
                <div className="absolute right-0 flex flex-row-reverse items-center px-auto bottom-4">
                  <div className="md:mr-[70px] mr-[20px]">
                    <Web23Button
                      text="Edit Cover Photo"
                      variant="secondary"
                      className="px-6"
                      onClick={() => {
                        setBackgroundImage(true);
                        document.getElementById("upload-background")?.click();
                      }}
                    />
					
                  </div>
                  <div className="px-4">
                    <Web23Button
                      text="Edit Profile"
                      variant="secondary"
                      className="px-6"
                      onClick={() => {
                        setDispName(userData.displayName);
                        setIntroduction(userData.profile.introduction);
                        setWebsite(userData.profile.website);
                        setTwitter(userData.profile.twitter);
                        setOtherLinks([]);
                        setLocation(userData.profile.location);
                        setPreview("");
                        setSelectedFile(undefined);

                        setShowEditProfileModal(true);
                      }}
                    />
                    <input
                      id="upload-background"
                      name="upload-background"
                      type="file"
                      className="hidden"
                      onChange={onSelectFile}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="relative top-[-72px] md:grid md:grid-cols-12 gap-5 md:px-[70px] px-[20px] flex flex-col">
			{/* Left Cols */}
            <div className="w-full max-w-[420px] col-span-4 relative mx-auto">
				
                <Introduction
                  isCreator={isCreator}
                  introduction={userData?.profile?.introduction || ""} //"It's a simple lift and shift job. Throughput criticality, pre-think our game-plan. So if you could do that, that would be great for critical mass."
                  location={userData?.profile?.location || ""}
                  website={userData?.profile?.website || ""}
                  links={[
                    ...(userData?.profile?.otherLinks || ""),
                    userData?.profile?.twitter || "",
                  ]}
                  domainName={userData?.domainName}
                  imgSrc={userData?.profile?.img || ""}
                  walletAddress={userData?.walletAddress}
                  onClickInsertProfile={() => setShowEditProfileModal(true)}
                  onClickCoppyAddress={() => {
                    showToast("Copied wallet address");
                    navigator.clipboard.writeText(userData?.walletAddress);
                  }}
                />
				<div className="relative top-[-90px] mt-8 flex flex-col items-center justify-center rounded-xl border-2 p-6">
                    <h4 className="text-[24px] font-bold text-grey-900 mb-3">
                      Web23 Fungible Token.
                    </h4>
                    <div className="w-[80%] relative p-4 mb-6 overflow-hidden bg-[#10610F] border border-indigo-300 rounded-xl">
                      <div className="flex flex-col justify-between mb-2">
                        <div>
                          <img src={"https://images.squarespace-cdn.com/content/v1/62fe8b1b52012f6be63d3de2/c52c59d8-2bb6-4349-982b-99977ad22846/Web23_Logo-Horizontal_Green_sml.png?format=1500w"} alt="Web23" style={{height:"48px"}} />
                        </div>
						<p className="text-sm font-bold text-right text-white">
							{web23TokenBalance==="0" &&<>No Balance</>}
							{web23TokenBalance!=="0" &&<><span className='text-light opacity-70'>Balance</span>: <span className='text-[18px]'>{web23TokenBalance}</span></>}
                        </p>
						<div className='mt-4'>
							<input type='text' id="__web23TokenInput" placeholder='Enter Number of Tokens' className='px-4 py-2 w-full rounded-3xl border-1'/>
							<button
								className='mt-4 mb-4 p-2 w-full rounded-3xl border-2'
								onClick={async() => {
									let _tno=(window as any).document.getElementById("__web23TokenInput").value;
									if(_tno.trim()===""){
										return;
									}
									try{
										const accountId = pairingData?.accountIds[0] ?? "";
										const { signer } = getProviderAndSigner(
										settings.network,
										accountId,
										pairingData?.topic ?? ""
										);
										if (!publicKey) {
										alert("reconnect the Hashpack wallet");
										await connectToExtension();
										}
										else{
											let txnData=await transferHBARTo(signer,_tno*10,accountId,"0.0.6730");
											await transferTokenTo(_tno,"0.0.6730",accountId);
											getWeb23TokenBalance();
										}
									}catch(_e){
										
									}
									
								  }}
								style={{backgroundColor:'#6acb71',color:'#ffffff'}}  
							>Buy</button>
							<p className="text-xs font-bold text-white opacity-50">
                            Per Token: {"10 HBAR"}
                            
                          </p>
						</div>
                      </div>
					</div>
				</div>
                {userData?.socialToken?.name?.length > 0 ? (
                  <div className="flex flex-col items-center py-6 border-grey-300 border-[2px] rounded-md">
                    <h3 className="text-[24px] font-bold text-grey-900 mb-3">
                      {userData?.socialToken?.name} Token
                    </h3>
                    <div className="w-[80%] relative p-4 mb-6 overflow-hidden bg-[#100F61] border border-indigo-300 rounded-xl">
                      <div className="flex items-end justify-between mb-2">
                        <p className="text-sm font-bold text-white opacity-70">
                          {userData?.socialToken?.name}
                        </p>
                        <div>
                          <img src={PerksImg} alt="perks" />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-black text-white md:text-[32px] lg:text-[48px] text-[36px]">
                            {userData?.socialToken?.tokenAmount}
                          </p>
                          <p className="text-base font-bold text-white md:mb-4 lg:mb-8">
                            {userData?.socialToken?.name}
                          </p>
                          <p className="text-xs font-bold text-white opacity-50">
                            {"Per token: $"}
                            {(
                              (parseFloat(
                                userData?.socialToken?.coinTokenAmount
                              ) /
                                parseFloat(
                                  userData?.socialToken?.tokenAmount
                                )) *
                              hbarPrcie
                            ).toFixed(6)}
                          </p>
                        </div>
                        <p className="text-xs font-bold text-white opacity-50 [writing-mode:vertical-lr]">
                          {userData?.socialToken?.name}
                        </p>
                      </div>
                      <p className="absolute bottom-[-50px] left-[-36px] font-black text-white opacity-10 text-[120px]">
                        {userData?.socialToken?.name}
                      </p>
                    </div>
                    {isCreator ? (
                      <div className="flex items-center gap-3 w-[80%]">
                        <div className="w-full">
                          <Web23Button
                            icon={<LoupeSVG />}
                            text="Top-Up"
                            className="w-full"
                          />
                        </div>
                        <div className="w-full">
                          <Web23Button
                            text="Gift"
                            variant="secondary"
                            icon={<CardGiftSVG />}
                            className="w-full"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="w-1/2">
                        <Web23Button text="Claim Now" />
                      </div>
                    )}
                  </div>
                ) : (
				<>
                  <div className="relative top-[-90px] mt-8 flex flex-col items-center justify-center rounded-xl border-2 p-6">
                    <h4 className="text-[24px] font-bold text-grey-900 mb-3">
                      Introducing Social Tokens
                    </h4>
                    <div className="w-[80%] relative p-4 mb-6 overflow-hidden bg-[#100F61] border border-indigo-300 rounded-xl">
                      <div className="flex items-end justify-between mb-2">
                        <p className="text-sm font-bold text-white opacity-70">
                          $BladeOfCode Balance
                        </p>
                        <div>
                          <img src={PerksImg} alt="perks" />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-black text-white md:text-[32px] lg:text-[48px] text-[36px]">
                            2500
                          </p>
                          <p className="text-base font-bold text-white md:mb-4 lg:mb-8">
                            $BladeOfcode
                          </p>
                          <p className="text-xs font-bold text-white opacity-50">
                            {"Per token: $5"}
                          </p>
                        </div>
                        <p className="text-xs font-bold text-white opacity-50 [writing-mode:vertical-lr]">
                          $BladeOfCode
                        </p>
                      </div>
                      <p className="absolute bottom-[-50px] left-[-36px] font-black text-white opacity-10 text-[120px]">
                        $BladeOfCode
                      </p>
                    </div>
                    <p className="text-[14px] font-bold text-grey-700 text-center px-5">
                      Setup your token and build your own economy in Web23. Once
                      launched, they can be used by your fans to unlock your
                      content & experiences.
                    </p>
					
                    {isCreator && (
                      <div className="flex flex-col items-center w-full gap-3 pb-5 mt-3 bg-green-100 rounded-md">
                        <p className="mt-3 text-base font-normal text-grey-900">
                          <span className="text-base font-bold text-black">
                            ${userData.displayName}
                          </span>{" "}
                          available
                        </p>
                        <Web23Button
                          className="items-center px-6 mt-3"
                          variant="fixed"
                          text="Get Started"
                          border="border"
                          onClick={() => {
                            setModalProcess({
                              ...modalProcess,
                              isCreatingSocialToken: true,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
				  
				</>  
                )}
					
            </div>
			{/* End Left Cols */}
			{/* Mid Cols */}
            <div className="w-full md:w-[640px] col-span-5 ">
                <div className="w-full mx-auto relative z-auto" style={{marginTop:"-136px"}}>         
                  
                  <div className="mt-8 ">
                    <div className="flex items-center">
                      
                    </div>
                  </div>
                  {timelineTab === "timeline" ? (
                    <div className="flex flex-col items-center">
                      {[...mediaData, ...nftData].length > 0 ? (
                        [...mediaData, ...nftData]
                          .filter((item:any) => !filter || item?.category === filter)
                          .sort((a, b) => {
                            if (a?.created === undefined) return -1;
                            if (b?.created === undefined) return -1;
                            if (a?.created > b?.created) return -1;
                            return 1;
                          })
                          .map((item: any, id) => {
                            return (
                              <div key={id} className="w-full">
                                <Web23TimeLineItem
                                  category={item?.category ?? "NFT"}
                                  content={item?.content ?? ""}
                                  detail={item?.detail ?? ""}
                                  isCreator={isCreator}
                                  copyright={item?.copyright ?? ""}
                                  nftImage={item?.nftImage ?? ""}
                                  price={item?.price?.price}
                                  nftName={item?.nftName}
                                  symbol={item?.nftName}
                                  likes="100"
                                  tokenId={item?.tokenId}
                                  serialNumber={item?.serialNumber}
                                  commentNumber={144}
								  id={item?._id}
								  domain={userData.domainName}
                                />
                              </div>
                            );
                          })
                      ) : isCreator ? (
                        <Web23Card
                          img={
                            <img src={SampleNft} alt="sample nft"/>
                          }
                          title="Let's populate this space, shall we?"
                          description="Post an update or share your thoughts"
                          button={
                            <Web23Button text={`Post`} variant="primary" />
                          }
                        />
                      ) : (
                        <p className="mt-8 text-base font-medium text-grey-400">
                          Nothing to show
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                      {nftData
                        ?.filter(
                          (item) =>
                            item?.price?.priceType?.toLowerCase() === "fixed" ||
                            item?.price?.priceType?.toLowerCase() === "auction"
                        )
                        ?.sort((a: any, b: any) => {
                          if (a?.created === undefined) return -1;
                          if (b?.created === undefined) return -1;
                          if (a?.created > b?.created) return -1;
                          return 1;
                        })
                        .map((item, id) => {
                          return (
                            <div key={id} className="col-span-1">
                              <Web23MarketItem
                                nftData={item}
                                content={item?.content ?? ""}
                                detail={item?.detail ?? ""}
                                isCreator={isCreator}
                                copyright={item?.copyright ?? ""}
                                nftImage={item?.nftImage ?? ""}
                                price={item?.price?.price}
                                nftName={item?.nftName}
                                symbol={item?.nftName}
                                likes="100"
                                tokenId={item?.tokenId}
                                serialNumber={item?.serialNumber}
                                commentNumber={144}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
            </div>
			{/* End Mid Cols */}
			{/* End Cols */}
			<div className="w-full max-w-[420px] col-span-3">
				<div className="w-full mx-auto mt-6"> 
				{/*	<>
					<div className='text-3xl'>{settings.walletAddress}</div>
					<div className='text-3xl'>{settings.displayName}</div>
					<div className='text-3xl'>{settings.userId}</div>
					
					<div className='text-3xl'>{userData.displayName}</div>
					<div className='text-3xl'>{userData.walletAddress}</div>
					<div className='text-3xl'>{userData.domainName}</div>
					<div className='text-3xl'>{userData.id}</div>
					</>
					*/
				}
				{settings.userId!=userData.id &&
					<>
					<button id="btnFlw900" className={'bg-blue-400 btn border w-[180px]  py-2 px-4 text-white text-lg mb-6'}
					onClick={async(e)=>{
						let userId:string=settings.userId;
						let toUserId:string=userData.id;
						if(follwingText==="Following"){
								const response = await axios.post(API_ENDPOINT_URL + "flw/remove", {
												fu:userId,
												tu:toUserId
											  });
								if(response.status===200){
									setFollwingText("Follow");
								}	
						}
						else{
							
							const response = await axios.post(API_ENDPOINT_URL + "flw/add", {
												fu:userId,
												tu:toUserId
											  });
								if(response.status===200){
									setFollwingText("Following");
								}	
						}								
					}}
					>{follwingText}</button>
					<button id="btnFlw901" className={' btn border   py-2 px-4 text-black text-lg mb-6'}
					onClick={async(e)=>{
												
					}}
					>Followers +{follwerCount}</button>
					
					</>
				}
				{settings.userId===userData.id &&
					<>
					<button id="btnFlw900" className={' btn border  py-2 px-4 text-black text-lg mb-6'}>Following +{follwingCount}</button>
					<button id="btnFlw901" className={' btn border  py-2 px-4 text-black text-lg mb-6'}>Followers +{follwerCount}</button>
					</>
				}
					<h6 className='text-base mt-4 font-bold text-grey-600'>Recent Nfts</h6>
					<div className="flex flex-row mt-2">
						
						<div className="relative w-12 h-12 " >
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>
						
						<div className="relative w-12 h-12 " style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>
						<div className="self-center px-2" >
							<i className="bi bi-three-dots" ></i>
						</div>
					</div>
				</div>
				<div className="w-full mx-auto mt-6"> 
					<h6 className='text-base font-bold text-grey-600'>Recent Photos</h6>
					<div className="flex mt-2">
						
						<div className="relative w-12 h-12 " >
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={EmptyPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={EmptyPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full border-2 w-12 h-12 border-yellow-50 shadow" src={EmptyPhoto}  />
						</div>
						
						<div className="self-center px-2" >
							<i className="bi bi-three-dots" ></i>
						</div>
					</div>
				</div>
				<hr className='mt-6'/>
				
			</div>
			{/*End of End Cols */}
            </div>
          </div>
        </PageContainer>
      </div>
      {postingModal && (
        <Web23Modal>
          <div className="max-w-[640px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between">
              <h5 className="text-[24px] font-bold text-grey-600">
                Hi, {settings.displayName}{" "}
              </h5>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setPostingModal(false)}
              />
            </div>
            <div className="mt-2">
              <h3 className="text-[32px] font-bold text-grey-900">
                Choose any option to post
              </h3>
            </div>
            <div>
              <div className="mt-6">
                <div className="grid items-center justify-around grid-cols-2 gap-2 p-4 md:gap-3 lg:gap-4 gap- md:grid-cols-3 lg:flex rounded-xl bg-grey-50">
                  <Web23ImgButton
                    text="NFT"
                    img={<NftSVG />}
                    backColor="bg-green-200"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({ ...modalProcess, isCreatingNFT: true });
                      // setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Photo"
                    img={<PhotoSVG />}
                    backColor="bg-red-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingPhoto: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Audio"
                    img={<AudioSVG />}
                    backColor="bg-purple-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingAudio: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Video"
                    img={<VideoSVG />}
                    backColor="bg-indigo-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingVideo: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Article"
                    img={<ArticleSVG />}
                    backColor="bg-grey-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingArticle: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Web23Modal>
      )}
      {showEditProfileModal && (
        <Web23Modal>
          <div className="relative w-[480px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-3xl font-bold text-grey-900">Edit Profile</h3>
              <div
                className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => {
                  setDispName(settings.displayName);
                  setIntroduction(settings.profile.introduction);
                  setWebsite(settings.profile.website);
                  setTwitter(settings.profile.twitter);
                  setOtherLinks([]);
                  setLocation(settings.profile.location);
                  setPreview("");
                  setSelectedFile(undefined);

                  setShowEditProfileModal(false);
                  setShowCropping(false);
                }}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="flex items-end mb-6">
              <div className="relative">
                {preview ? (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${preview})` }}
                  ></div>
                ) : userData?.profile?.img?.length > 0 ? (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${userData.profile.img})` }}
                  ></div>
                ) : (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${introAvatar})` }}
                  ></div>
                )}
                <div
                  className="absolute bottom-[6px] right-[8px] h-[24px] w-[24px] cursor-pointer rounded-full bg-grey-50 hover:bg-grey-100"
                  onClick={() => {
                    if (userData?.profile?.img.length > 0 || selectedFile)
                      setShowCropping(true);
                    else
                      showToast(
                        "Please choose profile image to crop",
                        "danger"
                      );
                  }}
                >
                  <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-5%]">
                    <ModeIcon />
                  </div>
                </div>
              </div>

              <div className="ml-[17px] flex cursor-pointer flex-col items-start">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    document.getElementById("upload-photo")?.click()
                  }
                >
                  <span className="mb-3 text-base font-bold underline cursor-pointer text-grey-900">
                    Upload Photo
                  </span>
                </div>
                <input
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  className="hidden"
                  onChange={onSelectFile}
                />

                <div className="relative cursor-pointer">
                  <span
                    id="from-wallet"
                    className="text-base font-bold underline cursor-pointer text-grey-900"
                  >
                    Upload from Wallet
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Display Name"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={dispName}
                onChange={(e) => setDispName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Short Bio"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={introduction}
                onChange={(e) => setIntroduction(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Location"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="WebSite"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Twitter"
                className="w-full"
                validate={true}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              >
                <div className="rounded-full p-[6px] duration-150 hover:bg-grey-300">
                  <TwitterIcon />
                </div>
              </Web23Input>
            </div>

            <div className="flex items-center mb-6">
              <div className="mr-4 text-lg font-bold text-grey-900">
                Other Links
              </div>
              <div>
                <Web23Button
                  text="Add"
                  variant="secondary"
                  className="px-6 hover:bg-grey-300"
                  onClick={() =>
                    setOtherLinks((past) => [
                      ...past,
                      {
                        placeholder: `Link ${past.length + 1}`,
                        content: "",
                      },
                    ])
                  }
                />
              </div>
            </div>
            {otherLinks.map((item) => {
              return (
                <div key={item.content + item.placeholder} className="mb-4">
                  <Web23Input
                    placeholder={item.placeholder}
                    className="w-full"
                    validate={true}
                    value={linkItem?.content}
                    onChange={(e) => {
                      setLinkItem({
                        placeholder: item.placeholder,
                        content: e.target.value,
                      });

                      // setOtherLinks((prev) => {
                      //   const mid = prev.filter(
                      //     (currentItem) =>
                      //       currentItem.placeholder != item.placeholder
                      //   );
                      //   const newItem = {
                      //     placeholder: item.placeholder,
                      //     content: e.target.value,
                      //   };
                      //   return [...mid, newItem];
                      // });
                    }}
                  >
                    <div className="p-1 rounded-full">
                      <DeleteIcon
                        className="duration-150 cursor-pointer"
                        // value={item.placeholder}
                        onClick={() =>
                          setOtherLinks((prev) =>
                            prev.filter(
                              (currentItem) =>
                                currentItem.placeholder != item.placeholder
                            )
                          )
                        }
                      />
                    </div>
                  </Web23Input>
                </div>
              );
            })}

            <div className="flex items-center">
              <div className="ml-auto">
                <button
                  className="text-xl font-bold underline text-grey-900"
                  onClick={() => {
                    setDispName("");
                    setIntroduction("");
                    setWebsite("");
                    setTwitter("");
                    setOtherLinks([]);
                    setLocation("");
                    setPreview("");
                    setSelectedFile(undefined);
                    setShowEditProfileModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="ml-9">
                <Web23Button
                  text="Save"
                  variant="primary"
                  className="p-6"
                  onClick={handleSaveProfile}
                />
              </div>
            </div>
          </div>
          {loading && (
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              <Web23Spinner />
            </div>
          )}
        </Web23Modal>
      )}
      {modalProcess.isCreatingNFT && (
        <Web23Modal>
          <div className="flex w-[480px]  flex-col rounded-md bg-white p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-3xl font-bold text-grey-900">Post NFT</h3>
              <div
                className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() =>
                  setModalProcess({
                    ...modalProcess,
                    isCreatingNFT: false,
                  })
                }
              >
                <CloseIcon />
              </div>
            </div>
            <div>
              <Web23Card
                className="m-auto"
                img={
                  <img className="rounded-[32px]" src={SampleNft} alt="nft" />
                }
                description="Do you know that more than 1 million NFTs are minted everyday?"
                button={
                  <Web23Button
                    text="Create an NFT"
                    variant="primary"
                    className="w-full"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isCreatingNFT: false,
                      });
                    }}
                  />
                }
                bottomButton={
                  <Web23Button
                    text="Add from Wallet"
                    variant="secondary"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isCreatingNFT: false,
                      });
                      setShowFromWalletModal(true);
                      setAddingNft(true);
                      setTimeout(() => {
                        setAddingNft(false);
                      }, 13000);
                    }}
                  />
                }
              />
            </div>
          </div>
        </Web23Modal>
      )}
      {showFromWalletModal &&
        (walletStatus ? (
          <Web23Modal>
            <div className="flex w-[480px]  flex-col rounded-md bg-white p-6">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-3xl font-bold text-grey-900">
                  Add NFT from Wallet
                </h3>
                <div
                  className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                  onClick={() => setShowFromWalletModal(false)}
                >
                  <CloseIcon />
                </div>
              </div>
              <div className="m-auto">
                <Web23Card
                  className="m-auto"
                  img={
                    addingNft ? (
                      <div className="relative">
                        <Web23Spinner />
                      </div>
                    ) : (
                      <div className="text-xl font-bold text-center text-green-800">
                        {" "}
                        Successfully added from wallet!
                      </div>
                    )
                  }
                  variant="primary"
                  description="Communicating with wallet. Sign in with your wallet."
                  button={
                    <Web23Button
                      text="Disconnect"
                      variant="secondary"
                      className="w-[148px]"
                    />
                  }
                />
              </div>
            </div>
          </Web23Modal>
        ) : (
          <Web23Modal>
            <div className="flex flex-col p-6 bg-white rounded-md">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-3xl font-bold text-grey-900">
                  Link Wallet
                </h3>
                <div
                  className="p-2 duration-150 cursor-pointer hover:bg-grey-300"
                  onClick={() => {
                    setShowFromWalletModal(false);
                  }}
                >
                  <CloseIcon />
                </div>
              </div>

              <div className="h-full">
                {!walletConnecting && !connectedWallet?.name && (
                  <div>
                    <p className="mb-6 text-base font-medium text-grey-600">
                      Choose your wallet connection. However, we only support
                      Hedera blockchain for now. More options will be available
                      soon.
                    </p>
                    <div>
                      <div className="relative flex justify-between before:absolute before:top-[100%] before:w-[100%] before:border">
                        {walletDetail.map((walletItem, index) => (
                          <div
                            key={walletItem.type + index}
                            className={`cursor-pointer px-6 py-3 pt-2 text-xl font-bold text-grey-600 ${
                              walletTab === index
                                ? "border-b border-grey-900 text-green-600"
                                : "border-grey-100"
                            }`}
                            onClick={() => setWalletTab(index)}
                          >
                            {walletItem.type}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-3 mt-6">
                      {walletDetail[walletTab].detail?.map((item, index) => (
                       <div
                          key={item.name + index}
                          className="flex cursor-pointer items-center justify-between rounded-[32px] border border-grey-900 p-3 active:bg-grey-100"
                          onClick={() => {
                            setWalletConnecting(true);
                            setTimeout(() => {
                              setWalletConnecting(false);
                              setConnectedWallet({
                                name: "Hedera",
                                addres: "0x6a218A95...94F09",
                              });
                            }, 3000);
                          }}
                        >
                          <div className="flex items-center gap-3">
                            {item.icon}
                            <p className="py-1 text-base font-bold text-grey-900">
                              {item.name}
                            </p>
                          </div>
                          {walletTab > 0 && (
                            <div className="rounded-[4px] bg-yellow-300 px-1 text-base font-bold text-grey-900 ">
                              Coming Soon
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {walletConnecting && (
                  <div className="flex flex-col justify-center h-full">
                    <div className="flex items-center justify-center">
                      <div
                        className="inline-block h-[75px] w-[75px] animate-spin rounded-full border-4 border-green-500 border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                      >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    </div>
                    <p className="my-2 text-base font-medium text-center text-grey-600">
                      Communicating with wallet. Sign in with your wallet.
                    </p>
                    <div className="flex justify-center">
                      <div className="w-[148px]">
                        <Web23Button text="Disconnect" variant="secondary" />
                      </div>
                    </div>
                  </div>
                )}
                {!walletConnecting && connectedWallet?.name && (
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <p className="mb-3 text-base font-medium text-grey-600">
                        Connected wallets
                      </p>
                      <div className="flex items-center justify-between p-4 mb-3 border rounded-3xl border-grey-900">
                        <div className="flex items-center gap-3">
                          <BladeWalletMDSVG />
                          <div>
                            <p className="mb-1 text-base font-medium text-grey-600">
                              Hedera
                            </p>
                            <p className="text-base font-bold text-grey-900">
                              0x6a218A95...94F09
                            </p>
                          </div>
                        </div>
                        <div className="flex gap-3">
                          <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-grey-100">
                            <ContentCopySVG className="fill-grey-900" />
                          </div>
                          <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-grey-100">
                            <LinkOffSVG className="fill-grey-900" />
                          </div>
                        </div>
                      </div>
                      <p className="p-1 mb-3 text-base font-bold underline cursor-pointer text-grey-900 active:text-grey-700">
                        Link more wallets
                      </p>
                    </div>
                    <Web23Button
                      text="Continue"
                      onClick={() => {
                        setShowFromWalletModal(false);
                        setWalletStatus(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Web23Modal>
        ))}

      {modalProcess.isCreatingNFT && (
        <Web23Modal>
          <CreatingNftModal effectFunc={fetchNftData} />
        </Web23Modal>
      )}
      {modalProcess.finalizaingNftCreation && (
        <>
          {!modalProcess.finishedNftCreation ? (
            <Web23Modal>
              <div className="flex max-w-[528px] flex-col items-start rounded-lg bg-white p-6">
                <h3 className="mb-6 text-[32px] font-bold text-grey-900">
                  Create NFT
                </h3>
                <div className="flex flex-col items-center w-full">
                  <Web23Spinner />
                  <p className="mt-6 text-base font-bold text-grey-600">
                    Complete the process in your wallet
                  </p>
                </div>
              </div>
            </Web23Modal>
          ) : (
            <Web23Modal>
              <div
                className="p-6 bg-white rounded-lg cursor-pointer"
                onClick={() => {
                  setModalProcess({
                    ...modalProcess,
                    finalizaingNftCreation: false,
                  });
                }}
              >
                <h3 className="mb-6 text-[32px] font-bold text-grey-900">
                  NFT Creation Successful
                </h3>
                <div className="flex flex-col items-center">
                  <CheckBigSVG />
                  <p className="mt-6 text-base font-bold text-grey-600">
                    NFT will be posted on to your timeline as soon as it’s
                    minted.
                  </p>
                </div>
              </div>
            </Web23Modal>
          )}
        </>
      )}
      {modalProcess.isPostingPhoto && (
        <Web23Modal>
          <Web23PostMedia variant="photo" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isPostingAudio && (
        <Web23Modal>
          <Web23PostMedia variant="audio" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isPostingVideo && (
        <Web23Modal>
          <Web23PostMedia variant="video" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isPostingArticle && (
        <Web23Modal>
          <Web23PostMedia variant="article" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isCreatingSocialToken && <CreateToken />}

      <div
        className={`${
          showCropping ? "block" : "hidden"
        } duration-300 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[640px] p-6 bg-white shadow-lg`}
      >
        <div className="flex justify-between mb-2">
          <h3 className="font-bold text-[32px] leading-10 text-grey-900">
            Edit your profile image
          </h3>
          <div onClick={() => setShowCropping(false)}>
            <CloseIcon />
          </div>
        </div>
        <div className="flex items-center w-full">
          {!!completedCrop && (
            <div className="flex justify-center w-full">
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: "120px",
                    height: "120px",
                    borderRadius: "100%",
                  }}
                />
              </div>
            </div>
          )}
          <div className="w-full">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={cropImgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
        </div>
        <div className="flex justify-center gap-4 mt-8">
          <Web23Button
            size="sm"
            variant="secondary"
            text="Cancel"
            onClick={() => setShowCropping(false)}
          />
          <Web23Button
            size="sm"
            text="Confirm"
            onClick={() => {
              previewCanvasRef?.current?.toBlob(async (blob) => {
                if (!blob) {
                  throw new Error("Failed to create blob");
                }
                const file = new File(
                  [blob],
                  "profile_img_" +
                    userData.displayName +
                    "_" +
                    new Date().valueOf().toString() +
                    ".jpg",
                  { lastModified: 1534584790000 }
                );
                setSelectedFile(file);
                setShowCropping(false);
              });
            }}
          />
        </div>
      </div>

      {ToasterBox}
    </>
  );
};

async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export default SharedPage;
