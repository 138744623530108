import axios from 'axios';
import FormData from 'form-data';

import { PINATA_JWT } from '../config';

const JWT = `Bearer ${PINATA_JWT}`;

const pinFileToIPFS = async (src: any, fileName: string = "File Name") => {
  
  const formData = new FormData();
  
  // const file = fs.createReadStream(src)
  formData.append('file', src)
  
  const metadata = JSON.stringify({
    name: fileName
  });
  formData.append('pinataMetadata', metadata);
  
  const options = JSON.stringify({
    cidVersion: 0,
  })
  formData.append('pinataOptions', options);

  try{
    const pinRes = await axios.post("https://api.pinata.cloud/pinning/pinFileToIPFS", formData, {
      maxBodyLength: Infinity,
      headers: {
        'Content-Type': `multipart/form-data;`,
        // 'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: JWT
      }
    });
    return pinRes.data;
  } catch (error) {
    return {}
  }
}

const pinJsonToIPFS = async (metadata: any) => {
  const config = {
    method: 'post',
    url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
    headers: { 
      'Content-Type': 'application/json', 
      'Authorization': JWT
    },
    data : metadata
  };
  
  const res = await axios(config);
  return res.data;
}
export {pinFileToIPFS, pinJsonToIPFS}