import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashconnectAPIProvider } from "./context/Hashconnect";
import {
  ContextProvider,
  ModalProcessingContextProvider,
} from "./context/context";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "./config";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ContextProvider>
        <ModalProcessingContextProvider>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <HashconnectAPIProvider>
              <App />
            </HashconnectAPIProvider>
          </GoogleOAuthProvider>
        </ModalProcessingContextProvider>
      </ContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
