import axios from "axios";
import React, { useEffect, useState,useContext } from "react";
import { API_ENDPOINT_URL } from "../config";
import { SettingContext} from "./../context/context";
import useToast from "./../utility/useToast";
const DisplayNftCard: React.FC<{
  nft?: any;
  
}> = ({
  nft
}) => {
  const { showToast } = useToast();	
  const { settings, saveSettings } = useContext(SettingContext);
  const [nftImage,setNftImg]=useState<string>("");
  const [nftMetadata,setNftMetadata]=useState<any>({});
  const [isAdded,setIsAdded]=useState<boolean>(false);
  let lockEffect:boolean=false;
  useEffect(() => {
  }, []);
  
  useEffect(()=>{
	if(lockEffect===false) { lockEffect=true;
		callNFTFromIPFS();
		checkAdded();
	}
  },[]);
  const decode_base64=(_metadata:string)=>{
	  var b=0;
	  var l=0;
	  var r:string='';
	  var s:any=_metadata.split('');
	  let i=0;
	  let m:string='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
	  for (i=0;i<s.length;i++) {
		b=(b<<6)+m.indexOf(s[i]); l+=6;
		if (l>=8) r+=String.fromCharCode((b>>>(l-=8))&0xff);
	  }
	  return r.trim();
  }
  const callNFTFromIPFS=async()=>{
	  try{
	  let ipfsToken:string=atob(nft?.metadata).replace("ipfs://",""); 
	  let response:any=await axios.get("https://ipfs.io/ipfs/"+ipfsToken);
	  if(response.status===200){
		  console.log(response.data);
		  let nftData=response?.data;
		  if(nftData===null || nftData===undefined) { return;}
		  setNftMetadata(nftData);
		  
		  
	  }}catch(_e){}
  }
  const checkAdded=async()=>{
	  try{
		let nftslno:string=nft?.serial_number;
		let nfttoken:string=nft?.token_id; 
		const baseURL = `${API_ENDPOINT_URL}` + "nft/get";  
		const response = await axios.post(baseURL, 
								{
								tokenId: nfttoken,
								serialNumber: nftslno
							  });
		if(response.status===200){
			setIsAdded(true);
		} else setIsAdded(false);			
	  }catch(_e){ console.log(_e); setIsAdded(false);}
  }
  return(
    <>
		{nftMetadata && nftMetadata?.image &&
		<div  className='h-[390px] border border-slate-300 overflow-hidden shadow rounded pb-2'>
			<>
				<div className='relative h-[280px] flex flex-col w-full justify-center overflow-hidden'>
					<img src={'https://ipfs.io/ipfs/'+(nftMetadata?.image?.replace("ipfs://","") )} className='m-auto' />
					<div className='absolute top-0 left-0 z-50 text-right w-full'>
					
					{ isAdded===false? 
						<>
						<button className='bg-blue-400 text-white px-4 rounded-tr-xl'
						onClick={async(e)=>{
						try{
							let nftslno:string=nft?.serial_number;
							let nfttoken:string=nft?.token_id; 
							let accountId=nft?.account_id;
							let name=nftMetadata?.name;
							let nftImg=nftMetadata?.image?.replace("ipfs://","");
							let created_timestamp=nft?.created_timestamp;
							let description=nftMetadata?.description;
							let ipfsToken=atob(nft?.metadata).replace("ipfs://","");
							let supply="1";
							const baseURL = `${API_ENDPOINT_URL}` + "nft/addexternalnft";
							const metadata = {
											name: name,
											description: description,
											image: nftImg,
											supply: 1,
											detail: {
											  collection: "",
											  externalLinks:"",
											  altText:"",
											},
											price: {
											  priceType:"Not For Sale",
											  price:0,
											  royalty: 0,
											  currency: "HBAR",
											  auctionStart:"",
											  auctionEnd:"",
											},
											property: [],
											additional: {},
										  };
							const response = await axios.post(baseURL, {
								user_id: settings.userId,
								chainName: "Hedera",
								walletAddress: accountId,
								ipfsHash: ipfsToken,
								metadata: metadata,
								tokenId: nfttoken,
								nftId: nftslno
							  });
							  if(response.status===200) {
								  showToast("Added Successfully");
								saveSettings({
											  ...settings,
											  nft: {
												chainName: "Hedera",
												nftName: name,
												nftImage: nftImg,
												tokenId: nfttoken,
												serialNumber: nftslno,
												supply: 1,
												nftDescription: description,
												details: {
												  collection: "",
												  externalLinks: "",
												  altText: "",
												},
												price: {
												  priceType: "Not For Sale",
												  price: 0,
												  royalty: 0,
												  currency: "Hbar",
												  auctionStart:"",
												  auctionEnd: "",
												},
												additional: {
												  watchNumber: 0,
												  favorites: 0,
												  messagesNumber: 0,
												},
											  },
										});  
								  
							  }
							  else showToast("Something went wrong","error");
						}catch(_E){
							console.log(" ADD EXT ");
							console.log(_E);
							let _e:any=_E;
							if(_e.response.status===400) showToast(_e.response?.data?.error,"error");
							else showToast("Something went wrong","error");
							
						}
						}}
						>Add to Timeline</button>
						</>
						:
						<><i className='bi bi-check2-square bg-blue-400 text-white px-4 rounded-tr-l py-2'></i></>
					}
					</div>
				</div>
				<div className='block h-[100px] p-2 bg-slate-50'>
					<div className='text-base text-black '>{nftMetadata?.name}</div>
					<div className='text-sm text-slate-500 pt-2'>{nftMetadata?.description}</div>
				</div>
			</>
		
		</div>
	}
	</>
  );
};

export default DisplayNftCard;
