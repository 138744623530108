import axios from "axios";
// import Image from 'next/image';
import React, { useContext, useEffect, useState } from "react";

import {
  Web23Button,
  Web23Input,
  Web23Modal,
  Web23RangeBar,
  Web23Spinner,
  Web23Toggle,
} from "../components";

import { WHBAR_TOKEN_ID_TESTNET, WHBAR_TOKEN_ID_MAINNET } from "../config";
import { API_ENDPOINT_URL } from "../config";
import { ModalProcessingContext, SettingContext } from "../context/context";
import { pinFileToIPFS, pinJsonToIPFS } from "../utility/ipfs";
import {
  createSocialToken,
  mintFT,
  createNewPool,
  testWhbar,
} from "../utility/hashgraph-transaction";
import { useHashconnectService } from "../context/Hashconnect";

import { ReactComponent as ArrowBackSVG } from "../assets/icon/arrow_back.svg";
import { ReactComponent as CloseSVG } from "../assets/icon/close.svg";
import { ReactComponent as CircleCheckSVG } from "../assets/icon/ic_check_circle.svg";
import { ReactComponent as OfferSVG } from "../assets/icon/local_offer_sm.svg";
import { ReactComponent as CafeSVG } from "../assets/icon/local_cafe.svg";
import { ReactComponent as WatchSVG } from "../assets/icon/watch_later.svg";
import { ReactComponent as VideoCamSVG } from "../assets/icon/videocam.svg";
import { ReactComponent as SubscriptSVG } from "../assets/icon/subscriptions.svg";
import { ReactComponent as TitleSVG } from "../assets/icon/title.svg";
import { ReactComponent as MoneySVG } from "../assets/icon/attach_money.svg";
import { ReactComponent as PaymentSVG } from "../assets/icon/payments.svg";
import { ReactComponent as TokenSVG } from "../assets/icon/token_sm.svg";
import { ReactComponent as TokenBGSVG } from "../assets/icon/token_bg_white.svg";

import TokenLayoutImage from "../assets/img/social_token.png";
import PerksImg from "../assets/img/perks.png";
import { numberValidator } from "../utility/utils";

const defaultProgressText = [
  "Choose Name",
  "Set Supply and Value",
  "Review Summary",
];

const CreateToken: React.FC = () => {
  const [step, setStep] = useState<number>(0);
  const [progress, setProgress] = useState<number>(0);
  const [socialTokenAmount, setSocialTokenAmount] = useState<number>(1000);
  const [coinAmount, setCoinAmount] = useState<number>(1000);
  const [hbarPrice, setHbarPrice] = useState<number>(0);
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  const { settings, saveSettings } = useContext(SettingContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [tokenName, setTokenName] = useState<string>("");
  const [supply, setSupply] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [isSupply, setIsSupply] = useState<boolean>(false);

  const {
    pairingData,
    availableExtension,
    connectToExtension,
    getProviderAndSigner,
    publicKey,
  } = useHashconnectService();

  const onHandleClose = () => {
    setModalProcess({
      ...modalProcess,
      isCreatingSocialToken: false,
    });
  };

  useEffect(() => {
    const getHbarPrice = async () => {
      const { data } = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=HBAR&tsyms=usd&api_key=8fc3e1cafe0aefdfb9819310e48db8e7ae472dbdfe734816e2b4bd1ae2f55ac8`
      );
      setHbarPrice(parseFloat(data["USD"]));
    };
    getHbarPrice();
  }, []);

  const handleCreateSocialToken = async () => {
    try {
      // const imgIpfs = await pinFileToIPFS(
      //   settings.profile.img ?? "https://tokeman.mypinata.cloud/ipfs/QmejFq6TRhDRmvUux7Kp7gGheNzJfbQUawGiUittcdEfg7"
      // );
      const metadata = {
        image: "ipfs/QmejFq6TRhDRmvUux7Kp7gGheNzJfbQUawGiUittcdEfg7",
        chainName: "Hedera",
        name: tokenName.indexOf("$") === 0 ? tokenName : "$" + tokenName,
        symbol: "Smart Social Token made by Web23 Company",
        description: "Smart Page Social Token made by " + settings?.displayName,
        amount: supply,
      };
      const metadataHash = await pinJsonToIPFS(metadata);

      const accountId = pairingData?.accountIds?.[0] ?? "";

      const { signer } = getProviderAndSigner(
        settings?.network,
        accountId,
        pairingData?.topic ?? ""
      );
      
      const result: any = await createSocialToken(
        settings?.network,
        metadata,
        publicKey ?? "",
        accountId,
        signer
      );
      await mintFT(result, metadataHash?.IpfsHash, signer);

      const response = await axios.post(
        `${API_ENDPOINT_URL}` + "socialToken/create",
        {
          user_id: settings?.userId,
          tokenId: result,
          metadata: metadata,
          metadataHash: metadataHash.IpfsHash
        }
      );
      if (response.status === 200) {
        saveSettings({
          ...settings,
          socialToken: {
            ...settings?.socialToken,
            name: response?.data?.data?.name,
            tokenId: response?.data?.data?.tokenId,
            tokenAmount: parseInt(supply),
          },
        });
      }
      setProgress((prev) => prev + 1);
    } catch (e: any) {
      setProgress((prev) => prev + 1);
    }
  };

  const handleCreateSwappingPool = async () => {
    try {
      setLoading(true);
        
      const accountId = pairingData?.accountIds?.[0] ?? "";
      const { signer } = getProviderAndSigner(
        settings.network,
        accountId,
        pairingData?.topic ?? ""
      );

      const WHBAR = settings?.network === 'testnet' ? WHBAR_TOKEN_ID_TESTNET: WHBAR_TOKEN_ID_MAINNET
      const params = {
        network: settings?.network,
        tokenA: settings?.socialToken?.tokenId,
        tokenB: WHBAR,
        tokenAAmount: settings?.socialToken?.tokenAmount?.toString(),
        tokenBAmount: value?.toString(),
      };

      const poolResult = await createNewPool(
        signer,
        params,
        publicKey ?? "",
        accountId
      );

      const response = await axios.post(
        `${API_ENDPOINT_URL}` + 'socialToken/createNewPool',
        {
          user_id: settings?.userId,
          network: settings?.network,
          tokenA: settings?.socialToken?.tokenId,
          tokenB: WHBAR,
          tokenAAmount: settings?.socialToken?.tokenAmount,
          tokenBAmount: value,
          pairAddress: poolResult,
        }
      );
      if (response.status === 200) {
        saveSettings({
          ...settings,
          socialToken: {
            ...settings.socialToken,
            coinTokenId: WHBAR,
            coinTokenAmount: parseInt(value),
            pairAddress: poolResult,
          },
        });
      }

      setLoading(false);
    } catch (e) {
      console.log("Error ", e);
      setLoading(false);
    }
  };
  return (
    <div className="h-full w-full">
      <Web23Modal>
        <div className="relative z-0 bg-white p-6 w-auto lg:w-[1100px] lg:max-h-fit lg:overflow-visible max-h-[700px] overflow-auto">
          {loading && (
            <div className="z-2 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              <Web23Spinner />
            </div>
          )}
          {progress < 3 && (
            <div className="absolute top-5 right-5" onClick={onHandleClose}>
              <CloseSVG className="cursor-pointer rounded-full duration-150 hover:bg-grey-300" />
            </div>
          )}
          <div>
            {step === 0 && (
              <div className="flex flex-col gap-4 rounded-xl">
                <div className="flex gap-3 items-center justify-center mb-8">
                  <p className="px-2 py-[6px] font-bold text-sm md:text-xl text-black bg-[#EEEDFC] rounded-[4px]">
                    Introducing
                  </p>
                  <p className="font-bold md:text-[32px] leading-10 text-black text-base">
                    Social Tokens
                  </p>
                </div>
                <div className="flex justify-center flex-col lg:flex-row">
                  <div className="lg:pr-8 pr-0 flex flex-col justify-center">
                    <img src={TokenLayoutImage} alt="token layout" />
                  </div>
                  <div className="mb-4 mt-4 lg:mt-0">
                    <p className="font-bold md:text-2xl text-base text-grey-900 text-center mb-3">
                      Setup your token and build your own economy in Web23
                    </p>
                    <p className="font-bold text-sm text-center text-grey-600 mb-4">
                      Your tokens, once launched can be used by your fans to
                      unlock your content & experiences
                    </p>
                    <div className="mb-4">
                      <div className="flex gap-3 items-center py-2">
                        <div className="p-2 bg-[#CCF3AC] rounded-full flex items-center justify-center">
                          <OfferSVG />
                        </div>
                        <div>
                          <p className="mb-1 font-bold text-base text-grey-900">
                            Coupon Giveaways
                          </p>
                          <p className="font-bold text-sm text-grey-600">
                            Giveaway the coupons to purchase your art at a
                            discount
                          </p>
                        </div>
                      </div>
                      <hr className="border-grey-400" />
                      <div className="flex gap-3 items-center py-2">
                        <div className="p-2 bg-[#C984EB] rounded-full flex items-center justify-center">
                          <CafeSVG />
                        </div>
                        <div>
                          <p className="mb-1 font-bold text-base text-grey-900">
                            Let fans Say Thanks
                          </p>
                          <p className="font-bold text-sm text-grey-600">
                            Receive donations from your fans
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="bg-[#FFD83D] p-1 rounded-tl-2xl rounded-tr-lg flex gap-[2px] w-[110px]">
                        <WatchSVG />
                        <p className="font-bold text-xs">Coming Soon</p>
                      </div>
                      <div className="p-2 border rounded-tl-2xl border-[#FFD83D] flex gap-3 items-center">
                        <div className="rounded-full w-9 h-9 bg-[#FF9F0A] flex items-center justify-center">
                          <div>
                            <VideoCamSVG />
                          </div>
                        </div>
                        <div>
                          <p className="font-bold text-base text-grey-900 mb-1">
                            10 minute Video Call
                          </p>
                          <p className="font-bold text-sm text-grey-600">
                            Let your fans can setup a 1:1 live video call with
                            you
                          </p>
                        </div>
                      </div>
                      <div className="p-2 border rounded-b-2xl border-[#FFD83D] flex gap-3 items-center">
                        <div className="rounded-full w-9 h-9 bg-[#FF9F0A] flex items-center justify-center">
                          <div>
                            <SubscriptSVG />
                          </div>
                        </div>
                        <div>
                          <p className="font-bold text-base text-grey-900 mb-1">
                            Subscription to your club
                          </p>
                          <p className="font-bold text-sm text-grey-600">
                            Sell recurring access to your content and
                            experiences
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4">
                      <Web23Button
                        text="Create social token"
                        size="sm"
                        onClick={() => {
                          setStep((prev) => prev + 1);
                          if (availableExtension && !pairingData)
                            connectToExtension();
                          if (!availableExtension) {
                            alert("Install the Hashpack wallet extension.");
                          }
                        }}
                      />
                      <Web23Button
                        text="Skip for now"
                        size="sm"
                        variant="secondary"
                        onClick={() => onHandleClose()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {step > 0 && (
            <div>
              <div
                className={`mb-10 flex gap-4 ${
                  progress < 3
                    ? "lg:flex-row flex-col lg:gap-[60px] lg:justify-start justify-between pr-[30px]"
                    : "justify-end"
                }`}
              >
                {progress < 3 && (
                  <div className="flex items-center gap-3">
                    <div
                      onClick={() => {
                        if (progress === 0) setStep(0);
                        else setProgress((prev) => prev - 1);
                      }}
                    >
                      <ArrowBackSVG className="cursor-pointer rounded-full duration-150 hover:bg-grey-300" />
                    </div>
                    <h6 className="lg:text-[32px] text-base font-bold leading-[48px] text-grey-900">
                      Create Social Token
                    </h6>
                  </div>
                )}
                {progress < 3 && (
                  <div className="flex justify-center gap-[20px] py-4">
                    {defaultProgressText.map((item, index) => (
                      <div
                        key={`${index} + ${item}`}
                        className="flex flex-col items-center justify-center"
                      >
                        <p
                          className={`relative flex h-6 w-6 items-center justify-center rounded-full text-center text-base font-medium text-white before:absolute before:top-[10px] before:left-[22px] before:h-[2px] before:w-[36px] after:absolute after:top-[10px] after:-left-[32px] after:h-[2px] after:w-[36px] after:bg-black ${
                            progress >= index ? "bg-green-700" : "bg-grey-300"
                          } ${
                            progress >= index
                              ? "before:bg-green-700 after:bg-green-700"
                              : "before:bg-grey-300 after:bg-grey-300"
                          } ${
                            index === 2
                              ? "before:content-none"
                              : 'before:content-[""]'
                          } ${
                            index === 0
                              ? "after:content-none"
                              : 'after:content-[""]'
                          }`}
                        >
                          {index < progress ? <CircleCheckSVG /> : index + 1}
                        </p>
                        <p className="mt-2 w-[70px] text-center text-sm font-medium text-black">
                          {item}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {progress === 0 && (
                <div className="flex justify-center flex-col lg:flex-row">
                  <div className="flex justify-center lg:block mb-4 lg:mb-0 mr-0 lg:mr-8">
                    <img src={TokenLayoutImage} alt="token layout" />
                  </div>
                  {!isSupply ? (
                    <div className="mb-8 flex flex-col justify-between">
                      <div>
                        <div className="flex justify-center mb-3">
                          <div className="bg-grey-900 rounded-lg flex justify-center items-center w-[48px] h-[48px]">
                            <TitleSVG />
                          </div>
                        </div>
                        <h3 className="text-center font-bold lg:text-2xl text-base text-grey-900 mb-3">
                          Set Supply and Value
                        </h3>
                        <p className="text-grey-600 font-bold text-sm mb-8">
                          Your token name will be used to identify your unique
                          social token
                        </p>
                        <Web23Input
                          placeholder="Token Name"
                          value={tokenName}
                          className="w-full"
                          onChange={(e) => setTokenName(e.target.value)}
                        />
                        <div className="flex justify-center">
                          <p className="font-bold text-sm text-grey-400 mt-3 max-w-[270px] text-center mb-4 lg:mb-0">
                            Token name should use 4-6 characters and should be
                            alphanumeric only
                          </p>
                        </div>
                      </div>
                      <Web23Button
                        size="sm"
                        text="Continue"
                        disabled={tokenName.length === 0}
                        onClick={() => setIsSupply(true)}
                      />
                    </div>
                  ) : (
                    <div className="mb-8 flex flex-col justify-between">
                      <div>
                        <div className="flex justify-center mb-3">
                          <div className="bg-grey-900 rounded-lg flex justify-center items-center w-[48px] h-[48px]">
                            <MoneySVG />
                          </div>
                        </div>
                        <h3 className="text-center font-bold lg:text-2xl text-base text-grey-900 mb-3">
                          Set Supply and Value
                        </h3>
                        <p className="text-grey-600 font-bold text-sm mb-8">
                          Set how much of {tokenName} will be in circulation and
                          it’s total value
                        </p>
                        <div className="flex flex-col gap-3 mb-3">
                          <Web23Input
                            placeholder="Supply"
                            value={supply}
                            className="w-full"
                            onChange={(e) => setSupply(e.target.value)}
                          />
                          <Web23Input
                            placeholder="Value"
                            value={value}
                            className="w-full"
                            onChange={(e) => setValue(e.target.value)}
                          />
                        </div>
                        <div className="flex justify-center">
                          <p className="font-bold text-sm text-grey-400 mt-3 max-w-[270px] text-center mb-4 lg:mb-0">
                            Total value of your {tokenName} would be ${supply}{" "}
                            (~{value} HBAR)
                          </p>
                        </div>
                      </div>
                      <Web23Button
                        size="sm"
                        text="Continue"
                        disabled={supply.length === 0 || value.length === 0}
                        onClick={() => setProgress((prev) => prev + 1)}
                      />
                    </div>
                  )}
                </div>
              )}
              {progress === 1 && (
                <div className="flex justify-center flex-col lg:flex-row">
                  <div className="mr-0 lg:mr-8 flex justify-center mb-4 lg:mb-0">
                    <img src={TokenLayoutImage} alt="token layout" />
                  </div>
                  <div className="mb-8 flex flex-col justify-between">
                    <div>
                      <div className="flex justify-center mb-3">
                        <div className="bg-grey-900 rounded-lg flex justify-center items-center w-[48px] h-[48px]">
                          <PaymentSVG />
                        </div>
                      </div>
                      <h3 className="text-center font-bold lg:text-2xl text-base text-grey-900 mb-3">
                        Review Summary
                      </h3>
                      <p className="text-grey-600 font-bold text-sm mb-8">
                        Set how much of {tokenName} will be in circulation and
                        it’s total value
                      </p>
                      <div className="flex flex-col gap-3 mb-3">
                        <Web23Input
                          placeholder="Supply"
                          value={supply}
                          className="w-full"
                          onChange={(e) => setSupply(e.target.value)}
                        />
                        <Web23Input
                          placeholder="Value"
                          value={value}
                          className="w-full"
                          onChange={(e) => setValue(e.target.value)}
                        />
                      </div>
                      <div className="flex justify-center">
                        <p className="font-bold text-sm text-grey-400 mt-3 max-w-[270px] text-center mb-4 lg:mb-0">
                          Total value of your {tokenName} would be ${supply}{" "}
                          (~3320 HBAR)
                        </p>
                      </div>
                    </div>
                    <Web23Button
                      size="sm"
                      text="Continue"
                      onClick={() => setProgress((prev) => prev + 1)}
                    />
                  </div>
                </div>
              )}
              {progress === 2 && (
                <div className="flex justify-center flex-col lg:flex-row">
                  <div className="mr-0 lg:mr-8 mb-4 lg:mb-0 flex justify-center lg:block">
                    <img src={TokenLayoutImage} alt="token layout" />
                  </div>
                  <div className="mb-8 flex flex-col justify-between">
                    <div>
                      <div className="flex justify-center mb-3">
                        <div className="bg-grey-900 rounded-lg flex justify-center items-center w-[48px] h-[48px]">
                          <PaymentSVG />
                        </div>
                      </div>
                      <h3 className="text-center font-bold lg:text-2xl text-base text-grey-900 mb-3">
                        Review Summary
                      </h3>
                      <p className="text-grey-600 font-bold text-sm mb-8">
                        Set how much of {tokenName} will be in circulation and
                        it’s total value
                      </p>
                      <div className="mb-4 lg:mb-0">
                        <p className="font-bold text-base text-grey-900 mb-3">
                          Creating
                        </p>
                        <div className="flex gap-3 items-center">
                          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-[#2D2BDE]">
                            <TokenSVG />
                          </div>
                          <div>
                            <p className="font-bold text-base text-grey-900 mb-1">
                              {supply} tokens of {tokenName}
                            </p>
                            <p className="font-bold text-xs text-grey-600">
                              Total Value = ℏ{value}
                            </p>
                          </div>
                        </div>
                        <hr className="border-grey-100 my-[10px]" />
                        <div className="flex gap-3 items-center">
                          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-[#FFCE0A]">
                            <p className="font-bold text-base text-black">V</p>
                          </div>
                          <div>
                            <p className="font-bold text-base text-grey-900 mb-1">
                              {settings?.displayName} {"'s"} Main Wallet
                            </p>
                            <p className="font-bold text-xs text-grey-600">
                              {settings?.walletAddress}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Web23Button
                      size="sm"
                      text="Continue"
                      onClick={async () => {
                        await handleCreateSocialToken();
                        setProgress((prev) => prev + 1);
                        // setTimeout(() => setProgress((prev) => prev + 1), 3000);
                      }}
                    />
                  </div>
                </div>
              )}
              {progress === 3 && (
                <div>
                  <h3 className="font-bold text-[32px] leading-10 text-grey-900 mb-6">
                    Create Social Token
                  </h3>
                  <div className="flex justify-center mb-4">
                    <Web23Spinner />
                  </div>
                  <p className="text-center font-bold text-base text-grey-600">
                    Complete the process in your wallet
                  </p>
                </div>
              )}
              {progress === 4 && (
                <div>
                  <h3 className="text-grey-900 font-bold text-[32px] leading-10 text-center mb-8">
                    Congratulations, {settings.displayName}!
                  </h3>
                  <div className="flex gap-8 flex-col lg:flex-row">
                    <div className="lg:w-[600px] lg:h-[600px] w-auto h-auto bg-transparent lg:bg-[#EFFBDF] flex justify-center items-center relative">
                      <div className="min-w-[420px] min-h-[270px] relative p-4 mb-6 overflow-hidden bg-[#100F61] border border-indigo-300 rounded-xl">
                        <div className="flex items-end justify-between mb-2">
                          <p className="text-sm font-bold text-white opacity-70">
                            {tokenName}
                          </p>
                          <div>
                            <img src={PerksImg} alt="perks" />
                          </div>
                        </div>
                        <div className="flex items-center justify-between">
                          <div>
                            <p className="font-black text-white text-[48px]">
                              {supply}
                            </p>
                            <p className="mb-8 text-base font-bold text-white">
                              {tokenName}
                            </p>
                            <p className="text-xs font-bold text-white opacity-50">
                              {"Per token: $"}
                              {value}
                            </p>
                          </div>
                          <p className="text-xs font-bold text-white opacity-50 [writing-mode:vertical-lr]">
                            {tokenName}
                          </p>
                        </div>
                        <p className="absolute bottom-[-50px] left-[-36px] font-black text-white opacity-10 text-[120px]">
                          {tokenName}
                        </p>
                      </div>
                      <div className="lg:flex hidden rounded-full w-[64px] h-[64px]  items-center justify-center rotate-45 absolute top-6 left-5 bg-[#004F9E]">
                        <TokenBGSVG />
                      </div>
                      <div className="rounded-full w-[64px] h-[64px] lg:flex hidden items-center justify-center rotate-45 absolute bottom-5 right-5 bg-[#9E6000]">
                        <TokenBGSVG />
                      </div>
                    </div>
                    <div className="flex flex-col justify-between">
                      <div>
                        <div className="flex justify-center mb-5">
                          <div className="bg-[#003870] w-[64px] h-[64px] rounded-full items-center justify-center flex">
                            <TokenBGSVG />
                          </div>
                        </div>
                        <p className="text-grey-900 font-bold lg:text-2xl text-base mb-3">
                          Your social token, {tokenName} is minted successfully
                        </p>
                        <p className="font-bold text-sm text-grey-600 mb-8">
                          You can now setup various perks associated with it in
                          the next step
                        </p>
                        <p className="font-bold text-base text-grey-900 mb-3">
                          You have minted
                        </p>
                        <div className="flex gap-3 items-center">
                          <div className="w-10 h-10 rounded-full flex justify-center items-center bg-[#2D2BDE]">
                            <div>
                              <TokenSVG />
                            </div>
                          </div>
                          <div>
                            <p className="font-bold text-base text-grey-900 mb-1">
                              {supply} tokens of {tokenName}
                            </p>
                            <p className="font-bold text-xs text-grey-600">
                              Total Value = ${numberValidator((hbarPrice * parseFloat(value)).toFixed(3))}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-4 mt-4 lg:mt-0">
                        <Web23Button
                          text="Setup Perks"
                          size="sm"
                          onClick={async () => {
                            await handleCreateSwappingPool();
                            onHandleClose()
                          }}
                        />
                        <Web23Button
                          text="Skip for now"
                          size="sm"
                          variant="secondary"
                          onClick={() => onHandleClose()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Web23Modal>
    </div>
  );
};

export default CreateToken;
