import { HashConnectTypes } from "hashconnect";
import { HashConnectConnectionState } from "hashconnect/dist/types";
import { FC, ReactNode, useEffect } from "react";
import { createContext, useState } from "react";

interface IContextProvider {
  children: ReactNode;
}

type UserProfile = {
  introduction: string;
  location: string;
  website: string;
  twitter: string;
  img: string;
  coverImg: string;
  otherLinks: string[];
};

type NftData = {
  chainName: string;
  nftName: string;
  nftImage: string;
  tokenId: string;
  serialNumber: string;
  supply: number;
  nftDescription: string;
  details: {
    collection: string;
    externalLinks: string;
    altText: string;
  };
  price: {
    priceType: string;
    price: number;
    royalty: number;
    currency: string;
    auctionStart: string;
    auctionEnd: string;
  };
  additional: {
    watchNumber: number;
    favorites: number;
    messagesNumber: number;
  };
};

type SocialToken = {
  name: string;
  tokenId: string;
  coinTokenId: string;
  tokenAmount: number;
  coinTokenAmount: number;
  pairAddress: string;
  //  network: string;
};

type HashpackData = {
  availableExtension: HashConnectTypes.WalletMetadata;
  state: HashConnectConnectionState;
  topic: string;
  pairingString: string;
  pairingData: HashConnectTypes.SavedPairingData | null;
};
export type UserData = {
  token: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  displayName: string;
  walletAddress: string;
  walletName: string;
  walletStatus: string;
  birthDay: string;
  domain: string;
  userId: string;
  network: string;
  socialToken: SocialToken;
  profile: UserProfile;
  nft: NftData;
  hashpackData: HashpackData;
  password: string;
  setting: {
    theme: "light" | "dark";
  };
};

type SettingsContextValue = {
  settings: UserData;
  saveSettings: (update: UserData) => void;
};

export const initialUserProfile = {
  introduction: "",
  location: "",
  website: "",
  twitter: "",
  img: "",
  coverImg: "",
  otherLinks: [""],
};
export const initialNft = {
  chainName: "Hedera",
  nftName: "",
  nftImage: "",
  tokenId: "",
  serialNumber: "",
  supply: 0,
  nftDescription: "",
  details: {
    collection: "",
    externalLinks: "",
    altText: "",
  },
  price: {
    priceType: "",
    price: 0,
    royalty: 0,
    currency: "Hbar",
    auctionStart: "",
    auctionEnd: "",
  },
  additional: {
    watchNumber: 0,
    favorites: 0,
    messagesNumber: 0,
  },
};
export const initialSocialToken = {
  name: "",
  tokenId: "",
  coinTokenId: "",
  tokenAmount: 1001,
  coinTokenAmount: 1000,
  pairAddress: "",
};

const appMetadata: HashConnectTypes.AppMetadata = {
  name: "dApp Example",
  description: "An example hedera dApp",
  icon: "https://www.hashpack.app/img/logo.svg",
};
const savedParingData: HashConnectTypes.SavedPairingData = {
  metadata: appMetadata,
  topic: "",
  encryptionKey: "",
  network: "",
  origin: "",
  accountIds: [],
  lastUsed: 0,
};
export const initialHashpackData = {
  availableExtension: appMetadata,
  state: HashConnectConnectionState.Disconnected,
  topic: "",
  pairingString: "",
  pairingData: savedParingData,
};

export const initialSettings = {
  token: "",
  email: "",
  phoneNumber: "",
  countryCode: "+1",
  displayName: "",
  walletAddress: "",
  walletName: "Hashpack",
  walletStatus: "false",
  birthDay: "",
  userId: "",
  domain: "",
  network: "testnet",
  profile: initialUserProfile,
  nft: initialNft,
  socialToken: initialSocialToken,
  hashpackData: initialHashpackData,
  password: "",
  setting: {
    theme: "light" as "light" | "dark",
  },
};

const SettingContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => void 0,
});

const storeSettings = (updatedSettings: UserData) => {
  localStorage.setItem("userData", JSON.stringify(updatedSettings));
};

const restoreSettings = () => {
  let settings = null;

  try {
    let userData = localStorage.getItem("userData");
    if (userData) {
      settings = JSON.parse(userData);
    } else settings = initialSettings;
  } catch (e) {
    console.log(e);
  }

  return settings;
};
const ContextProvider: FC<IContextProvider> = ({ children }) => {
  const [settings, setSettings] = useState<UserData>(initialSettings);

  useEffect(() => {
    const restored = restoreSettings();
    if (restored) {
      setSettings(restored);
    }
  }, []);

  const saveSettings = (updatedSettings: UserData) => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingContext.Provider value={{ settings, saveSettings }}>
      {children}
    </SettingContext.Provider>
  );
};

type ModalProcess = {
  isCreatingSocialToken: boolean;
  isCreatingNFT: boolean;
  isPostingVideo: boolean;
  isPostingAudio: boolean;
  isPostingPhoto: boolean;
  isPostingArticle: boolean;
  finalizaingNftCreation: boolean;
  finishedNftCreation: boolean;
};

type ModalProcessingValue = {
  modalProcess: ModalProcess;
  setModalProcess: (update: ModalProcess) => void;
};
export const initialModalProcess = {
  isCreatingSocialToken: false,
  isCreatingNFT: false,
  isPostingVideo: false,
  isPostingAudio: false,
  isPostingPhoto: false,
  isPostingArticle: false,
  finalizaingNftCreation: false,
  finishedNftCreation: false,
};

const ModalProcessingContext = createContext<ModalProcessingValue>({
  modalProcess: initialModalProcess,
  setModalProcess: () => void 0,
});

const ModalProcessingContextProvider: FC<IContextProvider> = ({ children }) => {
  const [modalProcess, setModalProcess] =
    useState<ModalProcess>(initialModalProcess);

  return (
    <ModalProcessingContext.Provider value={{ modalProcess, setModalProcess }}>
      {children}
    </ModalProcessingContext.Provider>
  );
};

export {
  ContextProvider,
  ModalProcessingContext,
  ModalProcessingContextProvider,
  SettingContext,
};
