import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import EmptyPhoto from "../../assets/icon/Photo.svg";
import NFTPhoto from "../../assets/icon/NFT.svg";

import CreatingNftModal from "../../layouts/create-nft";
import CreateToken from "../../layouts/create-token";
import { PageContainer, TopMenu } from "../../layouts/top-menu";

import {
  Introduction,
  Web23Button,
  Web23Card,
  Web23ImgButton,
  Web23Input,
  Web23MarketItem,
  Web23Spinner,
} from "../../components";
import { Web23Modal, Web23PostMedia } from "../../components";
import NftTab from "../../components/web23-tabs/nft-tab";
import Web23TimeLineItem from "../../components/web23-timeline-item";

import { API_ENDPOINT_URL,IMAGE_ENDPONT_URL } from "../../config";

import { SettingContext } from "../../context/context";
import { ModalProcessingContext } from "../../context/context";
import { useHashconnectService } from "../../context/Hashconnect";
import { transferHBARTo,transferTokenTo,tokenBalance } from "../../utility/hashgraph-transaction";
// import { pinFileToIPFS } from "../../utility/ipfs";
import useToast from "../../utility/useToast";
import { makeImgString } from "../../utility/makeImage";
import { getImageFromURL } from "../../utility/jimage";

import { ReactComponent as BladeWalletSVG } from "../../assets/icon/Blade1.svg";
import { ReactComponent as BladeWalletMDSVG } from "../../assets/icon/Blade_md.svg";
import { ReactComponent as CloseIcon } from "../../assets/icon/close.svg";
import { ReactComponent as ContentCopySVG } from "../../assets/icon/content_copy.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon/delete.svg";
import { ReactComponent as HashPackSVG } from "../../assets/icon/HashPack.svg";
import { ReactComponent as CheckBigSVG } from "../../assets/icon/ic_check_circle_big.svg";
import { ReactComponent as LinkOffSVG } from "../../assets/icon/link_off.svg";
import { ReactComponent as MetaMaskSVG } from "../../assets/icon/Metamask.svg";
import { ReactComponent as ModeIcon } from "../../assets/icon/mode.svg";
import { ReactComponent as NftLogo } from "../../assets/icon/nft_sketch.svg";
import { ReactComponent as NftSVG } from "../../assets/icon/NFT.svg";
import { ReactComponent as PhotoSVG } from "../../assets/icon/Photo.svg";
import { ReactComponent as AudioSVG } from "../../assets/icon/Audio.svg";
import { ReactComponent as VideoSVG } from "../../assets/icon/Video.svg";
import { ReactComponent as ArticleSVG } from "../../assets/icon/article.svg";
import { ReactComponent as RainbowSVG } from "../../assets/icon/Rainbow.svg";
import { ReactComponent as StoreSVG } from "../../assets/icon/store.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icon/twitter_grey.svg";
import { ReactComponent as TokenBGSVG } from "../../assets/icon/token_bg_white.svg";
import { ReactComponent as LoupeSVG } from "../../assets/icon/loupe.svg";
import { ReactComponent as CardGiftSVG } from "../../assets/icon/card_giftcard.svg";

import PerksImg from "../../assets/img/perks.png";
import SampleNft from "../../assets/img/sampleNFT.png";

import WalletListCard from "../../components/walletlistcard";
const introAvatar =
  "https://ipfs.io/ipfs/QmcXSeYnimkHuCj7mHJiSFDA4SqttAYdCxBHtKafz4jhMd";
const walletDetail = [
  {
    type: "Hedera",
    detail: [
      { name: "Blade", icon: <BladeWalletSVG /> },
      { name: "Hashpack", icon: <HashPackSVG /> },
    ],
  },
  {
    type: "Ethereum",
    detail: [
      { name: "Metamask", icon: <MetaMaskSVG /> },
      { name: "Rainbow", icon: <RainbowSVG /> },
    ],
  },
  {
    type: "Flow",
    detail: [],
  },
  {
    type: "Polygon",
    detail: [],
  },
  {
    type: "Solana",
    detail: [],
  },
];

const imgButtonDetail = [
  {
    text: "NFT",
    img: <NftSVG />,
    backColor: "bg-green-200",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Photo",
    img: <PhotoSVG />,
    backColor: "bg-red-50",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Audio",
    img: <AudioSVG />,
    backColor: "bg-purple-100",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Video",
    img: <VideoSVG />,
    backColor: "bg-indigo-50",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Article",
    img: <ArticleSVG />,
    backColor: "bg-grey-100",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
];

type IImageButtonDetail = {
  text: string;
  img: React.ReactNode;
  backColor: string;
  hoverBgColor: string;
  detail: any[];
}[];

type ITabsType = {
  label: string;
  index: number;
  Component: React.FC;
}[];

// Tabs Array
const tabs: ITabsType = [
  {
    label: "Timeline",
    index: 1,
    Component: NftTab,
  },
  {
    label: "Market",
    index: 2,
    Component: NftLogo,
  },
];

interface IOtherLinks {
  placeholder: string;
  content: string;
}
type IUserData = {
  birthday: string;
  countryCode: string;
  created: string;
  displayName: string;
  domainName: string;
  email: string;
  password: string;
  phoneNumber: string;
  role: string;
  verified: boolean;
  walletAddress: string;
  walletStatus: boolean;
  id: string;
  profile: {
    introduction: string;
    location: string;
    website: string;
    otherLinks: string[];
    twitter: string;
    coverImg: string;
    img: string;
  };
  socialToken: any;
};

const initialUserData = {
  birthday: "",
  countryCode: "",
  created: "",
  displayName: "",
  domainName: "",
  email: "",
  password: "",
  phoneNumber: "",
  role: "",
  verified: false,
  walletAddress: "",
  walletName: "",
  walletStatus: false,
  id: "",
  profile: {
    introduction: "",
    location: "",
    website: "",
    otherLinks: [],
    twitter: "",
    coverImg: "",
    img: "",
  },
  socialToken: {},
};

const tldList = ["blade", "bhc", "hangry", "boo", "cream", "hbar"];
const filterItem = ["nft", "photo", "audio", "video", "article"];

const TO_RADIANS = Math.PI / 180;

const OnboardingPage: React.FC = () => {
  const [showEditProfileModal, setShowEditProfileModal] =
    useState<boolean>(false);
  const [otherLinks, setOtherLinks] = useState<IOtherLinks[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [walletStatus, setWalletStatus] = useState<boolean>(false);
  const [showFromWalletModal, setShowFromWalletModal] =
    useState<boolean>(false);
  const [addingNft, setAddingNft] = useState<boolean>(false);

  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const [connectedWallet, setConnectedWallet] = useState<{
    name: string;
    addres: string;
  }>();

  const [walletTab, setWalletTab] = useState<number>(0);

  const { settings } = useContext(SettingContext);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  const [dispName, setDispName] = useState<string>("");
  const [introduction, setIntroduction] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [linkItem, setLinkItem] = useState<IOtherLinks>();
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  const [ isNFTCreateModelFlag, setIsNFTCreateModelFlag ] = useState<boolean>(false);
  const [ selectWalletsModel, setSelectWalletsModel] = useState<number>(0);
  const [backgroundImage, setBackgroundImage] = useState<boolean>(false);
  const [postingModal, setPostingModal] = useState<boolean>(false);
  const [mediaData, setMediaData] = useState<any[]>([]);
  const [nftData, setNftData] = useState<any[]>([]);

  const [hbarPrcie, setHbarPrice] = useState<number>(0);

  const { ToasterBox, showToast } = useToast();
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>(initialUserData);
  const [timelineTab, setTimelineTab] = useState<string>("timeline");
  const [showCropping, setShowCropping] = useState<boolean>(false);

  const [crop, setCrop] = useState<Crop>();
  const [cropImgSrc, setCropImgSrc] = useState<any>("");
  const [aspect, setAspect] = useState<number | undefined>(1 / 1);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    height: 120,
    unit: "px",
    width: 120,
    x: 0,
    y: 0,
  });
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [filter, setFilter] = useState<string>("");

  const [follwingText,setFollwingText]=useState<string>("Following");
  const [follwingCount,setFollwingCount]=useState<string>("0");
  const [follwerCount,setFollwerCount]=useState<string>("0");
  
  // Transfer Token Jilani
  const [pageDomainName,setPageDomainName]=useState<string>("");
  const [websiteURL,setWebsiteURL]=useState<string>("");
  const [ipfsReq,setIpfsReq]=useState<boolean>(false);
  const [ipfsWebsiteURL,setIpfsWebsiteURL]=useState<React.ReactNode>(<></>);
  
  const {
    pairingData,
    availableExtension,
    connectToExtension,
    disconnect,
    getProviderAndSigner,
    publicKey,
  } = useHashconnectService();
  
  useEffect(()=>{
	 (window as any).document.body.style.zoom=""+100+"%"; 
  });
  
   
  
  
  useEffect(() => {
	const rawPath = window.location.pathname.slice(1);
	const paths=rawPath.split("/");
	const rawDomain=paths[0];
    const urlParam = rawDomain;
	const getProfile = async () => {
      try {
        const id = await axios.get(
          `${API_ENDPOINT_URL}` + "account/getUserId",
          {
            params: {
              domainName: urlParam,
            },
          }
        );

        if (id.status === 200) {
			
          setUserData((prev) => {
            return {
              ...prev,
              ...id?.data?.data,
              id: id?.data?.data?._id,
            };
          });
		  //fetchMediaData(id?.data?.data?._id); 	// added by Jilani
		  //fetchNftData(id?.data?.data?._id);  		// added by Jilani
        }

        if (id.data.data._id === settings?.userId && settings?.userId?.length > 0){
			setIsCreator(true);
			localStorage.setItem('pageDomainName', urlParam);
		}

        const accountInfo = await axios.post(
          `${API_ENDPOINT_URL}` + "account/getAccount",
          {
            user_id: id?.data?.data?._id,
          }
        );
		
        let profileImage: any;
        let coverImage: any;
		
        if (accountInfo.status === 200 && accountInfo.data.data!=null) {
			let profileImageTMP=accountInfo?.data?.data?.profileImageURL;
			let coverImageTMP=accountInfo?.data?.data?.coverImageURL;
			let dpImageString=await getImageFromURL(IMAGE_ENDPONT_URL+"image/dp/"+profileImageTMP);
			let cpImageString=await getImageFromURL(IMAGE_ENDPONT_URL+"image/cp/"+coverImageTMP);
			await  setUserData((prev) => {
				return {
				  ...prev,
				  ...id?.data?.data,
				  id: id?.data?.data?._id,
				  profile: {
					  ...prev.profile,
					  img: dpImageString,
					  coverImg: cpImageString
					},
				};
			  });
			await setCropImgSrc(accountInfo?.data?.data?.profileImageURL);
			
			const profileInfo = await axios.post(`${API_ENDPOINT_URL}account/getProfile`, {user_id: id?.data?.data?._id,});
		
			if (profileInfo.status === 200) {
				await setUserData((prev) => {
					return {
					  ...prev,
					  ...id?.data?.data,
					  id: id?.data?.data?._id,
					  profile: {
						...prev.profile,  
						introduction: profileInfo?.data?.data?.introduction,
						location: profileInfo?.data?.data?.location,
						website: profileInfo?.data?.data?.website,
						twitter: profileInfo?.data?.data?.twitter,
						otherLinks: [...profileInfo?.data?.data?.links],
						
						
					  },
					};
				}); //setUserData
			}//profileInfo
		}//accountInfo.status
		
      
      } catch (e) {}
    };
    getProfile();
  }, [settings]);

  useEffect(() => {
    if (!selectedFile) {
      if (backgroundImage) {
        setBackgroundImage(false);
      } else {
        setPreview("");
      }
      return;
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      if (backgroundImage) {
        setBackgroundImage(false);
      } else {
        setPreview(objectUrl);
      }
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
	checkSubscription();
	
  }, [userData.id]);

  useEffect(() => {
    const getHbarPrice = async () => {
      const { data } = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=HBAR&tsyms=usd&api_key=8fc3e1cafe0aefdfb9819310e48db8e7ae472dbdfe734816e2b4bd1ae2f55ac8`
      );
      setHbarPrice(parseFloat(data["USD"]));
    };
    getHbarPrice();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectFile = async (e: any) => {
	
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return undefined;
    }
    if (backgroundImage) {

      await uploadCoverImage(e.target.files?.[0]);
	  setSelectedFile(undefined);
    } else {
      setCrop({
        height: 120,
        unit: "px",
        width: 120,
        x: 0,
        y: 0,
      }); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setCropImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setSelectedFile(e.target.files?.[0]);
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useEffect(() => {
    const t = setTimeout(async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [completedCrop, scale, rotate]);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 120,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  
  const uploadCoverImage = async (fileName: File) => {
    let coverImageURL:string="";
    try {
      const formData = new FormData();
		formData.append("file", fileName || "");
		let options={
		  headers:{
		  "Content-Type":"multipart/form-data;boundary=--------93278492837938247-------",
		 },
		 params: {
            type: "cover",
            userId: userData.id,
          }};	
		const response = await axios.post(`${API_ENDPOINT_URL}account/uploadcp`,formData,options);
      if (response.status === 200) {
        coverImageURL = response?.data?.data?.filename;
      }
	 
      setBackgroundImage(false);
    } catch (e) {
	  let _exp:any=e;
      setBackgroundImage(false);
	  if((_exp?.response?.status)===400)
		showToast(_exp?.response?.data?.error,"error");
	  return;
    }

    try {
      const imgUpdate = await axios.post(
        `${API_ENDPOINT_URL}` + "account/editImage",
        {
          user_id: userData.id,
          coverImage: coverImageURL,
        }
      );

      if (imgUpdate.status === 200) {
		let cpImageString:any=await getImageFromURL(IMAGE_ENDPONT_URL+"image/cp/"+coverImageURL);  
        setUserData((prev) => {
          return {
            ...prev,
            profile: {
              ...prev.profile,
              coverImg: cpImageString,
            },
          };
        });
      }
      showToast("Success saving the cover image.", "success");
      setBackgroundImage(false);
    } catch (e) {
      setBackgroundImage(false);
      showToast(
        "Error while saving the Cover Image. Please try again.",
        "error"
      );
    }
  };

  
  const handleSaveProfile = async () => {
    setLoading(true);
    let profileImageURL:string="";
    try 
	{
		const formData = new FormData();
		formData.append("file", selectedFile || "");
		let options={
		  headers:{
		  "Content-Type":"multipart/form-data;boundary=--------93278492837938247-------",
		 },
		 params: {
            type: "profile",
            userId: userData.id,
          }};	
		const response = await axios.post(`${API_ENDPOINT_URL}account/uploaddp`,formData,options);
		if (response.status === 200) {
			profileImageURL = response.data.data.filename;
			showToast("Success uploading the profile image to database.","success");
			const imgUpdate = await axios.post(`${API_ENDPOINT_URL}account/editImage`, {
			user_id: userData.id,
			profileImage: profileImageURL,
			});
			if (imgUpdate.status === 200) {
				let dpImageString:any=await getImageFromURL(IMAGE_ENDPONT_URL+"image/dp/"+profileImageURL);
				setUserData((prev) => {
				  return {
					...prev,
					profile: {
					  ...prev.profile,
					  img: dpImageString,
					},
				  };
				});
			}
		
		}
    } catch (e) {
      //showToast("Failed to upload the profile image to database. please try again.","error");
    }
	try {
      const baseUrl = `${API_ENDPOINT_URL}`;
      const response = await axios.post(baseUrl + "account/editIntro", {
        user_id: userData.id,
        introduction: introduction,
        website: website,
        twitter: twitter,
        location: location,
        links: otherLinks.map((item) => item.content),
      });

      if (response.status === 200) {
        setUserData((prev) => {
          return {
            ...prev,
            profile: {
              ...prev.profile,
              introduction: response?.data?.data?.introduction,
              website: response?.data?.data?.website,
              twitter: response?.data?.data?.twitter,
              location: response?.data?.data?.location,
              otherLinks: response?.data?.data?.links?.length
                ? response.data.data.links.map((item: string) => item)
                : [],
            },
          };
        });
      }

      
      setDispName("");
      setIntroduction("");
      setWebsite("");
      setTwitter("");
      setOtherLinks([]);
      setLocation("");
      setPreview("");
      setSelectedFile(undefined);
      setLoading(false);
      setShowEditProfileModal(false);
    } catch (e) {
      console.log("error", e);
      setDispName("");
      setIntroduction("");
      setWebsite("");
      setTwitter("");
      setOtherLinks([]);
      setLocation("");
      setPreview("");
      setSelectedFile(undefined);
      setLoading(false);
      setShowEditProfileModal(false);
      //showToast("Error while saving the Profile", "error");
    }
  };

  const handleClickImageBtn = async(indexTab: number) => {
    if (indexTab === 0) {
			const response = await axios.post(API_ENDPOINT_URL + `subscription/getAll`, {
			  ci:settings?.userId
			});
			if(response.status===200){
				if(response.data.subscriptions){
					if(response.data.subscribed.userId && response.data.subscribed.userId===settings.userId){
						setIsNFTCreateModelFlag(true);
						
						
					} else showToast("To mint the nft please activate the subsrciption","success");
				} 
			}
			
      
    } else if (indexTab === 1) {
      setModalProcess({
        ...modalProcess,
        isPostingPhoto: true,
      });
    } else if (indexTab === 2) {
      setModalProcess({
        ...modalProcess,
        isPostingAudio: true,
      });
    } else if (indexTab === 3) {
      setModalProcess({
        ...modalProcess,
        isPostingVideo: true,
      });
    } else {
      setModalProcess({
        ...modalProcess,
        isPostingArticle: true,
      });
    }
    setFilter("");
  };
  
  const [userSubscription,setUserSubscription]=useState<any>(null);
  const checkSubscription=async()=>{
  try{
	if(settings && settings.userId && settings.userId.length>0){ 
		let loginUser:string=settings.userId;
		const response = await axios.post(API_ENDPOINT_URL + `subscription/check`, {
		  ci:loginUser
		});
		if(response.status===200){
			if(response.data.status===1){
				
				setUserSubscription(response.data);
			} 
		} 
	}}catch(_e){
	}
  }
  return (
    <>
      <div
        className={`flex h-full w-full flex-col bg-white ${
          showEditProfileModal ||
          showFromWalletModal ||
          modalProcess.finalizaingNftCreation ||
          modalProcess.isCreatingNFT ||
          modalProcess.isPostingVideo ||
          modalProcess.isPostingAudio ||
          modalProcess.isPostingArticle ||
          modalProcess.isPostingPhoto ||
          modalProcess.isCreatingSocialToken ||
          postingModal
            ? "blur-lg"
            : "blur-0"
        }`}
      >
        <PageContainer>
          <TopMenu
            onClick={() => setPostingModal(true)}
            isCreator={isCreator}
			pageDomain={pageDomainName}
          />
          <div className="w-full m-auto">
            <div
              className={`${
                userData?.profile?.coverImg
                  ? "w-[100%] h-[320px] bg-cover relative top-[-72px] md:px-[54px] px-1"
                  : " bg-blue-100 w-[100%] h-[320px] relative top-[-72px] md:px-[54px] px-1"
              } `}
              style={
                userData?.profile?.coverImg &&
                userData?.profile?.coverImg.length === 46
                  ? {
                      backgroundImage: `url(https://ipfs.io/ipfs/${userData?.profile?.coverImg})`,
                    }
                  : { backgroundImage: `url(${userData?.profile?.coverImg})` }
              }
            >
				
              {isCreator && (
                <div className="absolute right-0 flex flex-row-reverse items-center px-auto bottom-4">
                  <div className="md:mr-[70px] mr-[20px]">
                    <Web23Button
                      text="Edit Cover Photo"
                      variant="secondary"
                      className="px-6"
                      onClick={() => {
                        setBackgroundImage(true);
                        document.getElementById("upload-background")?.click();
                      }}
                    />
					
                  </div>
                  <div className="px-4">
                    <Web23Button
                      text="Edit Profile"
                      variant="secondary"
                      className="px-6"
                      onClick={() => {
                        setDispName(userData.displayName);
                        setIntroduction(userData.profile.introduction);
                        setWebsite(userData.profile.website);
                        setTwitter(userData.profile.twitter);
                        setOtherLinks([]);
                        setLocation(userData.profile.location);
                        setPreview("");
                        setSelectedFile(undefined);
                        setShowEditProfileModal(true);
                      }}
                    />
                    <input
                      id="upload-background"
                      name="upload-background"
                      type="file"
                      className="hidden"
                      onChange={onSelectFile}
                    />
                  </div>
                </div>
              )}
            </div>
			<div className='relative flex w-full top-[-72px]' style={{backgroundColor:'#f0f2f5'}}>
            <div className="container mx-auto grid grid-cols-12 gap-4 px-4 ">
					{/* Left Cols */}
					<div className="col-span-4">
						
						<Introduction
						  isCreator={isCreator}
						  introduction={userData?.profile?.introduction || ""} //"It's a simple lift and shift job. Throughput criticality, pre-think our game-plan. So if you could do that, that would be great for critical mass."
						  location={userData?.profile?.location || ""}
						  website={userData?.profile?.website || ""}
						  links={[
							...(userData?.profile?.otherLinks || ""),
							userData?.profile?.twitter || "",
						  ]}
						  domainName={userData?.domainName}
						  imgSrc={userData?.profile?.img || ""}
						  walletAddress={userData?.walletAddress}
						  onClickInsertProfile={() => setShowEditProfileModal(true)}
						  onClickCoppyAddress={() => {
							showToast("Copied wallet address");
							navigator.clipboard.writeText(userData?.walletAddress);
						  }}
						  subscription={userSubscription}
						/>
					</div>
					
					{/* End Left Cols */}
					{/* Mid Cols */}
					<div className="col-span-5 ">
						<div className="w-full mx-auto mt-6">         
							<div className="mb-4">
								<Web23Input
								placeholder="Enter Website URL"
								className="w-full hover:bg-grey-100 focus:bg-grey-100"
								value={websiteURL}
								onChange={(e) => setWebsiteURL(e.target.value)}
								/>
							</div>
							<div className="mb-4">
								<Web23Button
								  text="Start Import"
								  variant="primary"
								  className="px-6 hover:bg-grey-300"
								  disabled={ipfsReq}
								  loading={ipfsReq}
								  onClick={async() =>{
									  try{
										  
										  setIpfsReq(true);
										  setIpfsWebsiteURL(<div className='text-sm'>wait...</div>);
										  let response=await axios.post(process.env.REACT_APP_SMART_PAGE_API_IMPORT+"importWebsite",{url:websiteURL});
										  if(response.status===200){
											  showToast("Successfully website has been added to IPFS cloud","success");
											  if(response.data.resData && response.data.resData.status===1){
												setIpfsWebsiteURL(<div>
													<div className="py-1 px-2 text-base text-slate-600">IPFS asset link</div>
													<ul className='list-disk'>
														<li className='py-1 px-2 text-sm text-sky-600'><a href={response.data.resData.urlA} target='_blank'>{response.data.resData.urlA}</a></li>
														<li className='py-1 px-2 text-sm text-sky-600'><a href={response.data.resData.urlB} target='_blank'>{response.data.resData.urlB}</a></li>
													</ul>	
												</div>);
												setIpfsReq(false);
												return;
											  }
											  else showToast("Something went wrong[PX-9001]","error");
										  }
										  else {
											  showToast("Something went wrong[PX-200]","error");
										  }
									  }catch(_e){
										  
										showToast("Something went wrong [WD-5001]","error");  
									  }
									  setIpfsWebsiteURL(<></>);
									  setIpfsReq(false);
								  }
								  }
								/>
							</div> 
							<div className="mb-4 mt-4">{ipfsWebsiteURL}</div>
						</div>
					</div>
			
					{/* End Mid Cols */}
					{/* End Cols */}
					<div className="col-span-3">
						<div className="w-full mx-auto mt-6"> 
						</div>
					</div>
					{/*End of End Cols */}
				</div>
				</div>
        </div>
        </PageContainer>
      </div>
      {postingModal && (
        <Web23Modal>
          <div className="max-w-[640px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between">
              <h5 className="text-[24px] font-bold text-grey-600">
                Hi, {settings.displayName}{" "}
              </h5>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setPostingModal(false)}
              />
            </div>
            <div className="mt-2">
              <h3 className="text-[32px] font-bold text-grey-900">
                Choose any option to post
              </h3>
            </div>
            <div>
              <div className="mt-6">
                <div className="grid items-center justify-around grid-cols-2 gap-2 p-4 md:gap-3 lg:gap-4 gap- md:grid-cols-3 lg:flex rounded-xl bg-grey-50">
                  <Web23ImgButton
                    text="NFT"
                    img={<NftSVG />}
                    backColor="bg-green-200"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({ ...modalProcess, isCreatingNFT: true });
                      // setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Photo"
                    img={<PhotoSVG />}
                    backColor="bg-red-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingPhoto: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Audio"
                    img={<AudioSVG />}
                    backColor="bg-purple-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingAudio: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Video"
                    img={<VideoSVG />}
                    backColor="bg-indigo-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingVideo: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Article"
                    img={<ArticleSVG />}
                    backColor="bg-grey-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingArticle: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Web23Modal>
      )}
      {showEditProfileModal && (
        <Web23Modal>
          <div className="relative w-[480px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-3xl font-bold text-grey-900">Edit Profile</h3>
              <div
                className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => {
                  setDispName(settings?.displayName);
                  setIntroduction(settings?.profile?.introduction);
                  setWebsite(settings?.profile?.website);
                  setTwitter(settings?.profile?.twitter);
                  setOtherLinks([]);
                  setLocation(settings?.profile?.location);
                  setPreview("");
                  setSelectedFile(undefined);
                  setShowEditProfileModal(false);
                  setShowCropping(false);
                }}
              >
				<CloseIcon />
              </div>
            </div>
            <div className="flex items-end mb-6">
              <div className="relative">
                {preview ? (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${preview})` }}
                  ></div>
                ) : userData?.profile?.img?.length > 0 ? (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${userData?.profile?.img})` }}
                  >
				  </div>
                ) : (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${introAvatar})` }}
                  ></div>
                )}
                <div
                  className="absolute bottom-[6px] right-[8px] h-[24px] w-[24px] cursor-pointer rounded-full bg-grey-50 hover:bg-grey-100"
                  onClick={() => {
                    if (userData?.profile?.img.length > 0 || selectedFile)
                      setShowCropping(true);
                    else
                      showToast(
                        "Please choose profile image to crop",
                        "danger"
                      );
                  }}
                >
                  <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-5%]">
                    <ModeIcon />
                  </div>
                </div>
              </div>

              <div className="ml-[17px] flex cursor-pointer flex-col items-start">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    document.getElementById("upload-photo")?.click()
                  }
                >
                  <span className="mb-3 text-base font-bold underline cursor-pointer text-grey-900">
                    Upload Photo
                  </span>
                </div>
                <input
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  className="hidden"
                  onChange={onSelectFile}
                />

                <div className="relative cursor-pointer">
                  <span
                    id="from-wallet"
                    className="text-base font-bold underline cursor-pointer text-grey-900"
                  >
                    Upload from Wallet
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Display Name"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={dispName}
                onChange={(e) => setDispName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Short Bio"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={introduction}
                onChange={(e) => setIntroduction(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Location"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="WebSite"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Twitter"
                className="w-full"
                validate={true}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              >
                <div className="rounded-full p-[6px] duration-150 hover:bg-grey-300">
                  <TwitterIcon />
                </div>
              </Web23Input>
            </div>

            <div className="flex items-center mb-6">
              <div className="mr-4 text-lg font-bold text-grey-900">
                Other Links
              </div>
              <div>
                <Web23Button
                  text="Add"
                  variant="secondary"
                  className="px-6 hover:bg-grey-300"
                  onClick={() =>
                    setOtherLinks((past) => [
                      ...past,
                      {
                        placeholder: `Link ${past.length + 1}`,
                        content: "",
                      },
                    ])
                  }
                />
              </div>
            </div>
            {otherLinks.map((item) => {
              return (
                <div key={item.content + item.placeholder} className="mb-4">
                  <Web23Input
                    placeholder={item.placeholder}
                    className="w-full"
                    validate={true}
                    value={linkItem?.content}
                    onChange={(e) => {
                      setLinkItem({
                        placeholder: item.placeholder,
                        content: e.target.value,
                      });

                      // setOtherLinks((prev) => {
                      //   const mid = prev.filter(
                      //     (currentItem) =>
                      //       currentItem.placeholder != item.placeholder
                      //   );
                      //   const newItem = {
                      //     placeholder: item.placeholder,
                      //     content: e.target.value,
                      //   };
                      //   return [...mid, newItem];
                      // });
                    }}
                  >
                    <div className="p-1 rounded-full">
                      <DeleteIcon
                        className="duration-150 cursor-pointer"
                        // value={item.placeholder}
                        onClick={() =>
                          setOtherLinks((prev) =>
                            prev.filter(
                              (currentItem) =>
                                currentItem.placeholder != item.placeholder
                            )
                          )
                        }
                      />
                    </div>
                  </Web23Input>
                </div>
              );
            })}

            <div className="flex items-center">
              <div className="ml-auto">
                <button
                  className="text-xl font-bold underline text-grey-900"
                  onClick={() => {
                    setDispName("");
                    setIntroduction("");
                    setWebsite("");
                    setTwitter("");
                    setOtherLinks([]);
                    setLocation("");
                    setPreview("");
                    setSelectedFile(undefined);
                    setShowEditProfileModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="ml-9">
                <Web23Button
                  text="Save"
                  variant="primary"
                  className="p-6"
                  onClick={handleSaveProfile}
                />
              </div>
            </div>
          </div>
          {loading && (
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              <Web23Spinner />
            </div>
          )}
        </Web23Modal>
      )}
      
	  {
		  isNFTCreateModelFlag &&
		  <>
		    <Web23Modal>
          <div className="flex w-[480px]  flex-col rounded-md bg-white p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-3xl font-bold text-grey-900">Post NFT</h3>
              <div
                className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() =>
                  setIsNFTCreateModelFlag(false)
                }
              >
                <CloseIcon />
              </div>
            </div>
            <div>
              <Web23Card
                className="m-auto"
                img={
                  <img className="rounded-[32px]" src={SampleNft} alt="nft" />
                }
                description="Do you know that more than 1 million NFTs are minted everyday?"
                button={
                  <Web23Button
                    text="Create an NFT"
                    variant="primary"
                    className="w-full"
                    onClick={() => {
						setIsNFTCreateModelFlag(false);
						setModalProcess({
							...modalProcess,
							isCreatingNFT: true,
						});
                    }}
                  />
                }
                bottomButton={
                  <Web23Button
                    text="Add from Wallet."
                    variant="secondary"
                    onClick={() => {
					  
					  setIsNFTCreateModelFlag(false);	
					  
                      setSelectWalletsModel(Math.random()*1000);
                    }}
                  />
                }
              />
            </div>
          </div>
        </Web23Modal>
		  </>
	  }
	  
      
      <div
        className={`${
          showCropping ? "block" : "hidden"
        } duration-300 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[640px] p-6 bg-white shadow-lg`}
      >
        <div className="flex justify-between mb-2">
          <h3 className="font-bold text-[32px] leading-10 text-grey-900">
            Edit your profile image
          </h3>
          <div onClick={() => setShowCropping(false)}>
            <CloseIcon />
          </div>
        </div>
        <div className="flex items-center w-full">
          {!!completedCrop && (
            <div className="flex justify-center w-full">
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: "120px",
                    height: "120px",
                    borderRadius: "100%",
                  }}
                />
              </div>
            </div>
          )}
          <div className="w-full">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={cropImgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
        </div>
        <div className="flex justify-center gap-4 mt-8">
          <Web23Button
            size="sm"
            variant="secondary"
            text="Cancel"
            onClick={() => setShowCropping(false)}
          />
          <Web23Button
            size="sm"
            text="Confirm"
            onClick={() => {
              previewCanvasRef?.current?.toBlob(async (blob) => {
                if (!blob) {
                  throw new Error("Failed to create blob");
                }
                const file = new File(
                  [blob],
                  "profile_img_" +
                    userData.displayName +
                    "_" +
                    new Date().valueOf().toString() +
                    ".jpg",
                  { lastModified: 1534584790000 }
                );
                setSelectedFile(file);
                setShowCropping(false);
              });
            }}
          />
        </div>
      </div>
	  
		<WalletListCard closeStatus={selectWalletsModel}/>
      {ToasterBox}
    </>
  );
};

async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export default OnboardingPage;
