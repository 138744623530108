import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import PhoneInput from "react-phone-input-2";
import { useGoogleLogin } from "@react-oauth/google";

import "react-phone-input-2/lib/bootstrap.css";
import { SettingContext } from "../../context/context";
import useToast from "../../utility/useToast";

import { Web23Button, Web23Input, Web23Spinner } from "../../components";

import { API_ENDPOINT_URL } from "../../config";
import { formatDate, validateEmail } from "../../utility/libs";

import { ReactComponent as AddCircleSVG } from "../../assets/icon/add_circle_outline.svg";
import { ReactComponent as AddLinkSVG } from "../../assets/icon/add_link.svg";
import { ReactComponent as BladeWalletMDSVG } from "../../assets/icon/Blade_md.svg";
import { ReactComponent as CheckSVG } from "../../assets/icon/check.svg";
import { ReactComponent as ContentCopySVG } from "../../assets/icon/content_copy.svg";
import { ReactComponent as EventSVG } from "../../assets/icon/event.svg";
import { ReactComponent as FaceBookSVG } from "../../assets/icon/Facebook.svg";
import { ReactComponent as GoogleSVG } from "../../assets/icon/Google.svg";
import { ReactComponent as HashPackSVG } from "../../assets/icon/HashPack.svg";
import { ReactComponent as HourglassSVG } from "../../assets/icon/hourglass_bottom.svg";
import { ReactComponent as CircleCheckSVG } from "../../assets/icon/ic_check_circle.svg";
import { ReactComponent as ArrowLeftSVG } from "../../assets/icon/keyboard_arrow_left.svg";
import { ReactComponent as LinkOffSVG } from "../../assets/icon/link_off.svg";
import { ReactComponent as TwitterSVG } from "../../assets/icon/Twitter.svg";
import { useHashconnectService } from "../../context/Hashconnect";
import VerifyImage from "../../assets/img/verified.png";

import HashPackImg from "../../assets/img/HashPack.png";
import BladeWalletImg from "../../assets/img/Blade.png";
import RainbowWalletImg from "../../assets/img/rainbow_32.png";
import MetamaskWalletImg from "../../assets/img/Metamask_32.png";

const defaultProgressText = [
  "Connect Wallet",
  "Personal Details",
  "Setup Domain",
  "Welcome Onboard!",
];

const supportedWallet = [
  {
    name: "Blade",
    icon: <CheckSVG className="fill-green-700" />,
  },
  {
    name: "Hashpack",
    icon: <CheckSVG className="fill-green-700" />,
  },
  {
    name: "Metamask",
    icon: <CheckSVG className="fill-green-700" />,
  },
  {
    name: "Rainbow",
    icon: <HourglassSVG />,
  },
  {
    name: "and more coming soon",
    icon: <></>,
  },
];

const walletDetail = [
  {
    type: "Hedera",
    detail: [
      { name: "Blade", icon: BladeWalletImg },
      { name: "Hashpack", icon: HashPackImg },
    ],
  },
  {
    type: "Ethereum",
    detail: [
      { name: "Metamask", icon: MetamaskWalletImg },
      { name: "Rainbow", icon: RainbowWalletImg },
    ],
  },
  {
    type: "Flow",
    detail: [],
  },
  {
    type: "Polygon",
    detail: [],
  },
  {
    type: "Solana",
    detail: [],
  },
];

const Web23PersonalDetail: React.FC<{
  final?: boolean;
}> = ({ final = false }) => {
  const {
    connectToExtension,
    disconnect,
    pairingData,
    availableExtension,
    network,
    pairingString,
  } = useHashconnectService();

  const [progress, setProgress] = useState<number>(0);
  const [showConnectWallet, setShowConnectWallet] = useState<boolean>(false);
  const [accountVerifying, setAccountVerifying] = useState<boolean>(false);
  const [verified, setVerified] = useState<number>(0);
  const [walletTab, setWalletTab] = useState<number>(0);
  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const [connectedWallet, setConnectedWallet] = useState<{
    name: string;
    addres: string;
  }>();

  const [showVerify, setShowVerify] = useState<boolean>(false);

  const { settings, saveSettings } = useContext(SettingContext);

  const [dispName, setDispName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [userCode, setUserCode] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isUniqEmail, setIsUniqEmail] = useState<boolean>(false);
  const [isUniqDispName, setIsUniqDispName] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = React.useState<Date>(new Date());
  const [emailValidation, setEmailValidation] = useState<boolean>(false);
  const [invalidNumber, setInvalidNumber] = useState<boolean>(false);
  const { ToasterBox, showToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (final) setProgress(3);
  }, [final]);

  useEffect(() => {
    if (pairingData) {
      saveSettings({
        ...settings,
        walletAddress: pairingData.accountIds[0],
      });
      setConnectedWallet({
        name: "Hashpack",
        addres: pairingData.accountIds[0],
      });
      setWalletConnecting(false);
    }
  }, [pairingData]);

  async function connectWallet(): Promise<void> {
   //to get around type checking
   (window as any).ethereum
     .request({
         method: "eth_requestAccounts",
     })
     .then((accounts : string[]) => {
        setConnectedWallet({
        name: "Metamask",
        addres: accounts[0],
      });
      setWalletConnecting(false);
     })
     .catch((error: any) => {
         alert(`Something went wrong: ${error}`);
     });
 }

  const handleWalletConnect = async (walletName: string) => {
    setWalletConnecting(true);
    disconnect();
    if(walletName==="Metamask"){
	connectWallet();
    }
    else{
    connectToExtension();
    }
  };

  const handleCreateAccount = async () => {
    const baseUrl = `${API_ENDPOINT_URL}auth/`;
    try {
      const { data } = await axios.post(`${baseUrl}`, {
        walletAddress: connectedWallet?.addres ?? "0.0.8256",
        walletStatus: "true",
        walletName: connectedWallet?.name ?? "Hashpack",
        displayName: dispName,
        email: email,
        birthDay:
          selectedDay.getFullYear() +
          "-" +
          formatDate(selectedDay.getMonth().toString()) +
          "-" +
          formatDate(selectedDay.getDay().toString()),
        phoneNumber: phoneNumber,
        password: password,
        countryCode: 1,
      });
      saveSettings({
        ...settings,
        walletAddress: connectedWallet?.addres ?? "0.0.8256",
        walletStatus: "true",
        walletName: connectedWallet?.name ?? "Hashpack",
        displayName: dispName,
        email: email,
        birthDay:
          selectedDay.getFullYear() +
          "-" +
          formatDate(selectedDay.getMonth().toString()) +
          "-" +
          formatDate(selectedDay.getDay().toString()),
        phoneNumber: phoneNumber,
        userId: data.id,
      });
      setShowVerify(true);
    } catch (e) {}
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleAccount = await axios.get(
        "https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );
      if (googleAccount.status === 200) {
        setEmail(googleAccount.data.emailAddresses[0].value);
        setDispName(googleAccount.data.names[0].displayName);
      }
    },
  });

  const handleVerify = async () => {
    const baseUrl = `${API_ENDPOINT_URL}auth/${settings.userId}/verify`;

    setVerified(0);
    setAccountVerifying(true);
    try {
      if (userCode.length !== 6) {
        setUserCode("");
        throw new Error("The length of the code must be 6.");
      }

      const response = await axios.post(baseUrl, {
        email: email,
        phoneNumber: phoneNumber,
        countryCode: 1,
        code: userCode,
      });

      setAccountVerifying(false);
      if (response.status === 200) {
        setVerified(1);
        navigate("/create-account/domain");
      } else {
        setVerified(-1);
        setUserCode("");
      }
    } catch (e) {
      setAccountVerifying(false);
      setVerified(-1);
      setUserCode("");
    }
  };

  React.useEffect(() => {
    const getDispName = setTimeout(async () => {
      try {
        const data = await axios.get(`${API_ENDPOINT_URL}auth/getDisplayName`, {
          params: {
            displayName: dispName,
          },
        });
        setIsUniqDispName(false);
      } catch (e) {
        setIsUniqDispName(true);
      }
    }, 300);

    return () => clearTimeout(getDispName);
  }, [dispName]);

  React.useEffect(() => {
    const getEmail = setTimeout(async () => {
      try {
        const data = await axios.get(`${API_ENDPOINT_URL}auth/getEmail`, {
          params: {
            email,
          },
        });
        setIsUniqEmail(false);
      } catch (e) {
        setIsUniqEmail(true);
      }
    }, 300);

    return () => clearTimeout(getEmail);
  }, [email]);

  return (
    <div className="flex flex-col w-full h-full p-6 bg-white border border-black rounded-2xl">
      <div className="flex justify-center md:gap-[20px] gap-[50px] py-4">
        {defaultProgressText.map((item, index) => (
          <div
            key={`${index} + ${item}`}
            className="flex flex-col items-center justify-center"
          >
            <p
              className={`relative flex h-6 w-6 items-center justify-center rounded-full text-center sm:text-base text-sm font-medium text-white before:absolute before:top-[10px] sm:before:left-[22px] before:left-[24px] before:h-[2px] sm:before:w-[30px] before:w-[25px] after:absolute after:top-[10px] sm:after:-left-[32px] after:-left-[25px] after:h-[2px] sm:after:w-[30px] after:w-[25px] after:bg-black ${
                progress >= index ? "bg-green-700" : "bg-grey-300"
              } ${
                progress >= index
                  ? "before:bg-green-700 after:bg-green-700"
                  : "before:bg-grey-300 after:bg-grey-300"
              } ${
                index === 3 ? "before:content-none" : 'before:content-[""]'
              } ${index === 0 ? "after:content-none" : 'after:content-[""]'}`}
            >
              {index < progress ? <CircleCheckSVG /> : index + 1}
            </p>
            <p className="mt-2 w-[64px] text-center sm:text-sm text-xs font-medium text-black sm:block hidden">
              {item}
            </p>
          </div>
        ))}
      </div>
      <hr className="border-grey-800 border-opacity-40" />
      {progress === 0 && !showConnectWallet && (
        <div className="flex flex-col justify-between h-full">
          <div className="mt-6">
            <h6 className="mb-2 text-[36px] font-bold text-grey-900">
              Connect Wallet
            </h6>
            <p className="mb-6 text-base font-medium text-grey-600">
              Connect your Web3 wallet to continue. This ensures a seamless
              experience with our platform.
            </p>
            <div className="flex flex-col items-center justify-center gap-3 mb-6 sm:gap-6 sm:flex-row">
              <div
                className="h-[180px] w-[180px] cursor-pointer rounded-3xl border border-grey-900 bg-green-500 active:bg-green-300"
                onClick={() => setShowConnectWallet(true)}
              >
                <div className="flex justify-center mt-10 mb-3">
                  <AddLinkSVG />
                </div>
                <p className="px-10 text-xl font-bold text-center text-grey-900">
                  Link Wallet
                </p>
              </div>
              <div
                className="h-[180px] w-[180px] cursor-pointer rounded-3xl border border-grey-900 active:bg-grey-300"
                onClick={() => setShowConnectWallet(true)}
              >
                <div className="flex justify-center mt-10 mb-3">
                  <AddCircleSVG />
                </div>
                <p className="px-10 text-xl font-bold text-center text-grey-900">
                  Create Wallet
                </p>
              </div>
            </div>
            <div>
              <p className="mb-2 text-base font-medium text-grey-900">
                Supported Wallets
              </p>
              {supportedWallet.map((item, index) => (
                <div
                  key={`${item.name + index}`}
                  className="flex items-center gap-1 text-sm font-medium bg-white text-grey-600"
                >
                  {item.icon}
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {progress === 0 && showConnectWallet && (
        <div className="h-full">
          {!walletConnecting && !connectedWallet?.name && (
            <div>
              <div
                className="flex items-center gap-1 p-1 mt-6 mb-4 text-sm font-bold cursor-pointer text-grey-600 active:fill-grey-900 active:text-grey-900"
                onClick={() => {
                  setShowConnectWallet(false);
                }}
              >
                <ArrowLeftSVG />
                <p className="underline">Connect Wallet</p>
              </div>
              <div>
                <h6 className="mb-2 text-[32px] font-bold text-grey-900">
                  Link/Create Wallet
                </h6>
                <p className="mb-6 text-base font-medium text-grey-600">
                  Choose your wallet connection. However, we only support Hedera
                  blockchain for now. More options will be available soon.
                </p>
                <div className="relative flex justify-between before:absolute before:top-[100%] before:w-[100%] before:border-b">
                  {walletDetail.map((walletItem, index) => (
                    <div
                      key={walletItem.type + index}
                      className={`cursor-pointer p-4 pt-2 text-xl font-bold text-grey-600 ${
                        walletTab === index
                          ? "border-b-2 border-grey-900 text-green-900"
                          : "border-grey-100"
                      }`}
                      onClick={() => setWalletTab(index)}
                    >
                      {walletItem.type}
                    </div>
                  ))}
                </div>
                <div className="flex flex-col gap-3 mt-6">
                  {walletDetail[walletTab].detail?.map((item, index) => (
                    <div
                      key={item.name + index}
                      className="flex cursor-pointer items-center justify-between rounded-[32px] border border-grey-900 p-3 active:bg-grey-100  hover:bg-grey-300 duration-300"
                      onClick={() => handleWalletConnect(item.name)}
                    >
                      <div className="flex items-center gap-3">
                        <div>
                          <img
                            src={item.icon}
                            width={32}
                            height={32}
                            alt="wallets"
                          />
                        </div>
                        <p className="py-1 text-base font-bold text-grey-900">
                          {item.name}
                        </p>
                      </div>
                      {walletTab > 0 && (
                        <div className="rounded-[4px] bg-yellow-300 px-1 text-base font-bold text-grey-900 ">
                          Coming Soon
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {walletConnecting && (
            <div className="flex flex-col justify-center h-full">
              <p className="mb-2 text-center text-[32px] font-bold text-grey-900">
                Linking Wallet
              </p>
              <div className="flex items-center justify-center">
                <div
                  className="inline-block h-[75px] w-[75px] animate-spin rounded-full border-4 border-green-500 border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status"
                >
                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
              <p className="my-2 text-base font-medium text-center text-grey-600">
                Communicating with wallet. Sign in with your wallet.
              </p>
              <div className="flex justify-center">
                <div className="w-[148px]">
                  <Web23Button
                    text="Disconnect"
                    variant="secondary"
                    onClick={() => {
                      disconnect();
                      setWalletConnecting(false);
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {!walletConnecting && connectedWallet?.name && (
            <div className="flex flex-col justify-between h-full">
              <div>
                <h6 className="py-6 text-[32px] font-bold text-grey-900">
                  Connected Wallets
                </h6>
                <p className="mb-2 text-base font-medium text-grey-600">
                  Connected wallets
                </p>
                <div className="flex items-center justify-between p-4 border rounded-3xl border-grey-900">
                  <div className="flex items-center gap-3">
                    {connectedWallet.name === "Blade" ? (
                      <BladeWalletMDSVG />
                    ) : (
                      <HashPackSVG />
                    )}
                    <div>
                      <p className="mb-1 text-base font-medium text-grey-600">
                        {connectedWallet.name}
                      </p>
                      <p className="text-base font-bold text-grey-900">
                        {connectedWallet.addres}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <div className="cursor-pointer flex h-[44px] w-[44px] items-center justify-center rounded-full bg-grey-100 duration-150 hover:bg-grey-300">
                      <ContentCopySVG
                        className="fill-grey-900"
                        onClick={() => {
                          showToast("Copied wallet address");
                          navigator.clipboard.writeText(connectedWallet.addres);
                        }}
                      />
                    </div>
                    <div className="cursor-pointer flex h-[44px] w-[44px] items-center justify-center rounded-full bg-grey-100 duration-150 hover:bg-grey-300">
                      <LinkOffSVG className="fill-grey-900" />
                    </div>
                  </div>
                </div>
                <p className="p-1 text-base font-bold underline cursor-pointer text-grey-900 active:text-grey-700">
                  Link more wallets
                </p>
              </div>
              <Web23Button
                text="Continue"
                onClick={() => {
                  setProgress((prev) => prev + 1);
                }}
              />
            </div>
          )}
        </div>
      )}
      {progress === 1 &&
        (!showVerify ? (
          <div className="flex flex-col justify-between h-full">
            <div>
              <h6 className="py-3 text-[32px] font-bold text-grey-900">
                Personal Details
              </h6>
              <div className="flex flex-col gap-3 mb-3">
                <Web23Input
                  placeholder="Display Name"
                  validate={true}
                  className="w-full"
                  value={dispName}
                  onChange={(e) => {
                    setDispName(e.target.value);
                  }}
                >
                  {isUniqDispName && dispName.length > 0 && (
                    <CheckSVG className="fill-green-700" />
                  )}
                </Web23Input>
                {dispName.length < 4 && dispName.length > 0 && (
                  <span className="pl-8 text-xs font-bold text-red-700">
                    DisplayName must be at least 4 characters
                  </span>
                )}
                <Web23Input
                  placeholder="E-mail Address"
                  validate={true}
                  className="w-full"
                  value={email}
                  onChange={(e) => {
                    if (!validateEmail(e.target.value))
                      setEmailValidation(false);
                    else setEmailValidation(true);
                    setEmail(e.target.value);
                  }}
                >
                  {isUniqEmail && email.length > 0 && (
                    <CheckSVG className="fill-green-700" />
                  )}
                </Web23Input>
                {!emailValidation && email.length > 0 && (
                  <span className="pl-8 text-xs font-bold text-red-700">
                    Invalid email
                  </span>
                )}
                <Web23Input
                  placeholder="password"
                  type="password"
                  className="w-full"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {password.length > 0 && password.length < 8 && (
                  <span className="pl-8 text-xs font-bold text-red-700">
                    Password must be at least 8 characters
                  </span>
                )}
                <Web23Input
                  placeholder="confirm the password"
                  type="password"
                  className="w-full"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {password.length > 0 &&
                  confirmPassword.length > 0 &&
                  password !== confirmPassword && (
                    <span className="pl-8 text-xs font-bold text-red-700">
                      Password confirmation does not match
                    </span>
                  )}
                <DateTimePicker
                  onChange={(e) => {
                    setSelectedDay(e);
                  }}
                  disableClock={true}
                  value={selectedDay}
                  showLeadingZeros={true}
                  format="y-MM-dd"
                />
                <div className="[&_.form-control]:!rounded-[32px]">
                  <PhoneInput
                    country={"eg"}
                    enableSearch={true}
                    value={phoneNumber}
                    onChange={(phone) => setPhoneNumber(phone)}
                  />
                </div>
                {invalidNumber && (
                  <span className="pl-8 text-xs font-bold text-red-700">
                    Please input your phone number
                  </span>
                )}
              </div>
              <p className="mb-6 text-sm font-bold text-center text-grey-600">
                OR
              </p>
              <div className="flex items-center justify-center gap-4 mb-4">
                <p>Continue with</p>
                <div className="flex items-center gap-4">
                  <FaceBookSVG />
                  <div onClick={() => googleLogin()}>
                    <GoogleSVG />
                  </div>
                  <TwitterSVG />
                </div>
              </div>
            </div>
            <Web23Button
              text="Continue"
              disabled={
                !(
                  dispName.length > 0 &&
                  email.length > 0 &&
                  confirmPassword === password &&
                  isUniqEmail &&
                  isUniqDispName &&
                  confirmPassword.length >= 8 &&
                  emailValidation &&
                  dispName.length > 3
                )
              }
              onClick={() => {
                if (phoneNumber.length < 5) setInvalidNumber(true);
                else {
                  setInvalidNumber(false);
                  handleCreateAccount();
                }
              }}
            />
          </div>
        ) : (
          <div className="flex flex-col justify-between h-full">
            <div>
              <div className="mt-6 mb-4">
                <div
                  className="flex items-center gap-1 p-1"
                  onClick={() => setShowVerify(false)}
                >
                  <ArrowLeftSVG className="fill-grey-600" />
                  <p className="text-sm font-bold cursor-pointer text-grey-600">
                    Personal Details
                  </p>
                </div>
              </div>
              <h6 className="mb-2 text-[32px] font-bold text-grey-900">
                Verification
              </h6>
              <p className="mb-6 text-base font-medium text-grey-900">
                Please enter the verification code send to{" "}
                <strong>{email}</strong>
              </p>
              <Web23Input
                placeholder="Enter 6 digit code"
                className="w-full"
                validate={true}
                value={userCode}
                onChange={(e) => {
                  setUserCode(e.target.value);
                  setVerified(0);
                }}
              />
              <p className="mt-6 text-sm font-bold text-grey-600">
                Didn’t receive code yet?{" "}
                <strong
                  className="underline cursor-pointer text-grey-900 active:text-grey-700"
                  onClick={handleVerify}
                >
                  Resend code
                </strong>
              </p>
            </div>
            {accountVerifying && (
              <div className="m-auto">
                <Web23Spinner />
              </div>
            )}
            {verified === 1 && (
              <div className="flex flex-col items-center m-auto">
                <CircleCheckSVG />
                <p className="text-xl font-bold text-green-900">
                  Successfully created!
                </p>
              </div>
            )}
            {verified === -1 && (
              <div className="m-auto">
                <p className="text-sm font-bold text-center text-red-800 md:text-xl">
                  Verification Failed!
                </p>
                <p className="text-sm font-bold text-center text-red-800 md:text-xl">
                  Please enter the correct verification code.
                </p>
              </div>
            )}
            <Web23Button text="Continue" onClick={handleVerify} />
          </div>
        ))}
      {progress === 3 && (
        <div className="flex flex-col justify-between h-full mt-6">
          <div>
            <div className="flex justify-center mb-3">
              <img
                src={VerifyImage}
                width={72}
                height={72}
                alt="Verified Account"
              />
            </div>
            <p className="mb-2 text-center text-[32px] font-bold text-grey-900">
              Congratulations, {dispName}!
            </p>
            <p className="mb-3 text-base font-medium text-center text-grey-600">
              You have successfully created your account on Web23. Now, you can
            </p>
            <div className="flex justify-center">
              <div className="flex w-[400px] flex-col gap-[10px]">
                <div className="flex items-start gap-2">
                  <CheckSVG className="fill-green-800" />
                  <p className="text-sm font-medium text-grey-900">
                    Post NFTs, photos, videos, articles & more
                  </p>
                </div>
                <div className="flex items-start gap-2">
                  <CheckSVG className="fill-green-800" />
                  <p className="text-sm font-medium text-grey-900">
                    Build a dedicated community and reward your best followers
                    with unique social coins
                  </p>
                </div>
                <div className="flex items-start gap-2">
                  <CheckSVG className="fill-green-800" />
                  <p className="text-sm font-medium text-grey-900">
                    Monentize your artwork and content on the go and get paid to
                    your web2 wallet
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Web23Button
              text="Continue to Smart Page (9)"
              onClick={() => {
                const searchIndex = settings.domain.lastIndexOf(".");
                navigate('/login');
              }}
            />
          </div>
        </div>
      )}
      {ToasterBox}
    </div>
  );
};

export default Web23PersonalDetail;
