// import { useImageUpload } from '@/hooks/image_upload';
import axios from "axios";
// import Image from 'next/image';
// import { useRouter } from 'next/router';
import React, { useContext, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import "./index.css";

import { useImageUpload } from "../../hooks/image_upload";

import {
  Web23Card,
  Web23Button,
  Web23Input,
  Web23Spinner,
} from "../../components";

import { ReactComponent as ArticleSVG } from "../../assets/icon/article.svg";
import { ReactComponent as CloseIconSVG } from "../../assets/icon/close.svg";
import { ReactComponent as CopyRightSVG } from "../../assets/icon/copyright.svg";
import { ReactComponent as LocationOnSVG } from "../../assets/icon/location_on.svg";
import { ReactComponent as DeleteSVG } from "../../assets/icon/delete1.svg";

import EmptyImageLogo from "../../assets/img/emptyImg.png";
import UploadVideo from "../../assets/img/upload-video.png";

import { API_ENDPOINT_URL } from "../../config";
import { SettingContext } from "../../context/context";
import { ModalProcessingContext } from "../../context/context";
import { pinFileToIPFS } from "../../utility/ipfs";

const buttonName = {
  photo: "Post Photo",
  audio: "Post Audio",
  video: "Post Video",
  article: "Post Article",
};

const fileList = {
  photo: ".jpg, .png, .gif, .svg, .tiff, .gltf, .glb|image/*",
  video: ".mp4, .mov, .avi|video/*",
  audio: ".mp3, .wav, .flac, .ogg|audio/*",
  article: "*.*",
};

const Web23PostMedia: React.FC<{
  variant?: "photo" | "audio" | "video" | "article";
  effectFunc?: () => void;
}> = ({ variant = "photo", effectFunc }) => {
  const [chooseFile, setChooseFile] = useState<boolean>(false);
  const [preview, setPreview, selectedFile, setSelectedFile, onSelectFile] =
    useImageUpload();

  const [loading, setLoading] = useState<boolean>(false);
  const [aboutMedia, setAboutMedia] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [copyright, setCopyright] = useState<string>("");

  const { settings, saveSettings } = useContext(SettingContext);
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const handlePostMedia = async () => {
    try {
      //Upload image to ipfs
      setLoading(true);
      let content;
      const baseUrl = `${API_ENDPOINT_URL}` + "media/postMedia";

      if (variant === "article") {
        content = aboutMedia;
      } else {
        const contentURL = await pinFileToIPFS(selectedFile);
        content = contentURL.IpfsHash;
      }

      await axios.post(baseUrl, {
        user_id: settings.userId,
        category: variant,
        detail: aboutMedia,
        location: location,
        copyright: copyright,
        content: content,
      });

      if (variant === "article") {
        setModalProcess({ ...modalProcess, isPostingArticle: false });
      } else if (variant === "audio") {
        setModalProcess({ ...modalProcess, isPostingAudio: false });
      } else if (variant === "video") {
        setModalProcess({ ...modalProcess, isPostingVideo: false });
      } else if (variant === "photo") {
        setModalProcess({ ...modalProcess, isPostingPhoto: false });
      }
      effectFunc && effectFunc();
      setLoading(false);
    } catch (e) {
      if (variant === "article") {
        setModalProcess({ ...modalProcess, isPostingArticle: false });
      } else if (variant === "audio") {
        setModalProcess({ ...modalProcess, isPostingAudio: false });
      } else if (variant === "video") {
        setModalProcess({ ...modalProcess, isPostingVideo: false });
      } else if (variant === "photo") {
        setModalProcess({ ...modalProcess, isPostingPhoto: false });
      }
      setLoading(false);
      return {};
    }
  };

  return (
    <div className="md:max-w-[824px] max-w-[400px] rounded-2xl bg-white p-6">
      {variant === "article" ? (
        <>
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-2 text-[32px] font-bold capitalize text-grey-900">
              <ArticleSVG />
              <span>Post {variant}</span>
            </div>
            <div
              className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-400"
              onClick={() => {
                setModalProcess({ ...modalProcess, isPostingArticle: false });
              }}
            >
              <CloseIconSVG />
            </div>
          </div>
          <div className="flex flex-col w-full">
            <div className="relative mb-4">
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="wysiwyg-editor"
                placeholder="New Post Title here..."
                toolbar={{
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: false },
                  history: { inDropdown: true },
                }}
                onEditorStateChange={(e) => {
                  // setEditorState(e)
                  setAboutMedia(
                    draftToHtml(convertToRaw(e.getCurrentContent()))
                  );
                  setEditorState(e);
                }}
              />
            </div>
            <Web23Button
              text={buttonName[variant]}
              disabled={aboutMedia?.length > 0 ? false : true}
              className="w-full mt-6"
              onClick={handlePostMedia}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-[32px] font-bold capitalize text-grey-900">
              Post {variant}
            </h3>
            <div
              className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-400"
              onClick={() => {
                if (variant === "audio") {
                  setModalProcess({ ...modalProcess, isPostingAudio: false });
                } else if (variant === "video") {
                  setModalProcess({ ...modalProcess, isPostingVideo: false });
                } else {
                  setModalProcess({ ...modalProcess, isPostingPhoto: false });
                }
              }}
            >
              <CloseIconSVG />
            </div>
          </div>
          <div className="flex items-start gap-6">
            <div className="w-[35%] flex flex-col items-center border-[1px] border-grey-100 rounded-[4px] relative">
              {selectedFile ? (
                <div
                  className={`relative ${
                    variant === "photo"
                      ? "h-[360px] w-[240px] my-4"
                      : `${
                          variant === "video"
                            ? "h-[132px] w-[240px] my-4"
                            : "h-[120px] w-[240px] my-4"
                        }`
                  }`}
                >
                  {variant === "video" ? (
                    <video
                      controls
                      className="absolute w-full h-full"
                      autoPlay
                      muted
                    >
                      <source src={preview} />
                    </video>
                  ) : variant === "audio" ? (
                    <audio
                      controls
                      className="absolute w-full h-full"
                      autoPlay
                      muted
                    >
                      <source src={preview} />
                    </audio>
                  ) : (
                    <div
                      className="absolute w-full h-full bg-cover"
                      style={{ backgroundImage: `url(${preview})` }}
                    ></div>
                  )}
                  <div
                    className="absolute right-[-10px] top-[-10px] w-8 h-8 rounded-full bg-grey-100 cursor-pointer hover:bg-grey-300 duration-150"
                    onClick={() => {
                      setPreview(undefined);
                      setSelectedFile(undefined);
                    }}
                  >
                    <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                      <DeleteSVG />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {variant === "photo" ? (
                    <>
                      <div className="m-[46px]">
                        <img src={EmptyImageLogo} alt="empty" />
                      </div>
                      <p className="px-4 pb-4 text-sm text-center text-grey-600">
                        File types supported: JPG, PNG, GIF, SVG, MP4, WEBM,
                        MP3, WAV, OGG, GLB, GLTF. Max size: 100 MB
                      </p>
                    </>
                  ) : variant === "video" ? (
                    <>
                      <div className="mx-[66px] my-[53px]">
                        <img src={UploadVideo} alt="empty" />
                      </div>
                      <p className="px-4 pb-4 text-sm text-center text-grey-600">
                        File types supported: MP4, MOV, AVI. Max size: 100 MB
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="mx-[66px] my-[53px]">
                        <img src={UploadVideo} alt="empty" />
                      </div>
                      <p className="px-4 pb-4 text-sm text-center text-grey-600">
                        File types supported: MP3, WAV, FLAC. Max size: 100 MB
                      </p>
                    </>
                  )}
                  <div className="w-[60%] pb-4">
                    <Web23Button
                      text="Upload"
                      onClick={() => {
                        document.getElementById("upload_img")?.click();
                      }}
                    />
                    <input
                      type="file"
                      className="hidden"
                      accept={fileList[variant]}
                      id="upload_img"
                      onChange={
                        onSelectFile as React.ChangeEventHandler<HTMLInputElement>
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="w-[60%]">
              <div className="flex flex-col w-full">
                <div className="relative mb-4">
                  <textarea
                    id="about-media"
                    className={`${
                      aboutMedia?.length > 280
                        ? "border-2 border-red-600"
                        : "border-[1px] border-grey-100"
                    } relative h-[192px] w-full rounded-[24px] p-5 pt-10 pl-5 text-base border-1 border-grey-100 font-bold text-grey-900`}
                    value={aboutMedia}
                    onChange={(e) => setAboutMedia(e.target.value)}
                  ></textarea>
                  <label
                    id="aboutNft"
                    className={`${
                      aboutMedia?.length
                        ? "absolute top-[10px] left-[20px] text-sm font-bold text-grey-400"
                        : "absolute top-[20px] left-[20px] text-base font-bold text-grey-400"
                    } duration-150`}
                  >
                    {aboutMedia?.length < 280 ? (
                      <span>Tell People about your {variant}...</span>
                    ) : (
                      <span className="text-red-600">
                        Max comment length is 280 characters
                      </span>
                    )}
                  </label>
                </div>
                <div className="flex w-full gap-2">
                  <div className="w-[230px]">
                    <Web23Input
                      placeholder="Add location"
                      validate={true}
                      value={location}
                      className="w-full"
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      <LocationOnSVG />
                    </Web23Input>
                  </div>
                  <div className="w-[230px]">
                    <Web23Input
                      placeholder="Add copyright"
                      validate={true}
                      value={copyright}
                      className="w-full"
                      onChange={(e) => setCopyright(e.target.value)}
                    >
                      <CopyRightSVG />
                    </Web23Input>
                  </div>
                </div>
                <Web23Button
                  loading={loading}
                  text={buttonName[variant]}
                  disabled={aboutMedia?.length > 0 ? false : true}
                  className="w-full mt-6"
                  onClick={handlePostMedia}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Web23PostMedia;
