import React, { useContext,useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_ENDPOINT_URL } from "./../config";
import { Web23Button } from "../components";

import { SettingContext, initialSettings } from "../context/context";

import { ReactComponent as TopMenuIcon01 } from "../assets/icon/topmenu01.svg";
import { ReactComponent as TopMenuIcon02 } from "../assets/icon/topmenu02.svg";
import { ReactComponent as TopMenuIcon03 } from "../assets/icon/topmenu03.svg";
import { ReactComponent as TopMenuIcon04 } from "../assets/icon/topmenu04.svg";
import { ReactComponent as Web23BadgeSVG } from "../assets/icon/Web23-Badge.svg";
import { ReactComponent as SmartPageLogoSVG } from "../assets/icon/smart-page-logo.svg";
import { ReactComponent as ArrowDownSVG } from "../assets/icon/keyboard_arrow_down.svg";
const PageContainer: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className = "" }) => {
  return (
    <div className={`h-full w-full bg-gray-50 relative ${className}`}>
      {children}
    </div>
  );
};

const TopMenu: React.FC<{
  children?: React.ReactNode[];
  onClick?: () => void;
  isCreator?: boolean;
  pageDomain?: string;
}> = ({ onClick, isCreator = false}) => {
  const navigate = useNavigate();
  const { settings, saveSettings } = useContext(SettingContext);
  const [userList,setUList]=useState<any>([]);
  
  const __openSP=async(__e:any)=>{
	let idata=__e.target.value;
	const tmpUserList:any=[];
	setUList([]);
	if(idata.trim()==="") {
		(window as any).document.getElementById("_openDSP").classList.add("hidden");
		return;
	}
	(window as any).document.getElementById("_openDSP").classList.remove("hidden");	
	const users = await axios.post(
          `${API_ENDPOINT_URL}` + "account/search",
          {
				key:idata.trim()
			
          }
        );
	if(users.status===200){
		setUList(users.data?users.data.data:[]);
	}		

  };
  return (
    <div className="sticky top-0 z-10 w-full" >
      <div className="bg-gray-50 w-full">
        <div className="ml-auto mr-auto w-full md:px-[70px] px-[20px] flex flex-col items-center justify-between gap-4 py-3 md:gap-0 md:flex-row">
          
		  <div className="flex items-center justify-between justify-items-center" >
            <SmartPageLogoSVG
              className="mr-10 cursor-pointer"
              onClick={() => {
				let _pageName=localStorage.getItem('pageDomainName'); 
				if(_pageName!=null && _pageName!="")
				//navigate("/"+_pageName);
				document.location="/"+_pageName;
              }}
            />
            <Web23BadgeSVG />
          </div>
		  <div className="flex flex-col grow mx-4" >
			<input type='' onChange={(e)=>__openSP(e)} 
				className='px-4 py-2 w-full rounded-3xl border border-neutral-300'
				placeholder="search your friend"
			/>
			<div className="w-full max-h-[450px] overflow-y-scroll px-4 py-4 shadow-lg absolute z-50 hidden" id="_openDSP" style={{marginTop:"48px",backgroundColor:'#fbfbfb',width:'480px'}}>
				<ul>
				{userList && 
					userList.map((item:any,i:any)=>{
						return(
						<li key={"k"+i}>
							<div className="flex flex-row mb-4">
								<div><img className='rounded-full' style={{height:"48px"}} src="https://img.freepik.com/premium-vector/man-avatar-profile-picture-vector-illustration_268834-538.jpg" /></div>
								<div className='flex flex-col grow px-4'>
									<div className='text-sm'>{item.domainName!=undefined?item.domainName:"No Domain"}</div>
									<div className='text-lg'>{item.displayName}</div>
								</div>
								<div>
								{item.domainName!=undefined &&
									<button className='btn text-cyan-600' id={"key"+i} value={item.domainName.replace(/\./g,"")}
									onClick={(e:any)=>{
											let btn:any=(window as any).document.getElementById(e.target.id);
											let value:string=btn.getAttribute("value");
												if(value!=null && value!=""){
													
													(window as any).document.location='/'+value;
												}
											} }
									>
									Show
									</button>
								}
								</div>
							</div>
						</li>
					);})
					
				}
				</ul>
			</div>
		  </div>
		  {!(settings && settings.userId && settings.userId!="") && 
		  <div className="flex items-center justify-between" >
			<Web23Button
                  text="Login"
                  className="px-6 border-0"
                  onClick={()=> navigate("/login")}
                  size="sm"
				  
            />
		  </div>
		  }
          {(settings && settings.userId && settings.userId!="") && (
            <div className="flex items-center justify-between" >
              <div className="ml-auto">
                <div className="flex items-center md:gap-3 gap-1">
				  <div className="relative h-[40px] w-[40px] cursor-pointer rounded-[100%] bg-gray-100 p-[13.33px] hover:bg-gray-300"
				  onClick={() => {
					  let x:string=(window as any).document.body.style.zoom;
					  if(x==="85%")
                      (window as any).document.body.style.zoom=""+100+"%";
					  else
						(window as any).document.body.style.zoom=""+85+"%";
                    }}
				  >
				  <i className="bi bi-aspect-ratio absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" style={{fontSize:"1.5rem"}}></i>
                  
                  </div>
                  <div className="relative h-[40px] w-[40px] cursor-pointer rounded-[100%] bg-gray-100 p-[13.33px] hover:bg-gray-300">
                    <TopMenuIcon01 className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                  </div>
                  <div className="relative h-[40px] w-[40px] cursor-pointer rounded-[100%] bg-gray-100 p-[13.33px] hover:bg-gray-300">
                    <TopMenuIcon02 className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                  </div>
                  <div className="relative h-[40px] w-[40px] cursor-pointer rounded-[100%] bg-gray-100 p-[13.33px] hover:bg-gray-300">
                    <TopMenuIcon03 className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                  </div>
                  <div
                    className="relative h-[40px] w-[40px] cursor-pointer rounded-[100%] bg-gray-100 p-[13.33px] hover:bg-gray-300"
                    onClick={() => {
                      const searchId = settings.domain.lastIndexOf(".");
                      navigate(
                        "/" +
                          settings.domain.slice(0, searchId) +
                          settings.domain.slice(searchId + 1) +
                          "/settings"
                      );
                    }}
                  >
                    <TopMenuIcon04 className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]" />
                  </div>
                  <div
                    className="p-1 pr-3 bg-grey-100 rounded-[32px] flex justify-between items-center active:bg-grey-400 hover:bg-grey-200 cursor-pointer"
                    onClick={() => {
					  saveSettings({ ...initialSettings });
					  localStorage.clear();
                      navigate("/login");
                    }}
                  >
                    <div className="flex gap-2 items-center">
                      <div className="w-8 h-8 uppercase rounded-full bg-[#FFCE0A] flex justify-center items-center font-bold text-base text-black">
                        {settings?.displayName?.charAt(0)}
                      </div>
                      <div className="font-bold text-base text-black no-wrap" style={{whiteSpace: "pre"}}>
                        {settings?.displayName}-Logout
                      </div>
                    </div>
                    {/*<ArrowDownSVG />*/}
                  </div>
                </div>
              </div>

              <div className="ml-3">
			  { 
				isCreator && 
                <Web23Button
                  text="Post"
                  className="sm:px-6 px-2 border-0"
                  onClick={onClick}
                  size="sm"
				  id="___mtpost"
                />
			  }
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { PageContainer, TopMenu };
