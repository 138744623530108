import React from 'react';

type IWeb23Card = {
  img?: React.ReactNode;
  title?: string;
  description?: string;
  button?: React.ReactNode;
  bottomButton?: React.ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary';
};

const Web23Card: React.FC<IWeb23Card> = ({
  img,
  title,
  description,
  button,
  className,
  bottomButton,
  variant = 'primary',
}) => {
  return (
    <div
      className={`${className} mt-8 flex max-w-[364px] flex-col items-center`}
    >
      <div
        className={`${
          variant === 'primary' ? 'h-[144px] w-[144px]' : 'h-[276px] w-[276px]'
        }`}
      >
        {img}
      </div>
      <h4 className='mt-6 text-xl font-bold text-grey-900'>{title}</h4>
      <p className='mt-1 text-base font-medium text-grey-700'>{description}</p>
      <div className='m-auto mt-6 w-full'>{button}</div>
      <div className='mt-6 w-full'>{bottomButton}</div>
    </div>
  );
};

export default Web23Card;
