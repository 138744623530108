import toast, { Toaster } from "react-hot-toast";

import { ReactComponent as CircleCheckSVG } from "../assets/icon/check_circle.svg";

const useToast = () => {
  return {
    ToasterBox: (
      <div className="[&>div>div]:!top-[90px]">
        <Toaster
          position="top-right"
          toastOptions={{
            duration: 2000,
          }}
        />
      </div>
    ),
    showToast: (
      title: string,
      type: "info" | "success" | "danger" | "error" = "success"
    ) => {
      toast.custom((t: any) => (
        <div
          className={`flex items-center px-4 py-1 border-2 border-stone-200 shadow rounded-2xl text-white  gap-2 pointer-events-auto 
					${t.visible ? "animate-enter" : "animate-leave" } 
					${type === "error" && "bg-rose-500"}
					${type === "success" && "bg-sky-400"}
			`}
        >
          {type === "success" && <CircleCheckSVG className="fill-grey-900" />}
          <p className={`text-base font-bold`}>
            {title}
          </p>
        </div>
      ));
    },
  };
};

export default useToast;
