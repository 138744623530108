const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const validateNumber = (str: string) => {
  return str.match(/^\d+$/);
};

const formatDate = (param: string) => {
  return ("0" + param).slice(-2);
};

export { validateEmail, validateNumber, formatDate };
