import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

import {
  Web23Button,
  Web23Input,
  Web23Modal,
  Web23Spinner,
} from "../../components";

import { API_ENDPOINT_URL } from "../../config";
import { SettingContext } from "../../context/context";
import useToast from "../../utility/useToast";

import { ReactComponent as Web23Logo } from "../../assets/icon/web23_logo.svg";
import { ReactComponent as CloseIconSVG } from "../../assets/icon/close.svg";
import { ReactComponent as FacebookSVG } from "../../assets/icon/Facebook.svg";
import { ReactComponent as GoogleSVG } from "../../assets/icon/Google.svg";
import { ReactComponent as TwitterSVG } from "../../assets/icon/Twitter.svg";

import SampleNFT from "../../assets/img/sampleNFT.png";
import HashPackImg from "../../assets/img/HashPack.png";
import MetamaskImg from "../../assets/img/Metamask.png";
import BladeWalletImg from "../../assets/img/Blade.png";
import AtlasWalletImg from "../../assets/img/Atlas.png";

import { useHashconnectService } from "../../context/Hashconnect";

type IRegisteredUsers = {
  domainName: string;
  displayName: string;
};
const LoginPage = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [failed, setFailed] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { settings, saveSettings } = useContext(SettingContext);
  const [showDomainModal, setShowDomainModal] = useState<boolean>(false);
  const [registeredUsers, setRegisteredUsers] = useState<IRegisteredUsers[]>(
    []
  );
  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const [loginType, setLoginType] = useState<boolean>(false);
  const navigate = useNavigate();
  const { ToasterBox, showToast } = useToast();
  const { connectToExtension, disconnect, pairingData, availableExtension } =
    useHashconnectService();

  const handleLogin = async () => {
    try {
      setLoading(true);
      const baseUrl = `${API_ENDPOINT_URL}` + "auth/web23-login";
      const response = await axios.post(baseUrl, {
		  email: email,
		  password: password
        });

      if (response.status === 200) {
        saveSettings({
          ...settings,
          userId: response?.data?.data?._id,
          displayName: response?.data?.data?.displayName,
          domain: response?.data?.data?.domainName || "",
          email: response?.data?.data?.email,
          walletAddress: response?.data?.data?.walletAddress,
          walletName: response?.data?.data?.walletName,
          walletStatus: pairingData ? "true" : "false",
          birthDay: response?.data?.data?.birthDay,
          countryCode: response?.data?.data?.countryCode,
          phoneNumber: response?.data?.data?.phoneNumber,
        });
        if (response?.data?.data?.domainName) {
			if(response?.data?.data?.walletName==="Hashpack"){
			  //await handleWalletConnect("");
			const searchIndex =response?.data?.data?.domainName.lastIndexOf(".");
			//alert(response?.data?.data?.domainName);			
			navigate(`/${response?.data?.data?.domainName?.slice(0, searchIndex) +""+response?.data?.data?.domainName?.slice(searchIndex + 1)}`);
			  
			}
			else if(response?.data?.data?.walletName==="Metamask"){
			    const searchIndex =response?.data?.data?.domainName.lastIndexOf(".");
				navigate(`/${response?.data?.data?.domainName?.slice(0, searchIndex) +""+response?.data?.data?.domainName?.slice(searchIndex + 1)}`);
			}
			else showToast("Please get the correct wallet with the wallet address linked to your smart page account.","error");
        }
		else navigate("/create-account/domain");
        setFailed(false);
      } else {
        setFailed(true);
        setEmail("");
        setPassword("");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setFailed(true);
      setEmail("");
      setPassword("");
    }
  };

  const handleWithWallet = async () => {
    setWalletConnecting(true);
    if (availableExtension) {
      setWalletConnecting(true);
	  let HashConnectPairedX:any=localStorage.getItem("HashConnectPaired");
	  let HashConnectPaired:string=HashConnectPairedX===null?"0.0.0":HashConnectPairedX.toString();
	  if(HashConnectPaired==="0.0.0"){
		  await connectToExtension();
		  var __o = setInterval(async ()=>{
					console.log("INIT -----");
					let HashConnectPairedX:any=localStorage.getItem("HashConnectPaired");
					let HashConnectPaired:string=HashConnectPairedX===null?"0.0.0":HashConnectPairedX.toString();
					if( HashConnectPaired==="0.0.0") {console.log("Not Paired");}
					else{
					
						clearInterval(__o);
						console.log("Pairing Data FOUND");
						console.log(HashConnectPaired);
						await fetchDomain(HashConnectPaired);
						setWalletConnecting(false);
				}}, 1000)
	  } else{
		 
		 await fetchDomain(HashConnectPaired);
		 setWalletConnecting(false); 
	  }
	  
    } else
      showToast(
        "Please install hashconnect wallet extension first. from chrome web store.",
        "error"
      );
    setWalletConnecting(false);
  };

  const handleLoginWithDomain = async (domainName: string) => {
    const baseURL = `${API_ENDPOINT_URL}` + "auth/login";
    try {
		
      setLoading(true);
      const response = await axios.post(baseURL, {
        domainName: domainName,
      });
      setLoading(false);
	 
      if (response.status === 200) {
		
        saveSettings({
          ...settings,
          userId: response?.data?.data?.user?.id,
          displayName: response?.data?.data?.user?.displayName,
          domain: response?.data?.data?.user?.domainName,
          email: response?.data?.data?.user?.email,
          walletAddress: response?.data?.data?.user?.walletAddress,
          walletName: response?.data?.data?.user?.walletName,
          walletStatus: pairingData ? "true" : "false",
          birthDay: response?.data?.data?.user?.birthDay,
          countryCode: response?.data?.data?.user?.countryCode,
          phoneNumber: response?.data?.data?.user?.phoneNumber,
        });
		
        setShowDomainModal(false);
		const searchIndex = response?.data?.data?.user?.domainName.lastIndexOf(".");
		navigate(
          `/${
            response?.data?.data?.user?.domainName?.slice(0, searchIndex) +
            "" +
            response?.data?.data?.user?.domainName?.slice(searchIndex + 1)
          }`
        );
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const fetchDomain = async (HashConnectPairedAcountId:string) => {
    const baseURL = `${API_ENDPOINT_URL}` + "auth/getWalletUser";
    try {
      if (HashConnectPairedAcountId) {
        const response = await axios.get(baseURL, {
          params: {
            walletAddress: HashConnectPairedAcountId,
          },
        });
        if (response.status === 200) {
          setRegisteredUsers(response?.data?.data ?? []);
          setShowDomainModal(true);
          setLoginType(true);
        }
      }
    } catch (e) {}
  };

  const handleWalletConnect = async (walletName: string) => {
    if (availableExtension) {
      setWalletConnecting(true);
      await connectToExtension();
      setWalletConnecting(false);
    } else {
      showToast(
        "Please install hashconnect wallet extension first from chrome web store.",
        "danger"
      );
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleAccount = await axios.get(
        "https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses",
        {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        }
      );
      if (googleAccount.status === 200) {
        setEmail(googleAccount.data.emailAddresses[0].value);
      }
    },
  });

  useEffect(() => {
   /* if (
      settings?.domain?.length > 0 &&
      settings?.displayName?.length > 0 &&
      settings?.userId?.length > 0
    ) {
      const searchIndex = settings?.domain?.lastIndexOf(".");
      navigate(
        `/${
          settings?.domain?.slice(0, searchIndex) +
          "" +
          settings?.domain?.slice(searchIndex + 1)
        }`
      );
    }*/
  }, [settings]);

  return (
   <div className='container-md'>
    <div className="flex flex-col w-full h-full bg-white">
      <div className="flex items-center gap-2 px-8 py-3 mb-2">
        <div>
          <Web23Logo />
        </div>
        <h1 className="text-[28px] font-bold text-green-700">Web23</h1>
      </div>

      <div className="flex justify-center flex-1 w-full h-full px-8 pb-8" >
        <div className="grid justify-center w-full h-full md:grid-cols-2 bg-yellow-50">
          <div className="m-auto flex w-full flex-col ">
            <div className="m-auto">
              <div className="p-5 rounded-lg bg-yellow-50">
                <img
                  src={SampleNFT}
                  alt="nft sample"
                  
                />
              </div>
            </div>
            <p className="mb-8 text-base font-medium text-center text-grey-700 p-6">
              By Signing in for Web23 Smart Pages, you have already agreed to
              our terms & Conditions. Learn have we process your data in privacy
              policy and cookies policy
            </p>
          </div>
          <div
            className={`flex h-full flex-col rounded-2xl border border-black bg-white p-6 ${
              showDomainModal && loginType ? "blur-sm" : "blur-0"
            }`} 
          >
            <div className="flex flex-col justify-between h-full" >
              <div className="relative flex flex-col justify-between h-full">
                <div>
                  <h6 className="pb-2 text-left text-[32px] font-bold text-grey-900">
                    Login
                  </h6>
                  <p className="mb-6 text-base font-medium text-grey-600">
                    Welcome back! Choose how you want to log in
                  </p>
                  {walletConnecting ? (
                    <div className="flex justify-center min-h-[]">
                      <Web23Spinner />
                    </div>
                  ) : (
                    <div className="flex flex-col gap-6 mb-6">
                      <Web23Input
                        placeholder="E-mail Address"
                        validate={true}
                        className="w-full"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setFailed(false);
                        }}
                      >
                        {/* <CheckSVG className='fill-green-700' /> */}
                      </Web23Input>
                      <Web23Input
                        placeholder="password"
                        validate={true}
                        className="w-full"
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setFailed(false);
                        }}
                      >
                        {/* <EventSVG /> */}
                      </Web23Input>
                      <Web23Button
                        text="Log in"
                        disabled={!(password.length > 0 && email?.length > 0)}
                        onClick={() => {
                          handleLogin();
                        }}
                      />
                      {failed && (
                        <span className="mt-[-15px] text-center text-sm font-bold text-red-900 duration-150">
                          Invalid Email Address or password
                        </span>
                      )}
                    </div>
                  )}

                  <p className="mb-6 text-sm font-bold text-center text-grey-600">
                    OR
                  </p>
                  <div className="flex justify-center mb-6">
                    <div className="min-w-[332px] flex flex-col items-center justify-center py-3 border border-[#9E9E9E] rounded-xl">
                      <p className="mb-4 text-xl font-bold text-grey-900">
                        Continue with a Wallet
                      </p>
                      <div className="flex gap-4 mb-2">
                        <div onClick={handleWithWallet}>
                          <img src={HashPackImg} alt="hashpack" />
                        </div>
                        <div>
                          <img src={MetamaskImg} alt="Metamask" style={{width:"48px"}} />
                        </div>
                        <div>
                          <img src={AtlasWalletImg} alt="atlas" />
                        </div>
                      </div>
                      <p className="text-base font-bold underline text-grey-600">
                        or see more wallets
                      </p>
                    </div>
                  </div>
                  <p className="my-6 text-sm font-bold text-center text-grey-600">
                    OR
                  </p>
                  <div className="flex justify-center gap-4 mb-4">
                    <p className="text-xl font-bold text-grey-600">
                      Continue with
                    </p>
                    <div>
                      <FacebookSVG />
                    </div>
                    <div onClick={() => googleLogin()}>
                      <GoogleSVG />
                    </div>
                    <div>
                      <TwitterSVG />
                    </div>
                  </div>
                </div>
                <p
                  className="text-base font-bold text-center underline text-grey-900"
                  onClick={() => navigate("/create-account")}
                >
                  Not a User yet? Register
                </p>
                {loading && !showDomainModal && (
                  <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <Web23Spinner />
                  </div>
                )}
              </div>
            </div>
          </div>
          {showDomainModal && loginType && (
            <Web23Modal>
              <div className="relative flex flex-col items center bg-white p-10 pt-5 rounded-lg w-[480px]">
                <CloseIconSVG
                  className="p-1 mb-10 ml-auto rounded-full cursor-pointer hover:bg-grey-300"
                  onClick={() => setShowDomainModal(false)}
                />
                <h3 className="font-bold text-center text-[20px] text-grey-900 mb-5">
                  Registered Users
                </h3>
                <div className="border-t-2 border-l-2 border-r-2">
                  <div className="flex items-center justify-between p-2 font-bold border-b-2 text-grey-900 border-b-grey-600">
                    <span>Domain Name</span>
                    <span>Display Name</span>
                  </div>
                  {registeredUsers.map((item, id) => {
                    return (
                      <div
                        key={id}
                        className="flex items-center justify-between p-2 duration-150 border-b-2 cursor-pointer hover:bg-grey-300"
                        onClick={async () => {
                          await handleLoginWithDomain(item.domainName);
                        }}
                      >
                        <span>{item.domainName}</span>
                        <span>{item.displayName}</span>
                      </div>
                    );
                  })}
                </div>
                {loading && (
                  <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
                    <Web23Spinner />
                  </div>
                )}
              </div>
            </Web23Modal>
          )}
        </div>
        {ToasterBox}
      </div>
    </div>
	</div>
  );
};

export default LoginPage;
