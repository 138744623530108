import React from 'react';

const Web23ImageLayout: React.FC<{
  imgSrc?: string;
  width?: string;
  height?: string;
  children?: React.ReactNode;
  className?: string;
}> = ({ imgSrc, width, height, children, className }) => {
  return (
    <div
      className={`h-[${height}] w-[${width}] bg-cover ${className}`}
      style={{ backgroundImage: `url(${imgSrc})` }}
    >
      {children}
    </div>
  );
};

export default Web23ImageLayout;
