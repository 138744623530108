const API_ENDPOINT_URL =process.env.REACT_APP_SMART_PAGE_API_ENDPOINT? process.env.REACT_APP_SMART_PAGE_API_ENDPOINT: "https://smartpages.websiteprotector.in/api/";
const IMAGE_ENDPONT_URL =process.env.REACT_APP_SMART_PAGE_IMAGE_ENDPOINT? process.env.REACT_APP_SMART_PAGE_IMAGE_ENDPOINT: "https://smartpages.websiteprotector.in/";
const PINATA_API_KEY = "9be136d488e645663d18";
const PINATA_API_SECRET =
  "52e5013a1bf673fc0480eedcb2a1bb541bbfb3e834acbdc88881234d1a14e6ce";
const PINATA_JWT =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIwYjc3OTU2Yy0yNTZkLTQ4MGMtYTM5OS0yMDhjZDViOGViYjQiLCJlbWFpbCI6InJhaHVsQHRva2VtYW4uY2FwaXRhbCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiI5YmUxMzZkNDg4ZTY0NTY2M2QxOCIsInNjb3BlZEtleVNlY3JldCI6IjUyZTUwMTNhMWJmNjczZmMwNDgwZWVkY2IyYTFiYjU0MWJiZmIzZTgzNGFjYmRjODg4ODEyMzRkMWExNGU2Y2UiLCJpYXQiOjE2NzYzOTk0MDN9.CPn__tviQ1dq_cE6vFmLAGGLpSxhtGffPeFDBHlWoF4";
const WHBAR_TOKEN_ID_TESTNET = "0.0.3377456";
const WHBAR_TOKEN_ID_MAINNET = "0.0.1456986";
const keys = {
  saucerSwap: {
    testNet: {
      v02Factory: process.env.SAUCERSWAP_TESTNET_V02FACTORY || "0.0.3395190",
      v02Router: process.env.SAUCERSWAP_TESTNET_V02ROUTER || "0.0.3395275",
      wHbarContract:
        process.env.SAUCERSWAP_TESTNET_WHBAR_CONTRACT || "0.0.3377455",
      wHbar: process.env.SAUCERSWAP_TESTNET_WHBAR || "0.0.3377456",
    },
    mainNet: {
      v02Factory: process.env.SAUCERSWAP_MAINNET_V02FACTORY || "0.0.1062784",
      v02Router: process.env.SAUCERSWAP_MAINNET_V02ROUTER || "0.0.1461860",
      wHbarContract:
        process.env.SAUCERSWAP_MAINNET_WHBAR_CONTRACT || "0.0.1456985",
      wHbar: process.env.SAUCERSWAP_MAINNET_WHBAR || "0.0.1456986",
    },
  },
};
const GOOGLE_CLIENT_ID =
  process.env.GOOGLE_CLIENT_ID ||
  "52046282017-s0otmthl29364pmh24j3oua7t3p4jvtp.apps.googleusercontent.com";

export {
  API_ENDPOINT_URL,
  PINATA_API_KEY,
  PINATA_API_SECRET,
  PINATA_JWT,
  WHBAR_TOKEN_ID_MAINNET,
  WHBAR_TOKEN_ID_TESTNET,
  keys,
  GOOGLE_CLIENT_ID,
  IMAGE_ENDPONT_URL
};
