import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";
import EmptyPhoto from "../../assets/icon/Photo.svg";
import NFTPhoto from "../../assets/icon/NFT.svg";

import CreatingNftModal from "../../layouts/create-nft";
import CreateToken from "../../layouts/create-token";
import { PageContainer, TopMenu } from "../../layouts/top-menu";

import {
  Introduction,
  Web23Button,
  Web23Card,
  Web23ImgButton,
  Web23Input,
  Web23MarketItem,
  Web23Spinner,
} from "../../components";
import { Web23Modal, Web23PostMedia } from "../../components";
import NftTab from "../../components/web23-tabs/nft-tab";
import Web23TimeLineItem from "../../components/web23-timeline-item";

import { API_ENDPOINT_URL,IMAGE_ENDPONT_URL } from "../../config";

import { SettingContext } from "../../context/context";
import { ModalProcessingContext } from "../../context/context";
import { useHashconnectService } from "../../context/Hashconnect";
import { transferHBARTo,transferTokenTo,tokenBalance } from "../../utility/hashgraph-transaction";
// import { pinFileToIPFS } from "../../utility/ipfs";
import useToast from "../../utility/useToast";
import { makeImgString } from "../../utility/makeImage";
import { getImageFromURL } from "../../utility/jimage";

import { ReactComponent as BladeWalletSVG } from "../../assets/icon/Blade1.svg";
import { ReactComponent as BladeWalletMDSVG } from "../../assets/icon/Blade_md.svg";
import { ReactComponent as CloseIcon } from "../../assets/icon/close.svg";
import { ReactComponent as ContentCopySVG } from "../../assets/icon/content_copy.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icon/delete.svg";
import { ReactComponent as HashPackSVG } from "../../assets/icon/HashPack.svg";
import { ReactComponent as CheckBigSVG } from "../../assets/icon/ic_check_circle_big.svg";
import { ReactComponent as LinkOffSVG } from "../../assets/icon/link_off.svg";
import { ReactComponent as MetaMaskSVG } from "../../assets/icon/Metamask.svg";
import { ReactComponent as ModeIcon } from "../../assets/icon/mode.svg";
import { ReactComponent as NftLogo } from "../../assets/icon/nft_sketch.svg";
import { ReactComponent as NftSVG } from "../../assets/icon/NFT.svg";
import { ReactComponent as PhotoSVG } from "../../assets/icon/Photo.svg";
import { ReactComponent as AudioSVG } from "../../assets/icon/Audio.svg";
import { ReactComponent as VideoSVG } from "../../assets/icon/Video.svg";
import { ReactComponent as ArticleSVG } from "../../assets/icon/article.svg";
import { ReactComponent as RainbowSVG } from "../../assets/icon/Rainbow.svg";
import { ReactComponent as StoreSVG } from "../../assets/icon/store.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icon/twitter_grey.svg";
import { ReactComponent as TokenBGSVG } from "../../assets/icon/token_bg_white.svg";
import { ReactComponent as LoupeSVG } from "../../assets/icon/loupe.svg";
import { ReactComponent as CardGiftSVG } from "../../assets/icon/card_giftcard.svg";

import PerksImg from "../../assets/img/perks.png";
import SampleNft from "../../assets/img/sampleNFT.png";

import WalletListCard from "../../components/walletlistcard";
const introAvatar =
  "https://ipfs.io/ipfs/QmcXSeYnimkHuCj7mHJiSFDA4SqttAYdCxBHtKafz4jhMd";
const walletDetail = [
  {
    type: "Hedera",
    detail: [
      { name: "Blade", icon: <BladeWalletSVG /> },
      { name: "Hashpack", icon: <HashPackSVG /> },
    ],
  },
  {
    type: "Ethereum",
    detail: [
      { name: "Metamask", icon: <MetaMaskSVG /> },
      { name: "Rainbow", icon: <RainbowSVG /> },
    ],
  },
  {
    type: "Flow",
    detail: [],
  },
  {
    type: "Polygon",
    detail: [],
  },
  {
    type: "Solana",
    detail: [],
  },
];

const imgButtonDetail = [
  {
    text: "NFT",
    img: <NftSVG />,
    backColor: "bg-green-200",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Photo",
    img: <PhotoSVG />,
    backColor: "bg-red-50",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Audio",
    img: <AudioSVG />,
    backColor: "bg-purple-100",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Video",
    img: <VideoSVG />,
    backColor: "bg-indigo-50",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
  {
    text: "Article",
    img: <ArticleSVG />,
    backColor: "bg-grey-100",
    hoverBgColor: "bg-grey-300",
    detail: [],
  },
];

type IImageButtonDetail = {
  text: string;
  img: React.ReactNode;
  backColor: string;
  hoverBgColor: string;
  detail: any[];
}[];

type ITabsType = {
  label: string;
  index: number;
  Component: React.FC;
}[];

// Tabs Array
const tabs: ITabsType = [
  {
    label: "Timeline",
    index: 1,
    Component: NftTab,
  },
  {
    label: "Market",
    index: 2,
    Component: NftLogo,
  },
];

interface IOtherLinks {
  placeholder: string;
  content: string;
}
type IUserData = {
  birthday: string;
  countryCode: string;
  created: string;
  displayName: string;
  domainName: string;
  email: string;
  password: string;
  phoneNumber: string;
  role: string;
  verified: boolean;
  walletAddress: string;
  walletStatus: boolean;
  id: string;
  profile: {
    introduction: string;
    location: string;
    website: string;
    otherLinks: string[];
    twitter: string;
    coverImg: string;
    img: string;
  };
  socialToken: any;
};

const initialUserData = {
  birthday: "",
  countryCode: "",
  created: "",
  displayName: "",
  domainName: "",
  email: "",
  password: "",
  phoneNumber: "",
  role: "",
  verified: false,
  walletAddress: "",
  walletName: "",
  walletStatus: false,
  id: "",
  profile: {
    introduction: "",
    location: "",
    website: "",
    otherLinks: [],
    twitter: "",
    coverImg: "",
    img: "",
  },
  socialToken: {},
};

const tldList = ["blade", "bhc", "hangry", "boo", "cream", "hbar"];
const filterItem = ["nft", "photo", "audio", "video", "article"];

const TO_RADIANS = Math.PI / 180;

const OnboardingPage: React.FC = () => {
  const navigate=useNavigate();
  const [showEditProfileModal, setShowEditProfileModal] =
    useState<boolean>(false);
  const [otherLinks, setOtherLinks] = useState<IOtherLinks[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [walletStatus, setWalletStatus] = useState<boolean>(false);
  const [showFromWalletModal, setShowFromWalletModal] =
    useState<boolean>(false);
  const [addingNft, setAddingNft] = useState<boolean>(false);

  const [walletConnecting, setWalletConnecting] = useState<boolean>(false);
  const [connectedWallet, setConnectedWallet] = useState<{
    name: string;
    addres: string;
  }>();

  const [walletTab, setWalletTab] = useState<number>(0);

  const { settings } = useContext(SettingContext);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  const [dispName, setDispName] = useState<string>("");
  const [introduction, setIntroduction] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [linkItem, setLinkItem] = useState<IOtherLinks>();
  const { modalProcess, setModalProcess } = useContext(ModalProcessingContext);
  const [ isNFTCreateModelFlag, setIsNFTCreateModelFlag ] = useState<boolean>(false);
  const [ selectWalletsModel, setSelectWalletsModel] = useState<number>(0);
  const [backgroundImage, setBackgroundImage] = useState<boolean>(false);
  const [postingModal, setPostingModal] = useState<boolean>(false);
  const [mediaData, setMediaData] = useState<any[]>([]);
  const [nftData, setNftData] = useState<any[]>([]);

  const [hbarPrcie, setHbarPrice] = useState<number>(0);

  const { ToasterBox, showToast } = useToast();
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserData>(initialUserData);
  const [timelineTab, setTimelineTab] = useState<string>("timeline");
  const [showCropping, setShowCropping] = useState<boolean>(false);

  const [crop, setCrop] = useState<Crop>();
  const [cropImgSrc, setCropImgSrc] = useState<any>("");
  const [aspect, setAspect] = useState<number | undefined>(1 / 1);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>({
    height: 120,
    unit: "px",
    width: 120,
    x: 0,
    y: 0,
  });
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [filter, setFilter] = useState<string>("");

  const [follwingText,setFollwingText]=useState<string>("Following");
  const [follwingCount,setFollwingCount]=useState<string>("0");
  const [follwerCount,setFollwerCount]=useState<string>("0");
  
  // Transfer Token Jilani
  const [pageDomainName,setPageDomainName]=useState<string>("");
  const [web23TokenBalance,setWeb23TokenBalance]=useState<string>("0");
  const {
    pairingData,
    availableExtension,
    connectToExtension,
    disconnect,
    getProviderAndSigner,
    publicKey,
  } = useHashconnectService();
  
  let coverIdFound:boolean=false;
  const [coverPosition,setCoverPosition]=useState<string>("0");
  useEffect(()=>{
	 
  });
  useEffect(()=>{
	  
	  if(userData.id!=null && userData.id!=""){
		getWeb23TokenBalance();
		getFollowingCount(userData.id);
		getFollowerCount(userData.id);
		
	  }
  },[userData.id]);
  useEffect(()=>{
	if(document?.getElementById?.("__cover") && coverIdFound===false){
		coverIdFound=true;
		let __curPointer:string=coverPosition; // bottom  
		
		(window as any).coverImagePosition("__cover",0,320,__curPointer,API_ENDPOINT_URL+"account/cvp",userData.id);
	}
  },[coverPosition]);
   
  const getWeb23TokenBalance=async()=>{
		
		   let bal:any=await tokenBalance("0.0.4472369",100,settings.walletAddress);
		   if(bal.value)
		   {
			   setWeb23TokenBalance(""+bal.value.toString());
		   }
		
  }
  const getFollowingCount=async(__uid:string)=>{
  try {
	    setFollwingCount("0");
	    let uid:string=settings.userId;
        const response = await axios.get(
          `${API_ENDPOINT_URL}` + "flw/count?userId="+uid);
		
		if(response.status===200){
			setFollwingCount(response?.data?.data[0]?.count);
		}
  }catch(_e){
	  
  }}
  const getFollowerCount=async(__uid:string)=>{
  try {
		setFollwerCount("0");
		
	    let uid:string=__uid;
        const response = await axios.get(
          `${API_ENDPOINT_URL}` + "flw/mycount?userId="+uid);
		
		if(response.status===200){
			
			setFollwerCount(response?.data?.data[0]?.count);
		}
  }catch(_e){
	  
  }}
  // End Transfer Token Jilani
  
  useEffect(() => {
	const rawDomain = window.location.pathname.slice(1);
    const urlParam = rawDomain.toLowerCase(); navigate("/"+urlParam);
	const getProfile = async () => {
      try {
		const id = await axios.get(
          `${API_ENDPOINT_URL}` + "account/getUserId",
          {
            params: {
              domainName: urlParam,
            },
          }
        );
		if (id.status === 200){
			setUserData((prev) => {
            return {
              ...prev,
              ...id?.data?.data,
              id: id?.data?.data?._id,
            };
			}); // setUserData
			if (id.data.data._id === settings?.userId && settings?.userId?.length > 0){
			setIsCreator(true);
			localStorage.setItem('pageDomainName', urlParam); // do not delete this hook
			}
			const accountInfo = await axios.post(
			  `${API_ENDPOINT_URL}` + "account/getAccount",
			  {
				user_id: id?.data?.data?._id,
			  }
			);
			let profileImage: any;
			let coverImage: any;
			if (accountInfo.status === 200 && accountInfo.data.data!=null) {
				let profileImageTMP=accountInfo?.data?.data?.profileImageURL;
				let coverImageTMP=accountInfo?.data?.data?.coverImageURL;
				setCoverPosition(accountInfo?.data?.data?.extraFields?.top);
				let dpImageString=await getImageFromURL(IMAGE_ENDPONT_URL+"image/dp/"+profileImageTMP);
				let cpImageString=await getImageFromURL(IMAGE_ENDPONT_URL+"image/cp/"+coverImageTMP);
				await  setUserData((prev) => {
					return {
					  ...prev,
					  ...id?.data?.data,
					  id: id?.data?.data?._id,
					  profile: {
						  ...prev.profile,
						  img: dpImageString,
						  coverImg: cpImageString
						},
					};
				  });
				await setCropImgSrc(dpImageString);
				const profileInfo = await axios.post(`${API_ENDPOINT_URL}account/getProfile`, {user_id: id?.data?.data?._id,});
				if (profileInfo.status === 200) {
					await setUserData((prev) => {
						return {
						  ...prev,
						  ...id?.data?.data,
						  id: id?.data?.data?._id,
						  profile: {
							...prev.profile,  
							introduction: profileInfo?.data?.data?.introduction,
							location: profileInfo?.data?.data?.location,
							website: profileInfo?.data?.data?.website,
							twitter: profileInfo?.data?.data?.twitter,
							otherLinks: [...profileInfo?.data?.data?.links],
							
							
						  },
						};
					}); //setUserData
				}//profileInfo
			}//accountInfo.status
			getSocialToken(id?.data?.data?._id);
	    }//account/getUserId 
		else {
			let lc:any=localStorage?.getItem("pageDomainName");
			if (lc?.length>0){
				showToast("User not found","error");
				navigate("/"+lc);
			} else navigate("/login");
		}
	} catch (e) {
			let lc:any=localStorage?.getItem("pageDomainName");
			if (lc?.length>0){
				showToast("User not found","error");
				navigate("/"+lc);
			} else navigate("/login");
	}
	};// getProfile Method
    getProfile();
  }, [settings]);

  useEffect(() => {
    if (!selectedFile) {
      if (backgroundImage) {
        setBackgroundImage(false);
      } else {
        setPreview("");
      }
      return;
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      if (backgroundImage) {
        setBackgroundImage(false);
      } else {
        setPreview(objectUrl);
      }
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  useEffect(() => {
	fetchMediaData(); 
    fetchNftData();
	checkSubscription();
	
  }, [userData.id]);

  useEffect(() => {
    const getHbarPrice = async () => {
      const { data } = await axios(
        `https://min-api.cryptocompare.com/data/price?fsym=HBAR&tsyms=usd&api_key=8fc3e1cafe0aefdfb9819310e48db8e7ae472dbdfe734816e2b4bd1ae2f55ac8`
      );
      setHbarPrice(parseFloat(data["USD"]));
    };
    getHbarPrice();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelectFile = async (e: any) => {
	
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return undefined;
    }
    if (backgroundImage) {
	  setBackgroundImage(false);
	  let imageType=e.target.files?.[0]?.type;
	  if(!imageType) return;
	  if(imageType && imageType.indexOf("image/")===-1){
		showToast("Invalid Cover Image");
		return;
	  }
	  if(e.target.files[0].size > 1024*1024*10) {
		showToast("Image too big (max 10Mb)","error");
		return;
      }
	  let img = new Image();
	  img.src = window.URL.createObjectURL(e.target.files?.[0]);
	  img.onload = async()=> {
		let width:number  = img.naturalWidth  || img.width;
		let height:number = img.naturalHeight || img.height; 
		if(width>=1800){
			await uploadCoverImage(e.target.files?.[0]);
			setSelectedFile(undefined);
		} else showToast("Cover image width must be atleast 1800px","error");
	  };
	  
    } // Cover Image
	else {
      setCrop({
        height: 120,
        unit: "px",
        width: 120,
        x: 0,
        y: 0,
      }); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setCropImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      setSelectedFile(e.target.files?.[0]);
	  setShowCropping(true);
	  console.log("iiiiiiiiiiiiiii SELECTED FILE=====");
	  console.log(e.target.files?.[0]);
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useEffect(() => {
    const t = setTimeout(async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    }, 100);

    return () => {
      clearTimeout(t);
    };
  }, [completedCrop, scale, rotate]);

  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 120,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const getSocialToken = async (userId: string) => {
    const baseURL = `${API_ENDPOINT_URL}socialToken`;

    // try {
    const response = await axios.post(baseURL + `/${userId}`, {
      user_id: userId,
    });

    if (response.status === 200) {
      const response1 = await axios.post(baseURL + "/getPairAddress", {
        user_id: userId,
      });

      console.log("socialToken", response1);
      if (response1.status === 200) {
        setUserData((prev) => {
          return {
            ...prev,
            socialToken: {
              name: response?.data?.data?.name,
              tokenId: response1?.data?.data?.tokenA,
              coinTokenId: response1?.data?.data?.tokenB,
              tokenAmount: Number.parseFloat(
                response1?.data?.data?.tokenAAmount
              ),
              coinTokenAmount: Number.parseFloat(
                response1?.data?.data?.tokenBAmount
              ),
              pairAddress: response1?.data?.data?.pairAddress,
            },
          };
        });
      }
    }
  };
  const uploadCoverImage = async (fileName: File) => {
    let coverImageURL:string="";
    try {
      const formData = new FormData();
		formData.append("file", fileName || "");
		let options={
		  headers:{
		  "Content-Type":"multipart/form-data;boundary=--------93278492837938247-------",
		 },
		 params: {
            type: "cover",
            userId: userData.id,
          }};	
		const response = await axios.post(`${API_ENDPOINT_URL}account/uploadcp`,formData,options);
      if (response.status === 200) {
        coverImageURL = response?.data?.data?.filename;
      }
	 
      setBackgroundImage(false);
    } catch (e) {
	  let _exp:any=e;
      setBackgroundImage(false);
	  if((_exp?.response?.status)===400)
		showToast(_exp?.response?.data?.error,"error");
	  return;
    }

    try {
      const imgUpdate = await axios.post(
        `${API_ENDPOINT_URL}` + "account/editImage",
        {
          user_id: userData.id,
          coverImage: coverImageURL,
        }
      );

      if (imgUpdate.status === 200) {
		let cpImageString:any=await getImageFromURL(IMAGE_ENDPONT_URL+"image/cp/"+coverImageURL);  
        setUserData((prev) => {
          return {
            ...prev,
            profile: {
              ...prev.profile,
              coverImg: cpImageString,
            },
          };
        });
      }
      showToast("Success saving the cover image.", "success");
      setBackgroundImage(false);
    } catch (e) {
      setBackgroundImage(false);
      showToast(
        "Error while saving the Cover Image. Please try again.",
        "error"
      );
    }
  };

  const fetchMediaData = async () => {
	if(userData.id==="") return;
    const baseUrl = `${API_ENDPOINT_URL}` + "media/getMedia";
    try {
      const response = await axios.post(baseUrl, {
        user_id:userData.id,	
		uid:settings.userId?settings.userId:""
      });
      setMediaData(response?.data?.data);
    } catch (e) {
      console.log("Error", e);
    }
  };

  const fetchNftData = async () => {
	if(userData.id==="") return;  
	  //alert("I am baba in fttlist="+settings.userId);
    const baseUrl = `${API_ENDPOINT_URL}` + "nft/getNftList";
    try {
      const response = await axios.post(baseUrl, {
        user_id: userData.id,	// commented by Jilani
        //user_id: __id,	// Added by Jilani 
      });

      setNftData(response?.data?.data);
    } catch (e) {
      console.log("Error ", e);
    }
  };
  // upload DP Image 
  const updateDP = async (file_:any=null) => {
    setLoading(true);
    let profileImageURL:string="";
    try 
	{
		const formData = new FormData();
		formData.append("file", file_===null?selectedFile:file_);
		let options={
		  headers:{
		  "Content-Type":"multipart/form-data;boundary=--------93278492837938247-------",
		 },
		 params: {
            type: "profile",
            userId: userData.id,
          }};	
		const response = await axios.post(`${API_ENDPOINT_URL}account/uploaddp`,formData,options);
		if (response.status === 200) {
			profileImageURL = response.data.data.filename;
			showToast("profile image updated.","success");
			const imgUpdate = await axios.post(`${API_ENDPOINT_URL}account/editImage`, {
			user_id: userData.id,
			profileImage: profileImageURL,
			});
			if (imgUpdate.status === 200) {
				let dpImageString:any=await getImageFromURL(IMAGE_ENDPONT_URL+"image/dp/"+profileImageURL);
				setUserData((prev) => {
				  return {
					...prev,
					profile: {
					  ...prev.profile,
					  img: dpImageString,
					},
				  };
				});
			}
		
		}
    } catch (e) {
      showToast("Something went wrong [UIDP-4002].","error");
    }
	setLoading(false);
  };

  
  // END DP Image
  const handleSaveProfile = async () => {
	
    setLoading(true);
	try {
      const baseUrl = `${API_ENDPOINT_URL}`;
	  const otherItemsLink:any=await otherLinks.map((item) => item.content);
      const response = await axios.post(baseUrl + "account/editIntro", {
        user_id: userData.id,
        introduction: introduction,
        website: website,
        twitter: twitter,
        location: location,
        links: otherItemsLink,
      });

      if (response.status === 200) {
        setUserData((prev) => {
          return {
            ...prev,
            profile: {
              ...prev.profile,
              introduction: response?.data?.data?.introduction,
              website: response?.data?.data?.website,
              twitter: response?.data?.data?.twitter,
              location: response?.data?.data?.location,
              otherLinks: response?.data?.data?.links?.length
                ? response.data.data.links.map((item: string) => item)
                : [],
            },
          };
        });
      }

      
      setDispName("");
      setIntroduction("");
      setWebsite("");
      setTwitter("");
      setOtherLinks([]);
      setLocation("");
      setPreview("");
      setSelectedFile(undefined);
      setLoading(false);
      
    } catch (e) {
      console.log("error", e);
      setDispName("");
      setIntroduction("");
      setWebsite("");
      setTwitter("");
      setOtherLinks([]);
      setLocation("");
      setPreview("");
      setSelectedFile(undefined);
      setLoading(false);
      showToast("Something went wrong while updating the Profile", "error");
    }
	setShowEditProfileModal(false);
  };

  const handleClickImageBtn = async(indexTab: number) => {
    if (indexTab === 0) {
			const response = await axios.post(API_ENDPOINT_URL + `subscription/getAll`, {
			  ci:settings?.userId
			});
			if(response.status===200){
				if(response.data.subscriptions){
					if(response.data.subscribed.userId && response.data.subscribed.userId===settings.userId){
						setIsNFTCreateModelFlag(true);
						
						
					} else showToast("To mint the nft please activate the subsrciption","success");
				} 
			}
			
      
    } else if (indexTab === 1) {
      setModalProcess({
        ...modalProcess,
        isPostingPhoto: true,
      });
    } else if (indexTab === 2) {
      setModalProcess({
        ...modalProcess,
        isPostingAudio: true,
      });
    } else if (indexTab === 3) {
      setModalProcess({
        ...modalProcess,
        isPostingVideo: true,
      });
    } else {
      setModalProcess({
        ...modalProcess,
        isPostingArticle: true,
      });
    }
    setFilter("");
  };
  
  const [userSubscription,setUserSubscription]=useState<any>(null);
  const checkSubscription=async()=>{
  try{
	if(settings && settings.userId && settings.userId.length>0){ 
		let loginUser:string=settings.userId;
		const response = await axios.post(API_ENDPOINT_URL + `subscription/check`, {
		  ci:loginUser
		});
		if(response.status===200){
			if(response.data.status===1){
				
				setUserSubscription(response.data);
			} 
		} 
	}}catch(_e){
	}
  }
  
  return (
    <>
      <div
        className={`flex h-full w-full flex-col bg-white ${
          showEditProfileModal ||
          showFromWalletModal ||
          modalProcess.finalizaingNftCreation ||
          modalProcess.isCreatingNFT ||
          modalProcess.isPostingVideo ||
          modalProcess.isPostingAudio ||
          modalProcess.isPostingArticle ||
          modalProcess.isPostingPhoto ||
          modalProcess.isCreatingSocialToken ||
          postingModal
            ? "blur-lg"
            : "blur-0"
        }`}
      >
        <PageContainer>
          <TopMenu
            onClick={() => setPostingModal(true)}
            isCreator={isCreator}
			pageDomain={pageDomainName}
          />
          <div className="w-full m-auto">
            <div
			  id="__cover"
              className={`${
                userData?.profile?.coverImg
                  ? "w-[100%] h-[320px] bg-cover relative top-[-72px] md:px-[54px] px-1"
                  : " bg-blue-100 w-[100%] h-[320px] relative top-[-72px] md:px-[54px] px-1"
              } `}
              style={
                userData?.profile?.coverImg &&
                userData?.profile?.coverImg.length === 46
                  ? {
                      backgroundImage: `url(https://ipfs.io/ipfs/${userData?.profile?.coverImg})`,
                    }
                  : { backgroundImage: `url(${userData?.profile?.coverImg})`,
					  backgroundPosition:`${"0px "+coverPosition+"px"}`,
					  backgroundAttachment: 'fixed'
					}
              }
            >
			  <input type='button' id="mud" className="mupdw" value=""/>	
              {isCreator && (
			  <>
				
                <div className="absolute right-0 flex flex-row-reverse items-center px-auto bottom-4">
                  <div className="md:mr-[70px] mr-[20px]">
                    <Web23Button
                      text="Edit Cover Photo"
                      variant="secondary"
                      className="px-6"
                      onClick={() => {
                        setBackgroundImage(true);
                        document.getElementById("upload-background")?.click();
                      }}
                    />
					
                  </div>
                  <div className="px-4">
                    <Web23Button
                      text="Edit Profile"
                      variant="secondary"
                      className="px-6"
                      onClick={() => {
                        setDispName(userData.displayName);
                        setIntroduction(userData.profile.introduction);
                        setWebsite(userData.profile.website);
                        setTwitter(userData.profile.twitter);
                        setOtherLinks([]);
                        setLocation(userData.profile.location);
                        setPreview("");
                        setSelectedFile(undefined);
                        setShowEditProfileModal(true);
                      }}
                    />
                    <input
                      id="upload-background"
                      name="upload-background"
                      type="file"
                      className="hidden"
                      onChange={onSelectFile}
                    />
                  </div>
                </div>
			   </>
              )}
            </div>
			<div className='relative flex w-full top-[-72px]' style={{backgroundColor:'#f0f2f5'}}>
            <div className="container mx-auto grid grid-cols-12 gap-4 px-4 ">
			{/* Left Cols */}
            <div className="col-span-4">
				
                <Introduction
                  isCreator={isCreator}
                  introduction={userData?.profile?.introduction || ""} //"It's a simple lift and shift job. Throughput criticality, pre-think our game-plan. So if you could do that, that would be great for critical mass."
                  location={userData?.profile?.location || ""}
                  website={userData?.profile?.website || ""}
                  links={[
                    ...(userData?.profile?.otherLinks || ""),
                    userData?.profile?.twitter || "",
                  ]}
                  domainName={userData?.domainName}
                  imgSrc={userData?.profile?.img || ""}
                  walletAddress={userData?.walletAddress}
                  onClickInsertProfile={() => setShowEditProfileModal(true)}
                  onClickCoppyAddress={() => {
                    showToast("Copied wallet address");
                    navigator.clipboard.writeText(userData?.walletAddress);
                  }}
				  subscription={userSubscription}
				  
                />
				<div className="relative bg-white top-[-90px] mt-8 flex flex-col items-center justify-center rounded-xl border-1 p-4 shadow">
                    <h4 className="text-[24px] font-bold text-grey-900 mb-3">
                      Web23 Fungible Token.
                    </h4>
                    <div className="w-[100%] relative p-4 mb-6 overflow-hidden bg-[#f7f7f7] border  rounded-xl">
                      <div className="flex flex-col justify-between mb-2">
                        <div>
                          <img src={"https://images.squarespace-cdn.com/content/v1/62fe8b1b52012f6be63d3de2/c52c59d8-2bb6-4349-982b-99977ad22846/Web23_Logo-Horizontal_Green_sml.png?format=1500w"} alt="Web23" style={{height:"48px"}} />
                        </div>
						<p className="text-sm font-bold text-right text-black">
							{web23TokenBalance==="0" &&<>No Balance</>}
							{web23TokenBalance!=="0" &&<><span className='text-light opacity-70'>Balance</span>: <span className='text-[18px]'>{web23TokenBalance}</span></>}
                        </p>
						<div className='mt-4'>
							<input type='text' id="__web23TokenInput" placeholder='Enter Number of Tokens' className='px-4 py-2 w-full rounded-3xl border-2' style={{'border':"#dedede!important"}}/>
							<button
								className='mt-4 mb-4 p-2 w-full rounded-3xl border-2'
								onClick={async() => {
									let _tno=(window as any).document.getElementById("__web23TokenInput").value;
									if(_tno.trim()===""){
										return;
									}
									try{
										const accountId = pairingData?.accountIds[0] ?? "";
										const { signer } = getProviderAndSigner(
										settings.network,
										accountId,
										pairingData?.topic ?? ""
										);
										if (!signer) {
										alert("reconnect the Hashpack wallet");
										await connectToExtension();
										}
										else{
											let txnData=await transferHBARTo(signer,_tno*10,accountId,"0.0.6730");
											await transferTokenTo(_tno,"0.0.6730",accountId);
											getWeb23TokenBalance();
										}
									}catch(_e){
										showToast("Something went wrong","error");
									}
									
								  }}
								style={{backgroundColor:'#6acb71',color:'#ffffff'}}  
							>Buy</button>
							<p className="text-xs font-bold text-black opacity-50">
                            Per Token: {"10 HBAR"}
                            
                          </p>
						</div>
                      </div>
					</div>
				</div>
                {userData?.socialToken?.name?.length > 0 ? (
                  <div className="flex flex-col bg-white items-center p-4 border-grey-300 border-1 rounded-md shadow">
                    <h3 className="text-[24px] font-bold text-grey-900 mb-3">
                      {userData?.socialToken?.name} Token
                    </h3>
                    <div className="w-[100%] relative p-4 mb-6 overflow-hidden bg-[#100F61] border border-indigo-300 rounded-xl">
                      <div className="flex items-end justify-between mb-2">
                        <p className="text-sm font-bold text-white opacity-70">
                          {userData?.socialToken?.name}
                        </p>
                        <div>
                          <img src={PerksImg} alt="perks" />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-black text-white md:text-[32px] lg:text-[48px] text-[36px]">
                            {userData?.socialToken?.tokenAmount}
                          </p>
                          <p className="text-base font-bold text-white md:mb-4 lg:mb-8">
                            {userData?.socialToken?.name}
                          </p>
                          <p className="text-xs font-bold text-white opacity-50">
                            {"Per token: $"}
                            {(
                              (parseFloat(
                                userData?.socialToken?.coinTokenAmount
                              ) /
                                parseFloat(
                                  userData?.socialToken?.tokenAmount
                                )) *
                              hbarPrcie
                            ).toFixed(6)}
                          </p>
                        </div>
                        <p className="text-xs font-bold text-white opacity-50 [writing-mode:vertical-lr]">
                          {userData?.socialToken?.name}
                        </p>
                      </div>
                      <p className="absolute bottom-[-50px] left-[-36px] font-black text-white opacity-10 text-[120px]">
                        {userData?.socialToken?.name}
                      </p>
                    </div>
                    {isCreator ? (
                      <div className="flex items-center gap-3 w-[100%]">
                        <div className="w-full">
                          <Web23Button
                            icon={<LoupeSVG />}
                            text="Top-Up"
                            className="w-full"
                          />
                        </div>
                        <div className="w-full">
                          <Web23Button
                            text="Gift"
                            variant="secondary"
                            icon={<CardGiftSVG />}
                            className="w-full"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="w-1/2">
                        <Web23Button text="Claim Now" />
                      </div>
                    )}
                  </div>
                ) : (
				<>
                  <div className="relative bg-white top-[-90px] mt-8 flex flex-col items-center justify-center rounded-xl border-1 p-4 shadow">
                    <h4 className="text-[24px] font-bold text-grey-900 mb-3">
                      Introducing Social Tokens
                    </h4>
                    <div className="w-[100%] relative p-4 mb-6 overflow-hidden bg-[#100F61] border border-indigo-300 rounded-xl">
                      <div className="flex items-end justify-between mb-2">
                        <p className="text-sm font-bold text-white opacity-70">
                          $BladeOfCode Balance
                        </p>
                        <div>
                          <img src={PerksImg} alt="perks" />
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="font-black text-white md:text-[32px] lg:text-[48px] text-[36px]">
                            2500
                          </p>
                          <p className="text-base font-bold text-white md:mb-4 lg:mb-8">
                            $BladeOfcode
                          </p>
                          <p className="text-xs font-bold text-white opacity-50">
                            {"Per token: $5"}
                          </p>
                        </div>
                        <p className="text-xs font-bold text-white opacity-50 [writing-mode:vertical-lr]">
                          $BladeOfCode
                        </p>
                      </div>
                      <p className="absolute bottom-[-50px] left-[-36px] font-black text-white opacity-10 text-[120px]">
                        $BladeOfCode
                      </p>
                    </div>
                    <p className="text-[14px] font-bold text-grey-700 text-center px-5">
                      Setup your token and build your own economy in Web23. Once
                      launched, they can be used by your fans to unlock your
                      content & experiences.
                    </p>
					
                    {isCreator && (
                      <div className="flex flex-col bg-white items-center w-full gap-3 p-4 mt-3 bg-green-100 rounded-md shadow">
                        <p className="mt-3 text-base font-normal text-grey-900">
                          <span className="text-base font-bold text-black">
                            ${userData.displayName}
                          </span>{" "}
                          available
                        </p>
                        <Web23Button
                          className="items-center px-6 mt-3"
                          variant="fixed"
                          text="Get Started"
                          border="border"
                          onClick={() => {
                            setModalProcess({
                              ...modalProcess,
                              isCreatingSocialToken: true,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
				  
				</>  
                )}
					
            </div>
			
			{/* End Left Cols */}
			{/* Mid Cols */}
            <div className="col-span-5 ">
                <div className="w-full mx-auto mt-6">         
                  <div className="flex flex-col items-start p-3 rounded-md bg-white shadow">
                    <div className="flex items-center pb-3 gap-2 mb-3 border-b-[1px] border-b-grey-100 w-[100%]">
                      <div>
                        {userData?.profile?.img?.length ? (
                          userData?.profile?.img?.length === 46 ? (
                            <div
                              className="h-[32px] w-[32px] rounded-full bg-cover"
                              style={{
                                backgroundImage: `url(https://ipfs.io/ipfs/${userData?.profile?.img})`,
                              }}
                            ></div>
                          ) : (
                            <div
                              className="!bg-transparent h-[32px] w-[32px] rounded-full bg-cover"
                              style={{
                                backgroundImage: `url(${userData?.profile?.img})`,
                              }}
                            ></div>
                          )
                        ) : (
                          <div
                            className="!bg-transparent h-[32px] w-[32px] rounded-full bg-cover"
                            style={{ backgroundImage: `url(${introAvatar})` }}
                          ></div>
                        )}
                      </div>
                      <p className="text-base font-bold text-grey-600">
                        What’s on your mind?
                      </p>
                    </div>

                    <div className="flex items-center justify-stretch w-full grid-cols-5 rounded-xl bg-white">
                      {imgButtonDetail.map((item, id) => {
                        return (
                          <Web23ImgButton
						    key={item.text + id}
                            text={item.text}
                            img={item.img}
                            backColor={"bg-gray"}
                            hoverBgColor={item.hoverBgColor}
                            onClick={() => {
                              if (isCreator) handleClickImageBtn(id);
                              else {
                                setFilter(filterItem[id]);
                              }
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="mt-8 border-b-2 border-grey-300">
                    <div className="flex items-center">
                      <span
                        className={`${
                          timelineTab === "timeline"
                            ? "border-b-green-900 text-green-900"
                            : "border-b-0 text-grey-600"
                        } 
                    cursor-pointer border-b-2 hover: border-b-green-900 py-4 px-8 text-[20px] font-bold text-grey-600 hover:text-green-900`}
                        onClick={() => setTimelineTab("timeline")}
                      >
                        Time Line
                      </span>
                      <div
                        className={`${
                          timelineTab === "market"
                            ? "border-b-green-900 text-green-900"
                            : "border-b-0 text-grey-600"
                        } 
                    cursor-pointer border-b-2 hover: border-b-green-900 py-4 px-8 text-[20px] font-bold text-grey-600 hover:text-green-900 flex items-center gap-2`}
                        onClick={() => setTimelineTab("market")}
                      >
                        <span>Market</span>
                        <StoreSVG />
                      </div>
                    </div>
                  </div>
                  {timelineTab === "timeline" ? (
                    <div className="flex flex-col items-center">
                      {[...mediaData, ...nftData].length > 0 ? (
                        [...mediaData, ...nftData]
                          .filter((item:any) => !filter || item?.category === filter)
                          .sort((a, b) => {
                            if (a?.created === undefined) return -1;
                            if (b?.created === undefined) return -1;
                            if (a?.created > b?.created) return -1;
                            return 1;
                          })
                          .map((item: any, id) => {
                            return (
                              <div key={id} className="w-full">
                                <Web23TimeLineItem
                                  category={item?.category ?? "NFT"}
                                  content={item?.content ?? ""}
                                  detail={item?.detail ?? ""}
                                  isCreator={isCreator}
                                  copyright={item?.copyright ?? ""}
                                  nftImage={item?.nftImage ?? ""}
                                  price={item?.price}
                                  nftName={item?.nftName}
                                  nftDescription={item?.nftDescription}
                                  symbol={item?.nftName}
                                  likes="100"
                                  tokenId={item?.tokenId}
                                  serialNumber={item?.serialNumber}
                                  commentNumber={144}
								  id={item?._id}
								  domain={userData.domainName}
								  walletName={item?.chainName}
								  postStatus={item?.status}
                                />
                              </div>
                            );
                          })
                      ) : isCreator ? (
                        <Web23Card
                          img={
                            <img src={SampleNft} alt="sample nft"/>
                          }
                          title="Let's populate this space, shall we?"
                          description="Post an update or share your thoughts"
                          button={
                            <Web23Button text={`Post`} variant="primary"
							 onClick={()=>{
								document.getElementById("___mtpost")?.click(); 
							 }}
							/>
                          }
                        />
                      ) : (
                        <p className="mt-8 text-base font-medium text-grey-400">
                          Nothing to show
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
                      {nftData
                        ?.filter(
                          (item) =>
                            item?.price?.priceType?.toLowerCase() === "fixed" ||
                            item?.price?.priceType?.toLowerCase() === "auction"
                        )
                        ?.sort((a: any, b: any) => {
                          if (a?.created === undefined) return -1;
                          if (b?.created === undefined) return -1;
                          if (a?.created > b?.created) return -1;
                          return 1;
                        })
                        .map((item, id) => {
                          return (
                            <div key={id} className="col-span-1">
                              <Web23MarketItem
                                nftData={item}
                                content={item?.content ?? ""}
                                detail={item?.details ?? ""}
                                isCreator={isCreator}
                                copyright={item?.copyright ?? ""}
                                nftImage={item?.nftImage ?? ""}
                                price={item?.price?.price}
                                nftName={item?.nftName}
                                symbol={item?.nftName}
                                likes="100"
                                tokenId={item?.tokenId}
                                serialNumber={item?.serialNumber}
                                commentNumber={144}
                              />
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
            </div>
			
			{/* End Mid Cols */}
			{/* End Cols */}
			<div className="col-span-3">
				<div className="w-full mx-auto mt-6"> 
				{/*	<>
					<div className='text-3xl'>{settings.walletAddress}</div>
					<div className='text-3xl'>{settings.displayName}</div>
					<div className='text-3xl'>{settings.userId}</div>
					
					<div className='text-3xl'>{userData.displayName}</div>
					<div className='text-3xl'>{userData.walletAddress}</div>
					<div className='text-3xl'>{userData.domainName}</div>
					<div className='text-3xl'>{userData.id}</div>
					</>
					*/
				}
				{settings.userId!=userData.id &&
					<>
					<button id="btnFlw900" className={'bg-blue-400 btn border w-[180px]  py-2 px-4 text-white text-lg mb-6'}
					onClick={async(e)=>{
						let userId:string=settings.userId;
						let toUserId:string=userData.id;
						if(follwingText==="Following"){
								const response = await axios.post(API_ENDPOINT_URL + "flw/remove", {
												fu:userId,
												tu:toUserId
											  });
								if(response.status===200){
									setFollwingText("Follow");
								}	
						}
						else{
							
							const response = await axios.post(API_ENDPOINT_URL + "flw/add", {
												fu:userId,
												tu:toUserId
											  });
								if(response.status===200){
									setFollwingText("Following");
								}	
						}								
					}}
					>{follwingText}</button>
					<button id="btnFlw901" className={' btn border   py-2 px-4 text-black text-lg mb-6'}
					onClick={async(e)=>{
												
					}}
					>Followers +{follwerCount}</button>
					
					</>
				}
				{settings.userId===userData.id &&
					<>
					<button id="btnFlw900" className={' btn border  py-2 px-4 text-black text-lg mb-6'}>Following +{follwingCount}</button>
					<button id="btnFlw901" className={' btn border  py-2 px-4 text-black text-lg mb-6'}>Followers +{follwerCount}</button>
					</>
				}
					<h6 className='text-base mt-4 font-bold text-grey-600'>Recent Nfts</h6>
					<div className="flex flex-row mt-2">
						
						<div className="relative w-12 h-12 " >
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>
						
						<div className="relative w-12 h-12 " style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={NFTPhoto} />
						</div>
						<div className="self-center px-2" >
							<i className="bi bi-three-dots" ></i>
						</div>
					</div>
				</div>
				<div className="w-full mx-auto mt-6"> 
					<h6 className='text-base font-bold text-grey-600'>Recent Photos</h6>
					<div className="flex mt-2">
						
						<div className="relative w-12 h-12 " >
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={EmptyPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full w-12 h-12 border-2 border-yellow-50 shadow" src={EmptyPhoto} />
						</div>

						<div className="relative w-12 h-12" style={{marginLeft:"-10px"}}>
						<img className="rounded-full border-2 w-12 h-12 border-yellow-50 shadow" src={EmptyPhoto}  />
						</div>
						
						<div className="self-center px-2" >
							<i className="bi bi-three-dots" ></i>
						</div>
					</div>
				</div>
				<hr className='mt-6'/>
				
			</div>
			</div>
			{/*End of End Cols */}
            </div>
			
          </div>
        </PageContainer>
      </div>
      {postingModal && (
        <Web23Modal>
          <div className="max-w-[640px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between">
              <h5 className="text-[24px] font-bold text-grey-600">
                Hi, {settings.displayName}{" "}
              </h5>
              <CloseIcon
                className="rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setPostingModal(false)}
              />
            </div>
            <div className="mt-2">
              <h3 className="text-[32px] font-bold text-grey-900">
                Choose any option to post
              </h3>
            </div>
            <div>
              <div className="mt-6">
                <div className="grid items-center justify-around grid-cols-2 gap-2 p-4 md:gap-3 lg:gap-4 gap- md:grid-cols-3 lg:flex rounded-xl bg-grey-50">
                  <Web23ImgButton
                    text="NFT"
                    img={<NftSVG />}
                    backColor="bg-green-200"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({ ...modalProcess, isCreatingNFT: true });
                      // setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Photo"
                    img={<PhotoSVG />}
                    backColor="bg-red-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingPhoto: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Audio"
                    img={<AudioSVG />}
                    backColor="bg-purple-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingAudio: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Video"
                    img={<VideoSVG />}
                    backColor="bg-indigo-50"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingVideo: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                  <Web23ImgButton
                    text="Article"
                    img={<ArticleSVG />}
                    backColor="bg-grey-100"
                    hoverBgColor="bg-grey-300"
                    onClick={() => {
                      setModalProcess({
                        ...modalProcess,
                        isPostingArticle: true,
                      });
                      setPostingModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Web23Modal>
      )}
      {showEditProfileModal && (
        <Web23Modal>
          <div className="relative w-[480px] rounded-md bg-white p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-3xl font-bold text-grey-900">Edit Profile</h3>
              <div
                className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => {
                  setDispName(settings?.displayName);
                  setIntroduction(settings?.profile?.introduction);
                  setWebsite(settings?.profile?.website);
                  setTwitter(settings?.profile?.twitter);
                  setOtherLinks([]);
                  setLocation(settings?.profile?.location);
                  setPreview("");
                  setSelectedFile(undefined);
                  setShowEditProfileModal(false);
                  setShowCropping(false);
                }}
              >
				<CloseIcon />
              </div>
            </div>
            <div className="flex items-end mb-6">
              <div className="relative">
                {preview ? (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${preview})` }}
                  ></div>
                ) : userData?.profile?.img?.length > 0 ? (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${userData?.profile?.img})` }}
                  >
				  </div>
                ) : (
                  <div
                    className="h-[120px] w-[120px] rounded-full bg-cover"
                    style={{ backgroundImage: `url(${introAvatar})` }}
                  ></div>
                )}
                <div
                  className="absolute bottom-[6px] right-[8px] h-[24px] w-[24px] cursor-pointer rounded-full bg-grey-50 hover:bg-grey-100"
                  onClick={() => {
                    if (cropImgSrc && cropImgSrc!="")
                      setShowCropping(true);
                    else
                      showToast(
                        "Please choose profile image to crop",
                        "error"
                      );
                  }}
                >
                  <div className="absolute left-[50%] top-[0%] pt-[8px]">
                    <ModeIcon />
                  </div>
                </div>
              </div>

              <div className="ml-[17px] flex cursor-pointer flex-col items-start">
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    document.getElementById("upload-photo")?.click()
                  }
                >
                  <span className="mb-3 text-base font-bold underline cursor-pointer text-grey-900">
                    Upload Photo
                  </span>
                </div>
                <input
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  className="hidden"
                  onChange={onSelectFile}
                />

                <div className="relative cursor-pointer">
                  <span
                    id="from-wallet"
                    className="text-base font-bold underline cursor-pointer text-grey-900"
                  >
                    Upload from Wallet
                  </span>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Display Name"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={dispName}
                onChange={(e) => setDispName(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Short Bio"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={introduction}
                onChange={(e) => setIntroduction(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Location"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="WebSite"
                className="w-full hover:bg-grey-100 focus:bg-grey-100"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <Web23Input
                placeholder="Twitter"
                className="w-full"
                validate={true}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              >
                <div className="rounded-full p-[6px] duration-150 hover:bg-grey-300">
                  <TwitterIcon />
                </div>
              </Web23Input>
            </div>

            <div className="flex items-center mb-6">
              <div className="mr-4 text-lg font-bold text-grey-900">
                Other Links
              </div>
              <div>
                <Web23Button
                  text="Add"
                  variant="secondary"
                  className="px-6 hover:bg-grey-300"
                  onClick={() =>
                    setOtherLinks((past) => [
                      ...past,
                      {
                        placeholder: `Link ${past.length + 1}`,
                        content: "",
                      },
                    ])
                  }
                />
              </div>
            </div>
            {otherLinks.map((item) => {
              return (
                <div key={item.content + item.placeholder} className="mb-4">
                  <Web23Input
                    placeholder={item.placeholder}
                    className="w-full"
                    validate={true}
					defaultValue={item.content}
                    onBlur={async(e) => {
						let tmp:any=otherLinks;
						let tmpUpdated:any=await tmp.map((data:any)=>{
							if(data.placeholder===item.placeholder){
							return({placeholder: item.placeholder, content: e.target.value})	
							}
							return(data)	
						});
						await setOtherLinks(tmpUpdated);
					    console.log(tmpUpdated);
                    }}
                  >
                    <div className="p-1 rounded-full">
                      <DeleteIcon
                        className="duration-150 cursor-pointer"
                        // value={item.placeholder}
                        onClick={() =>
                          setOtherLinks((prev) =>
                            prev.filter(
                              (currentItem) =>
                                currentItem.placeholder != item.placeholder
                            )
                          )
                        }
                      />
                    </div>
                  </Web23Input>
                </div>
              );
            })}

            <div className="flex items-center">
              <div className="ml-auto">
                <button
                  className="text-xl font-bold underline text-grey-900"
                  onClick={() => {
                    setDispName("");
                    setIntroduction("");
                    setWebsite("");
                    setTwitter("");
                    setOtherLinks([]);
                    setLocation("");
                    setPreview("");
                    setSelectedFile(undefined);
                    setShowEditProfileModal(false);
                  }}
                >
                  Cancel
                </button>
              </div>
              <div className="ml-9">
                <Web23Button
                  text="Save"
                  variant="primary"
                  className="p-6"
                  onClick={handleSaveProfile}
                />
              </div>
            </div>
          </div>
          {loading && (
            <div className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              <Web23Spinner />
            </div>
          )}
        </Web23Modal>
      )}
      
	  {
		  isNFTCreateModelFlag &&
		  <>
		    <Web23Modal>
          <div className="flex w-[480px]  flex-col rounded-md bg-white p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-3xl font-bold text-grey-900">Post NFT</h3>
              <div
                className="p-2 duration-150 rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() =>
                  setIsNFTCreateModelFlag(false)
                }
              >
                <CloseIcon />
              </div>
            </div>
            <div>
              <Web23Card
                className="m-auto"
                img={
                  <img className="rounded-[32px]" src={SampleNft} alt="nft" />
                }
                description="Do you know that more than 1 million NFTs are minted everyday?"
                button={
                  <Web23Button
                    text="Create an NFT"
                    variant="primary"
                    className="w-full"
                    onClick={() => {
						setIsNFTCreateModelFlag(false);
						setModalProcess({
							...modalProcess,
							isCreatingNFT: true,
						});
                    }}
                  />
                }
                bottomButton={
                  <Web23Button
                    text="Add from Wallet."
                    variant="secondary"
                    onClick={() => {
					  
					  setIsNFTCreateModelFlag(false);	
					  
                      setSelectWalletsModel(Math.random()*1000);
                    }}
                  />
                }
              />
            </div>
          </div>
        </Web23Modal>
		  </>
	  }
	  
      {modalProcess.isCreatingNFT && (
        <Web23Modal>
          <CreatingNftModal effectFunc={fetchNftData} />
        </Web23Modal>
      )}
      {modalProcess.finalizaingNftCreation && (
        <>
          {!modalProcess.finishedNftCreation ? (
            <Web23Modal>
              <div className="flex max-w-[528px] flex-col items-start rounded-lg bg-white p-6">
                <h3 className="mb-6 text-[32px] font-bold text-grey-900">
                  Create NFT
                </h3>
                <div className="flex flex-col items-center w-full">
                  <Web23Spinner />
                  <p className="mt-6 text-base font-bold text-grey-600">
                    Complete the process in your wallet
                  </p>
                </div>
              </div>
            </Web23Modal>
          ) : (
            <Web23Modal>
              <div
                className="p-6 bg-white rounded-lg cursor-pointer"
                onClick={() => {
                  setModalProcess({
                    ...modalProcess,
                    finalizaingNftCreation: false,
                  });
                }}
              >
                <h3 className="mb-6 text-[32px] font-bold text-grey-900">
                  NFT Creation Successful
                </h3>
                <div className="flex flex-col items-center">
                  <CheckBigSVG />
                  <p className="mt-6 text-base font-bold text-grey-600">
                    NFT will be posted on to your timeline as soon as it’s
                    minted.
                  </p>
                </div>
              </div>
            </Web23Modal>
          )}
        </>
      )}
      {modalProcess.isPostingPhoto && (
        <Web23Modal>
          <Web23PostMedia variant="photo" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isPostingAudio && (
        <Web23Modal>
          <Web23PostMedia variant="audio" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isPostingVideo && (
        <Web23Modal>
          <Web23PostMedia variant="video" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isPostingArticle && (
        <Web23Modal>
          <Web23PostMedia variant="article" effectFunc={fetchMediaData} />
        </Web23Modal>
      )}
      {modalProcess.isCreatingSocialToken && <CreateToken />}

      <div
        className={`${
          showCropping ? "block" : "hidden"
        } duration-300 fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[640px] p-6 bg-white shadow-lg`}
      >
        <div className="flex justify-between mb-2">
          <h3 className="font-bold text-[32px] leading-10 text-grey-900">
            Edit your profile image
          </h3>
          <div onClick={() => {setShowCropping(false)}}>
            <CloseIcon />
          </div>
        </div>
        <div className="flex items-center w-full">
          {!!completedCrop && (
            <div className="flex justify-center w-full">
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: "120px",
                    height: "120px",
                    borderRadius: "100%",
                  }}
                />
              </div>
            </div>
          )}
          <div className="w-full">
		  <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={true}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={cropImgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
		  </ReactCrop>
		  
          </div>
        </div>
        <div className="flex justify-center gap-4 mt-8">
          <Web23Button
            size="sm"
            variant="secondary"
            text="Cancel"
            onClick={() =>{setShowCropping(false)}}
          />
          <Web23Button
            size="sm"
            text="Confirm"
            onClick={() => {
              previewCanvasRef?.current?.toBlob(async (blob:any) => {
                if (blob===undefined || blob===null) {
                 showToast("Profile Image Exception");
				  return;
                }
                else{ 
					const file = new File([blob as any],"profile_img_" +userData.displayName+
					"_" +new Date().valueOf().toString() +".jpg",
					{ lastModified: (new Date()).getTime(),
					  type:blob?.type
					}
                );
                await setSelectedFile(file);
				updateDP(file);
                setShowCropping(false);
              } // else 
			  }
			  );
            }}
          />
        </div>
      </div>
	  
		<WalletListCard closeStatus={selectWalletsModel}/>
      {ToasterBox}
    </>
  );
};

async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export default OnboardingPage;
