import axios from "axios";
// import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Web23Button, Web23Input, Web23Modal, Web23Spinner } from "../../components";

import { ReactComponent as ArrowSVG } from "../../assets/icon/keyboard_arrow_left.svg";
import { ReactComponent as LanguageSVG } from "../../assets/icon/language.svg";
import { ReactComponent as ShoppingSVG } from "../../assets/icon/shopping_cart.svg";
import { ReactComponent as Web23BadgeSVG } from "../../assets/icon/Web23-Badge.svg";
import { ReactComponent as SmartPageLogoSVG } from "../../assets/icon/smart-page-logo.svg";
import { ReactComponent as ArrowDownSVG } from "../../assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as CloseIconSVG } from "../../assets/icon/close.svg";
import { SettingContext, initialSettings } from "../../context/context";
import { useHashconnectService } from "../../context/Hashconnect";
import { API_ENDPOINT_URL } from "../../config";

const yourDomains = ["pecanpie.hbar", "rahul.hbar", "abrahan.hbar"];

const ConfigDomainPage: React.FC = () => {
  const { settings, saveSettings } = useContext(SettingContext);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>("");
  const [domains, setDomains] = useState<string[]>([]);
  const [showWeb2DomainModal, setShowWeb2DomainModal] = useState<boolean>(true);
  const [gKey, setGKey] = useState<string>("");
  const [gSecret, setGSecret] = useState<string>("");
  const [web2Domains, setWeb2Domains] = useState<string[]> ([]);

  const navigate = useNavigate();

  const { pairingData, connectToExtension, disconnect } =
    useHashconnectService();

  useEffect(() => { 
   alert(settings.walletName);
  if(settings!=null && settings.walletName!=undefined)
	if(settings.walletName==="Metamask")
		 fetchEthDomains();
	else fetchDomains();	
    
  }, []);
  
  const fetchDomains = async () => {
	  
    const baseURL = `${API_ENDPOINT_URL}` + "domain/getTld";
    console.log("=======", pairingData);
    try {
      const response = await axios.post(baseURL, {
        accountId: pairingData?.accountIds[0] || "0.0.3768831",
        network: "testnet",
      });
      
      console.log("domain", response);
      if (response.status === 200) {
        setDomains([...response?.data?.data]);
      }
    } catch (e) {
      console.log(e);
    }
	
  };
  const fetchEthDomains = async () => {
	  
    const baseURL = `${API_ENDPOINT_URL}` + "domain/getETHDomain";
    try {
      const response = await axios.post(baseURL, {
        accountId: settings.walletAddress,
        network: "testnet",
      });
      
      console.log("domain", response);
      if (response.status === 200) {
        setDomains([...response?.data?.data]);
      }
    } catch (e) {
      console.log(e);
    }
	
  };

  const fetchWeb2Domain = async () => {
    const baseURL = `${API_ENDPOINT_URL}` + 'domain/getWeb2Domain';
    setLoading(true);
    try {
      const response = await axios.post(baseURL, {
        params: {
          sso: `sso-key ${gKey}:${gSecret}`
        }
      })
      if (response.status === 200) {
        setWeb2Domains(response?.data?.data?.map((item:any) => item?.domain))
      }
      setLoading(false);
    } catch(e: any) {
      console.log(e.message);
      setLoading(false);
    }
  }

  return (
    <>
      <div
        className={`flex h-full w-full flex-col bg-white ${
          showConfirmModal ||
          showWeb2DomainModal
          ? "blur-sm" : "blur-0"

        }`}
      >
        <div className="flex items-center justify-between px-8 py-3">
          <div className="flex items-center gap-2 mb-2 ">
            <div className="flex items-center justify-between justify-items-center">
              <SmartPageLogoSVG className="mr-10" />
              <Web23BadgeSVG />
            </div>
          </div>
          <div className="flex items-center gap-3">
            <div className="flex items-center gap-4">
              <div className="flex items-center justify-center w-10 h-10 duration-150 rounded-full cursor-pointer bg-grey-100 hover:bg-grey-300">
                <ShoppingSVG />
              </div>
              <div
                className="p-1 pr-3 bg-grey-100 rounded-[32px] flex justify-between items-center active:bg-grey-400 hover:bg-grey-200 cursor-pointer"
                onClick={() => {
                  saveSettings({ ...initialSettings });
                  navigate("/login");
                }}
              >
                <div className="flex items-center gap-2">
                  <div className="w-8 h-8 uppercase rounded-full bg-[#FFCE0A] flex justify-center items-center font-bold text-base text-black">
                    {settings?.displayName?.charAt(0)}
                  </div>
                  <p className="text-base font-bold text-black">
                    {settings?.displayName}
                  </p>
                </div>
                <ArrowDownSVG />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between bg-green-900 px-[70px] py-3">
          <p className="text-xl font-bold text-grey-50">Choose Domain</p>
        </div>
        <div className="flex flex-col justify-center h-full px-4 mt-4 sm:px-0">
          <div>
            <h3 className="mb-3 text-center sm:text-[32px] text-xl font-bold text-grey-900">
              Smart domains. Tokenized on Hedera.
            </h3>
            <div className="flex justify-center mt-4">
              <div className="max-w-[640px]">
                <a
                  className="mb-6 text-base font-bold text-center underline cursor-pointer sm:text-xl text-grey-600"
                  href="https://app.web23.io"
                >
                  Booking new domains click here
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="relative w-[640px]">
              {loading && (
                <div className="absolute left-[50%] top-3">
                  <Web23Spinner />
                </div>
              )}
              <p className="mb-3 pt-[120px] text-xl font-bold text-grey-900">
                Your Web3 Domains
              </p>
              <div className="flex flex-col mb-8 sm:flex-row">
                <div className="flex items-center gap-1 p-2 border-b border-grey-900">
                  <p className="text-base font-bold text-green-700">
                    Your Domains
                  </p>
                  <p className="flex items-center justify-center w-6 h-6 text-sm font-bold text-green-700 bg-green-300 rounded-full">
                    {domains?.length || 0}
                  </p>
                </div>
                <div className="flex items-center gap-1 p-2 border-b border-grey-100">
                  <p className="text-base font-bold text-grey-700">
                    Domains from other providers
                  </p>
                  <p className="flex items-center justify-center w-6 h-6 text-sm font-bold rounded-full bg-grey-100 text-grey-700">
                    0
                  </p>
                </div>
              </div>
              {domains?.map((item:any, id:number) => {
                return (
                  <div
                    key={item?.domainName + id}
                    className="mb-3 flex items-center justify-between rounded-[32px] border border-grey-100 bg-white sm:px-4 sm:py-5 px-2 py-3"
                  >
                    <div className="flex gap-1 sm:gap-2">
                      <LanguageSVG />
                      <p className="text-base font-medium text-grey-900">
                        {item?.domainName}
                      </p>
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="sm:w-[105px] w-[80px] cursor-pointer">
                        <Web23Button
                          text="Choose"
                          size="sm"
                          onClick={() => {
                            setDomain(item?.domainName);
                            setShowConfirmModal(true);
                          }}
                          disabled={item?.domainName === domain ? true : false}
                          variant={item?.domainName === domain ? "secondary" : "primary"}
                        />
                      </div>
                      <div className="sm:w-[105px] w-[80px]">
                        <a 
                          href={`${settings.network === 'mainnet' ? "https://app.web23.io": "https://internaltesting.web23.io"}`}
                            className="text-base font-bold text-grey-900 px-6 py-4 rounded-[32px] border-[2px] border-black">
                          Manage
                        </a>
                      </div>
                      <div className="flex items-center justify-center w-10 h-10 rounded-full bg-grey-100">
                        <ArrowSVG className="-rotate-90 fill-grey-900" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-center mt-3">
            <div className="relative w-[640px]">
              {loading && (
                <div className="absolute left-[50%] top-3">
                  <Web23Spinner />
                </div>
              )}
              <p className="mb-3 text-xl font-bold text-grey-900">
                Your Web2 Domains
              </p>
              <div className="flex flex-col mb-8 sm:flex-row">
                <div className="flex items-center gap-1 p-2 border-b border-grey-900">
                  <p className="text-base font-bold text-green-700">
                    Your Domains
                  </p>
                  <p className="flex items-center justify-center w-6 h-6 text-sm font-bold text-green-700 bg-green-300 rounded-full">
                    {web2Domains?.length || 0}
                  </p>
                </div>
              </div>
              {web2Domains?.map((item:any, id:number) => {
                return (
                  <div
                    key={item+ id}
                    className="mb-3 flex items-center justify-between rounded-[32px] border border-grey-100 bg-white sm:px-4 sm:py-5 px-2 py-3"
                  >
                    <div className="flex gap-1 sm:gap-2">
                      <LanguageSVG />
                      <p className="text-base font-medium text-grey-900">
                        {item}
                      </p>
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="sm:w-[105px] w-[80px] cursor-pointer">
                        <Web23Button
                          text="Choose"
                          size="sm"
                          onClick={() => {
                            setDomain(item);
                            setShowConfirmModal(true);
                          }}
                          disabled={item === domain ? true : false}
                          variant={item === domain ? "secondary" : "primary"}
                        />
                      </div>
                      <div className="sm:w-[105px] w-[80px]">
                        <a 
                          href="https://www.godaddy.com"
                            className="text-base font-bold text-grey-900 px-6 py-4 rounded-[32px] border-[2px] border-black">
                          Manage
                        </a>
                      </div>
                      <div className="flex items-center justify-center w-10 h-10 rounded-full bg-grey-100">
                        <ArrowSVG className="-rotate-90 fill-grey-900" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      </div>
      {showConfirmModal && (
        <Web23Modal>
          <div className="w-[480px] rounded-2xl bg-white p-6 shadow-lg">
            <CloseIconSVG
              className="mb-3 ml-auto duration-150 bg-white rounded-full cursor-pointer hover:bg-grey-300"
              onClick={() => setShowConfirmModal(false)}
            />
            <p className="mb-2 text-[32px] font-bold text-grey-900">
              Are you sure you want to use{" "}
              <span className="text-grey-600">${domain}</span> for your smart
              page account?
            </p>
            <p className="mb-6 text-base font-medium text-grey-600">
              This is the last step for creating your account.
            </p>
            <Web23Button
              text="Confirm"
              size="sm"
              onClick={async () => {
                if (domain) {
                  const baseUrl = `${API_ENDPOINT_URL}auth/createWithDomain`;
                  const { data } = await axios.post(`${baseUrl}`, {
                    walletAddress: settings.walletAddress,
                    walletStatus: "true",
                    walletName: settings.walletName,
                    domainName: domain,
                    displayName: settings.displayName,
                    email: settings.email,
                    birthDay: settings.birthDay,
                    phoneNumber: settings.phoneNumber,
                    password: settings.password,
                    countryCode: 1,
                  });
                  saveSettings({
                    ...settings,
                    userId: data.id,
                    domain: domain,
                  });
                  navigate("/create-account?final=true");
                } else {
                  setDomain("");
                  setShowConfirmModal(false);
                }
              }}
            />
            <p
              className="py-1 text-sm font-bold text-center underline cursor-pointer text-grey-900"
              onClick={() => {
                setDomain("");
                setShowConfirmModal(false);
              }}
            >
              Cancel
            </p>
          </div>
        </Web23Modal>
      )}
      {
        showWeb2DomainModal && (
          <Web23Modal>
            <div className="w-[480px] rounded-2xl bg-white p-6 shadow-lg relative">
              <CloseIconSVG
                className="mb-3 ml-auto duration-150 bg-white rounded-full cursor-pointer hover:bg-grey-300"
                onClick={() => setShowWeb2DomainModal(false)}
              />
              <p className="mb-2 text-[32px] font-bold text-grey-900">
                Choose your Web2 domain.
              </p>
              <div className="mb-3">
                <Web23Input 
                  placeholder="Godaddy key"
                  className="w-full"
                  value={gKey}
                  onChange={(e) => setGKey(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <Web23Input 
                  placeholder="Godaddy secret"
                  className="w-full"
                  value={gSecret}
                  onChange={(e) => setGSecret(e.target.value)}
                />
              </div>
              <Web23Button
                text="Ok"
                size="sm"
                onClick={async () => {
                  await fetchWeb2Domain();
                  setShowWeb2DomainModal(false);
                }}
              />
              <p
                className="py-1 text-sm font-bold text-center underline cursor-pointer text-grey-900"
                onClick={() => {
                  setDomain("");
                  setShowWeb2DomainModal(false);
                }}
              >
                Cancel
              </p>
              {loading && (
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                  <Web23Spinner />
                </div>
              )}
            </div>
          </Web23Modal>
        )
      }
    </>
  );
};

export default ConfigDomainPage;
